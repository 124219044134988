import React from 'react';
import PropTypes from 'prop-types';
import 'src/assets/css/availableResource.scss';

const AvailableResources = ({ availableResources }) => {
  return (
    <div className="available-resource__container">
      {availableResources ? (
        <div className="available-resource__inner">
          {availableResources.map(availableResourcesInfo => (
            <div
              className="available-resource__content"
              key={availableResourcesInfo.key}
            >
              <div className="available-resource__row">
                <div className="available-resource__column">
                  <img
                    className="available-resource__image"
                    src="images/user-sample.jpeg"
                    alt=""
                  />
                </div>
              </div>
              <div className="available-resource__row">
                <div className="available-resource__column">
                  <span className="available-resource__label">Name</span>
                  <span className="available-resource__item">
                    {availableResourcesInfo.name}
                  </span>
                </div>
                <div className="available-resource__column">
                  <span className="available-resource__label">Designation</span>
                  <span className="available-resource__item">
                    {availableResourcesInfo.designation}
                  </span>
                </div>
                <div className="available-resource__column">
                  <span className="available-resource__label">
                    Previous Project
                  </span>
                  <span className="available-resource__item">
                    {availableResourcesInfo.previousProject}
                  </span>
                </div>
                <div className="available-resource__column">
                  <span className="available-resource__label">Idle Months</span>
                  <span className="available-resource__item">
                    {availableResourcesInfo.idleMonths}
                  </span>
                </div>
              </div>
              <div className="available-resource__row">
                <div className="available-resource__column">
                  <span className="available-resource__label">Skills</span>
                  {availableResourcesInfo.skills.map(skills => (
                    <span className="available-resource__item" key={skills.key}>
                      {skills}
                    </span>
                  ))}
                </div>
              </div>
              <div className="available-resource__row">
                <div className="available-resource__column">
                  <span className="available-resource__label available-resource-hidden__label" />
                  {availableResourcesInfo.ratings.map(rate => (
                    <span className="available-resource__item" key={rate.key}>
                      {rate}
                    </span>
                  ))}
                </div>
              </div>
              <div className="available-resource__row">
                <div className="available-resource__column">
                  <span className="available-resource__label">
                    Previous Project
                  </span>
                  {availableResourcesInfo.previousProjects.map(prevProjects => (
                    <span
                      className="available-resource__item"
                      key={prevProjects.key}
                    >
                      {prevProjects}
                    </span>
                  ))}
                </div>
              </div>
              <div className="available-resource__row">
                <div className="available-resource__column">
                  <span className="available-resource__label available-resource-hidden__label" />
                  {availableResourcesInfo.projectTimespan.map(timespan => (
                    <span
                      className="available-resource__item"
                      key={timespan.key}
                    >
                      {timespan}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

AvailableResources.propTypes = {
  availableResources: PropTypes.arrayOf(PropTypes.string)
};

export default AvailableResources;
