import axios from 'axios';
import { store } from '../../redux/v1';

export const ENV = {
  test: 'http://api-hris.test-web.asia'
};

export const api = axios.create({
  baseURL: ENV.test
});

export const auth = headers => {
  const { user } = store.getState();

  return {
    headers: {
      Authorization: `Bearer ${user.user.token}`,
      ...headers
    }
  };
};
