import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toHoursMinsSecs } from 'src/helpers/utils';

const Timer = ({ duration, startTimer, submitFinishedTimer = () => {} }) => {
  const [runningDuration, setRunningDuration] = useState(60);
  const timeStr = toHoursMinsSecs(runningDuration);
  const [h, m, s] = timeStr.split(':');

  useEffect(() => {
    const interval = setInterval(() => {
      if (runningDuration > 0 && startTimer) {
        setRunningDuration(runningDuration - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [runningDuration, startTimer]);

  useEffect(() => {
    if (duration <= 0 && startTimer) {
      submitFinishedTimer();
    } else {
      setRunningDuration(duration * 60);
    }
  }, [startTimer, duration]);

  useEffect(() => {
    if (runningDuration === 0 && startTimer) {
      submitFinishedTimer();
    }
  }, [runningDuration]);

  return (
    <div className="relative w-40 text-[14px] font-stolzlBold before:absolute before:top-0 before:left-[-30px] before:content-[url('/src/assets/icons/clock.svg')]">
      {h}hr {m}mins {s}secs
    </div>
  );
};

Timer.propTypes = {
  duration: PropTypes.number,
  startTimer: PropTypes.bool,
  submitFinishedTimer: PropTypes.func
};

export default Timer;
