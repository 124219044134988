import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UserEvaluationDetails from 'src/components/Parts/Evaluation/UserEvaluationDetails';
import 'src/assets/css/generalEvaluationSection.scss';
import 'src/assets/css/userEvaluation.scss';
import { getUserEvaluation } from 'src/redux/modules/users/userActions';
import EvaluationSectionTitle from 'src/components/Common/EvaluationSectionTitle';
import EvaluationSubSectionTitle from 'src/components/Common/EvaluationSubSectionTitle';

const UserEvaluation = () => {
  const { id } = useParams();
  const { user } = useSelector(state => state.user.selectedUserDetails);
  const dispatch = useDispatch();
  const [userEvaluation, setUserEvaluation] = useState(null);
  const templateName = userEvaluation?.eval_form_name || null;
  const scores = [];

  const fetchUserEvaluation = async () => {
    try {
      const { data, success } = await dispatch(getUserEvaluation(id));
      if (success) {
        setUserEvaluation(data.evaluation);
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  useEffect(() => {
    fetchUserEvaluation();
  }, []);

  const userDetails = [
    {
      id: userEvaluation?.user?.id || '-',
      name: `${user.first_name} ${user.last_name}` || '-',
      jobTitle: user?.position || '-',
      latestProject: userEvaluation?.project?.name || '-',
      salaryGrade: userEvaluation?.salary_grades?.type || '-'
    }
  ];

  return (
    <div className="user-evaluation__container">
      <h1 className="user-evaluation__heading">Evaluations</h1> <br />
      <h2 className="user-evaluation__heading__inner">
        {' '}
        Operation Template({templateName || '-'})
      </h2>
      <br />
      <div className="user-evaluation__wrapper">
        <div className="user-evaluation__wrapper_inner">
          <UserEvaluationDetails userDetails={userDetails} />

          <div className="boss-evaluation__contents">
            {userEvaluation ? (
              <div>
                {userEvaluation.user_evaluation_section
                  ?.sort((a, b) => (a?.id || 0) - (b?.id || 0))
                  .map(data => (
                    <div className="user-evaluation-section" key={data.id}>
                      <div className="general-evaluation-flex">
                        <div className="general-evaluation-section__container">
                          <EvaluationSectionTitle
                            title={data.form_name}
                            weight="50%"
                          />
                          <div className="general-evaluation-sub-section-actual-score">
                            {userEvaluation.user_evaluation_status.name ===
                            'Done'
                              ? `- ${data.actual_score}`
                              : ''}
                          </div>
                        </div>
                      </div>

                      {data.user_evaluation_sub_section?.map(subSectionData => (
                        <div
                          className="general-evaluation-sub-section"
                          key={subSectionData.id}
                        >
                          <div className="general-evaluation-flex">
                            <EvaluationSubSectionTitle
                              title={subSectionData.form_name}
                              weight="50%"
                            />
                            <div className="general-evaluation-sub-section-actual-score">
                              {userEvaluation.user_evaluation_status.name ===
                              'Done'
                                ? `- ${subSectionData.actual_score}`
                                : ''}
                            </div>
                          </div>

                          <div className="general-evaluation-sub-section__content--title">
                            <div className="general-evaluation-sub-section__column-title--1">
                              <div className="general-evaluation-sub-section__label">
                                Self Score
                              </div>
                            </div>
                            <div className="general-evaluation-sub-section__column-title--2">
                              <div className="general-evaluation-sub-section__label">
                                Boss Score
                              </div>
                            </div>
                          </div>
                          {subSectionData.user_evaluation_field
                            ?.sort((a, b) => (a?.id || 0) - (b?.id || 0))
                            ?.map(evaluationField => {
                              scores.push(evaluationField?.boss_score);
                              return (
                                <div
                                  className="general-evaluation-sub-section__content"
                                  key={evaluationField.id}
                                >
                                  <div className="general-evaluation-sub-section__row">
                                    <div className="general-evaluation-sub-section__column">
                                      <div className="general-evaluation-sub-section__label">
                                        {evaluationField.field.title}
                                        <i className="img__description" />
                                        <div className="general-evaluation-sub-section__description">
                                          <div className="tooltip__description">
                                            {evaluationField.field.description}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="general-evaluation-sub-section__row">
                                    <div className="general-evaluation-sub-section__column">
                                      {/* <div className="general-evaluation-sub-section__label weight__bold">{`${evaluationField.weight_percentage}%`}</div> */}
                                    </div>
                                  </div>

                                  <div className="general-evaluation-sub-section__row--wrapper">
                                    <div className="general-evaluation-sub-section__row">
                                      <div className="general-evaluation-sub-section__column">
                                        <div className="general-evaluation-sub-section__item">
                                          <div className="general-evaluation-sub-section__item user-score">
                                            {evaluationField.self_score}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="general-evaluation-sub-section__row">
                                      <div className="general-evaluation-sub-section__column">
                                        <div className="general-evaluation-sub-section__item center">
                                          <div className="boss-score">
                                            {evaluationField.boss_score}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            ) : (
              <div className="user-evaluation-section">
                <div className="general-evaluation-section__container">
                  <EvaluationSectionTitle
                    title="NO RECORD FOUND!"
                    weight="50%"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEvaluation;
