import { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';

const useSearch = ({ form, submitFilter }) => {
  const { control } = form;
  const [searchValue, setSearchValue] = useState('');
  const searchValueCapitalize =
    searchValue.charAt(0).toUpperCase() + searchValue.slice(1);

  useWatch({
    control,
    name: 'search',
    defaultValue: 'all'
  });

  const handleInputChange = useCallback(
    event => {
      setSearchValue(event.target.value);
    },
    [setSearchValue]
  );

  const handleSubmit = event => {
    event.preventDefault();
    form.setValue('search', searchValueCapitalize);
    submitFilter(searchValueCapitalize);
  };

  return {
    searchValue,
    handleInputChange,
    handleSubmit
  };
};

export default useSearch;
