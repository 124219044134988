import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllUsersService } from 'src/api/modules/user';
import {
  getLowerCasedValue,
  filterData,
  updateObjectById,
  deleteObjectsById
} from 'src/helpers/utils';
import { STATUS_OPTIONS } from 'src/helpers/constants';
import { setUserListUpdate } from 'src/redux/modules/users/userActions';
import { setIdDeletion } from 'src/redux/modules/datatable/datatableActions';

const useUsersIndex = () => {
  const mounted = useRef(true);
  const dispatch = useDispatch();
  const { isListUpdated } = useSelector(state => state.user);
  const { deletedIds } = useSelector(state => state.datatable);

  const [showModal, setShowModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();

  const filterOptions = [
    {
      value: 'system_admin',
      label: 'System Admin'
    }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status'
    },
    {
      type: 'checkbox',
      options: filterData(filterOptions, 'label'),
      name: 'is_system_admin'
    }
  ];

  const userListMemo = useMemo(() => {
    return userList ?? [];
  }, [userList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-users-search-params', searchParams);
  };

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-users-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-users-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);
      const res = await getAllUsersService(savedPageIndex, params);
      if (mounted.current) {
        const newList =
          savedPageIndex !== 1
            ? userList.concat(res.data.items)
            : res.data.items;
        setUserList(newList);

        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(savedPageIndex + 1);
        }
      }
    } catch (error) {
      if (mounted.current) {
        toast.error(`Error fetching data: ${error.message}`);
      }
    }
  }, [page, getAllUsersService, setUserList, setPage, setHasMore, userList]);

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setUserId(modal ? id : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setUserId]
  );

  const handleViewModal = useCallback(
    (modal, id = null, data = null) => {
      setShowViewModal(modal);
      setUserId(modal ? id : null);
      setUserData(modal ? data : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setUserId, setUserData]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, first_name, last_name, suffix }) => {
      const name = `${first_name || ''} ${last_name || ''} ${suffix || ''} `;
      setIsOpen(!!modal);
      setShowDeleteModal(modal);
      setUserId(modal ? id : null);
      setUserName(modal ? name : null);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setUserId, setUserName]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  useEffect(() => {
    const savedPageIndex = localStorage.getItem('hris-users-page-no');
    mounted.current = true;

    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }

    pushQuery({ status: 'A' });

    return () => {
      // Set mounted to false when the component is unmounted
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isListUpdated) {
      const rerenderImageUpdate = {
        ...isListUpdated.payload,
        image: isListUpdated.payload.image
          ? `${isListUpdated.payload.image}?${Date.now()}`
          : ''
      };
      const updatedObjects = updateObjectById(
        userList,
        isListUpdated.id,
        rerenderImageUpdate
      );
      setUserList(updatedObjects);
      dispatch(setUserListUpdate(false));
    }
  }, [isListUpdated]);

  useEffect(() => {
    if (deletedIds) {
      const updatedList = deleteObjectsById(userListMemo, deletedIds);
      setUserList(updatedList);
      dispatch(setIdDeletion(null));
    }
  }, [deletedIds]);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-users-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('hris-users-page-no', JSON.stringify(page));
  }, [page]);

  return {
    showModal,
    showViewModal,
    showDeleteModal,
    showBulkDeleteModal,
    userId,
    userData,
    userName,
    userList,
    userListMemo,
    page,
    hasMore,
    inputs,
    form,
    isOpen,
    submitFilter,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    handleBulkDeleteModal,
    fetchData
  };
};

export default useUsersIndex;
