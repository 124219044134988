/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Button from 'src/components/Common/Button';
import { useUserAccess } from 'src/hooks/useUserAccess';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import { useNavigate } from 'react-router-dom';

const AddQuiz = ({
  quizId,
  handleChange,
  handleNumberInput,
  handleQuizSubmit,
  viewOnly,
  menuCode,
  quizFields,
  validationQuizSchema,
  statusOptions,
  handleSearchSkills,
  searchSkill,
  handleAddSkill,
  addedSkill,
  skillsRef,
  handleDeleteSkill
}) => {
  const classDisabled = viewOnly ? 'bg-[#f7f7f7]' : 'bg-white';
  const viewEditTitle = viewOnly ? 'View' : 'Edit';
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={quizFields}
      validationSchema={validationQuizSchema}
      onSubmit={() => handleQuizSubmit(quizId)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="addQuizForm">
          <div className="border-solid border-[1px] border-[#dedede] p-[31px_30px_28px_30px] rounded-[3px] mb-5">
            <div className="flex justify-between w-auto pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {!quizId ? 'Add Quiz' : `${viewEditTitle} Quiz Details`}
              </h4>
              {viewOnly ? (
                <div>
                  <Button
                    name="Questions"
                    modifier="bg-[#FFFFFF] border border-solid border-1 text-[#222222] p-[10px_14px] text-[12px] leading-unset rounded mr-[10px] hover:bg-gray-700"
                    spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/list.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/quiz-manager/${quizId}/questions`);
                    }}
                    disabled={
                      isSubmitting ||
                      (quizId && !access.can_edit) ||
                      !access.can_add
                    }
                  />
                  <Button
                    name="Edit"
                    modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[100px] hover:bg-gray-500"
                    spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                    type="submit"
                    disabled={!access.can_edit}
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/quiz-manager/edit/${quizId}`);
                    }}
                  />
                </div>
              ) : (
                <Button
                  name="Save"
                  modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[100px] hover:bg-gray-500"
                  spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    (quizId && !access.can_edit) ||
                    !access.can_add
                  }
                />
              )}
            </div>
            <div className="flex">
              <div className="w-[1090px] max-w-full">
                <div className="grid grid-cols-2 flex gap-5">
                  {/* 1st column */}
                  <div className="w-full">
                    <div className="w-full">
                      <FormCard>
                        <FormField
                          label="Title"
                          required
                          name="title"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={quizFields.title ?? ''}
                          error={errors.title && touched.title}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Duration"
                          required
                          name="duration"
                          type={viewOnly ? 'text' : 'number'}
                          placeholder=""
                          errorMessage="Field Required"
                          value={quizFields.duration ?? ''}
                          error={errors.duration && touched.duration}
                          onChange={e => handleNumberInput(e)}
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="duration"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                      <div className="">
                        <FormField
                          className="mb-[22px]"
                          label="Status"
                          type="text"
                          name="status"
                          value={
                            statusOptions?.filter(
                              data => data.value === quizFields.status
                            )?.[0]?.label ?? ''
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <div>
                        <FormCard>
                          <FormField
                            label="Code"
                            required
                            name="code"
                            type="text"
                            placeholder=""
                            errorMessage="Field Required"
                            value={quizFields.code ?? ''}
                            error={errors.code && touched.code}
                            onChange={e =>
                              handleChange(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError,
                                false
                              )
                            }
                            disabled={viewOnly}
                          />
                          <ErrorMessage
                            name="code"
                            component="div"
                            className="text-[10px] text-[#E43B26] font-stolzlBook"
                          />
                        </FormCard>
                      </div>
                    </div>
                    <div className="w-full">
                      <label className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                        Description
                        <span className="text-[#E43B26]">*</span>
                      </label>
                      <textarea
                        className={`${
                          errors.description
                            ? 'border-[#e43b26] placeholder-[#e43b26] text-[10px]'
                            : 'border-[#eaeaea]'
                        } ${classDisabled} block w-full border-solid border-[1px] rounded text-[14px] text-[#222222] font-normal font-stolzlBook leading-[20px] h-[110px] px-3 py-[5px] resize-none`}
                        name="description"
                        value={quizFields?.description ?? ''}
                        onChange={e =>
                          handleChange(
                            e,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        disabled={viewOnly}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-[10px] text-[#E43B26] font-stolzlBook"
                      />
                    </div>
                  </div>
                  {/* 2nd column */}
                  <div className="w-full">
                    <div className="mb-4 relative">
                      <label
                        className="block mb-2.5 text-[12px] text-[#414141] leading-[14px] font-stolzlBook"
                        htmlFor="skills"
                      >
                        Skills
                        <div className="relative">
                          <input
                            className={`block w-full bg-white border-solid border-[1px] mt-[5px] ${
                              errors.skills
                                ? 'border-[#E43B26] placeholder-[#E43B26]'
                                : 'border-[#eaeaea] text-[14px]'
                            } rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[10px_10px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                            type="text"
                            name="skills"
                            id="skills"
                            ref={skillsRef}
                            placeholder="Search"
                            autoComplete="off"
                            onChange={e =>
                              handleSearchSkills(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            disabled={viewOnly}
                          />
                        </div>
                      </label>
                      {searchSkill.length ? (
                        <div className="absolute top-62px left-0 w-full bg-white z-20 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                          <ul className="max-h-40 overflow-auto custom-scrollbar">
                            {searchSkill.map((item, index) => (
                              <li
                                // eslint-disable-next-line react/no-array-index-key
                                key={item.id}
                                className="flex items-center text-[14px] text-[#222222] font-normal leading-[14px] h-10 px-2.5 hover:bg-[#E8F1FF60] ease duration-200 capitalize font-stolzlBook"
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                role="button"
                                tabIndex={0}
                                onClick={() => handleAddSkill(item)}
                                onKeyDown={event => {
                                  if (
                                    event.key === 'Enter' ||
                                    event.key === ' '
                                  ) {
                                    handleAddSkill(item);
                                  }
                                }}
                              >
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      {addedSkill.length ? (
                        <div className="flex gap-2 flex-wrap">
                          {addedSkill.map(skill => (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={skill.id}
                              className="rounded-full text-left text-[12px] leading-[100%] h-[26px] px-5 w-max inline-flex items-center justify-center bg-[#DDDDDD] text-black"
                            >
                              {skill.name}
                              <button
                                type="button"
                                className="ml-1 relative inline-flex items-center justify-center h-4 w-4 rounded-full text-gray-100"
                                onClick={() => handleDeleteSkill(skill.id)}
                                disabled={viewOnly}
                              >
                                <span className="block w-[13px] h-0.5 bg-[#b1b1b1] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-45 rounded" />
                                <span className="block w-[13px] h-0.5 bg-[#b1b1b1] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-[-45deg] rounded" />
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddQuiz;
