import { GET_ADMIN_SALARY_GRADES_LIST } from './salaryGradesTypes';

const INITIAL_STATE = {
  salaryGrades: []
};

export default function salaryGradesReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_ADMIN_SALARY_GRADES_LIST:
      return {
        ...state,
        salaryGrades: payload
      };
    default:
      return state;
  }
}
