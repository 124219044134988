import { useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  takeQuizService,
  saveAnswerService,
  submitAttemptService
} from 'src/api/modules/assessment';
import { toast } from 'react-toastify';
import { CgCheckO, CgCloseO, CgInfo } from 'react-icons/cg';

const useQuiz = assessment => {
  const defaultQuestions = [
    {
      id: 1,
      sequence: 1,
      is_multi_answer: false,
      content: '',
      points: 3,
      answers: [
        { id: 1, content: '', selected: false },
        { id: 2, content: '', selected: false },
        { id: 3, content: '', selected: false },
        { id: 4, content: '', selected: false }
      ]
    }
  ];
  const navigate = useNavigate();
  const myAssessment = useSelector(state => state?.assessment?.myAssessment);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [assessmentAttempt, setAssessmentAttempt] = useState(null);
  const [questions, setQuestions] = useState(defaultQuestions);
  const [section, setSection] = useState(1);
  const [quiz, setQuiz] = useState([]);
  const [quizDuration, setQuizDuration] = useState(0);
  const [startTimer, setStartTimer] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(null);
  const [isFinishedTimer, setIsFinishedTimer] = useState(false);

  const getCurrentQuestion = useMemo(() => {
    return questions.find(q => q.sequence === currentQuestion);
  }, [currentQuestion, questions]);

  const handleOptionChange = useCallback(
    optionId => {
      updateSelectedOptions([optionId]);
      setSelectedOption(optionId);
      saveAnswer(optionId, { ...getCurrentQuestion }, true);
    },
    [selectedOption, questions, currentQuestion]
  );

  const saveAnswer = useCallback(
    async (answerId, question, selected) => {
      try {
        await saveAnswerService(
          assessmentAttempt,
          answerId,
          question,
          selected
        );
      } catch (error) {
        toast.error('Unable to save your answer.', {
          icon: <CgCloseO />
        });
      }
    },
    [assessmentAttempt]
  );

  const submitAttempt = useCallback(async () => {
    try {
      const { quizzes } = assessment;

      await submitAttemptService(assessmentAttempt);

      handleSubmitQuizModal(null);
      setStartTimer(false);
      setIsFinishedTimer(false);

      toast.success('Quiz successfully submitted.', {
        className: 'w-[400px]',
        bodyClassName: '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
        icon: <CgCheckO />,
        closeOnClick: false
      });

      setTimeout(() => {
        if (section < quizzes.length) {
          setSection(prevSection => prevSection + 1);
        } else {
          navigate('/my-assessments');

          setTimeout(() => {
            toast.success(
              `Good job! You have completed the ${assessment.title}.`,
              {
                className: 'w-[400px]',
                bodyClassName:
                  '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
                icon: <CgCheckO />,
                closeOnClick: false,
                autoClose: 6000
              }
            );
          }, 500);
        }
      }, 4000);
    } catch (error) {
      toast.error('Unable to submit quiz.', {
        icon: <CgCloseO />
      });
    }
  }, [assessmentAttempt]);

  const handleOptionCheckboxChange = optionId => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(prevSelectedOptions => {
        const selected = prevSelectedOptions.filter(id => id !== optionId);
        updateSelectedOptions(selected);
        saveAnswer(optionId, { ...getCurrentQuestion }, false);
        return selected;
      });
    } else {
      setSelectedOptions(prevSelectedOptions => {
        const selected = [...prevSelectedOptions, optionId];
        updateSelectedOptions(selected);
        saveAnswer(optionId, { ...getCurrentQuestion }, true);
        return selected;
      });
    }
  };

  const updateSelectedOptions = selected => {
    const questionsList = questions.map(question => {
      if (currentQuestion === question.sequence) {
        const answers = question.answers.map(answer => {
          return {
            ...answer,
            selected: selected.includes(answer.id)
          };
        });

        return {
          ...question,
          answered: selected.length >= 1,
          answers: [...answers]
        };
      }

      return question;
    });
    setQuestions(questionsList);
  };

  const handleDashClick = questionId => {
    setCurrentQuestion(questionId);
    setSelectedOption(null);
    setSelectedOptions([]);
  };

  const handlePrevClick = () => {
    setCurrentQuestion(prevQuestion => Math.max(0, prevQuestion - 1));
    setSelectedOption(null);
    setSelectedOptions([]);
  };

  const handleNextClick = () => {
    setCurrentQuestion(prevQuestion =>
      Math.min(questions.length, prevQuestion + 1)
    );
    setSelectedOption(null);
    setSelectedOptions([]);
  };

  const handleFinishTestClick = () => {
    const answered = questions.filter(question => question.answered);
    if (answered.length < questions.length) {
      toast('Please provide answers to all the questions before submitting.', {
        className: 'w-[400px]',
        bodyClassName: '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
        icon: <CgInfo />
      });
    } else {
      handleSubmitQuizModal('SubmitQuizModal', quiz.id);
    }
  };

  const handleTakeQuizModal = useCallback(
    (modal, id) => {
      setShowConfirmModal(modal);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowConfirmModal]
  );

  const handleSubmitQuizModal = useCallback(
    (modal, id) => {
      setShowConfirmSubmitModal(modal);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowConfirmSubmitModal]
  );

  const takeQuiz = useCallback(async quizId => {
    try {
      const res = await takeQuizService(myAssessment.id, quizId);
      const data = res.data?.data;

      setCurrentQuestion(1);
      handleTakeQuizModal(null);
      setAssessmentAttempt(data);
      const questionsList = data.quiz.questions.map((questionItem, i) => {
        const answers = questionItem.answers.map(answer => {
          return {
            ...answer,
            selected: false
          };
        });

        return {
          ...questionItem,
          sequence: i + 1,
          answers: [...answers]
        };
      });
      setQuestions(questionsList);
    } catch (error) {
      handleCancelTakeQuiz();
      toast.error('Unable to take this Quiz!', {
        icon: <CgCloseO />
      });
    }
  }, []);

  const handleConfirmTakeQuiz = quizId => {
    setCurrentQuestion(1);
    takeQuiz(quizId);
    setStartTimer(true);
  };

  const handleConfirmSubmitQuiz = () => {
    submitAttempt();
  };

  const handleCancelTakeQuiz = () => {
    navigate(`/my-assessments/view/${myAssessment.id}`);
  };

  const handleCancelSubmitQuiz = () => {
    setShowConfirmSubmitModal(null);
  };

  const submitFinishedTimer = () => {
    setIsFinishedTimer(true);
  };

  useEffect(() => {
    if (isFinishedTimer && assessmentAttempt) {
      submitAttempt();
    }
  }, [isFinishedTimer, assessmentAttempt]);

  useEffect(() => {
    if (selectedOptions.length) {
      const questionsList = questions.map(question => {
        if (currentQuestion === question.sequence) {
          const answers = question.answers.map(answer => {
            return {
              ...answer,
              answered: selectedOptions.length >= 1,
              selected: selectedOptions.includes(answer.id)
            };
          });

          return {
            ...question,
            answers: [...answers]
          };
        }

        return question;
      });

      setQuestions(questionsList);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!assessmentAttempt) {
      const { quizzes } = assessment;
      const items = Array.from({
        length: Object.keys(quizzes[section - 1].questions).length
      }).map((u, i) => {
        return {
          ...defaultQuestions[0],
          id: i + 1,
          sequence: i + 1
        };
      });
      setQuestions(items);
    }
  }, []);

  useEffect(() => {
    const { duration, user_attempt } = quiz;

    if (user_attempt?.started_at) {
      const currentTime = moment();
      const startedAt = moment(user_attempt.started_at);
      const elapsedTimeInMilliseconds = currentTime.diff(startedAt);
      const remainingTimeInMinutes = Math.max(
        duration - elapsedTimeInMilliseconds / (1000 * 60),
        0
      );
      setQuizDuration(parseFloat(remainingTimeInMinutes.toFixed()));
    } else {
      setQuizDuration(duration);
    }
  }, [quiz, assessmentAttempt]);

  useEffect(() => {
    handleTakeQuizModal('TakeQuizModal');
  }, [quizDuration, quiz.id]);

  useEffect(() => {
    const { quizzes } = assessment;

    if (section <= quizzes.length) {
      const currentQuiz = quizzes[section - 1];

      if (
        currentQuiz.user_attempt?.finished_at &&
        currentQuiz.user_attempt?.score !== undefined
      ) {
        setSection(prevSection => prevSection + 1);
      } else {
        setQuiz(currentQuiz);
      }
    }
  }, [assessment, section]);

  return {
    questions,
    currentQuestion,
    selectedOption,
    selectedOptions,
    getCurrentQuestion,
    handleDashClick,
    handleNextClick,
    handleOptionChange,
    handleOptionCheckboxChange,
    handlePrevClick,
    assessmentAttempt,
    section,
    quizDuration,
    quiz,
    showConfirmModal,
    showConfirmSubmitModal,
    handleTakeQuizModal,
    handleConfirmTakeQuiz,
    handleCancelTakeQuiz,
    handleFinishTestClick,
    handleSubmitQuizModal,
    handleConfirmSubmitQuiz,
    handleCancelSubmitQuiz,
    startTimer,
    submitFinishedTimer
  };
};

export default useQuiz;
