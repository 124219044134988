import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filterData } from 'src/helpers/v1/utils';
import PageTitle from 'src/components/v1/Common/PageTitle';
import SearchFilter from 'src/components/v1/Common/SearchFilter';
import { getAllUsers } from 'src/redux/v1/modules/users/userActions';
import { getProjectListAction } from 'src/redux/v1/modules/projects/projectActions';

const Projects = () => {
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.user);
  const { projectList } = useSelector(state => state.projects);

  const [usersData, setUsersData] = useState([{}]);
  const [projectData, setProjectData] = useState([
    {
      id: 0,
      name: '',
      code: '',
      technicalDirector: '',
      projectManager: '',
      projectManagerCebu: '',
      manPower: 0,
      projectStart: '',
      projectEnd: '',
      member: ''
    }
  ]);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getProjectListAction());
  }, []);

  useEffect(() => {
    if (users?.length > 0) {
      setUsersData(
        users.map(user => {
          return { fullname: `${user.first_name} ${user.last_name}` };
        })
      );
    }

    if (projectList?.length > 0) {
      setProjectData(
        projectList.map(project => {
          if (!project.Members.length) {
            return {
              id: project.id,
              name: project.name,
              code: project.project_code?.toString() || '',
              technicalDirector: project.TechnicalDirector
                ? project.TechnicalDirector.full_name?.toString() || ''
                : '',
              projectManager: project.ProjectManager
                ? project.ProjectManager.full_name?.toString() || ''
                : '',
              projectManagerCebu: project.ProjectManagerCebu
                ? project.ProjectManagerCebu.full_name?.toString() || ''
                : '',
              manPower: project.manpower,
              projectStart: project.start_date,
              projectEnd: project.end_date?.toString() || '',
              member: ''
            };
          }
          let members = '';
          project.Members.forEach(member => {
            if (member.User) {
              members = `${member.User.full_name}, ${members}`;
            }
          });

          return {
            id: project.id,
            name: project.name,
            code: project.project_code?.toString() || '',
            technicalDirector: project.TechnicalDirector
              ? project.TechnicalDirector.full_name?.toString() || ''
              : '',
            projectManager: project.ProjectManager
              ? project.ProjectManager.full_name?.toString() || ''
              : '',
            projectManagerCebu: project.ProjectManagerCebu
              ? project.ProjectManagerCebu.full_name?.toString() || ''
              : '',
            manPower: project.manpower,
            projectStart: project.start_date,
            projectEnd: project.end_date?.toString() || '',
            member: members
          };
        })
      );
    }
  }, [users, projectList]);

  const scheduleColumns = [
    {
      key: 'name',
      label: 'Project Name'
    },
    {
      key: 'technicalDirector',
      label: 'Technical Director'
    },
    {
      key: 'projectManager',
      label: 'Project Manager'
    },
    {
      key: 'manPower',
      label: 'Manpower'
    },
    {
      key: 'projectStart',
      label: 'Assign Start Date'
    },
    {
      key: 'projectEnd',
      label: 'Assign End Date'
    }
  ];

  return (
    <div className="projects">
      <PageTitle title="PROJECTS" />
      <SearchFilter
        searchInputPlaceholder="Search Project"
        buttonName="Project"
        inputs={[
          {
            label: 'Technical Director',
            type: 'dropdown',
            options: filterData(usersData, 'fullname'),
            name: 'technicalDirector'
          },
          {
            label: 'Project Manager',
            type: 'dropdown',
            options: filterData(usersData, 'fullname'),
            name: 'projectManager'
          },
          {
            label: 'Start Date',
            type: 'datepicker',
            name: 'projectStart'
          },
          {
            label: 'End Date',
            type: 'datepicker',
            name: 'projectEnd'
          }
        ]}
        buttonLink="/project/add"
        title="Project List"
        data={projectData}
        columns={scheduleColumns}
      />
    </div>
  );
};

export default Projects;
