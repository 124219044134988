import { memo, useState, useEffect, useRef, useCallback } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isEqual } from 'src/helpers/v1/utils';
import { SECTIONS } from 'src/api/v1/endpoints';
import { evaluatorLevel } from 'src/static/v1/evaluation';
import {
  createEvaluationTemplate,
  updateEvaluationTemplateById,
  deleteEvaluationTemplateById
} from 'src/redux/v1/modules/evaluations/templates/templatesActions';
import Box from 'src/components/v1/Common/Box';
import KebabMenu from 'src/components/v1/Common/KebabMenu';
import useKeypressEscape from 'src/hooks/v1/useKeypressEscape';
import LabeledText from 'src/components/v1/Common/LabeledText';
import PropTypes from 'prop-types';
import TemplateSubSectionList from './TemplateSubSectionList';
import TemplateScoreWeights from './TemplateScoreWeights';
import {
  numberOnly,
  setScoreWeights,
  getScoreWeights,
  detectSalaryGrade,
  getEvaluationLevel
} from './TemplateHelpers';

const TemplateSectionItem = ({
  sectionIndex,
  sectionFields,
  isEditTemplate,
  setSectionFields
}) => {
  const dispatch = useDispatch();
  const sectionRef = useRef(null);
  const sections = [...sectionFields];
  const section = sections[sectionIndex];
  const [copy, setCopy] = useState(null);
  const isNewSection = section?.isNewSection;
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditSection, setIsEditSection] = useState(true);
  const [sectionWeights, setSectionWeights] = useState(null);
  const [subSectionFields, setSubSectionFields] = useState([]);

  useKeypressEscape({
    isEdit: isEditSection && !isEditTemplate,
    cancelEdit: () => {
      if (isNewSection) {
        section.title = '';
        section.description = '';
        section.evaluator_level = 1;
        setSectionFields(sections);
        sectionRef.current.focus();
      }

      if (!isNewSection) {
        if (!isNewSection && sectionID !== null && sectionID !== undefined) {
          const {
            id,
            isEdit,
            evaluation_form_id,
            sub_sections,
            ...sectionFormData
          } = section;
          if (!isEqual(sectionFormData, copy)) {
            toast.warn('Changes not saved! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
            section.title = copy?.title;
            section.description = copy?.description;
            section.evaluator_level = copy?.evaluator_level;
            section.g1 = copy?.g1;
            section.g2 = copy?.g2;
            section.g3 = copy?.g3;
            section.sg1 = copy?.sg1;
            section.sg2 = copy?.sg2;
            section.sg3 = copy?.sg3;
            setSectionFields(sections);
          }
        }
        setIsEditSection(false);
      }
    }
  });

  useEffect(() => {
    if (isNewSection) sectionRef?.current.focus();
    copyData();
    const { g1, g2, g3, sg1, sg2, sg3 } = section;
    const weights = { g1, g2, g3, sg1, sg2, sg3 };
    setSectionWeights(Object.values(weights));
  }, []);

  useEffect(() => {
    setIsEditSection(isNewSection);

    const subSectionsAPI = section?.sub_sections;
    const subSectionsFieldsAPI = subSectionsAPI && [
      ...subSectionsAPI.map(
        (
          {
            id,
            sub_section_name,
            sub_section_description,
            sub_section_weights,
            ...rest
          },
          _
        ) => ({
          id,
          title: sub_section_name,
          description: sub_section_description,
          evaluation_form_id,
          isNewSubSection: false,
          evaluation_section_id: parseInt(section?.id, 10),
          g1: getScoreWeights('G1', sub_section_weights),
          g2: getScoreWeights('G2', sub_section_weights),
          g3: getScoreWeights('G3', sub_section_weights),
          sg1: getScoreWeights('SG1', sub_section_weights),
          sg2: getScoreWeights('SG2', sub_section_weights),
          sg3: getScoreWeights('SG3', sub_section_weights),
          ...rest
        })
      )
    ];

    if (subSectionsFieldsAPI?.length) {
      const sorted = subSectionsFieldsAPI?.sort((a, b) => a.id - b.id);
      setSubSectionFields(sorted);
    }
  }, [section, isNewSection]);

  async function handleRemoveSection(sectionID) {
    try {
      const { success } = await dispatch(
        deleteEvaluationTemplateById(SECTIONS, sectionID)
      );
      if (success) {
        toast.success('Section successfully deleted! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
        setSectionFields(sections?.filter(sec => sec?.id !== sectionID));
      } else {
        toast.error('Something went wrong! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSaveLoading(true);

    const { sub_sections, ...sectionFormData } = section;
    const {
      id,
      title,
      description,
      evaluation_form_id,
      evaluator_level,
      isNewSec
    } = sectionFormData;
    const weightData = { g1, g2, g3, sg1, sg2, sg3 };

    if (isNewSec && id === undefined) {
      try {
        const payload = {
          evaluation_form_id,
          title,
          description,
          evaluator_level
        };
        const { data, success } = await dispatch(
          createEvaluationTemplate(SECTIONS, payload)
        );
        if (success) {
          toast.success('Section successfully created! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          section.id = data?.id;
          section.isNewSec = false;
          setSectionFields(sections);
          setSaveLoading(false);
          setIsEditSection(false);
          copyData();
          setScoreWeights(evaluation_form_id, 'section', data?.id, {
            ...weightData
          });
        } else {
          toast.error('Something went wrong! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
        }
      } catch (error) {
        return error;
      }
    }

    if (!isNewSec) {
      const weightCopy = {
        g1: copy?.g1,
        g2: copy?.g2,
        g3: copy?.g3,
        sg1: copy?.sg1,
        sg2: copy?.sg2,
        sg3: copy?.sg3
      };
      const formData = { title, description, evaluator_level, ...weightData };

      if (isEqual(formData, copy)) {
        setSaveLoading(false);
        setIsEditSection(false);
      } else {
        try {
          const payload = { title, description, evaluator_level };
          const { success } = await dispatch(
            updateEvaluationTemplateById(SECTIONS, id, payload)
          );
          if (success) {
            toast.success('Section successfully updated! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
            setSaveLoading(false);
            setIsEditSection(false);
            copyData();
            if (isEqual(weightData, weightCopy)) return undefined;
            setScoreWeights(evaluation_form_id, 'section', sectionID, {
              ...weightData
            });
          } else {
            toast.error('Something went wrong! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
          }
        } catch (error) {
          return error;
        }
      }
    }

    return undefined;
  }

  const handleChange = useCallback(({ target: { name, value, maxLength } }) => {
    const getValue = numberOnly(name) ? Number(value) : value;
    section[name] = getValue;
    setSectionFields(sections);

    if (detectSalaryGrade(name)) {
      const integer = Number(value.slice(0, maxLength));
      const positiveOnly = integer >= 0 ? integer : 0;
      const hundredOnly = positiveOnly <= 100 ? positiveOnly : 100;
      section[name] = parseInt(hundredOnly, 10);
      setSectionFields(sections);
    }
  });

  function copyData() {
    setCopy({ title, description, evaluator_level, g1, g2, g3, sg1, sg2, sg3 });
  }

  function renderList(list) {
    return list?.map(({ value, label }) => (
      <option key={value} value={value} label={label} />
    ));
  }

  if (!section) return null;

  const {
    id: sectionID,
    title: TITLE,
    description,
    evaluator_level,
    evaluation_form_id,
    g1,
    g2,
    g3,
    sg1,
    sg2,
    sg3
  } = section;
  const title = TITLE === '' ? '' : TITLE;
  const isFilled =
    Boolean(title) &&
    Boolean(description) &&
    Boolean(g1 === 0 ? true : g1) &&
    Boolean(g2 === 0 ? true : g2) &&
    Boolean(g3 === 0 ? true : g3) &&
    Boolean(sg1 === 0 ? true : sg1) &&
    Boolean(sg2 === 0 ? true : sg2) &&
    Boolean(sg3 === 0 ? true : sg3);

  return (
    <div className="evaluation__template-section">
      <Box outline>
        <form onSubmit={handleSubmit}>
          <div className="evaluation__row --nowrap">
            <div className="evaluation__column evaluation__column--two">
              <div className="evaluation__heading">
                <h3>Section {sectionIndex + 1}</h3>
              </div>
            </div>
            <div className="evaluation__column evaluation__column--two reverse">
              {isEditSection ? (
                <div className="evaluation__button evaluation__button--save-section">
                  <button
                    type="submit"
                    className={`button button__save dark evaluation__save-button${
                      !isFilled ? ' disabled' : ''
                    }`}
                    disabled={!isFilled}
                  >
                    <span className="button__text">
                      {saveLoading ? <FaSpinner className="spinner" /> : 'Save'}
                    </span>
                  </button>
                </div>
              ) : (
                <KebabMenu
                  options={[
                    {
                      label: 'Edit Section',
                      onClick: () => setIsEditSection(true)
                    },
                    {
                      label: 'Delete Section',
                      onClick: () => handleRemoveSection(sectionID)
                    }
                  ]}
                  evaluation
                />
              )}
            </div>
          </div>
          <div className="evaluation__row">
            <div className="evaluation__column evaluation__column--two">
              <label
                className="evaluation__label"
                htmlFor={`section_title_${sectionIndex}`}
              >
                Section Name
                {isEditSection ? (
                  <input
                    className="evaluation__input"
                    id={`section_title_${sectionIndex}`}
                    name="title"
                    type="text"
                    value={title}
                    ref={sectionRef}
                    disabled={saveLoading}
                    onChange={handleChange}
                  />
                ) : (
                  <LabeledText text={title} />
                )}
              </label>
            </div>
            <div className="evaluation__column evaluation__column--two two">
              <label
                className="evaluation__label"
                htmlFor={`section_evaluator_level_${sectionIndex}`}
              >
                Evaluator Level
                {isEditSection ? (
                  <select
                    className="evaluation__input evaluation__input--select"
                    id={`section_evaluator_level_${sectionIndex}`}
                    name="evaluator_level"
                    type="text"
                    disabled={saveLoading}
                    onChange={handleChange}
                    value={evaluator_level}
                  >
                    <option disabled label="Evaluator Level" />
                    {renderList(evaluatorLevel)}
                  </select>
                ) : (
                  <LabeledText text={getEvaluationLevel(evaluator_level)} />
                )}
              </label>
            </div>
          </div>

          <div className="evaluation__row">
            <div className="evaluation__column">
              <label
                className="evaluation__label"
                htmlFor="section_description"
              >
                Description
                {isEditSection ? (
                  <textarea
                    className="evaluation__input evaluation__input--textarea"
                    name="description"
                    id="section_description"
                    value={description}
                    onChange={handleChange}
                  />
                ) : (
                  <LabeledText
                    text={
                      description === '' || description === null
                        ? '—'
                        : description
                    }
                    templateDescription
                  />
                )}
              </label>
            </div>
          </div>

          <div className="evaluation__row">
            <div className="evaluation__column evaluation__column--sixty">
              <TemplateScoreWeights
                type="section"
                item={section}
                loading={saveLoading}
                isEdit={isEditSection}
                weights={sectionWeights}
                itemIndex={sectionIndex}
                handleChange={handleChange}
              />
            </div>
          </div>
        </form>

        <TemplateSubSectionList
          sectionID={sectionID}
          sectionIndex={sectionIndex}
          isSectionEdit={isEditSection}
          templateID={evaluation_form_id}
          subSectionFields={subSectionFields}
          setSubSectionFields={setSubSectionFields}
        />
      </Box>
    </div>
  );
};

TemplateSectionItem.propTypes = {
  sectionIndex: PropTypes.number,
  sectionFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditTemplate: PropTypes.bool,
  setSectionFields: PropTypes.func
};

export default memo(TemplateSectionItem);
