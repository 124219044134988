import _ from 'lodash';
import { store } from 'src/redux/';
import { DateTime } from 'luxon';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const isEqual = (a, b) => {
  return _.isEqual(a, b);
};

export const filterData = (array, key, keyValue = null) => {
  return array
    ?.map(value => (!keyValue ? value[key] : [value[key], value[keyValue]]))
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .filter(test => test != null || test !== undefined);
};

export const arrayFillKeys = (keys, value) => {
  const retObj = [];
  Object.keys(keys).forEach(e => {
    retObj[keys[e]] = value;
  });
  return retObj;
};

export const joinValuesByKey = (array, key) => {
  if (!array.length) return '';
  const values = [];
  array.forEach(arr => values.push(arr[key]));
  return values.join(', ');
};

export const formatDate = date => {
  const newDate = new Date(date);
  const year = newDate.toLocaleDateString('default', { year: 'numeric' });
  const month = newDate.toLocaleDateString('default', { month: '2-digit' });
  const day = newDate.toLocaleDateString('default', { day: '2-digit' });
  return `${month}/${day}/${year}`;
};

export const getCostCenter = (type, costCenterCode = null) => {
  const state = store.getState();
  if (!state) return null;
  const costCenterList = state?.costCenter?.all?.sort((a, b) => a - b);
  const divisions = state?.divisions?.all?.items || null;
  const departments = state?.departments?.all?.items || null;
  const sections = state?.sections?.all?.items || null;
  const subSections = state?.subSections?.all?.items || null;

  switch (type) {
    case 'name':
      return getCostCenterInfo(costCenterCode);
    case 'head':
      return getCostCenterInfo(costCenterCode);
    case 'division':
      return divisions?.sort((a, b) => (a?.id || null) - (b?.id || null));
    case 'department':
      return departments?.sort((a, b) => (a?.id || null) - (b?.id || null));
    case 'section':
      return sections?.sort((a, b) => (a?.id || null) - (b?.id || null));
    case 'sub-section':
      return subSections?.sort((a, b) => (a?.id || null) - (b?.id || null));
    default:
      return costCenterList;
  }

  function getCostCenterInfo(costCenterValue) {
    const cost_center_code = handleDisplayData(
      costCenterList,
      'cost_center_code',
      costCenterValue,
      ''
    );

    if (!cost_center_code) return null;
    const getCostCenterCode = cost_center_code?.match(/(..?)/g);
    const costCenterLength = getCostCenterCode?.length;
    const lastItem = getCostCenterCode[costCenterLength - 1];
    let result;

    switch (costCenterLength) {
      case 1: {
        const division_name = handleDisplayData(
          divisions,
          'division_code',
          lastItem,
          'division_name'
        );
        const division_head = handleDisplayData(
          divisions,
          'division_code',
          lastItem,
          'division_head'
        );
        if (type === 'name') result = division_name;
        if (type === 'head') result = division_head;
        return result ?? null;
      }
      case 2: {
        const department_name = handleDisplayData(
          departments,
          'department_code',
          lastItem,
          'department_name'
        );
        const department_head = handleDisplayData(
          departments,
          'department_code',
          lastItem,
          'department_head'
        );
        if (type === 'name') result = department_name;
        if (type === 'head') result = department_head;
        return result ?? null;
      }
      case 3: {
        const section_name = handleDisplayData(
          sections,
          'section_code',
          lastItem,
          'section_name'
        );
        const section_head = handleDisplayData(
          sections,
          'section_code',
          lastItem,
          'section_head'
        );
        if (type === 'name') result = section_name;
        if (type === 'head') result = section_head;
        return result ?? null;
      }
      case 4: {
        const sub_section_name = handleDisplayData(
          subSections,
          'sub_section_code',
          lastItem,
          'sub_section_name'
        );
        const sub_section_head = handleDisplayData(
          subSections,
          'sub_section_code',
          lastItem,
          'sub_section_head'
        );
        if (type === 'name') result = sub_section_name;
        if (type === 'head') result = sub_section_head;
        return result ?? null;
      }
      default:
        return '';
    }
  }

  function handleDisplayData(arr, arr_field, data, arr_field_to_return) {
    if (!arr_field) return null;
    const result = arr?.find(arrs => arrs[arr_field] === data);
    if (!result) return null;
    if (
      !arr_field_to_return === undefined ||
      !arr_field_to_return ||
      arr_field_to_return.length === 0
    ) {
      return result[arr_field];
    }

    return result[arr_field_to_return];
  }
};

export function getCostCenterFull() {
  const state = store.getState();
  const { costCenterList } = state?.costCenter || null;
  return costCenterList?.map(costCenter => {
    const { cost_center_code } = costCenter;
    const costCenterName = getCostCenter('name', cost_center_code);
    return { cost_center_code: `${cost_center_code} - ${costCenterName}` };
  });
}

export const dateFormat = date => {
  return DateTime.fromJSDate(new Date(date)).toLocaleString();
};

export const getLowerCasedValue = value =>
  typeof value === 'string' ? value.toLowerCase() : value;

export const autoCapitalize = words => {
  return words
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatDateYMD = date => {
  const newDate = new Date(date);
  const year = newDate.toLocaleDateString('default', { year: 'numeric' });
  const month = newDate.toLocaleDateString('default', { month: '2-digit' });
  const day = newDate.toLocaleDateString('default', { day: '2-digit' });
  return `${year}-${month}-${day}`;
};

export const updateObjectById = (objects, id, payload) => {
  const updatedObjects = objects.map(obj => {
    if (obj.id === id) {
      return { ...obj, ...payload };
    }
    return obj;
  });

  return updatedObjects;
};

export const deleteObjectsById = (array, deleteIds) => {
  return array.filter(item => !deleteIds.includes(item.id));
};

export const formatDateTime = (dateTimeString, formatOptions = {}) => {
  if (!dateTimeString) {
    return '-';
  }

  const defaultOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC'
  };

  const combinedOptions = { ...defaultOptions, ...formatOptions };

  const isoDateTimeString = dateTimeString.replace(/\.\d+Z$/, 'Z');
  const dateTime = new Date(isoDateTimeString);

  if (Number.isNaN(dateTime.getTime())) {
    return '-';
  }

  try {
    const formattedDateTime = new Intl.DateTimeFormat(
      'en-US',
      combinedOptions
    ).format(dateTime);
    return formattedDateTime.replace(/,([^,]*)$/, '$1');
  } catch (error) {
    return '-';
  }
};

export const calculateAveragePercentage = (param1, param2) => {
  const num1 = typeof param1 === 'string' ? parseFloat(param1) : param1;
  const num2 = typeof param2 === 'string' ? parseFloat(param2) : param2;

  if (Number.isNaN(num1) || Number.isNaN(num2)) {
    throw new Error(
      'Both parameters must be valid numbers or convertible strings'
    );
  }

  const percentage = (num1 / num2) * 100;

  return percentage.toFixed();
};

export const toHoursMinsSecs = duration => {
  if (duration <= 0 || !duration) {
    return '00:00:00';
  }

  return new Date(duration * 1000).toISOString().slice(11, 19);
};

export const tableFormatDateTime = dateString => {
  if (!dateString) {
    return '-';
  }

  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Create the formatted date string
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const statusBadge = statusValue => {
  const statusMap = {
    A: { className: 'bg-[#23B53A] text-white', text: 'Active' },
    C: { className: 'bg-[#DE5858] text-white', text: 'Cancelled' },
    F: { className: 'bg-[#468EFF] text-white', text: 'Fulfilled' },
    O: { className: 'bg-[#F2CC62] text-[#232932]', text: 'Onhold' },
    default: { className: 'bg-[#e8f1ff] text-[#232932]', text: 'New' }
  };

  const { className, text } = statusMap[statusValue] || statusMap.default;
  const badgeStyle = `rounded-full text-center text-[12px] leading-[100%] h-[26px] w-[84px] inline-flex items-center justify-center ${className}`;
  return <span className={badgeStyle}>{text}</span>;
};

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};

export const averageColor = (average, type = 'text') => {
  if (average >= 0 && average <= 25) return `${type}-[#f44336]`;
  if (average <= 50) return `${type}-[#FFA500]`;
  if (average <= 75) return `${type}-[#00bbff]`;
  return `${type}-[#23B53A]`;
};

export const costCenterTree = (code, costCenterList) => {
  const costCenter = costCenterList.find(
    item => item.cost_center_code === code
  );

  if (!costCenter) {
    return [];
  }

  const list = ['division', 'department', 'section', 'sub_section'];

  return list
    .map(section => {
      const sectionItem = costCenter[`${section}_list`];

      if (sectionItem && sectionItem[`${section}_name`]) {
        return sectionItem[`${section}_name`];
      }
      return null;
    })
    .filter(value => value !== null);
};

export const costCenterName = (code, costCenterList) => {
  if (!code) {
    return '-';
  }

  if (!costCenterList) {
    return code || '-';
  }

  const centerTree = costCenterTree(code, costCenterList);
  const name = centerTree ? centerTree[centerTree.length - 1] : null;

  return name ? `${code}/${name}` : code;
};
