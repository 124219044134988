import PropTypes from 'prop-types';
import { getUserMenusServiceByMenuCode } from 'src/api/modules/userMenus';
import { useEffect, useState } from 'react';

const PrivateRoute = ({ element, ...rest }) => {
  const [menuComponent, setMenuComponent] = useState(null);

  useEffect(() => {
    if (element.props.menuCode) {
      getUserMenusServiceByMenuCode(element.props.menuCode).then(res => {
        if (
          res?.response?.data.statusCode === 401 ||
          res.data[0] === undefined ||
          res?.data[0]?.user_group_access.can_view === false
        ) {
          window.location.href = '/access-denied';
        } else {
          setMenuComponent(element);
        }
      });
    }
  }, [element.props.menuCode]);

  return menuComponent;
};

PrivateRoute.propTypes = {
  element: PropTypes.element
};

export default PrivateRoute;
