import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getLowerCasedValue, filterData } from 'src/helpers/utils';
import { getQuestionItemsService } from 'src/api/modules/question';
import { toast } from 'react-toastify';
import { STATUS_OPTIONS } from 'src/helpers/constants';

const useQuestionIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: undefined });
  const questionDataList = useSelector(state => state?.questions?.questionList);
  const [questionList, setQuestionList] = useState([]);
  const [questionTitle, setQuestionTitle] = useState(null);
  const [modalQuestionId, setModalQuestionId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);

  const questionColumns = [
    { key: 'id', label: 'ID' },
    { key: 'content', label: 'Question' },
    // { key: 'skill_name', label: 'Skill' },
    { key: 'type', label: 'Type' },
    { key: 'points', label: 'Points' },
    { key: 'level', label: 'Difficulty' },
    { key: 'status', label: 'Status' }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status'
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-question-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-question-list-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);

      const res = await getQuestionItemsService(savedPageIndex, params);

      const newList =
        savedPageIndex !== 1
          ? questionList.concat(res.data.items)
          : res.data.items;

      setQuestionList(
        newList.map(o => {
          return {
            id: o.id,
            content: o.content,
            // skill_name: o.skill.name,
            type: o.type,
            points: o.points.toString(),
            level: o.level.toString(),
            status: o.status
          };
        })
      );

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getQuestionItemsService,
    setQuestionList,
    setPage,
    setHasMore,
    questionList
  ]);

  const questionListMemo = useMemo(() => {
    return questionList ?? [];
  }, [questionList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: `${searchParams}&token=${Math.random()
        .toString(36)
        .substring(2, 7)}`
    });
    // Save search params in localStorage
    localStorage.setItem('hris-question-params', searchParams);
  };

  const handleDeleteModal = useCallback(
    ({ modal, id, name }) => {
      setModalQuestionId(modal ? id : null);
      setQuestionTitle(modal ? name : null);
      setShowDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setModalQuestionId, setQuestionTitle]
  );

  const handleBulkDeleteModal = useCallback(
    modal => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-question-params');
    const savedPageIndex = localStorage.getItem('hris-question-list-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      localStorage.setItem('hris-question-list-page-no', JSON.stringify(1));
    } else {
      // set default status
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      setPage(1);
      localStorage.setItem('hris-question-list-page-no', JSON.stringify(1));
      setHasMore(true);
      fetchData();
    }
  }, [location.search, questionDataList]);

  useEffect(() => {
    localStorage.setItem('hris-question-list-page-no', JSON.stringify(page));
  }, [page]);

  return {
    showDeleteModal,
    questionList,
    page,
    hasMore,
    questionColumns,
    form,
    questionListMemo,
    submitFilter,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    questionTitle,
    modalQuestionId,
    inputs
  };
};

export default useQuestionIndex;
