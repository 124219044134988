import React from 'react';
import { Breadcrumbs } from 'src/components/Common';
import useMyEvaluationSingle from 'src/hooks/Pages/admin/evaluation/useMyEvaluationSingle';
import tooltipIcon from 'src/assets/icons/circle-question.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';

// eslint-disable-next-line react/prop-types
const MyEvaluationSingle = ({ id = null }) => {
  // eslint-disable-next-line no-empty-pattern
  const {
    infoDataColumns,
    employeeDetail,
    evaluationGroupData,
    evaluationDataColumns
  } = useMyEvaluationSingle();
  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Evaluation' },
          { name: 'My Evaluation', link: '/my-evaluation' },
          { name: employeeDetail?.template_name }
        ]}
      />
      <div className="flex items-start flex-wrap gap-x-[50px] gap-y-[10px] mt-10 mb-6">
        {infoDataColumns.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`data-${i}`} className="mb-2">
            <h5 className="text-[14px] font-stolzlMedium">{item.label}</h5>
            <p className="text-[14px] font-stolzlBook">
              {employeeDetail[item.key]}
            </p>
          </div>
        ))}
        <div className="ml-auto" />
      </div>
      {evaluationGroupData.map((item, i) => (
        <table
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="w-full mb-8 bg-white border border-[#E0E0E0]]"
        >
          <thead>
            <tr>
              {item.table?.map((column, sub_i) => (
                <th
                  // eslint-disable-next-line react/no-array-index-key
                  key={`data-${sub_i}`}
                  className={`w-[125px] first:w-[380px] last:w-auto border-t border-t-[#DEDEDE] border-b border-b-[#DEDEDE] first:border-l first:border-l-[#DEDEDE] border-r border-r-[#DEDEDE] px-[24px] py-[10px] bg-[#F0F0F0] font-normal whitespace-nowrap ${
                    !sub_i
                      ? 'text-[15px] font-stolzlBold text-left'
                      : 'text-[14px] font-stolzlBook'
                  } ${
                    (column.key === 'target_details' ||
                      column.key === 'description_details') &&
                    'w-[300px]'
                  }`}
                >
                  {column.label || item.group_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {item?.details?.map((column, sub_i) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`data-${sub_i}`}>
                {item.table?.map((sub_column, sub_ii) => (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={`data-${sub_ii}`}
                    className="px-[24px] py-[10px] text-[15px] text-center font-stolzlBook border-b border-b-[#DEDEDE] first:border-l first:border-l-[#DEDEDE] border-r border-r-[#DEDEDE] first:text-left last:text-[14px] last:font-stolzlBook last:text-left"
                  >
                    <div>
                      {sub_column.key !== 'name' ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          data-tooltip-id={
                            sub_column.key === 'boss_score'
                              ? `tooltip-${item.section_id}_${column.criteria_id}_${sub_column.key}`
                              : ``
                          }
                          data-tooltip-content={
                            sub_column.key === 'boss_score'
                              ? `max score: ${column.max_score}`
                              : ``
                          }
                          className={`w-full block text-[#222222] hover:text-[#222222] ${
                            sub_column.key === 'boss_score'
                              ? 'text-[15px] text-center font-stolzlMedium'
                              : 'text-[14px] text-left font-stolzlBook'
                          }`}
                        >
                          {column[sub_column.key]}
                        </a>
                      ) : (
                        parse(column[sub_column.key])
                      )}
                      {sub_column.key === 'name' &&
                        column.description !== '' && (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            className="w-[20px] inline-block"
                            data-tooltip-id={`tooltip-${i}${sub_i}${sub_ii}`}
                            data-tooltip-content={column.description}
                          >
                            <img
                              className="ml-[5px] cursor-pointer inline-block align-text-top"
                              src={tooltipIcon}
                              alt="tooltip"
                            />
                          </a>
                        )}
                      <ReactTooltip
                        className="max-w-[450px] !bg-white !text-[#222222] !text-[12px] border-[1px] border-gray-600 !font-stolzlBook shadow-lg"
                        id={`tooltip-${i}${sub_i}${sub_ii}`}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default MyEvaluationSingle;
