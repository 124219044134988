import { useNavigate, useLocation } from 'react-router-dom';

export function useRemoveSelectedItems() {
  const navigate = useNavigate();
  const location = useLocation();

  function unSelectItems() {
    navigate({
      pathname: location.pathname,
      search: `${location.search}&unselectItems=true`
    });
  }

  return { unSelectItems };
}
