import { FETCH_SKILLS } from './skillTypes';

const INITIAL_STATE = {
  skills: []
};

export default function userReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case FETCH_SKILLS:
      return {
        ...state,
        skills: payload
      };
    default:
      return state;
  }
}
