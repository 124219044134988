import React from 'react';
import { Breadcrumbs } from 'src/components/Common';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMyEvaluation from 'src/hooks/Pages/admin/evaluation/useMyEvaluation';
import PropTypes from 'prop-types';

const MyEvaluations = ({ menuCode }) => {
  const {
    myEvaluationListMemo,
    hasMore,
    evaluationEntryColumns,
    form,
    inputs,
    access,
    fetchData,
    submitFilter,
    handleViewPage
  } = useMyEvaluation();

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Evaluation' }, { name: 'My Evaluation' }]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Template Name, Evaluator Name, Cost Center"
            showActionBtn={false}
            showDeleteBtn={false}
            inputs={inputs}
            buttonName=""
            buttonLink={false}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={myEvaluationListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            style={{ overflow: 'visible' }}
            endMessage={
              <p className="text-center mt-5">
                <b>
                  {myEvaluationListMemo?.length !== 0
                    ? 'No more data to load'
                    : 'No records found'}
                </b>
              </p>
            }
          >
            {evaluationEntryColumns.length === 0 ? null : (
              <Datatable
                shouldDisplayEditable={false}
                datasource={myEvaluationListMemo || []}
                clickableRows={false}
                headingColumns={evaluationEntryColumns}
                title="Employee Information"
                handleViewModal={handleViewPage}
                actions={['view']}
                access={access}
                shouldEllipsis
                onExport={false}
                isExport={false}
              />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

MyEvaluations.propTypes = {
  menuCode: PropTypes.string
};

export default MyEvaluations;
