import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatDate, costCenterName } from 'src/helpers/utils';

import fontSrcStolzlRegular from 'src/assets/fonts/Stolzl-Regular.woff';
import fontSrcStolzlBold from 'src/assets/fonts/Stolzl-Bold.woff';
import fontSrcStolzlMedium from 'src/assets/fonts/Stolzl-Medium.woff';
import fontSrcNotoJPRegular from 'src/assets/fonts/NotoSansJP-Regular.ttf';
import fontSrcNotoJPBold from 'src/assets/fonts/NotoSansJP-Bold.ttf';
import fontSrcNotoJPMedium from 'src/assets/fonts/NotoSansJP-Medium.ttf';

import {
  Page,
  Text,
  Image,
  View,
  Font,
  Document,
  PDFViewer
} from '@react-pdf/renderer';

import useEvaluationResultSingle from 'src/hooks/Pages/admin/evaluation/useEvaluationResultSingle';

const fontSources = {
  'Stolzl Regular': fontSrcStolzlRegular,
  'Stolzl Medium': fontSrcStolzlBold,
  'Stolzl Bold': fontSrcStolzlMedium,
  'Noto Sans JP Regular': fontSrcNotoJPRegular,
  'Noto Sans JP Medium': fontSrcNotoJPBold,
  'Noto Sans JP Bold': fontSrcNotoJPMedium
};

Object.entries(fontSources).forEach(([family, src]) => {
  Font.register({ family, src });
});

const style = {
  fontSize6: {
    fontSize: '6pt'
  },
  fontSize7: {
    fontSize: '7pt'
  },
  fontSize8: {
    fontSize: '8pt'
  },
  fontSize12: {
    fontSize: '12pt'
  },
  fontStolzlRegular: {
    fontFamily: 'Stolzl Regular'
  },
  fontStolzlMedium: {
    fontFamily: 'Stolzl Medium'
  },
  fontStolzlBold: {
    fontFamily: 'Stolzl Bold'
  },
  textEllipsis: {
    textOverflow: 'ellipsis'
  },
  textCenter: {
    textAlign: 'center'
  },
  whiteSpaceNormal: {
    whiteSpace: 'normal'
  },
  paddingX6: {
    paddingHorizontal: '6px'
  },
  paddingX10: {
    paddingHorizontal: '10px'
  },
  paddingX15: {
    paddingHorizontal: '15px'
  },
  paddingY8: {
    paddingVertical: '8px'
  },
  paddingY6: {
    paddingVertical: '6px'
  },
  paddingTop2: {
    paddingTop: '2px'
  },
  paddingTop5: {
    paddingTop: '5px'
  },
  paddingTop20: {
    paddingTop: '20px'
  },
  paddingBottom2: {
    paddingBottom: '2px'
  },
  paddingBottom3: {
    paddingBottom: '3px'
  },
  paddingBottom5: {
    paddingBottom: '5px'
  },
  paddingBottom10: {
    paddingBottom: '10px'
  },
  flexRow: {
    flexDirection: 'row'
  },
  flexAlignBottom: {
    alignItems: 'flex-end'
  },
  borderTBL: {
    borderTop: '1px solid #afafaf',
    borderBottom: '1px solid #afafaf',
    borderLeft: '1px solid #afafaf'
  },
  borderBL: {
    borderBottom: '1px solid #afafaf',
    borderLeft: '1px solid #afafaf'
  },
  borderR: {
    borderRight: '1px solid #afafaf'
  },
  borderT: {
    borderTop: '1px solid #afafaf'
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30
  },
  marginY20: {
    marginHorizontal: '20px'
  }
};

const EmployeeInfoItem = ({ textLabel, textValue }) => {
  return (
    <>
      <Text
        style={[style.fontSize6, style.paddingBottom2, style.fontStolzlMedium]}
      >
        {textLabel}
      </Text>
      <Text
        style={[
          style.fontSize6,
          style.paddingBottom5,
          style.fontStolzlRegular,
          style.whiteSpaceNormal,
          { width: '90px' }
        ]}
      >
        {textValue}
      </Text>
    </>
  );
};

const UserPerformance = ({ score }) => {
  let rating = '';
  if (score >= 90) {
    rating = 'Outstanding';
  } else if (score >= 70) {
    rating = 'Exceeds Expectation';
  } else if (score >= 50) {
    rating = 'Meets Expectation';
  } else if (score >= 25) {
    rating = 'Needs Improvement';
  } else {
    rating = 'Failed';
  }
  return (
    <Text
      style={[
        style.fontSize7,
        style.fontStolzlRegular,
        style.textCenter,
        style.paddingBottom2
      ]}
    >
      {rating}
    </Text>
  );
};

const EmployeeTableRow = ({
  isHead,
  name,
  score,
  comment,
  targetDetails = ''
}) => {
  return (
    <View
      style={[
        style.flexRow,
        { backgroundColor: isHead ? '#F0F0F0' : '#FFFFFF' }
      ]}
    >
      <View
        style={[isHead ? style.borderTBL : style.borderBL, { width: '25%' }]}
      >
        <Text
          style={[
            style.paddingX10,
            style.paddingY8,
            style.fontSize6,
            style.fontNotoJPBold
          ]}
        >
          {name}
        </Text>
      </View>
      <View
        style={[
          style.textCenter,
          isHead ? style.borderTBL : style.borderBL,
          { width: '10%' }
        ]}
      >
        <Text
          style={[
            style.paddingX10,
            style.paddingY8,
            style.fontSize6,
            isHead ? style.fontStolzlRegular : style.fontStolzlMedium
          ]}
        >
          {score}
        </Text>
      </View>
      {targetDetails.length ? (
        <>
          <View
            style={[
              isHead ? style.borderTBL : style.borderBL,
              {
                width: '32.5%',
                textAlign: isHead ? 'center' : 'left'
              }
            ]}
          >
            <Text
              style={[
                style.paddingX10,
                style.paddingY8,
                style.fontSize6,
                style.fontStolzlRegular
              ]}
            >
              {targetDetails}
            </Text>
          </View>
          <View
            style={[
              isHead ? style.borderTBL : style.borderBL,
              style.borderR,
              {
                width: '32.5%',
                textAlign: isHead ? 'center' : 'left'
              }
            ]}
          >
            <Text
              style={[
                style.paddingX10,
                style.paddingY8,
                style.fontSize6,
                style.fontStolzlRegular
              ]}
            >
              {comment}
            </Text>
          </View>
        </>
      ) : (
        <View
          style={[
            isHead ? style.borderTBL : style.borderBL,
            style.borderR,
            {
              width: '65%',
              textAlign: isHead ? 'center' : 'left'
            }
          ]}
        >
          <Text
            style={[
              style.paddingX10,
              style.paddingY8,
              style.fontSize6,
              style.fontStolzlRegular
            ]}
          >
            {comment}
          </Text>
        </View>
      )}
    </View>
  );
};

const EvaluationResultSinglePDF = () => {
  const company = useSelector(state => state.userCompany);
  const { user } = useSelector(state => state.user);
  const costCenterList = useSelector(
    state => state.costCenter.costCenterAll.items
  );
  const { dataPDFMemo, scheduleDate, evaluationComment } =
    useEvaluationResultSingle();
  const date = formatDate(new Date());

  const nullish = value => {
    return value || '';
  };

  const userFullName = currentUser => {
    const {
      first_name = '',
      middle_name = '',
      last_name = '',
      suffix = ''
    } = currentUser || {};
    return `${nullish(first_name)}${
      middle_name ? ` ${nullish(middle_name)}` : ''
    } ${nullish(last_name)}${suffix ? ` ${nullish(suffix)}` : ''}`;
  };

  const employeeFullName = employee => {
    const {
      first_name = '',
      middle_name = '',
      last_name = ''
    } = employee || {};
    return `${nullish(first_name)}${
      middle_name ? ` ${nullish(middle_name)}` : ''
    } ${nullish(last_name)}`;
  };

  return (
    <PDFViewer showToolbar="true" width="100%" height="900">
      <Document>
        {dataPDFMemo?.items?.map((evaluation, eval_i) => {
          const {
            employee_evaluation_details: evaluation_details,
            employee_evaluation_master: evaluation_master
          } = evaluation || {};
          const { employee } = evaluation_master || {};

          return (
            <Page
              size="A4"
              style={{ padding: '30px' }}
              // eslint-disable-next-line react/no-array-index-key
              key={eval_i}
              renderTextLayer={false}
              wrap
            >
              <View
                style={[
                  style.paddingBottom10,
                  style.flexRow,
                  style.flexAlignBottom
                ]}
              >
                <View style={[style.paddingBottom5, { width: '25%' }]}>
                  <Image
                    src={company.company.logo}
                    style={[style.paddingBottom5, { width: '58%' }]}
                  />
                  <Text style={[style.fontSize12, style.fontStolzlMedium]}>
                    Evaluation Result
                  </Text>
                </View>
                <View
                  style={{
                    width: '60%',
                    flexDirection: 'row'
                  }}
                >
                  <View style={[style.paddingX15]}>
                    <EmployeeInfoItem
                      textLabel="Employee Name"
                      textValue={employeeFullName(employee?.employee_info)}
                    />
                    <EmployeeInfoItem
                      textLabel="Cost Center"
                      textValue={costCenterName(
                        employee.cost_center_code,
                        costCenterList
                      )}
                    />
                  </View>
                  <View style={[style.paddingX15]}>
                    <EmployeeInfoItem
                      textLabel="Salary Grade"
                      textValue={employee.salary_grade.type}
                    />
                    <EmployeeInfoItem
                      textLabel="Evaluation Sheet Name"
                      textValue={evaluation_master.evaluation_template.name}
                    />
                  </View>
                  <View style={[style.paddingX15, style.textEllipsis]}>
                    <EmployeeInfoItem
                      textLabel="Schedule Name"
                      textValue={evaluation_master.evaluation_schedule.name}
                    />
                    <EmployeeInfoItem
                      textLabel="Evaluation Date"
                      textValue={scheduleDate}
                    />
                  </View>
                </View>
                <View style={[style.paddingBottom10, { width: '15%' }]}>
                  <Text
                    style={[
                      style.fontSize8,
                      style.fontStolzlMedium,
                      style.textCenter,
                      style.paddingBottom3
                    ]}
                  >
                    Overall Score:
                  </Text>
                  <Text
                    style={[
                      style.fontSize12,
                      style.textCenter,
                      style.fontStolzlBold,
                      style.paddingBottom3
                    ]}
                  >
                    {parseFloat(evaluation_master.overall_score).toFixed(2)}%
                  </Text>
                  <UserPerformance score={evaluation_master.overall_score} />
                </View>
              </View>
              {typeof evaluation_details !== 'undefined' &&
                evaluation_details.map((details, tab_i) => (
                  <View
                    key={details.id}
                    style={[style.paddingBottom10]}
                    wrap={false}
                  >
                    <EmployeeTableRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={tab_i}
                      isHead
                      name={`${tab_i + 1}. ${details.template_section.name}`}
                      score="Score"
                      comment="Comment"
                      targetDetails={
                        details.template_section.type === 'T'
                          ? 'Target Details'
                          : ''
                      }
                    />
                    {typeof details.criteria_details !== 'undefined' &&
                      details.criteria_details.map((subEvaluation, sub_ii) => (
                        <EmployeeTableRow
                          // eslint-disable-next-line react/no-array-index-key
                          key={sub_ii}
                          isHead={false}
                          name={`${
                            typeof subEvaluation.template_criteria.fields
                              .title !== 'undefined'
                              ? `${tab_i + 1}-${sub_ii + 1}. ${
                                  subEvaluation.template_criteria.fields.title
                                }`
                              : subEvaluation.template_criteria.fields.question
                          }`}
                          score={`${subEvaluation.boss_score}`}
                          comment={evaluationComment(
                            subEvaluation,
                            details.template_section.type
                          )}
                          targetDetails={
                            details.template_section.type === 'T'
                              ? subEvaluation.evaluator_value?.target_details
                              : ''
                          }
                        />
                      ))}
                    <View style={[style.flexRow, style.paddingTop20]}>
                      <View style={[{ width: '75%' }]} />
                      <View style={[{ width: '25%', textAlign: 'center' }]}>
                        <Text
                          style={[
                            style.paddingTop5,
                            style.fontSize8,
                            style.fontStolzlRegular
                          ]}
                        >
                          {userFullName(details.evaluator.employee_info)}
                        </Text>
                      </View>
                    </View>
                    <View style={[style.flexRow]}>
                      <View style={[{ width: '75%' }]} />
                      <View
                        style={[
                          style.borderT,
                          { width: '25%', textAlign: 'center' }
                        ]}
                      >
                        <Text
                          style={[style.fontSize6, style.fontStolzlRegular]}
                        >
                          Evaluator Name / Signature
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              {/* Start Page Footer */}
              <View style={[style.flexRow, style.footer]} fixed>
                <View style={[style.borderTBL, { flex: 1 }]}>
                  <Text
                    style={[
                      style.paddingX6,
                      style.paddingTop5,
                      style.fontSize6,
                      style.fontNotoJPBold
                    ]}
                  >
                    Printed by:
                  </Text>
                  <Text
                    style={[
                      style.paddingX6,
                      style.paddingTop5,
                      style.fontSize8,
                      style.paddingBottom2,
                      style.fontNotoJPBold,
                      style.textCenter,
                      { textTransform: 'uppercase' }
                    ]}
                  >
                    {userFullName(user.user)}
                  </Text>
                  <View
                    style={[
                      style.borderT,
                      style.paddingBottom2,
                      style.paddingTop2,
                      style.marginY20,
                      { textAlign: 'center' }
                    ]}
                  >
                    <Text style={[style.fontSize6, style.fontStolzlRegular]}>
                      Signature Over Printed Name / Date
                    </Text>
                  </View>
                </View>
                <View style={[style.borderTBL, { flex: 1 }]}>
                  <Text
                    style={[
                      style.paddingX6,
                      style.paddingTop5,
                      style.fontSize6,
                      style.fontNotoJPBold
                    ]}
                  >
                    Issued by:
                  </Text>
                  <Text
                    style={[
                      style.paddingX6,
                      style.paddingTop5,
                      style.paddingBottom2,
                      style.fontSize8,
                      style.fontNotoJPBold,
                      style.textCenter,
                      { textTransform: 'uppercase' }
                    ]}
                  >
                    {' '}
                  </Text>
                  <View
                    style={[
                      style.borderT,
                      style.paddingBottom2,
                      style.paddingTop2,
                      style.marginY20,
                      { textAlign: 'center' }
                    ]}
                  >
                    <Text style={[style.fontSize6, style.fontStolzlRegular]}>
                      Signature Over Printed Name / Date
                    </Text>
                  </View>
                </View>
                <View style={[style.borderTBL, style.borderR, { flex: 1 }]}>
                  <Text
                    style={[
                      style.paddingX6,
                      style.paddingTop5,
                      style.fontSize6,
                      style.fontNotoJPBold
                    ]}
                  >
                    Received by:
                  </Text>
                  <Text
                    style={[
                      style.paddingX6,
                      style.paddingTop5,
                      style.paddingBottom2,
                      style.fontSize8,
                      style.fontNotoJPBold,
                      style.textCenter,
                      { textTransform: 'uppercase' }
                    ]}
                  >
                    {employeeFullName(employee.employee_info)}
                  </Text>
                  <View
                    style={[
                      style.borderT,
                      style.paddingBottom2,
                      style.paddingTop2,
                      style.marginY20,
                      { textAlign: 'center' }
                    ]}
                  >
                    <Text style={[style.fontSize6, style.fontStolzlRegular]}>
                      Signature Over Printed Name / Date
                    </Text>
                  </View>
                </View>
              </View>
              {/* END Page Footer */}
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
};

EmployeeTableRow.propTypes = {
  isHead: PropTypes.bool,
  name: PropTypes.string,
  score: PropTypes.string,
  comment: PropTypes.string,
  targetDetails: PropTypes.string
};

EmployeeInfoItem.propTypes = {
  textLabel: PropTypes.string,
  textValue: PropTypes.string
};

UserPerformance.propTypes = {
  score: PropTypes.string
};

export default EvaluationResultSinglePDF;
