import {
  getUserDetailsByID,
  getUserSkillsByID,
  getUserSkillsByIdService,
  userInfoService,
  getUserInfoByIDMyTeam,
  getAdminProjectTeamInfoService,
  getUserForEvaluationByID,
  patchUserEvaluationAnswer,
  getAllUsersService,
  getUserEvaluationByID
} from 'src/api/v1/modules/user';
import {
  SET_USER,
  REMOVE_USER,
  SET_USER_INFO,
  SET_USER_INFO_BYID,
  FETCH_DATA,
  SET_USER_BY_IMAGE,
  GET_USER_SKILLS_BYID,
  FETCH_ALL_USERS
} from './userTypes';

export function setUser(payload) {
  return { type: SET_USER, payload };
}

export function setUserInfo(payload) {
  return { type: SET_USER_INFO, payload };
}

export function removeUser(payload) {
  return { type: REMOVE_USER, payload };
}

export function setUserInfoById(payload) {
  return { type: SET_USER_INFO_BYID, payload };
}

export function setUserSkillsById(payload) {
  return { type: GET_USER_SKILLS_BYID, payload };
}
export function fetchDataLoad() {
  return { type: FETCH_DATA };
}

export function setUserByImage(payload) {
  return { type: SET_USER_BY_IMAGE, payload };
}

export function setAllUsers(payload) {
  return { type: FETCH_ALL_USERS, payload };
}

export function getUserAction() {
  return async function (dispatch) {
    const response = await userInfoService();
    if (response?.data) dispatch(setUserInfo(response.data));
  };
}

export function getUserInfo() {
  return async function (dispatch) {
    const response = await userInfoService();
    if (response?.data) dispatch(setUserInfo(response.data));
  };
}

export function getUserDetails(userID) {
  return async function (dispatch) {
    const response = await getUserDetailsByID(userID);
    if (response?.data) dispatch(setUserInfoById(response.data));
  };
}
export function getUserInfoMyTeam(userID) {
  return async function (dispatch) {
    const response = await getUserInfoByIDMyTeam(userID);
    if (response?.success && response?.data)
      dispatch(setUserInfoById(response.data));
  };
}

export function getAdminProjectTeamInfo(userID) {
  return async function () {
    return await getAdminProjectTeamInfoService(userID);
  };
}

export function getUserSkills(skillID) {
  return async function (dispatch) {
    const response = await getUserSkillsByID(skillID);
    if (response?.data) dispatch(setUserSkillsById(response.data));
  };
}

export function getUserSkillsById(skillId) {
  return async function () {
    return await getUserSkillsByIdService(skillId);
  };
}

export function getUserBossEvaluation(ID) {
  return async function () {
    return await getUserForEvaluationByID(ID);
  };
}

export function getUserEvaluation(ID) {
  return async function () {
    return await getUserEvaluationByID(ID);
  };
}

export function patchEvaluationAnswer(evaluationID, data) {
  return async function () {
    return await patchUserEvaluationAnswer(evaluationID, data);
  };
}

export function getAllUsers() {
  return async function (dispatch) {
    const response = await getAllUsersService();
    if (response?.success && response?.data)
      dispatch(setAllUsers(response.data));
  };
}
