import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Box from 'src/components/v1/Common/Box';
import PageTitle from 'src/components/v1/Common/PageTitle';
import Button from 'src/components/v1/Common/Button';
import { createEvaluationSchedulesService } from 'src/api/v1/modules/evaluation';
import { getEvaluationScheduleVerification } from 'src/redux/v1/modules/evaluations/schedules/schedulesActions';
import EvaluationSchedulesVerification from './EvaluationSchedulesVerification';
import GeneratedEvaluationSchedule from './GeneratedEvaluationSchedule';

const EvaluationAddSchedule = () => {
  const dispatch = useDispatch();
  const { evaluationSchedulesVerification } = useSelector(
    state => state.evaluationSchedule
  );

  const initialFormData = {
    schedule_name: '',
    evaluation_start_date: '',
    evaluation_end_date: '',
    schedule_description: '',
    is_included: 0
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isScheduleGenerated, setIsScheduleGenerated] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [scheduleVerificationResponse, setScheduleVerificationResponse] =
    useState('');
  const [generatedScheduleResponse, setGeneratedScheduleResponse] =
    useState('');

  const handleSubmit = event => {
    setFormData(formData);
    event.preventDefault();

    if (!isSubmitDisabled) setIsConfirming(true);
    dispatch(getEvaluationScheduleVerification(formData.is_included));
  };

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked ? 1 : 0
    });

    if (event.target.name !== 'is_included') {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleModalClose = () => {
    setIsConfirming(false);
    setIsScheduleGenerated(false);
  };

  const handleConfirm = async () => {
    const result = await createEvaluationSchedulesService(formData);

    if (result.success) {
      setGeneratedScheduleResponse(result.data);
      setIsScheduleGenerated(true);
    } else {
      toast.error('Evaluation Schedule creation failed!');
    }

    setIsConfirming(false);
    setFormData(initialFormData);
  };

  useEffect(() => {
    const validate = !Object.values(formData).some(data => data === '');

    setIsSubmitDisabled(true);

    if (validate) setIsSubmitDisabled(false);
  }, [formData, isSubmitDisabled]);

  useEffect(() => {
    if (evaluationSchedulesVerification && isConfirming) {
      setScheduleVerificationResponse(
        evaluationSchedulesVerification.verification
      );
    }
  }, [evaluationSchedulesVerification]);

  return (
    <>
      <EvaluationSchedulesVerification
        data={scheduleVerificationResponse}
        isOpen={isConfirming}
        onClose={handleModalClose}
        onConfirm={handleConfirm}
      />

      <GeneratedEvaluationSchedule
        data={generatedScheduleResponse}
        isOpen={isScheduleGenerated}
        onClose={handleModalClose}
      />
      <PageTitle
        title="Add Evaluation Schedule"
        backButton
        backPath="/evaluations/schedules"
      />

      <div className="evaluation__breadcrumbs">
        <ul className="evaluation__breadcrumbs-list">
          <li className="evaluation__breadcrumbs-item">
            <Link to="/evaluations/schedules">Evaluation Schedule</Link>
          </li>
          <li className="evaluation__breadcrumbs-item">
            Add Evaluation Schedule
          </li>
        </ul>
      </div>

      <div className="evaluation__container evaluation__container--inner">
        <form action="" method="POST" onSubmit={handleSubmit}>
          <div className="evaluation__content">
            <div className="evaluation__information">
              <div className="evaluation__row mb0 alignCenter">
                <div className="evaluation__column evaluation__column--two">
                  <h2>Information</h2>
                </div>
                <div className="evaluation__column evaluation__column--two reverse">
                  <Button
                    name="Generate Schedule"
                    modifier="button__add-white dark button--pc"
                    type="submit"
                    tabIndex="0"
                    disabled={isSubmitDisabled}
                  />
                </div>
              </div>
            </div>

            <div className="evaluation__information evaluation__information--form">
              <Box>
                <div className="evaluation__row">
                  <div className="evaluation__column evaluation__column--two">
                    <label
                      className="evaluation__label"
                      htmlFor="schedule_name"
                    >
                      Evaluation Name
                      <input
                        className="evaluation__input"
                        name="schedule_name"
                        id="schedule_name"
                        type="text"
                        value={formData.schedule_name}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className="evaluation__column evaluation__column--two">
                    <div className="evaluation__row">
                      <div className="evaluation__column evaluation__column--two">
                        <label
                          className="evaluation__label"
                          htmlFor="evaluation_start_date"
                        >
                          Start Date
                          <input
                            className="evaluation__input evaluation__input--date"
                            name="evaluation_start_date"
                            id="evaluation_start_date"
                            type="date"
                            value={formData.evaluation_start_date}
                            onChange={handleChange}
                            max="9999-12-31"
                          />
                        </label>
                      </div>
                      <div className="evaluation__column evaluation__column--two">
                        <label
                          className="evaluation__label"
                          htmlFor="evaluation_end_date"
                        >
                          End Date
                          <input
                            className="evaluation__input evaluation__input--date"
                            name="evaluation_end_date"
                            id="evaluation_end_date"
                            type="date"
                            value={formData.evaluation_end_date}
                            onChange={handleChange}
                            max="9999-12-31"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="evaluation__row">
                  <div className="evaluation__column">
                    <label
                      className="evaluation__label"
                      htmlFor="schedule_description"
                    >
                      Description
                      <textarea
                        className="evaluation__input evaluation__input--textarea"
                        name="schedule_description"
                        id="schedule_description"
                        value={formData.schedule_description}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <input
                    type="checkbox"
                    id="is_included"
                    name="is_included"
                    onChange={handleChange}
                  />
                  <label className="evaluation__label" htmlFor="is_included">
                    {' '}
                    Included exempted users
                  </label>
                </div>
              </Box>
            </div>

            <div className="evaluation__information evaluation__information--note">
              <Box>
                <h3>NOTE</h3>
                <p>
                  The system automatically assigns Evaluation Templates to
                  Employees based on the Department and Salary Grades set on the
                  Template. To ensure that the auto-assignment is accurate
                  please set all draft, archived or incomplete Templates as
                  Invalid. You will be able to change the template afterwards
                  for any corrections on the Manage Evaluation Schedule page.
                </p>
              </Box>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EvaluationAddSchedule;
