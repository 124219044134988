import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProjectsHistory } from 'src/redux/v1/modules/projects/projectActions';
import Breadcrumbs from 'src/components/v1/Common/Breadcrumbs';
import PageTitle from 'src/components/v1/Common/PageTitle';
import SectionTitle from 'src/components/v1/Common/SectionTitle';
import Button from 'src/components/v1/Common/Button';
import LabeledText from 'src/components/v1/Common/LabeledText';
import Tab from 'src/components/v1/Common/Tab';
import Datatable from 'src/components/v1/Common/Datatable';
import {
  getUserDetails,
  getUserInfoMyTeam,
  fetchDataLoad,
  getUserSkills
} from 'src/redux/v1/modules/users/userActions';
import defaultPicture from 'src/assets/v1/icons/defaultProfile2.png';
import 'src/assets/v1/css/user.scss';

const SkillSetTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [newSkill, setNewSkill] = useState([]);
  const userSkill = useSelector(state => state.user.userSkillDetails);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(getUserSkills(Number(id)));
  }, [dispatch]);

  useEffect(() => {
    const skills = [...userSkill]?.map(
      ({
        rating,
        skill: {
          SkillCategory: { name: categoryName },
          name: skillName
        }
      }) => ({
        category: categoryName,
        skill: skillName,
        rating: rating.toString()
      })
    );

    setNewSkill(skills);
  }, [userSkill]);

  return (
    <div className="skillset-tab">
      <div className="skillset-table__heading">
        <SectionTitle title="All Skillset" />
        <Button
          name="Edit Skillset"
          modifier="button__edit"
          onClick={() => navigate(`${location.pathname}/skills/edit`)}
        />
      </div>
      <Datatable
        keyField="skillset"
        headingColumns={[
          { key: 'category', label: 'Category' },
          { key: 'skill', label: 'Skillset' },
          { key: 'rating', label: 'Rating' }
        ]}
        datasource={newSkill}
      />
    </div>
  );
};

const ProjectTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userProjects = useSelector(state => state.projects);
  const projects =
    (userProjects && userProjects.projects.projectHistory) || null;

  useEffect(() => {
    dispatch(getUserProjectsHistory(Number(id)));
  }, [id, dispatch]);

  const projectHistoryData = projects?.map(project => {
    const {
      Project,
      ProjectRole,
      resource_perc,
      assignment_start_date,
      assignment_end_date
    } = project;

    return {
      project_name: (Project && Project.name) || '-',
      industry: (Project && Project.Industry) || '-',
      resource: `${resource_perc}%` || '-',
      role: (ProjectRole && ProjectRole.project_role_code) || '-',
      assignment_start: assignment_start_date || '-',
      assignment_end: assignment_end_date || '-'
    };
  });

  return (
    <div className="project-tab">
      <SectionTitle title="Project Statistics" />
      <div className="project-statistics">
        <LabeledText label="Current Resource" text="100%" />
        <LabeledText label="Occupancy Ratio (6 months)" text="50%" />
        <LabeledText label="Months Idle" text="0.1" />
      </div>

      <div className="user__section">
        <SectionTitle title="Project History" />
        <Datatable
          datasource={projectHistoryData}
          headingColumns={[
            { key: 'project_name', label: 'Project Name' },
            { key: 'industry', label: 'Industry' },
            { key: 'resource', label: 'Resource' },
            { key: 'role', label: 'Role' },
            { key: 'assignment_start', label: 'Assignment Start' },
            { key: 'assignment_end', label: 'Assignment End' }
          ]}
        />
      </div>
    </div>
  );
};

const UserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.user.loading);
  const selectedUser = useSelector(state => state.user.selectedUserDetails);
  const user = (selectedUser && selectedUser.user) || null;
  const info = (selectedUser && selectedUser.info) || null;

  useEffect(() => {
    dispatch(fetchDataLoad());
    dispatch(getUserInfoMyTeam(Number(id)));
    dispatch(getUserDetails(Number(id)));
  }, [id, dispatch]);

  return (
    <div className="user__container">
      <div className="user__heading">
        <div className="user__heading--title">
          <PageTitle title="User Detail" backButton backPath="/users" />
        </div>

        <Breadcrumbs
          crumbs={[{ link: '/users', name: 'User' }, 'User Detail']}
        />
      </div>

      <div className={`user__section ${loading ? 'loading' : ''}`}>
        <div className="user__section--heading">
          <SectionTitle title="Information" />
        </div>

        <div className="info-details__content">
          <div className="info-details__content--row">
            <div className="info-details__content--col">
              <div className="profile-image">
                <img
                  src={user && user.image ? user.image : defaultPicture}
                  alt=""
                />
              </div>
            </div>

            <div className="info-details__content--col">
              <div className="info-details__content--row">
                <div className="info-details__content--col">
                  <LabeledText
                    label="ID"
                    text={info && info.id_no ? info.id_no : '-'}
                  />
                  <LabeledText
                    label="Position"
                    text={user && user.position ? user.position : '-'}
                  />
                  <LabeledText
                    label="Email"
                    text={user && user.email ? user.email : '-'}
                  />
                </div>
                <div className="info-details__content--col">
                  <LabeledText
                    label="First Name"
                    text={user && user.first_name ? user.first_name : '-'}
                  />
                  <LabeledText
                    label="Employment Status"
                    text={
                      info && info.employment_status
                        ? info.employment_status.name
                        : '-'
                    }
                  />
                </div>
                <div className="info-details__content--col">
                  <LabeledText
                    label="Last Name"
                    text={user && user.last_name ? user.last_name : '-'}
                  />
                  <LabeledText
                    label="Work Years"
                    text={
                      info && (info.work_years || info.work_months)
                        ? `${info.work_years}y ${info.work_months}m`
                        : '-'
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="info-details__content--action-row">
            <Button
              name="User Info"
              modifier="button__info"
              onClick={() => navigate(`${location.pathname}/info`)}
            />
          </div>
        </div>
      </div>

      <div className="user__section">
        <Tab
          padded={false}
          shaded={false}
          tabs={[
            {
              title: 'Skillset',
              component: <SkillSetTab />
            },
            { title: 'Project', component: <ProjectTab /> }
          ]}
        />
      </div>
    </div>
  );
};

export default UserDetails;
