/* eslint-disable react/prop-types */
import React from 'react';

const OptionCheckbox = ({ name, item, isChecked, onSelectCheckbox }) => {
  return (
    <button
      type="button"
      className={`custom__radio flex items-center p-[30px] rounded rounded-[4px] ${
        isChecked ? 'bg-[#E3ECFF]' : 'bg-[#F7F7F7]'
      }`}
      onClick={onSelectCheckbox}
    >
      <label
        className="custom__checkbox inline-block align-left text-[0] cursor-pointer pointer-events-none"
        htmlFor={name}
      >
        <input
          checked={isChecked}
          type="checkbox"
          name={name}
          id={name}
          value={item}
          hidden
          onChange={() => true}
        />
        <span className="inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative">
          <em className="absolute block w-[5px] h-0.5 bg-white top-[9px] left-[3px] rotate-[40deg] rounded-md" />
          <em className="absolute block w-2.5 h-0.5 bg-white top-2 left-1.5 rotate-[-40deg] rounded-md" />
        </span>
        <i className="inline-block align-middle cursor-pointer ml-[11px] -mt-[1px] not-italic text-[16px] text-[#222222] leading-[14px] font-stolzlBook">
          {item}
        </i>
      </label>
    </button>
  );
};

export default OptionCheckbox;
