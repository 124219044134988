import { GET_ADMIN_SUB_SECTIONS_LIST } from './subsectionsTypes';

const INITIAL_STATE = {
  subSections: []
};

export default function sectionsReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_ADMIN_SUB_SECTIONS_LIST:
      return {
        ...state,
        subSections: payload
      };
    default:
      return state;
  }
}
