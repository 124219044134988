export const scheduleColumns = [
  {
    key: 'evaluationName',
    label: 'Evaluation Name'
  },
  {
    key: 'evaluationStartDate',
    label: 'Start Date'
  },
  {
    key: 'evaluationEndDate',
    label: 'End Date'
  },
  {
    key: 'completion',
    label: 'Completion'
  },
  {
    key: 'status',
    label: 'Status'
  }
];

export const manageEvaluationScheduleColumns = [
  {
    key: 'employee_id',
    label: 'Employee ID',
    readOnly: true
  },
  {
    key: 'name',
    label: 'Name',
    readOnly: true
  },
  {
    key: 'cost_center',
    label: 'Cost Center',
    readOnly: true
  },
  {
    key: 'template',
    label: 'Template',
    readOnly: true
  }
];
