// import {
//   getEvaluationScheduleService,
//   getEvaluationsScheduleVerificationService
// } from 'src/api/modules/evaluationSchedule';

import {
  getAllEvaluationTemplatesService,
  getActiveEvaluationTemplatesService
} from 'src/api/modules/evaluation';

import {
  GET_EVALUATION_SCHEDULE_LIST,
  GET_EVALUATION_SCHEDULE_VERIFICATION,
  GET_SCHEDULE_TEMPLATES
} from './evaluationScheduleTypes';

export function setScheduleTemplates(data) {
  return { type: GET_SCHEDULE_TEMPLATES, payload: data };
}

export function getActiveScheduleTemplates() {
  async function activeTemplates(dispatch) {
    const response = await getActiveEvaluationTemplatesService();
    if (response?.success && response?.data) {
      dispatch(setScheduleTemplates(response?.data));
    }
  }
  return activeTemplates;
}

// export function setEvalutionList(data) {
//   return { type: GET_EVALUATION_SCHEDULE_LIST, payload: data };
// }
// export function setEvalutionVerification(data) {
//   return { type: GET_EVALUATION_SCHEDULE_VERIFICATION, payload: data };
// }

// export function getEvaluationSchedule() {
//   return async function (dispatch) {
//     const response = await getEvaluationScheduleService();
//     if (response?.data) dispatch(setEvalutionList(response.data));
//   };
// }

// export function getEvaluationScheduleVerification() {
//   return async function (dispatch) {
//     const response = await getEvaluationsScheduleVerificationService();
//     if (response?.data) dispatch(setEvalutionVerification(response.data));
//   };
// }
