import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  getLowerCasedValue,
  formatDate,
  costCenterTree
} from 'src/helpers/utils';
import {
  useLocation,
  useNavigate,
  useParams,
  createSearchParams
} from 'react-router-dom';
import {
  getEvaluationResultByIdService,
  getEvaluationResultItemByIdService,
  createEvaluationScheduleDownloadByIdService
} from 'src/api/modules/evaluation';
import { log, useEmployees } from 'src/hooks/EvaluationSchedule/helpers';

const useEvaluationResultSingle = () => {
  const [scheduleResultColumns, setScheduleResultColumns] = useState([]);
  const [evaluationResultItemList, setEvaluationResultItemList] = useState([]);
  const company = useSelector(state => state.userCompany);
  const costCenterList = useSelector(
    state => state.costCenter.costCenterAll.items
  );
  const { ids } = useSelector(state => state.datatable);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const evaluationResultId = useParams().id || null;
  const { employeeName } = useEmployees(evaluationResultId);
  const [resultItemInfo, setResultItemInfo] = useState('');
  const [printPDF, setPrintPDF] = useState(false);
  const [scheduleDate, setScheduleDate] = useState('');
  const [dataPDF, setDataPDF] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const columnCount = useRef(0);
  const resultItems = useRef([]);

  const inputs = [
    {
      type: 'date-range',
      options: [
        {
          label: 'Hired Date (From)',
          name: 'hired_date_from'
        },
        {
          label: 'Hired Date (To)',
          name: 'hired_date_to'
        }
      ]
    }
  ];

  const evaluationResultDataColumns = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'Name' },
    { key: 'cost_center_code', label: 'Cost Center' },
    { key: 'hired_date', label: 'Hired Date' },
    { key: 'template', label: 'Template' }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') delete searchParamsObject.search;

    if (params.status === '') delete searchParamsObject.status;

    if (params.hired_date_from === '')
      delete searchParamsObject.hired_date_from;

    if (params.hired_date_to === '') delete searchParamsObject.hired_date_to;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
      if (value === '') {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem(
      'hris-evaluation-result-item-search-params',
      searchParams
    );
  };

  const generateColumns = useCallback(
    async sections => {
      if (sections) {
        const evaluatorColumns = Array.from({ length: sections }, (_, i) => ({
          key: `evaluator_${i + 1}`,
          label: `Evaluator ${i + 1}`
        }));
        setScheduleResultColumns(columns => [
          ...columns,
          ...evaluationResultDataColumns.slice(0, 4),
          ...evaluatorColumns,
          ...evaluationResultDataColumns.slice(-1)
        ]);
      }
    },
    [evaluationResultId]
  );

  const getList = useCallback(
    async maxCount => {
      resultItems.current = [
        ...(evaluationResultItemList?.map(
          ({
            id,
            employee,
            evaluation_template,
            employee_evaluation_sections
          }) => {
            const sectionCount = employee_evaluation_sections.length;
            const missing = maxCount ? maxCount - sectionCount : 0;
            const {
              cost_center_code = '',
              date_hired = '',
              employee_info = {}
            } = employee || {};
            const item = {
              id,
              name: employeeName(employee_info),
              cost_center_code,
              cost_center_tree: costCenterTree(
                employee?.cost_center_code,
                costCenterList
              ),
              hired_date: date_hired ? formatDate(date_hired) : '-',
              template: evaluation_template?.name
            };

            if (missing > 0) {
              employee_evaluation_sections.push(
                ...Array.from({ length: missing }, () => ({
                  id: null,
                  section_id: null,
                  evaluator: null
                }))
              );
            }

            const evaluation_sections = employee_evaluation_sections?.map(
              ({ id: evaluationSectionId, evaluator }, index) => ({
                [`evaluator_${index + 1}`]:
                  evaluationSectionId && evaluator?.id
                    ? employeeName(evaluator?.employee_info)
                    : '-'
              })
            );

            return evaluation_sections?.reduce(
              (object, current) => ({ ...object, ...current }),
              { ...item }
            );
          }
        ) || [])
      ];
    },
    [evaluationResultItemList]
  );

  const scheduleResultItems = useMemo(() => {
    return resultItems.current || [];
  }, [evaluationResultItemList, resultItems.current]);

  const getResultDetails = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-result-item-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-evaluation-result-item-page-no'),
        10
      );

      const newSearchParams = savedSearchParams
        .replace('status=A&', '')
        .replace('status=A', '');

      const newPageIndex =
        savedPageIndex > totalPage ? savedPageIndex - 1 : savedPageIndex;

      const params = new URLSearchParams(newSearchParams);
      const resultItem = await getEvaluationResultItemByIdService(
        evaluationResultId
      );
      const resultDataItems = await getEvaluationResultByIdService(
        newPageIndex || page,
        params,
        evaluationResultId
      );

      columnCount.current = resultDataItems.data.max_section;

      setScheduleDate(
        `${resultItem?.data?.start_date} - ${resultItem?.data?.end_date}`
      );

      const newList =
        savedPageIndex !== 1
          ? evaluationResultItemList.concat(resultDataItems.data.items)
          : resultDataItems.data.items;

      setTotalPage(resultDataItems.data.total_pages);
      setResultItemInfo(resultItem.data);
      setEvaluationResultItemList(newList);

      if (
        resultDataItems.data.items.length === 0 ||
        resultDataItems.data.current_page >= resultDataItems.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [evaluationResultItemList]);

  const sortByName = (a, b) => {
    const nameA =
      a.employee_evaluation_master.employee?.employee_info.first_name.toLowerCase();
    const nameB =
      b.employee_evaluation_master.employee?.employee_info.first_name.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const dataPDFMemo = useMemo(() => {
    if (!dataPDF?.items?.length) {
      setPrintPDF(true);
    } else {
      setPrintPDF(false);
    }

    return dataPDF;
  }, [dataPDF]);

  useEffect(async () => {
    if (!ids.length) {
      setDataPDF({});
      return;
    }

    const dataEvaluation = {};
    const sortedItems = {};

    const submitDataIds = {
      evaluation_id: ids
    };

    const getEvaluationData = await createEvaluationScheduleDownloadByIdService(
      evaluationResultId,
      submitDataIds
    );

    if (getEvaluationData?.statusCode === 200) {
      dataEvaluation.items = getEvaluationData.data.evaluations;
      sortedItems.items = dataEvaluation.items.slice().sort(sortByName);

      setDataPDF(sortedItems);
    } else {
      setDataPDF({});
    }
  }, [ids]);

  const handlePDFDownload = async () => {
    window.open(
      `/evaluation-results/${evaluationResultId}/pdf`,
      '_blank',
      'width=1100,height=900'
    );
  };

  const evaluationComment = (evaluationItem, templateType) => {
    let evaluatorComment = '';
    const { evaluator_value } = evaluationItem;
    switch (templateType) {
      case 'R':
        evaluatorComment = evaluator_value?.comment;
        break;
      case 'T':
        evaluatorComment = evaluator_value?.how_to_achieve;
        break;
      default: // C
        evaluatorComment = evaluator_value?.question;
    }

    return evaluatorComment;
  };

  useEffect(() => {
    if (columnCount.current) {
      generateColumns(columnCount.current);
      getList(columnCount.current);
    }
  }, [evaluationResultItemList]);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-result-item-search-params'
    );
    const newSearch = location.search
      ?.replace('status=A&', '')
      .replace('status=A', '');
    const newLocation = { ...location, search: newSearch };
    const searchParams = Object.fromEntries(
      new URLSearchParams(savedSearchParams)
    );
    pushQuery(searchParams);
    setPage(1);
    localStorage.setItem(
      'hris-evaluation-result-item-page-no',
      JSON.stringify(1)
    );
    setHasMore(true);
    getResultDetails();
    navigate(newLocation);
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem(
      'hris-evaluation-result-item-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-result-item-search-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-result-item-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem(
        'hris-evaluation-result-item-page-no',
        JSON.stringify(1)
      );
      form.reset(Object.fromEntries(parsedSearchParams));
      getResultDetails();
    } else {
      // set default status
      form.reset({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    const newLocation = { ...location, search: '' };
    navigate(newLocation);
  }, []);

  return {
    form,
    hasMore,
    inputs,
    getResultDetails,
    submitFilter,
    setPrintPDF,
    handlePDFDownload,
    company,
    printPDF,
    scheduleDate,
    dataPDFMemo,
    resultItemInfo,
    evaluationResultId,
    // resultDetailsListMemo,
    evaluationResultItemList,
    evaluationResultDataColumns,
    scheduleResultColumns,
    scheduleResultItems,
    evaluationComment,
    columnCount
  };
};

export default useEvaluationResultSingle;
