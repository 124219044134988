import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import AverageLabel from 'src/components/Common/AverageLabel';
import {
  getLowerCasedValue,
  filterData,
  formatDateYMD,
  tableFormatDateTime
} from 'src/helpers/utils';
import { getAssessmentUsersService } from 'src/api/modules/assessment';
import { toast } from 'react-toastify';
import { EVALUATION_STATUS } from 'src/helpers/constants';

const useAssessmentUserList = (assessment_id, menuCode) => {
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: undefined });
  const userDataList = useSelector(state => state?.quiz?.userList);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const userListColumns = [
    { key: 'id', label: 'ID' },
    {
      key: 'first_name',
      label: 'User Name',
      userIconKey: 'image',
      lastNameKey: 'last_name'
    },
    { key: 'average', label: 'Average' },
    { key: 'status', label: 'Status' },
    { key: 'date_added', label: 'Date Added' },
    { key: 'deadline', label: 'Deadline' },
    { key: 'date_started', label: 'Date Started' },
    { key: 'date_completed', label: 'Date Completed' }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(EVALUATION_STATUS, 'label', 'value'),
      multiOptions: EVALUATION_STATUS,
      name: 'status',
      label: 'Status',
      defaultValue: ['N', 'A', 'O', 'C', 'F']
    }
  ];

  const formatItem = item => ({
    id: item.id,
    first_name: `${item.user?.first_name}`,
    last_name: `${item.user?.last_name}`,
    image: item.user?.image ? item.user.image : undefined,
    average: item.user_assessment_result?.average,
    duration: item.duration,
    status: item.status,
    date_added: formatDateYMD(item.start_date),
    deadline: formatDateYMD(item.end_date),
    date_started: tableFormatDateTime(
      item.user_assessment_result?.date_started
    ),
    date_completed:
      item.status === 'F'
        ? tableFormatDateTime(item.user_assessment_result?.date_completed)
        : '-'
  });

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-assessment-users-list-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-assessment-users-list-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);
      const res = await getAssessmentUsersService(
        assessment_id,
        savedPageIndex,
        params
      );

      let assessmentUsers = res.data.items.map(formatItem);

      if (savedPageIndex > 1) {
        assessmentUsers = userList.concat(assessmentUsers);
      }
      setUserList(assessmentUsers);

      if (
        res.data.items.length !== 0 &&
        res.data.current_page < res.data.total_pages
      ) {
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAssessmentUsersService,
    setUserList,
    setPage,
    setHasMore,
    userList
  ]);

  const userListMemo = useMemo(() => {
    return userList ?? [];
  }, [userList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-assessment-users-list-params', searchParams);
  };

  const averageFormatter = value =>
    value ? <AverageLabel value={value.toFixed()} /> : '-';

  useEffect(() => {
    localStorage.setItem(
      'hris-assessment-users-list-page-no',
      JSON.stringify(1)
    );
    const savedSearchParams = localStorage.getItem(
      'hris-assessment-users-list-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-assessment-users-list-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      form.reset(Object.fromEntries(parsedSearchParams));
    }
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      setPage(1);
      localStorage.setItem(
        'hris-assessment-users-list-page-no',
        JSON.stringify(1)
      );
      setHasMore(true);
      fetchData();
    }
  }, [location.search, userDataList]);

  useEffect(() => {
    localStorage.setItem(
      'hris-assessment-users-list-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  return {
    userList,
    page,
    hasMore,
    userListColumns,
    form,
    userListMemo,
    submitFilter,
    fetchData,
    inputs,
    averageFormatter
  };
};

export default useAssessmentUserList;
