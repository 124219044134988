import React from 'react';
import PropTypes from 'prop-types';

const EvaluationSectionTitle = ({ title, weight }) => {
  return (
    <div className="user-evaulation-section__title">
      <h1 className="user-evaluation-section__heading">{title}</h1>
    </div>
  );
};

EvaluationSectionTitle.propTypes = {
  title: PropTypes.string,
  weight: PropTypes.number
};

export default EvaluationSectionTitle;
