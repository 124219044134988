/* eslint-disable jsx-a11y/label-has-associated-control */
import prev from 'src/assets/icons/prev-white.svg';
import next from 'src/assets/icons/next-white.svg';
import { Button, TextHolder, Timer, ModalCenter } from 'src/components/Common';
import useQuiz from 'src/hooks/Pages/LearningManagement/TakeQuiz/useQuiz';
import PropTypes from 'prop-types';
import OptionCheckbox from './OptionCheckbox';
import OptionRadio from './OptionRadio';
import TakeQuizModal from './TakeQuizModal';
import SubmitQuizModal from './SubmitQuizModal';

const Quiz = ({ assessment }) => {
  const {
    questions,
    currentQuestion,
    selectedOptions,
    getCurrentQuestion,
    handleDashClick,
    handleNextClick,
    handleOptionChange,
    handleOptionCheckboxChange,
    handlePrevClick,
    assessmentAttempt,
    section,
    quizDuration,
    quiz,
    showConfirmModal,
    showConfirmSubmitModal,
    handleTakeQuizModal,
    handleConfirmTakeQuiz,
    handleCancelTakeQuiz,
    handleFinishTestClick,
    handleSubmitQuizModal,
    handleConfirmSubmitQuiz,
    handleCancelSubmitQuiz,
    startTimer,
    submitFinishedTimer
  } = useQuiz(assessment);
  const isAnswered = answered =>
    answered
      ? '!bg-[#B0C9FF] !border-[#8EB2FF] shadow-[0_0_0_2px_white_inset] text-[#444444]'
      : '!bg-white text-[#444444]';
  return (
    <div>
      <div className="flex justify-between items-center border-b border-b-[#f7f7f7] border-b-[2px] pb-[16px]">
        <h5 className="text-[14px] font-stolzlBold">
          Section {section} of{' '}
          {assessment ? Object.keys(assessment?.quizzes).length : 0}
        </h5>
        <div className="flex flex-wrap items-center items-center gap-[10px]">
          <button
            type="button"
            className="relative bg-[#bcbcbc] text-[#ffffff] rounded rounded-full w-[24px] h-[24px] flex items-center justify-center"
            onClick={handlePrevClick}
            disabled={currentQuestion === 1}
          >
            <img className="h-[10px]" src={prev} alt="Previous Icon" />
          </button>
          {questions.map(question => (
            <div className="custom__radio" key={question.sequence}>
              <input
                className="relative w-[20px] h-[20px] mr-[10px] hidden"
                type="radio"
                id={`navigation-${question.sequence}`}
                name="navQuizOptions"
                checked={question.sequence === currentQuestion}
                onChange={() => handleDashClick(question.sequence)}
              />
              <label
                className="flex items-center"
                htmlFor={`navigation-${question.sequence}`}
              >
                <span
                  className={`w-[20px] h-[20px] inline-block rounded-full border border-[#bcbcbc] text-xs flex items-center stolzlBook justify-center ${
                    question.sequence === currentQuestion
                      ? 'bg-black text-white'
                      : isAnswered(question.answered)
                  }`}
                >
                  {question.sequence}
                </span>
              </label>
            </div>
          ))}

          <button
            type="button"
            className="relative bg-[#bcbcbc] text-[#ffffff] rounded rounded-full w-[24px] h-[24px] flex items-center justify-center"
            onClick={handleNextClick}
            disabled={currentQuestion === questions.length}
          >
            <img className="h-[10px]" src={next} alt="Next Icon" />
          </button>
        </div>
        <Timer
          duration={quizDuration}
          startTimer={startTimer}
          submitFinishedTimer={submitFinishedTimer}
        />
      </div>

      <div className="max-w-[1250px] w-full mx-auto pt-[35px]">
        <p className="text-[14px] text-[#A5A5A5] mb-[5px]">
          Question {currentQuestion} of {questions.length}
          <span className="bg-[#23B53A] p-[4px] ml-[5px] text-white rounded rounded-[4px]">
            {getCurrentQuestion.points} PTS
          </span>
        </p>

        {getCurrentQuestion.content ? (
          <p className="text-[24px] font-StolzlMedium mb-[20px]">
            {getCurrentQuestion.content}
          </p>
        ) : (
          <TextHolder />
        )}

        <ul className="grid grid-cols-2 gap-[40px] border-t border-b border-t-[#f7f7f7] border-t-[2px] border-b-[#f7f7f7] border-b-[2px] py-[20px]">
          {getCurrentQuestion.is_multi_answer
            ? getCurrentQuestion.answers.map(option => (
                <OptionCheckbox
                  key={`question${currentQuestion}-option${option.id}`}
                  name={`q-${currentQuestion}-option${option.id}`}
                  item={option.content}
                  isChecked={option.selected}
                  onSelectCheckbox={() => handleOptionCheckboxChange(option.id)}
                />
              ))
            : getCurrentQuestion.answers.map(option => (
                <OptionRadio
                  key={`question${currentQuestion}-option${option.id}`}
                  currentQuestion={currentQuestion}
                  option={option}
                  handleOptionChange={handleOptionChange}
                />
              ))}
        </ul>
        <div className="flex justify-end mt-[30px]">
          <Button
            type="button"
            name="Next"
            modifier="bg-gray-500 hover:opacity-70 text-white p-[8px_14px] text-[12px] leading-[24px] rounded mr-[10px]"
            spanModifier="before:absolute before:left-0 !pl-0"
            onClick={handleNextClick}
            disabled={currentQuestion === questions.length}
          />
          <Button
            type="button"
            name="Finish Test"
            modifier="bg-[#EEEEEE] hover:opacity-70 p-[8px_14px] text-[12px] text-[#000000] leading-[24px] rounded"
            spanModifier="before:absolute before:left-0 !pl-0"
            onClick={handleFinishTestClick}
          />
        </div>
      </div>
      <ModalCenter showModal={showConfirmModal} modalName="TakeQuizModal">
        {showConfirmModal && (
          <TakeQuizModal
            handleTakeQuizModal={handleTakeQuizModal}
            handleConfirmTakeQuiz={handleConfirmTakeQuiz}
            handleCancelTakeQuiz={handleCancelTakeQuiz}
            id={quiz.id}
            modal={showConfirmModal}
            quizDuration={quizDuration}
            section={section}
            submitFinishedTimer={submitFinishedTimer}
            resume={quiz.user_attempt?.started_at !== undefined}
          />
        )}
      </ModalCenter>
      <ModalCenter
        showModal={showConfirmSubmitModal}
        modalName="SubmitQuizModal"
      >
        {showConfirmSubmitModal && (
          <SubmitQuizModal
            handleSubmitQuizModal={handleSubmitQuizModal}
            handleConfirmSubmitQuiz={handleConfirmSubmitQuiz}
            handleCancelSubmitQuiz={handleCancelSubmitQuiz}
            id={quiz.id}
            modal={showConfirmSubmitModal}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Quiz.propTypes = {
  assessment: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    quizzes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    )
  })
};

export default Quiz;
