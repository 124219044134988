/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Button from 'src/components/Common/Button';
import CriteriaPart from 'src/Pages/EvaluationTemplate/components/CriteriaPart';
import { useUserAccess } from 'src/hooks/useUserAccess';

import { FormField, FormCard } from 'src/components/Common';
// Hooks
import useEvaluationTemplate from 'src/hooks/EvaluationTemplate/useEvaluationTemplate';

const SectionPart = ({
  templateId,
  handleChange,
  handSelectChange,
  handleSectionSubmit,
  handleCriteriaSubmit,
  handleAddCriteria,
  deleteSectionSubmit,
  deleteCriteriaSubmit,
  allEmployeesOptions,
  templateFields,
  sectionFields,
  criteriaFields,
  weightFields,
  totalWeights,
  salaryGrades,
  addCriteriaFlag,
  handleDeleteTemplateModal,
  viewOnly,
  menuCode,
  criteriaError,
  formErrors
}) => {
  const { validationSectionSchema, sectionOptions } = useEvaluationTemplate();
  const { access } = useUserAccess(menuCode);

  return (
    <>
      {sectionFields.map((section, sectionIndex, score) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={sectionIndex}
          className="bg-[#f8f9fa] p-[18px] mb-[20px] rounded"
        >
          <Formik
            // enableReinitialize
            initialValues={sectionFields[sectionIndex]}
            validationSchema={validationSectionSchema}
            onSubmit={() => handleSectionSubmit(templateId, sectionIndex)}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              setFieldError
            }) => (
              <Form id="evaluationSectionForm">
                <div className="border-solid border-[1px] border-[#F8F9FA] rounded-[3px] bg-[#F8F9FA] mb-4">
                  <div className="flex">
                    <div className="w-[1120px] max-w-full">
                      <div className="flex gap-4">
                        <div className="flex-1">
                          <div className="flex gap-4">
                            {/* Start Column 1 */}
                            <div className="flex basis-1/2">
                              <div className="flex flex-col w-full gap-3">
                                <div>
                                  <FormField
                                    label="Section Names"
                                    required
                                    name="section_name"
                                    data-sectionindex={sectionIndex}
                                    type="text"
                                    placeholder=""
                                    errorMessage="Field Required"
                                    value={
                                      sectionFields[sectionIndex]
                                        ?.section_name ?? ''
                                    }
                                    error={
                                      errors.section_name &&
                                      touched.section_name
                                    }
                                    onChange={e =>
                                      handleChange(
                                        e,
                                        'section',
                                        sectionIndex,
                                        setFieldValue,
                                        setFieldTouched,
                                        setFieldError
                                      )
                                    }
                                    disabled={viewOnly}
                                  />
                                </div>
                                <div className="flex w-full gap-4">
                                  <FormCard className="!mb-0">
                                    <FormField
                                      label="Section Type"
                                      type="select"
                                      required
                                      name="section_type"
                                      errors={
                                        errors?.section_type &&
                                        touched?.section_type
                                      }
                                      placeholder="Select Section Type"
                                      options={sectionOptions}
                                      selectName="section_type"
                                      selectedValue={
                                        sectionFields[sectionIndex]
                                          ?.section_type ?? ''
                                      }
                                      onChangeValue={value =>
                                        handSelectChange(
                                          value,
                                          'section_type',
                                          {
                                            part: 'section',
                                            sectionIndex
                                          },
                                          setFieldValue,
                                          setFieldTouched,
                                          setFieldError,
                                          sectionIndex
                                        )
                                      }
                                      disabled={viewOnly}
                                    />
                                  </FormCard>
                                  <FormCard className="!mb-0">
                                    <FormField
                                      label="Evaluator"
                                      type="combobox"
                                      listType="evaluator"
                                      errors={
                                        errors?.evaluator_id &&
                                        touched?.evaluator_id
                                      }
                                      placeholder="Select Evaluator"
                                      options={allEmployeesOptions}
                                      selectName="division_list.division_code"
                                      selectedValue={
                                        sectionFields[sectionIndex]
                                          ?.evaluator_id ?? ''
                                      }
                                      onChangeValue={value => {
                                        handSelectChange(
                                          value,
                                          'evaluator_id',
                                          {
                                            part: 'section',
                                            sectionIndex
                                          },
                                          setFieldValue,
                                          setFieldTouched,
                                          setFieldError,
                                          sectionIndex
                                        );
                                      }}
                                      disabled={viewOnly}
                                    />
                                  </FormCard>
                                </div>
                              </div>
                            </div>
                            {/* End column 1 */}
                            {/* Start column 2 */}
                            <div className="flex basis-1/2">
                              <div className="w-full">
                                <FormField
                                  className="min-h-[111px]"
                                  data-sectionindex={sectionIndex}
                                  label="Section Description"
                                  required
                                  name="section_description"
                                  type="textarea"
                                  placeholder=""
                                  value={
                                    sectionFields[sectionIndex]
                                      ?.section_description ?? ''
                                  }
                                  error={
                                    errors.section_description &&
                                    touched.section_description
                                  }
                                  max="255"
                                  onChange={e =>
                                    handleChange(
                                      e,
                                      'section',
                                      sectionIndex,
                                      setFieldValue,
                                      setFieldTouched,
                                      setFieldError
                                    )
                                  }
                                  disabled={viewOnly}
                                />
                              </div>
                            </div>
                            {/* End column 2 */}
                          </div>
                        </div>

                        {!viewOnly ? (
                          <div className="flex-none">
                            <div className="flex flex-col gap-6 mt-[20px]">
                              <Button
                                name="Save"
                                modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[110px] hover:bg-gray-500"
                                spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                                type="submit"
                                disabled={
                                  isSubmitting ||
                                  sectionFields[sectionIndex].isSaveDisabled ||
                                  (templateFields.orig_status !== undefined &&
                                    templateFields.orig_status !== 'N') ||
                                  !access.can_edit
                                }
                              />

                              {sectionFields[sectionIndex].section_id ? (
                                <Button
                                  name="Delete"
                                  modifier="bg-[#e56367] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[110px] hover:bg-[#F29396]"
                                  spanModifier="before:absolute before:left-0 before:top-1/2 before:transform before:mt-[-10px] -translate-y-1/2 before:content-[url('/src/assets/icons/delete.svg')] "
                                  type="button"
                                  disabled={
                                    isSubmitting ||
                                    (templateFields.orig_status !== undefined &&
                                      templateFields.orig_status !== 'N') ||
                                    !access.can_edit ||
                                    (criteriaFields[sectionIndex] &&
                                      typeof criteriaFields[sectionIndex] ===
                                        'object' &&
                                      Object.keys(criteriaFields[sectionIndex])
                                        .length > 0)
                                  }
                                  onClick={() => {
                                    handleDeleteTemplateModal({
                                      name: 'DeleteModal',
                                      part: 'section',
                                      templateId,
                                      sectionIndex
                                    });
                                  }}
                                />
                              ) : (
                                <Button
                                  name="Cancel"
                                  modifier="bg-[#eee] text-[#232932] p-[8px_14px] text-[12px] leading-[24px] rounded w-[110px] hover:bg-opacity-75"
                                  spanModifier="before:absolute before:left-0 before:top-1/2 before:transform before:mt-[-10px] -translate-y-1/2 !pl-0"
                                  type="button"
                                  disabled={
                                    isSubmitting ||
                                    (templateFields.orig_status !== undefined &&
                                      templateFields.orig_status !== 'N') ||
                                    !access.can_edit ||
                                    (criteriaFields[sectionIndex] &&
                                      typeof criteriaFields[sectionIndex] ===
                                        'object' &&
                                      Object.keys(criteriaFields[sectionIndex])
                                        .length > 0)
                                  }
                                  onClick={() =>
                                    deleteSectionSubmit(
                                      templateId,
                                      sectionIndex
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <CriteriaPart
            criteriaError={criteriaError}
            templateId={templateId}
            handleChange={handleChange}
            handleCriteriaSubmit={handleCriteriaSubmit}
            deleteCriteriaSubmit={deleteCriteriaSubmit}
            templateFields={templateFields}
            sectionFields={sectionFields}
            criteriaFields={criteriaFields}
            totalWeights={totalWeights}
            weightFields={weightFields}
            sectionIndex={sectionIndex}
            score={score}
            salaryGrades={salaryGrades}
            handleDeleteTemplateModal={handleDeleteTemplateModal}
            viewOnly={viewOnly}
            menuCode={menuCode}
          />

          {formErrors[`criteria_error_${sectionIndex}`] && !viewOnly ? (
            <div className="text-[14px] text-[#FC5252] leading-[24px] mb-[20px] flex font-stolzlBook">
              <img
                src="./icons/error_icn.png"
                alt=""
                className="w-[17px] h-[17px] mr-[5px] mt-[2px]"
              />
              <p>Criteria not found</p>
            </div>
          ) : (
            ''
          )}

          <Formik
            enableReinitialize
            initialValues={sectionFields[sectionIndex]}
            validationSchema={validationSectionSchema}
            onSubmit={() => handleSectionSubmit(templateId, sectionIndex)}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ isSubmitting }) => (
              <Form id="evaluationAddCriteriaForm">
                {!viewOnly ? (
                  <div>
                    {sectionFields[sectionIndex].section_id ? (
                      <div className="flex justify-center border border-t-0 border-x-0 border-b-1 border-[#e1e1e1] relative">
                        <span className="bg-[#F8F9FA] absolute -bottom-2 h-[18px] flex">
                          <Button
                            name="+ Add Criteria"
                            modifier="bg-[#F8F9FA] text-[#468EFF] text-[12px] rounded px-4 hover:text-[#0264FF]"
                            spanModifier="!pl-0"
                            type="button"
                            onClick={() => handleAddCriteria(sectionIndex)}
                            disabled={
                              !addCriteriaFlag ||
                              sectionFields[sectionIndex].section_type !==
                                sectionFields[sectionIndex].orig_section_type ||
                              (templateFields.orig_status !== undefined &&
                                templateFields.orig_status !== 'N') ||
                              !access.can_edit
                            }
                          />
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Form>
            )}
          </Formik>
        </div>
      ))}
    </>
  );
};

SectionPart.propTypes = {
  sectionFields: PropTypes.arrayOf(Object)
};

export default SectionPart;
