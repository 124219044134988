const Loading = () => {
  return (
    <div className="absolute top-1/2 left-0 right-0 mx-auto text-center transform -translate-y-1/2 z-2 loading flex items-center justify-center w-[65px]">
      <div className="circles">
        <span />
      </div>
    </div>
  );
};

export default Loading;
