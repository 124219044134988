// Libraries
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

// Common Components
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import ModalCenter from 'src/components/Common/ModalCenter';
import ScheduleModalUI from 'src/components/Common/ScheduleModalUI';

// Hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useEvaluationSchedule from 'src/hooks/EvaluationSchedule/useEvaluationSchedule';

// Evaluation Components
import SchedulePart from 'src/Pages/EvaluationSchedule/components/SchedulePart';

// Utils
import { cn } from 'src/helpers/utils';

// eslint-disable-next-line react/prop-types
const HandleEvaluationSchedule = ({ menuCode, viewOnly }) => {
  const { id } = useParams();
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();
  const {
    ids,
    form,
    inputs,
    actions,
    columns,
    templateFields,
    totalResultItems,
    showStartEvaluationModal,
    evaluationStarted,
    isScheduleItemActive,
    isScheduleItemOnhold,
    isResultItemsGenerated,
    hasActiveCancelledItems,
    isScheduleNameExist,
    scheduleResultItems,
    generateExclude,
    isScheduleItemFulfilled,
    hasData,
    editCurrentId,
    handleChange,
    submitFilter,
    handleUpdateRow,
    getEvaluationInfo,
    handleDateOnChange,
    handleConfirmModal,
    handleScheduleSubmit,
    handleCloseEvaluation,
    handleStartEvaluation,
    getScheduleItemDetail,
    handleExcludeEvaluation,
    handleCloseConfirmModal,
    handleResetScheduleItem,
    handleGenerateEvaluation,
    updateSortedData
  } = useEvaluationSchedule(id, viewOnly);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Evaluation' },
          { name: 'Evaluation Schedules', link: '/evaluation-schedule' },
          {
            // eslint-disable-next-line no-nested-ternary
            name: `${!id ? 'Add Schedule' : templateFields.name ?? ''}`
          }
        ]}
      />

      <div className="user__heading evaluation-schedule">
        <div className="user__heading--title evaluation-schedule relative">
          {id ? (
            <div
              className={cn(
                'absolute top-1/2 right-0 transform -translate-y-1/2',
                isScheduleItemActive && '-right-[10px]'
              )}
            >
              {!isScheduleItemFulfilled && (
                <button
                  type="button"
                  className={cn(
                    'text-[12px] text-white font-normal leading-[100%] border-none p-[14px_19px] rounded mr-[10px] transition duration-150 bg-[#23B53A] cursor-pointer',
                    (evaluationStarted || isScheduleItemActive) &&
                      'bg-[#DD5859]',
                    (!access.can_edit ||
                      !isResultItemsGenerated ||
                      scheduleResultItems.length === 0 ||
                      scheduleResultItems.length !== totalResultItems) &&
                      'opacity-50 duration-150 cursor-not-allowed',
                    templateFields.orig_status !== 'N' &&
                      !access.can_delete &&
                      isScheduleItemActive &&
                      'hover:bg-opacity-50'
                  )}
                  onClick={() => {
                    if (evaluationStarted || isScheduleItemActive) {
                      handleCloseEvaluation();
                    } else {
                      handleConfirmModal('StartEvaluation');
                    }
                  }}
                  disabled={
                    !access.can_delete ||
                    !isResultItemsGenerated ||
                    scheduleResultItems.length === 0 ||
                    scheduleResultItems.length !== totalResultItems
                  }
                >
                  {evaluationStarted || isScheduleItemActive
                    ? 'Close Evaluation'
                    : 'Start Evaluation'}
                </button>
              )}

              {!evaluationStarted &&
                !isScheduleItemActive &&
                !isScheduleItemFulfilled && (
                  <button
                    type="button"
                    className={cn(
                      'text-[12px] text-white font-normal leading-[100%] bg-[#232932] border-none p-[14px_19px] rounded hover:bg-gray-500 transition duration-150',
                      (!access.can_edit &&
                        !isScheduleItemActive &&
                        !isResultItemsGenerated) ||
                        ((hasActiveCancelledItems || isScheduleItemOnhold) &&
                          'bg-gray-500 opacity-50 duration-150 cursor-not-allowed'),
                      templateFields.orig_status !== 'N' &&
                        !access.can_delete &&
                        'bg-gray-700 hover:bg-gray-700 cursor-not-allowed'
                    )}
                    onClick={e => {
                      e.preventDefault();
                      navigate(
                        `/evaluation-schedule/${
                          viewOnly ? 'edit' : 'view'
                        }/${id}`
                      );
                    }}
                    disabled={
                      (!access.can_delete &&
                        !isResultItemsGenerated &&
                        templateFields.orig_status !== 'N') ||
                      hasActiveCancelledItems ||
                      isScheduleItemOnhold
                    }
                  >
                    <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                      {viewOnly ? 'Edit' : 'View'}
                    </span>
                  </button>
                )}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5">
        <SchedulePart
          templateId={id}
          viewOnly={viewOnly}
          menuCode={menuCode}
          templateFields={templateFields}
          isScheduleNameExist={isScheduleNameExist}
          handleChange={handleChange}
          getEvaluationInfo={getEvaluationInfo}
          handleScheduleSubmit={handleScheduleSubmit}
        />

        {id ? (
          <div className="filter__content--search overflow-visible">
            <Filter
              searchInputPlaceholder="Username, Cost Center, Evaluators, Template"
              showActionBtn={false}
              showDeleteBtn={false}
              inputs={inputs}
              buttonLink={false}
              form={form}
              submitFilter={submitFilter}
              menuCode={menuCode}
              disabled
              dateOnChange={handleDateOnChange}
            >
              {!evaluationStarted &&
                !isScheduleItemActive &&
                id &&
                !isScheduleItemFulfilled && (
                  <div className="relative flex justify-end mt-[30px]__mb-[30px]">
                    <button
                      type="button"
                      className={cn(
                        'cursor-pointer text-[12px] text-[#232932] font-normal leading-[100%] border-none p-[14px_19px] rounded mr-[10px] bg-[#EEEEEE] hover:opacity-50 transition duration-150',
                        (!access.can_edit ||
                          isScheduleItemActive ||
                          ids.length === 0) &&
                          'opacity-50 duration-150 cursor-not-allowed',
                        (templateFields.orig_status !== 'N' ||
                          !access.can_delete) &&
                          'hover:opacity-500',
                        Boolean(ids) &&
                          generateExclude.all &&
                          generateExclude.cancelled &&
                          'opacity-50 duration-150 cursor-not-allowed'
                      )}
                      onClick={() => {
                        handleExcludeEvaluation(id);
                      }}
                      disabled={
                        ids.length === 0 ||
                        !access.can_delete ||
                        (generateExclude.all && generateExclude.cancelled)
                      }
                    >
                      Exclude
                    </button>
                    <button
                      type="button"
                      className={cn(
                        'cursor-pointer text-[12px] text-white font-normal leading-[100%] border-none p-[14px_19px] rounded bg-[#222222] hover:opacity-50 transition duration-150',
                        (!access.can_edit ||
                          isScheduleItemActive ||
                          ids.length === 0) &&
                          'opacity-50 duration-150 cursor-not-allowed',
                        (templateFields.orig_status !== 'N' ||
                          !access.can_delete) &&
                          'hover:opacity-500',
                        Boolean(ids) &&
                          generateExclude.all &&
                          generateExclude.active &&
                          'opacity-50 duration-150 cursor-not-allowed'
                      )}
                      onClick={() => handleGenerateEvaluation(id)}
                      disabled={
                        ids.length === 0 ||
                        !access.can_delete ||
                        (generateExclude.all && generateExclude.active)
                      }
                    >
                      Generate
                    </button>
                  </div>
                )}
            </Filter>
            {!hasData && <p className="text-center mt-4">Loading...</p>}
            {hasData && Boolean(columns.length) && (
              <Datatable
                keyField="id"
                shouldEllipsis
                isSchedule
                editCurrentId={editCurrentId}
                access={access}
                actions={actions}
                onExport={false}
                isExport={false}
                clickableRows={false}
                title="Evaluation Schedule"
                headingColumns={columns}
                handleUpdateRow={handleUpdateRow}
                datasource={scheduleResultItems || []}
                handleResetScheduleItem={handleResetScheduleItem}
                scheduleItem={getScheduleItemDetail}
                updateSortedData={updateSortedData}
              />
            )}
          </div>
        ) : null}
      </div>

      <ModalCenter
        showModal={showStartEvaluationModal}
        modalName="StartEvaluation"
      >
        {showStartEvaluationModal && (
          <ScheduleModalUI
            header="Start Evaluation"
            submit={handleStartEvaluation}
            cancel={() => handleCloseConfirmModal('StartEvaluation')}
          >
            <div className="mt-7">
              Are you sure you want to start
              <br /> the evaluation?
            </div>
          </ScheduleModalUI>
        )}
      </ModalCenter>
    </div>
  );
};

export default HandleEvaluationSchedule;
