import {
  GET_EVALUATION_SCHEDULE_LIST,
  GET_EVALUATION_SCHEDULE_VERIFICATION,
  GET_SCHEDULE_TEMPLATES
} from './evaluationScheduleTypes';

const INITIAL_STATE = {
  evaluationsched: [],
  scheduleverification: [],
  activeScheduleTemplates: []
};

export default function evalutionListReducers(
  state = INITIAL_STATE,
  { type, payload } = null
) {
  switch (type) {
    case GET_EVALUATION_SCHEDULE_LIST:
      return {
        ...state,
        evaluationsched: payload
      };
    case GET_EVALUATION_SCHEDULE_VERIFICATION:
      return {
        ...state,
        scheduleverification: payload
      };
    case GET_SCHEDULE_TEMPLATES:
      return {
        ...state,
        activeScheduleTemplates: payload
      };
    default:
      return state;
  }
}
