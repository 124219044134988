import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import _ from 'lodash';
import { getAllIndustry } from 'src/api/v1/modules/industry';
import { getAllUsers } from 'src/api/v1/modules/user';
import { addProjectService } from 'src/api/v1/modules/projects';
import Box from 'src/components/v1/Common/Box';
import Input from 'src/components/v1/Common/Input';
import Button from 'src/components/v1/Common/Button';
import SectionTitle from 'src/components/v1/Common/SectionTitle';
import 'src/assets/v1/css/projects.scss';

const ProjectsAddProject = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [projectName, setProjectName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientRepresentative, setClientRepresentative] = useState('');
  const [description, setDescription] = useState('');
  const [technicalDirector, setTechnicalDirector] = useState(0);
  const [projectManagerCebID, setProjectManagerCebID] = useState(0);
  const [projectManagerJPID, setProjectManagerJPID] = useState(0);
  const [bridgeEngineer, setBridgeEngineer] = useState('');
  const [Industries, setIndustries] = useState([]);
  const [IndustryID, setIndustryID] = useState(0);
  const [projectCode, setProjectCode] = useState('');
  const [technicalDirectors, setTechnicalDirectors] = useState([]);
  const [projectManagersCeb, setProjectManagersCeb] = useState([]);
  const [projectManagersJP, setProjectManagersJP] = useState([]);
  const [bridgeEngrs, setBridgeEngrs] = useState([]);

  useEffect(() => {
    async function setData() {
      const IndustryList = await getAllIndustry();
      const UserList = await getAllUsers();

      const selectDisplay = strlabel => ({
        value: 0,
        label: strlabel
      });

      const valIndustryList = IndustryList.data.data.map(industry => ({
        value: industry.id,
        label: industry.name
      }));

      const valTechList = UserList.data.data.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      }));

      const valProjMgrList = UserList.data.data.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      }));

      const valBridgeList = UserList.data.data.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      }));

      valIndustryList.unshift(selectDisplay('Select Industry'));
      setIndustries(valIndustryList);

      valTechList.unshift(selectDisplay('Select Technical Director'));
      setTechnicalDirectors(valTechList);

      valProjMgrList.unshift(selectDisplay('Select Project Manager'));
      setProjectManagersCeb(valProjMgrList);
      setProjectManagersJP(valProjMgrList);

      valBridgeList.unshift(selectDisplay('Select Bridge Engineer'));
      setBridgeEngrs(valBridgeList);
    }

    setData();
  }, []);

  const save = () => {
    setIsLoading(true);

    const data = {
      project_code: projectCode,
      name: projectName,
      start_date: startDate,
      end_date: endDate,
      client_name: clientName,
      client_representative: clientRepresentative,
      description,
      project_manager: projectManagerJPID,
      project_manager_cebu: projectManagerCebID,
      bridge_engineer: bridgeEngineer,
      technical_director: technicalDirector,
      industry: IndustryID
    };

    addProjectService(data)
      .then(res => {
        if (res.status === 200) {
          navigate('/project');
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const validate = () => {
    return (
      !_.isEmpty(projectCode) &&
      !_.isEmpty(projectName) &&
      !_.isEmpty(startDate) &&
      !_.isEmpty(endDate) &&
      !_.isEmpty(clientName) &&
      !_.isEmpty(clientRepresentative) &&
      !_.isEmpty(description) &&
      !_.isEqual(technicalDirector, 0) &&
      !_.isEqual(projectManagerCebID, 0) &&
      !_.isEqual(projectManagerJPID, 0) &&
      !_.isEmpty(bridgeEngineer)
    );
  };

  return (
    <div className="projects__add-project-container">
      <h1 className="evaluation__heading evaluation__heading--inner">
        <button
          type="button"
          className="back-button"
          onClick={() => navigate('/project')}
        />
        Add Project
      </h1>

      <div className="evaluation__breadcrumbs">
        <ul className="evaluation__breadcrumbs-list">
          <li className="evaluation__breadcrumbs-item">
            <Link to="/project">Project</Link>
          </li>
          <li className="evaluation__breadcrumbs-item">Add Project</li>
        </ul>
      </div>

      <div className="projects__add-project-heading">
        <SectionTitle title="Information" />
        <Button
          disabled={isLoading || !validate()}
          name={isLoading ? <FaSpinner className="spinner" /> : 'Save'}
          modifier="button__save dark"
          onClick={() => {
            if (validate()) {
              save();
            }
          }}
        />
      </div>

      <Box modifier="projects__add-project-form" padding="30px 90px 64px 30px">
        <div className="projects__column">
          <Input
            type="input"
            modifier="projects__input"
            label="Project Code"
            value={projectCode}
            onChange={value => setProjectCode(value)}
          />
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Project Name"
              value={projectName}
              onChange={value => setProjectName(value)}
            />
          </div>
          <div className="projects__column row">
            <div className="projects__column">
              <Input
                type="date"
                modifier="projects__input"
                label="Start Date"
                value={startDate}
                inputType="date"
                onChange={value => setStartDate(value)}
              />
            </div>
            <div className="projects__column">
              <Input
                type="date"
                modifier="projects__input"
                label="End Date"
                value={endDate}
                inputType="date"
                onChange={value => setEndDate(value)}
              />
            </div>
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Client Name"
              value={clientName}
              onChange={value => setClientName(value)}
            />
          </div>
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Client Representative"
              value={clientRepresentative}
              onChange={value => setClientRepresentative(value)}
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column projects__column-one">
            <Input
              type="textarea"
              modifier="projects__input"
              label="Description"
              value={description}
              onChange={value => setDescription(value)}
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input"
              label="Technical Director"
              selectOptions={technicalDirectors}
              value={technicalDirector}
              onChange={value => {
                setTechnicalDirector(value);
              }}
            />
          </div>
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input"
              label="Project Manager Cebu"
              selectOptions={projectManagersCeb}
              value={projectManagerCebID}
              onChange={value => {
                setProjectManagerCebID(value);
              }}
            />
          </div>
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input"
              label="Project Manager JP"
              selectOptions={projectManagersJP}
              value={projectManagerJPID}
              onChange={value => {
                setProjectManagerJPID(value);
              }}
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input"
              label="Bridge Engineer"
              selectOptions={bridgeEngrs}
              value={bridgeEngineer}
              onChange={value => setBridgeEngineer(value)}
            />
          </div>
          <div className="projects__column row projects__column-two-unequal">
            <div className="projects__column">
              <Input
                type="select"
                modifier="projects__input-select"
                selectOptions={Industries}
                value={IndustryID}
                onChange={value => {
                  setIndustryID(value);
                }}
                label="Industry"
              />
            </div>
            <div className="projects__column projects__checkbox-wrapper">
              <label
                className="projects__checkbox_custom-checkbox"
                htmlFor="outsideCody"
              >
                <input
                  type="checkbox"
                  className="projects__input-checkbox"
                  id="outsideCody"
                  name="outsideCody"
                  value="Outside Cody"
                />
                <span className="projects__checkbox-checkmark">
                  Outside Cody
                </span>
              </label>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ProjectsAddProject;
