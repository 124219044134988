import Select from 'src/components/Common/Select';
import PropTypes from 'prop-types';

const Dropdown = ({
  label,
  name,
  required = false,
  options = [],
  onChangeValue,
  selectedValue,
  disabled = false,
  placeholder = '',
  selectName,
  errors = null,
  currentValue,
  errorMessage = 'Field Required' // Default value
}) => {
  return (
    <Select
      options={options}
      onChangeValue={onChangeValue}
      selectedValue={selectedValue}
      disabled={disabled}
      selectName={selectName}
      errorMessage={errorMessage}
      placeholder={placeholder}
      required={required}
      errors={errors}
      currentValue={currentValue}
    />
  );
};

Dropdown.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({ Object })),
    PropTypes.array
  ]),
  onChangeValue: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectName: PropTypes.string,
  currentValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool
};

export default Dropdown;
