import PropTypes from 'prop-types';
import { cn } from 'src/helpers/utils';

const ModalCenter = ({ children, showModal, modalName, modifiers }) => {
  return (
    <>
      <div
        className={cn(
          'fixed block w-full h-full top-0 right-0 z-[60] bg-[#000] opacity-80 ease-out duration-300',
          showModal === modalName ? 'show' : 'hidden'
        )}
      />
      <div
        className={cn(
          'fixed w-[445px] rounded-[8px] h-[243px] left-[calc(50%)] -translate-x-1/2 -translate-y-1/2 top-1/2 bg-[#F8F9FA] shadow-[0_0_5px_rgba(0,0,0,0.1)] z-[61] ease-out duration-300 text-sm',
          showModal === modalName ? 'show' : 'hidden',
          modifiers
        )}
      >
        {children}
      </div>
    </>
  );
};

ModalCenter.propTypes = {
  children: PropTypes.element,
  showModal: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  modalName: PropTypes.string,
  modifiers: PropTypes.string
};
export default ModalCenter;
