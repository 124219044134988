import {
  SET_USER_PROJECT_DETAILS_BY_ID,
  SET_PROJECT_USERS,
  SET_PROJECT_LIST
} from './projectTypes';

const INITIAL_STATE = {
  projects: [],
  projectUsers: [],
  projectList: []
};

export default function projectReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case SET_USER_PROJECT_DETAILS_BY_ID:
      return {
        ...state,
        projects: payload
      };
    case SET_PROJECT_USERS:
      return {
        ...state,
        projectUsers: payload
      };
    case SET_PROJECT_LIST:
      return {
        ...state,
        projectList: payload
      };
    default:
      return state;
  }
}
