/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Button from 'src/components/Common/Button';
import MultipleChoiceQuestion from 'src/components/Common/MultipleChoiceQuestion';
import { useUserAccess } from 'src/hooks/useUserAccess';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import { useNavigate } from 'react-router-dom';

const AddQuestion = ({
  questionId,
  handleChange,
  handleSelectChange,
  handleQuestionSubmit,
  viewOnly,
  menuCode,
  questionFields,
  validationQuestionSchema,
  handleCheckboxChange,
  handleAnswerChange,
  statusOptions,
  skillOptions,
  handleAddOption,
  handleRemoveOption,
  optionsCount
}) => {
  const classDisabled = viewOnly ? 'bg-[#f7f7f7]' : 'bg-white';
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();
  const question_type = ['Multiple Choice', 'Essay'];
  const skillLevelOptions = ['Beginner', 'Intermediate', 'Advanced'];

  return (
    <Formik
      enableReinitialize
      initialValues={questionFields}
      validationSchema={validationQuestionSchema}
      onSubmit={() => handleQuestionSubmit(questionId)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="AddQuestionForm">
          <div className="border-solid border-[1px] border-[#dedede] p-[31px_30px_28px_30px] rounded-[3px] mb-5">
            <div className="flex justify-between w-auto pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                <div className="h-10">
                  {viewOnly ? 'View' : 'Edit'} Question Details
                </div>
              </h4>
              {viewOnly ? (
                <Button
                  name="Edit"
                  modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[100px] hover:bg-gray-500"
                  spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                  type="button"
                  disabled={!access.can_edit || questionFields.status !== 'N'}
                  onClick={e => {
                    navigate(`/questions/edit/${questionId}`);
                  }}
                />
              ) : (
                <Button
                  name="Save"
                  modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[100px] hover:bg-gray-500"
                  spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    (questionId && !access.can_edit) ||
                    !access.can_add
                  }
                />
              )}
            </div>
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-2 flex gap-5">
                  {/* 1st column */}
                  <div className="w-full">
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Type"
                          type="select"
                          name="type"
                          required
                          options={question_type}
                          onChangeValue={value =>
                            handleSelectChange(value, 'type')
                          }
                          currentValue={questionFields.type}
                          selectedValue={questionFields.type}
                          errors={errors?.type}
                          disabled={viewOnly}
                        />
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Points"
                          required
                          name="points"
                          type="number"
                          placeholder=""
                          errorMessage="Field Required"
                          value={
                            questionFields.points
                              ? questionFields.points.toString()
                              : ''
                          }
                          error={errors.points && touched.points}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          readOnly={viewOnly}
                        />
                        <ErrorMessage
                          name="points"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Skill"
                          type="select"
                          required
                          options={skillOptions}
                          onChangeValue={value =>
                            handleSelectChange(value, 'skill_id')
                          }
                          selectedValue={questionFields.skill_id}
                          errors={errors?.skill_id}
                          disabled={questionFields && viewOnly}
                          placeholder="Select Skill"
                        />
                        <ErrorMessage
                          name="skill_id"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Difficulty"
                          placeholder="Select Difficulty"
                          type="select"
                          name="level"
                          required
                          options={skillLevelOptions}
                          onChangeValue={value =>
                            handleSelectChange(value, 'level')
                          }
                          currentValue={questionFields.level}
                          selectedValue={questionFields.level}
                          errors={errors?.level}
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="level"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          className="mb-[22px]"
                          label="Status"
                          type="text"
                          name="status"
                          value={
                            statusOptions?.filter(
                              data => data.value === questionFields.status
                            )?.[0]?.label ?? 'Blanks'
                          }
                          disabled
                        />
                      </FormCard>
                      <FormCard className="flex items-center">
                        <label className="custom__checkbox inline-block align-middle text-[0] cursor-pointer">
                          <input
                            type="checkbox"
                            checked={
                              questionFields.is_multi_answer ? 'checked' : false
                            }
                            name="is_multi_answer"
                            disabled={viewOnly}
                            onChange={e => handleCheckboxChange(e)}
                            hidden
                          />
                          <span
                            className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative 
                            ${
                              viewOnly ? '!border-[#eaeaea] !bg-[#F7F7F7]' : ''
                            }`}
                          >
                            <em
                              className={`absolute block w-[5px] h-0.5 top-[9px] left-[3px] rotate-[40deg] rounded ${
                                !questionFields.is_multi_answer
                                  ? '!bg-transparent'
                                  : '!bg-white'
                              }`}
                            />
                            <em
                              className={`absolute block w-2.5 h-0.5 top-2 left-1.5 rotate-[-40deg] rounded ${
                                !questionFields.is_multi_answer
                                  ? '!bg-transparent'
                                  : '!bg-white'
                              }`}
                            />
                          </span>
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                            Multiple Answer
                          </i>
                        </label>
                      </FormCard>
                    </div>
                    <div className="w-full">
                      <label className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                        Question
                        <span className="text-[#E43B26]">*</span>
                      </label>
                      <textarea
                        className={`${
                          errors.content
                            ? 'border-[#e43b26] placeholder-[#e43b26] text-[10px]'
                            : 'border-[#eaeaea]'
                        } ${classDisabled} block w-full border-solid border-[1px] rounded text-[14px] text-[#222222] font-normal font-stolzlBook leading-[20px] h-[110px] px-3 py-[5px] resize-none`}
                        name="content"
                        value={questionFields.content ?? ''}
                        onChange={e =>
                          handleChange(
                            e,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        disabled={viewOnly}
                      />
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="text-[10px] text-[#E43B26] font-stolzlBook"
                      />
                    </div>
                  </div>
                  <MultipleChoiceQuestion
                    viewOnly={viewOnly}
                    question={questionFields}
                    onUpdateQuestion={handleAnswerChange}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    isSubmitting={isSubmitting}
                    setFieldError={setFieldError}
                    optionsCount={optionsCount}
                    handleAddOption={handleAddOption}
                    handleRemoveOption={handleRemoveOption}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddQuestion;
