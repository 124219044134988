import React, { useState, useEffect } from 'react';
import Heading from 'src/components/Common/Heading';
// eslint-disable-next-line import/no-named-as-default
import Button from 'src/components/Common/Button';
import { USER_PROJECTS, USER_INFO, USER_ALL } from 'src/api/endpoints';
import { api, auth } from 'src/api/config';
import defaultPicture from 'src/assets/icons/defaultProfile2.png';
import 'src/assets/css/activeProjects.scss';

const ActiveProjects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedProject, setHasLoadedProject] = useState(false);
  const [activeProjects, setActiveProjects] = useState([]);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [renderActiveProjectList, setRenderActiveProjectList] = useState([]);
  const [technicalDirectorDefault, setTechnicalDirectorDefault] = useState({
    id: 0,
    first_name: '-',
    last_name: ''
  });

  const [projectMangerDefault, setProjectManagerDefault] = useState({
    id: 0,
    first_name: '-',
    last_name: ''
  });

  const [bridgeEngineerDefault, setBridgeEngineerDefault] = useState({
    id: 0,
    first_name: '-',
    last_name: ''
  });

  useEffect(() => {
    fetchUser();
    fetchUsers();
    fetchProjects();
  }, []);

  useEffect(() => {
    if (!hasLoadedProject || isLoading) return;
    extractActiveProjects();
  }, [isLoading, hasLoadedProject]);

  useEffect(() => {
    if (!users.length || !user) return;
    setIsLoading(false);
  }, [users, user]);

  const fetchProjects = async () => {
    try {
      const { status, statusText, data } = await api.get(USER_PROJECTS, auth());
      if (status === 200 && statusText === 'OK') {
        setActiveProjects(data.data.active_projects);
        setHasLoadedProject(true);
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  const fetchUser = async () => {
    try {
      const { status, statusText, data } = await api.get(USER_INFO, auth());
      if (status === 200 && statusText === 'OK') {
        setUser(data.data.user);
        // checkHasLoaded();
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  const fetchUsers = async () => {
    try {
      const { status, statusText, data } = await api.get(
        `${USER_ALL}/all`,
        auth()
      );
      if (status === 200 && statusText === 'OK') {
        setUsers(data.data);
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  const extractActiveProjects = () => {
    const { id: currentUserID } = user;
    const activeProjectList = activeProjects?.map((projects, index) => {
      const {
        name,
        start_date,
        end_date,
        Members,
        TechnicalDirector,
        ProjectManager,
        BridgeEngineer
      } = projects;
      let memberStartDate;
      let memberEndDate;
      let memberPercentage;
      let memberRole;
      Members?.map(
        ({
          assignment_start_date,
          assignment_end_date,
          resource_perc,
          ProjectRole
        }) => {
          memberStartDate = assignment_start_date;
          memberEndDate = assignment_end_date;
          memberPercentage = resource_perc;
          memberRole = ProjectRole.project_role_code;

          return {
            memberStartDate,
            memberEndDate,
            memberPercentage,
            memberRole
          };
        }
      );

      if (!TechnicalDirector) setTechnicalDirectorDefault(TechnicalDirector);
      if (!ProjectManager) setProjectManagerDefault(ProjectManager);
      if (!BridgeEngineer) setBridgeEngineerDefault(BridgeEngineer);

      const {
        id: userTDId,
        first_name: firstnameTD,
        last_name: lastnameTD
      } = technicalDirectorDefault;
      const {
        id: userPMId,
        first_name: firstnamePM,
        last_name: lastnamePM
      } = projectMangerDefault;
      const {
        id: userBeId,
        first_name: firstnameBE,
        last_name: lastnameBE
      } = bridgeEngineerDefault;

      const imageTD = renderImageUserID(userTDId);
      const imagePM = renderImageUserID(userPMId);
      const imageBE = renderImageUserID(userBeId);

      const nameTD = `${firstnameTD} ${lastnameTD}`;
      const namePM = `${firstnamePM} ${lastnamePM}`;
      const nameBE = `${firstnameBE} ${lastnameBE}`;

      return {
        currentUserID,
        projectName: name,
        startDate: start_date,
        endDate: end_date,
        memberStartDate,
        memberEndDate,
        memberPercentage,
        memberRole,
        projectAdmin: {
          imageTD,
          imagePM,
          imageBE,
          TD: nameTD,
          PM: namePM,
          BE: nameBE
        }
      };
    });

    setRenderActiveProjectList(activeProjectList);
  };

  function renderImageUserID(imageID) {
    if (imageID === 0) {
      return defaultPicture;
    }
    const image = users?.filter(userData => userData.id === imageID);
    return image.image;
  }

  if (technicalDirectorDefault?.id) return null;

  return (
    <div className="activeProjects__container no-border">
      <Heading heading="Active Projects" />
      <div className="activeProjects__wrapper no-padding">
        <div className="activeProjects__inner">
          {(isLoading || !hasLoadedProject) && (
            <div className="activeProjects__texts">Loading...</div>
          )}

          {!isLoading &&
            hasLoadedProject &&
            !renderActiveProjectList.length && (
              <div className="activeProjects__texts">
                Currently no active projects
              </div>
            )}
          {renderActiveProjectList.map(
            ({
              projectName,
              memberPercentage,
              memberRole,
              currentUserID,
              memberStartDate,
              memberEndDate,
              startDate,
              endDate,
              projectAdmin
            }) => (
              <div className="activeProjects__content" key={currentUserID}>
                <div className="activeProjects__column">
                  <div className="activeProjects__row">
                    <span className="activeProjects__label">Project Name</span>
                    <span className="activeProjects__text">{projectName}</span>
                  </div>
                  <div className="activeProjects__row">
                    <span className="activeProjects__label">
                      Resource Percentage
                    </span>
                    <span className="activeProjects__text">
                      {memberPercentage}%
                    </span>
                  </div>

                  <div className="activeProjects__row">
                    <span className="activeProjects__label">Role</span>
                    <span className="activeProjects__text">{memberRole}</span>
                  </div>

                  <div className="activeProjects__row">
                    <Button
                      name="View Full Info"
                      modifier="button__info button__info_sp default"
                      openNewTab
                      link="/project"
                      projectId={currentUserID}
                    />
                  </div>
                </div>
                <div className="activeProjects__column ">
                  <div className="activeProjects__row">
                    <span className="activeProjects__label">
                      Assignment Start Date
                    </span>
                    <span className="activeProjects__text">
                      {memberStartDate}
                    </span>
                  </div>

                  <div className="activeProjects__row">
                    <span className="activeProjects__label">
                      Assignment End Date
                    </span>
                    <span className="activeProjects__text">
                      {memberEndDate}
                    </span>
                  </div>
                  <div className="activeProjects__row" />

                  <div className="activeProjects__row" />
                </div>
                <div className="activeProjects__column">
                  <div className="activeProjects__row">
                    <span className="activeProjects__label">
                      Project Start Date
                    </span>
                    <span className="activeProjects__text">{startDate}</span>
                  </div>

                  <div className="activeProjects__row">
                    <span className="activeProjects__label">
                      Project End Date
                    </span>
                    <span className="activeProjects__text">{endDate}</span>
                  </div>

                  <div className="activeProjects__row" />

                  <div className="activeProjects__row" />
                </div>

                <div className="activeProjects__column">
                  <div className="activeProjects__row activeProjects__row-members">
                    <div className="activeProjects__members">
                      <div className="activeProjects__members-img">
                        <div
                          className="activeProjects__memberImage_bg"
                          style={{
                            backgroundImage: `url(${projectAdmin.imageBE})`
                          }}
                        />
                      </div>
                      <div className="activeProjects__memberInfo">
                        <p className="activeProjects__role">Bridge Engineer</p>
                        <p className="activeProjects__member">
                          {projectAdmin.BE}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="activeProjects__row activeProjects__row-members">
                    <div className="activeProjects__members">
                      <div className="activeProjects__members-img">
                        <div
                          className="activeProjects__memberImage_bg"
                          style={{
                            backgroundImage: `url(${projectAdmin.imagePM})`
                          }}
                        />
                      </div>
                      <div className="activeProjects__memberInfo">
                        <p className="activeProjects__role">Project Manager</p>
                        <p className="activeProjects__member">
                          {projectAdmin.PM}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="activeProjects__row">
                    <div className="activeProjects__members activeProjects__row-members">
                      <div className="activeProjects__members-img">
                        <div
                          className="activeProjects__memberImage_bg"
                          style={{
                            backgroundImage: `url(${projectAdmin.imageTD})`
                          }}
                        />
                      </div>
                      <div className="activeProjects__memberInfo">
                        <p className="activeProjects__role">
                          Technical Director
                        </p>
                        <p className="activeProjects__member">
                          {projectAdmin.TD}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="activeProjects__row sp_view">
                    <Button
                      name="View Full Info"
                      modifier="button__info button__info_sp_bottom default"
                      openNewTab
                      link="/project"
                      projectId={currentUserID}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveProjects;
