import React from 'react';
import { AverageLabel } from 'src/components/Common';
import PropTypes from 'prop-types';

// utils
import {
  formatDateTime,
  calculateAveragePercentage,
  statusBadge,
  averageColor,
  cn
} from 'src/helpers/utils';

// assement result component
import ProgressBar from 'src/Pages/LearningManagement/components/ProgressBar';
import GaugeChart from 'src/Pages/LearningManagement/components/GaugeChart';

const Results = ({ assessmentResult, getRemarks }) => {
  const {
    user,
    user_assessment,
    assessment_average_score,
    assessment_top_score,
    average,
    date_started,
    date_completed
  } = assessmentResult;
  return (
    <div className="border-solid border-[1px] border-[#dedede] p-[31px_30px_28px_30px] rounded-[3px] mb-5">
      <div className="flex justify-between w-auto pb-[20px]">
        <h4 className="text-2xl font-stolzlMedium">
          {`${user?.first_name} ${user?.middle_name} ${user?.last_name}`}
        </h4>
      </div>
      <div className="flex flex-wrap gap-6">
        <div>
          <p className="font-stolzBold text-[14px] mb-[12px]">Date Started</p>
          <p className="font-stolzlBook text-[14px] mb-[12px]">
            {formatDateTime(date_started)}
          </p>
        </div>
        <div>
          <p className="font-stolzBold text-[14px] mb-[12px]">
            Date Completed:
          </p>
          <p className="font-stolzlBook text-[14px] mb-[12px]">
            {formatDateTime(date_completed)}
          </p>
        </div>
        <div>
          <p className="font-stolzBold text-[14px] mb-[12px]">Status:</p>
          {statusBadge(user_assessment?.status)}
        </div>
      </div>
      {average !== undefined && (
        <>
          <GaugeChart
            dataSet={[25, 50, 75, 100]}
            result={average.toFixed()}
            dataLabels={['0-25%', '26-50%', '51-75%', '76-100%']}
          />
          <p className="text-[24px] text-center text-[#000000] w-full font-stolzlMedium mb-[20px]">
            {getRemarks(average)}
          </p>
        </>
      )}

      <div className="grid grid-cols-2 gap-[20px] mt-8">
        <div>
          <h4 className="font-stolzMedium text-[20px] mb-[12px]">
            Test Include in Scoring
          </h4>

          {user_assessment?.user_assessment_attempts.map(item => (
            <div
              key={item.id}
              className="bg-[#F7F7F7] p-[40px_30px] border border-[#E0E0E0] rounded rounded-[4px] mb-[20px]"
            >
              <div className="flex flex-wrap justify-between">
                <p className="font-stolzBold text-[15px] mb-[15px] text-[#444444]">
                  {item.quiz.title} (
                  {`${item.score || 0}/${item.total_quiz_points}`})
                </p>
                <AverageLabel
                  className="font-stolzBold text-[15px] mb-[15px]"
                  value={calculateAveragePercentage(
                    item.score,
                    item.total_quiz_points
                  )}
                />
              </div>
              <ProgressBar
                percentage={calculateAveragePercentage(
                  item.score,
                  item.total_quiz_points
                )}
                color={averageColor(
                  calculateAveragePercentage(
                    item.score,
                    item.total_quiz_points
                  ),
                  'bg'
                )}
              />
            </div>
          ))}
        </div>
        <div>
          <h4 className="font-stolzMedium text-[20px] mb-[12px]">Average</h4>
          <div className="relative bg-[#F7F7F7] p-[45px_25px] border border-[#E0E0E0] rounded rounded-[4px]">
            <div className="relative">
              {average && (
                <div className="relative mb-[15px]">
                  <p
                    title="User Score"
                    style={{
                      left:
                        average < 100
                          ? `calc(${average}% - 28px)`
                          : `calc(${average}% - 50px)`
                    }}
                    className={`${averageColor(
                      average,
                      'bg'
                    )} text-white text-center text-[14px] rounded rounded-md p-1 w-[55px] relative z-10`}
                  >
                    {average.toFixed()}%
                    <span
                      className={cn(
                        `${averageColor(
                          average,
                          'bg'
                        )} absolute bottom-[-15px] w-[1px] h-[15px]`,
                        average < 100 && 'left-1/2 transform -translate-x-1/2',
                        average === 100 && 'left-[48px]'
                      )}
                    />
                  </p>
                </div>
              )}
              <ProgressBar
                percentage={average}
                color={averageColor(average, 'bg')}
              />
              {assessment_average_score && assessment_top_score && (
                <div className="relative h-[75px]">
                  <p
                    title="Assessment Overall Average"
                    style={{
                      left: `calc(${assessment_average_score}% - 55px)`
                    }}
                    className="bg-[#B4B4B4] text-white text-center text-[14px] rounded-b-md rounded-tl-md p-1 w-[55px] absolute z-10 top-[6px]"
                  >
                    {assessment_average_score.toFixed()}
                    %
                    <span
                      className={cn(
                        'absolute top-[-6px] w-[1px] h-[6px] bg-[#B4B4B4] left-[54px]'
                      )}
                    />
                  </p>

                  <p
                    title="Assessment Top Score"
                    style={{
                      left:
                        assessment_top_score < 100
                          ? `calc(${assessment_top_score}% - 28px)`
                          : `calc(${assessment_top_score}% - 50px)`
                    }}
                    className="bg-[#5AB269] text-white text-center text-[14px] rounded-md p-1 w-[55px] absolute z-1 top-[42px]"
                  >
                    {assessment_top_score.toFixed()}%
                    <span
                      className={cn(
                        'absolute top-[-42px] w-[1px] h-[42px] bg-[#5AB269]',
                        assessment_top_score < 100 &&
                          'left-1/2 transform -translate-x-1/2',
                        assessment_top_score === 100 && 'left-[48px]'
                      )}
                    />
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2 mt-[40px]">
              <p className="font-stolzlBook text-[14px] flex flex-wrap items-center">
                <span className="block w-[20px] h-[20px] mr-[10px] bg-[#C6C6C6] rounded rounded-full" />
                Overall Average ({assessment_average_score?.toFixed()}%)
              </p>
              <p className="font-stolzlBook text-[14px] flex flex-wrap items-center">
                <span className="block w-[20px] h-[20px] mr-[10px] bg-[#5AB269] rounded rounded-full" />
                Top Score ({assessment_top_score?.toFixed()}
                %)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {
  assessmentResult: PropTypes.shape({
    user: PropTypes.shape({
      first_name: PropTypes.string,
      middle_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    user_assessment: PropTypes.shape({
      status: PropTypes.string,
      user_assessment_attempts: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    assessment_average_score: PropTypes.number,
    assessment_top_score: PropTypes.number,
    average: PropTypes.number,
    date_started: PropTypes.string,
    date_completed: PropTypes.string
  }),
  getRemarks: PropTypes.func
};

export default Results;
