import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Content from './components/Content';
import { persistor, store } from './redux';

const App = () => {
  const header = {
    mainTitle: 'images/logo-sm.svg',
    subTitle: 'Personnel Information Management',
    shortSubTitle: 'PIM'
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Content sidebarHeader={header} />
      </PersistGate>
    </Provider>
  );
};

export default App;
