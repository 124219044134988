import React from 'react';
import { useParams } from 'react-router-dom';

// Common Components
import Breadcrumbs from 'src/components/Common/Breadcrumbs';

// Hooks
import useMyAssessmentAction from 'src/hooks/Pages/LearningManagement/MyAssessment/useMyAssessmentAction';

import ViewMyAssessment from 'src/Pages/LearningManagement/MyAssessment/components/ViewMyAssessment';

// eslint-disable-next-line react/prop-types
const MyAssessmentAction = ({ menuCode, viewOnly }) => {
  const { id } = useParams();

  const { myAssessmentInfo, handleTakeAssessment, showConfirmModal } =
    useMyAssessmentAction(id, viewOnly);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'My Assessments', link: '/my-assessments' },
          {
            name: 'Information'
          }
        ]}
      />

      <div className="mt-5">
        <ViewMyAssessment
          userAssessmentId={id}
          menuCode={menuCode}
          myAssessmentInfo={myAssessmentInfo}
          handleTakeAssessment={handleTakeAssessment}
          showConfirmModal={showConfirmModal}
        />
      </div>
    </div>
  );
};

export default MyAssessmentAction;
