import * as Yup from 'yup';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  updateAssessmentByIdService,
  createAssessmentService,
  getAssessmentByIDService
} from 'src/api/modules/assessment';

// Utilities
import { getLowerCasedValue } from 'src/helpers/utils';

// Others
import { toast } from 'react-toastify';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

import { getAllSkillsList } from 'src/redux/modules/skill/skillActions';
import { getAllStatusList } from 'src/redux/modules/status/statusActions';
import { getAllQuizList } from 'src/redux/modules/quiz/quizActions';
import { getAllUsersList } from 'src/redux/modules/users/userActions';

const useAssessmentAction = (assessmentId, viewOnly) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizRef = useRef(null);
  const usersRef = useRef(null);

  const defaultAssessmentFields = {
    skill_id: '',
    code: '',
    title: '',
    content: '',
    status: 'N',
    type: 'Q',
    quizzes: [],
    users: []
  };

  const skillList = useSelector(state => state.skill.skills);
  const statusList = useSelector(state => state.status.statuses);
  const quizList = useSelector(state => state.quiz.allQuizzes);
  const userList = useSelector(state => state.user.users);
  const form = useForm({ defaultValues: undefined });
  const [searchQuiz, setSearchQuiz] = useState([]);
  const [searchUser, setSearchUser] = useState([]);
  const [addedQuiz, setAddedQuiz] = useState([]);
  const [addedUser, setAddedUser] = useState([]);
  const [assessmentFields, setAssessmentFields] = useState(
    defaultAssessmentFields
  );

  const handleSearchQuiz = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    let foundQuiz = [];
    const {
      target: { title, value }
    } = e;
    if (value.length) {
      foundQuiz = quizList.filter(data =>
        `${getLowerCasedValue(data.title)}`.includes(
          getLowerCasedValue(e.target.value)
        )
      );
    }
    setSearchQuiz(foundQuiz);
    setFieldValue('quiz', value);
    setFieldTouched('quiz', true);
    setFieldError('quiz', '');
  };

  const handleSearchUser = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    let foundUser = [];
    const {
      target: { name, value }
    } = e;
    if (value.length) {
      foundUser = userList.filter(
        data =>
          data.status === 'A' &&
          (`${getLowerCasedValue(data.first_name)} ${getLowerCasedValue(
            data.last_name
          )}`.includes(getLowerCasedValue(e.target.value)) ||
            `${getLowerCasedValue(data.last_name)} ${getLowerCasedValue(
              data.first_name
            )}`.includes(getLowerCasedValue(e.target.value)) ||
            `${getLowerCasedValue(data.last_name)} ${getLowerCasedValue(
              data.suffix
            )} ${getLowerCasedValue(data.first_name)}`.includes(
              getLowerCasedValue(e.target.value)
            ) ||
            `${getLowerCasedValue(data.last_name)} ${getLowerCasedValue(
              data.first_name
            )} ${getLowerCasedValue(data.suffix)}`.includes(
              getLowerCasedValue(e.target.value)
            ))
      );
    }
    setSearchUser(foundUser);
    setFieldValue('users', value);
    setFieldTouched('users', true);
    setFieldError('users', '');
  };

  const handleSelectChange = useCallback((value, name) => {
    setAssessmentFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleChange = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    allowAllCharacters = true
  ) => {
    const { name, value } = e.target;
    const filteredValue = !allowAllCharacters
      ? e.target.value.replace(/[^a-zA-Z0-9]/g, '')
      : value;

    setAssessmentFields(prevState => ({
      ...prevState,
      [name]: filteredValue
    }));
  };

  const handleAddQuiz = e => {
    const dataFind = assessmentFields?.quizzes.some(data => data === e.id);
    if (dataFind) {
      setSearchQuiz([]);
      return;
    }

    const { title, code, duration, id } = e;
    const quizInfo = { title, code, duration, id };

    setAddedQuiz(prevState => [...prevState, quizInfo]);
    setAssessmentFields(prevState => ({
      ...prevState,
      quizzes: [...prevState.quizzes, id]
    }));

    setSearchQuiz([]);
    quizRef.current.value = null;
  };

  const handleAddUser = e => {
    const dataFind = assessmentFields?.users.some(
      data => data.user_id === e.id
    );
    if (dataFind) {
      setSearchUser([]);
      return;
    }

    const { id, first_name, last_name, middle_name, suffix, user_assessments } =
      e;
    const end_date = user_assessments?.end_date ?? '';
    const userAssessmentDetails = { user_id: id, end_date };
    const userInfo = {
      id,
      first_name,
      last_name,
      middle_name,
      suffix,
      user_assessments: userAssessmentDetails
    };

    setAddedUser(prevState => [...prevState, userInfo]);
    setAssessmentFields(prevState => ({
      ...prevState,
      users: [...prevState.users, { user_id: id, end_date }]
    }));

    setSearchUser([]);
    usersRef.current.value = null;
  };

  const handleDeleteQuiz = quizId => {
    const updatedAssessment = assessmentFields?.quizzes.filter(e => {
      return e !== quizId;
    });
    const updatedQuizzes = addedQuiz?.filter(e => {
      return e.id !== quizId;
    });

    setAssessmentFields(prevState => ({
      ...prevState,
      quizzes: updatedAssessment
    }));
    setAddedQuiz(updatedQuizzes);
  };

  const handleDeleteUser = userId => {
    const updatedAssessmentUser = assessmentFields?.users.filter(e => {
      return e.user_id !== userId;
    });
    const updatedUsers = addedUser?.filter(e => {
      return e.id !== userId;
    });

    setAssessmentFields(prevState => ({
      ...prevState,
      users: updatedAssessmentUser
    }));
    setAddedUser(updatedUsers);
  };

  const updateAddedUserDeadline = (user, value) => {
    const updatedAddeduser = addedUser.map(item => {
      if (item.id !== user.id) return item;

      return {
        ...item,
        user_assessments: {
          ...item.user_assessments,
          end_date: value
        }
      };
    });

    setAddedUser(updatedAddeduser);
  };

  const updateAssessmentFieldsUserDeadline = (user, value) => {
    const updatedAssessmentUser = assessmentFields?.users.map(item => {
      if (item.user_id !== user.id) return item;

      return {
        ...item,
        end_date: value
      };
    });

    setAssessmentFields(prevState => ({
      ...prevState,
      users: updatedAssessmentUser
    }));
  };

  const handleDeadlineChange = (e, user) => {
    const { value } = e.target;
    const today = moment().startOf('day');
    const dateValue = moment(value).startOf('day');
    const isDateValid = dateValue.isSameOrAfter(today);

    if (isDateValid) {
      updateAddedUserDeadline(user, value);
      updateAssessmentFieldsUserDeadline(user, value);
    }
  };

  const statusOptions = statusList
    .filter(status => status.code !== 'F')
    .map(status => ({
      value: status.code,
      label: status.name,
      hidden: false
    }));

  const skillOptions = skillList.map(skill => ({
    value: skill.id,
    label: skill.name,
    hidden: false
  }));

  const typeOptions = [
    {
      value: 'Q',
      label: 'Quiz'
    },
    {
      value: 'C',
      label: 'Coding'
    }
  ];

  const validCodeChars = /^[a-zA-Z0-9]+$/;
  const validCodeCharsMessage = 'Only letters and numbers are allowed';
  const blankSpaceRegex = /^\S.*$/;
  const blankSpageMessage =
    'Only letters with spaces, hyphens, or periods are allowed';

  const validationAssessmentSchema = Yup.object({
    skill_id: Yup.number().required('Skill is required'),
    code: Yup.string()
      .required('Code is required')
      .matches(validCodeChars, validCodeCharsMessage)
      .min(2, 'Add at least 2 characters')
      .max(8, 'Maximum of 8 characters allowed'),
    title: Yup.string()
      .required('Title is required')
      .max(255, 'Maximum 255 characters allowed')
      .matches(blankSpaceRegex, blankSpageMessage),
    content: Yup.string().required('Content is required'),
    status: Yup.string().required('Status is required'),
    type: Yup.string().required('Type is required'),
    users: Yup.array().of(
      Yup.object().shape({
        end_date: Yup.string().required('Deadline is required')
      })
    ),
    quizzes: Yup.array().min(1, 'Add at least one quiz')
  });

  const handleAssessmentSubmit = useCallback(async id => {
    try {
      if (id) {
        const updateResponse = await updateAssessmentByIdService(
          id,
          assessmentFields
        );
        if (updateResponse.statusCode === 200) {
          toast.success('Successfully Updated!', { icon: <CgCheckO /> });
          navigate(`/assessments`);
        } else {
          toast.error('Unable to update!', { icon: <CgCloseO /> });
        }
      } else {
        const addResponse = await createAssessmentService(assessmentFields);
        if (addResponse.status === 201) {
          toast.success('Successfully Added!', { icon: <CgCheckO /> });
          navigate(`/assessments`);
        } else {
          toast.error('Add Assessment Failed', { icon: <CgCloseO /> });
        }
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  });

  const getAssessmentInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getAssessmentByIDService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        const quizIds = responseData.quizzes.map(item => item.id);
        const users = responseData.users.map(item => ({
          user_id: item.id,
          end_date: item.user_assessments.end_date
        }));
        const assessmentData = {
          skill_id: responseData.skill_id,
          code: responseData.code,
          title: responseData.title,
          content: responseData.content,
          status: responseData.status,
          type: responseData.type,
          quizzes: quizIds,
          users
        };
        setAssessmentFields(assessmentData);
        setAddedQuiz(responseData.quizzes);
        setAddedUser(responseData.users);
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  };

  const statusData = statusValue => {
    let badgeClassName = '';
    let badgeText = '';
    switch (statusValue) {
      case 'A':
        badgeClassName = 'bg-[#23B53A] text-white';
        badgeText = 'Active';
        break;
      case 'C':
        badgeClassName = 'bg-[#DE5858] text-white';
        badgeText = 'Cancelled';
        break;
      case 'F':
        badgeClassName = 'bg-[#468EFF] text-white';
        badgeText = 'Fulfilled';
        break;
      case 'O':
        badgeClassName = 'bg-[#F2CC62] text-[#232932]';
        badgeText = 'Onhold';
        break;
      default:
        badgeClassName = 'bg-[#e8f1ff] text-[#232932]';
        badgeText = 'New';
    }
    return (
      <span
        className={`rounded-full text-center text-[12px] leading-[100%] h-[26px] w-[84px] inline-flex items-center justify-center ${badgeClassName}`}
      >
        {badgeText}
      </span>
    );
  };

  useEffect(() => {
    if (assessmentId) {
      getAssessmentInfo(assessmentId);
    }
  }, [viewOnly]);

  useEffect(() => {
    dispatch(getAllSkillsList());
    dispatch(getAllStatusList());
    dispatch(getAllQuizList());
    dispatch(getAllUsersList());
  }, []);

  return {
    getAssessmentInfo,
    handleChange,
    handleSelectChange,
    setAssessmentFields,
    handleSearchQuiz,
    handleAddQuiz,
    handleDeleteQuiz,
    handleAssessmentSubmit,
    validationAssessmentSchema,
    form,
    statusOptions,
    assessmentFields,
    skillList,
    searchQuiz,
    addedQuiz,
    quizRef,
    skillOptions,
    typeOptions,
    handleSearchUser,
    searchUser,
    handleAddUser,
    addedUser,
    usersRef,
    handleDeleteUser,
    handleDeadlineChange,
    statusData
  };
};

export default useAssessmentAction;
