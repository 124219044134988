import React, { useState, useEffect } from 'react';
import { fetchAPI } from 'src/api/v1/fetchAPI';
import { USER_PROJECTS, USER_INFO } from 'src/api/v1/endpoints';
import 'src/assets/v1/css/projectTeam.scss';
import PropTypes from 'prop-types';

const ProjectTeam = () => {
  const [projects, setProject] = useState([]);
  const [users, setUsers] = useState(null);
  let activeProjectFlag = false;

  const fetchProjects = async () => {
    const { data, success } = await fetchAPI({
      method: 'GET',
      endpoint: USER_PROJECTS
    });

    if (success) {
      setProject(data);
    }
  };

  const fetchUsers = async () => {
    const { data, success } = await fetchAPI({
      method: 'GET',
      endpoint: USER_INFO
    });

    if (success) {
      setUsers(data.user);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, []);

  if (!projects) return null;
  if (!users) return null;

  const { id: currentUserID } = users;
  const { active_projects } = projects;

  const renderActiveProjectList = active_projects?.map((activeProject, i) => {
    let active;
    const dateToday = new Date().toLocaleDateString(undefined, {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit'
    });

    const currentDate = new Date(dateToday);
    const { start_date, end_date, Members } = activeProject;
    const projectStartDate = new Date(start_date);
    const projectEndDate = new Date(end_date);

    Members?.map(({ assignment_start_date, assignment_end_date, User }) => {
      if (currentUserID === User.id) {
        const assignmentStartDate = new Date(assignment_start_date);
        const assignmentEndDate = new Date(assignment_end_date);
        active =
          currentDate >= projectStartDate &&
          currentDate <= projectEndDate &&
          currentDate >= assignmentStartDate &&
          currentDate <= assignmentEndDate ? (
            <ActiveProjects projects={activeProject} key={User.id} />
          ) : null;

        if (active != null) {
          activeProjectFlag = true;
        }
      }
      return null;
    });
    return active;
  });

  if (activeProjectFlag === true) {
    return <> {renderActiveProjectList} </>;
  }
  return (
    <div className="project-team__container">
      <div className="project-team__description">No data available.</div>
    </div>
  );
};

const ActiveProjects = ({ projects }) => {
  const {
    BridgeEngineer,
    ProjectManager,
    TechnicalDirector,
    ProjectManagerCebu,
    name,
    Members
  } = projects;

  const renderMembersList = array => {
    return [
      ...array.map(({ assignment_end_date, assignment_start_date, User }) => {
        const dateToday = new Date().toLocaleDateString(undefined, {
          year: 'numeric',
          day: '2-digit',
          month: '2-digit'
        });
        const currentDate = new Date(dateToday);
        const assignmentStartDate = new Date(assignment_start_date);
        const assignmentEndDate = new Date(assignment_end_date);

        if (
          currentDate >= assignmentStartDate &&
          currentDate <= assignmentEndDate
        ) {
          return {
            id: User.id,
            fullName: `${User.first_name} ${User.last_name}`
          };
        }

        return null;
      })
    ];
  };

  ActiveProjects.propTypes = {
    projects: PropTypes.objectOf(PropTypes.object())
  };

  const currentProjects = {
    projectName: name,
    projectTeamMembers: renderMembersList(Members)
  };

  return (
    <div className="project-team__container">
      <div className="project-team__row">
        <div className="project-team__column">
          <span className="project-team__label">Bridge Engineer</span>
          <span className="project-team__item">
            {BridgeEngineer?.first_name} {BridgeEngineer?.last_name}
          </span>
        </div>
        <div className="project-team__column">
          <span className="project-team__label">Project Manager</span>
          <span className="project-team__item">
            {ProjectManager?.first_name} {ProjectManager?.last_name}
          </span>
        </div>
        <div className="project-team__column">
          <span className="project-team__label">Technical Director</span>
          <span className="project-team__item">
            {TechnicalDirector?.first_name} {TechnicalDirector?.last_name}
          </span>
        </div>
        <div className="project-team__column">
          <span className="project-team__label">Cebu Project Manager</span>
          <span className="project-team__item">
            {ProjectManagerCebu?.first_name} {ProjectManagerCebu?.last_name}
          </span>
        </div>
      </div>
      <div className="project-team__row">
        <div className="project-team__column">
          <span className="project-team__label">{`Project Team Members - ${name}`}</span>
          <div className="project-team__member">
            {currentProjects?.projectTeamMembers?.map(member => (
              <span className="project-team__item" key={member.id}>
                {member}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTeam;
