import { GET_TEMPLATES, GET_TEMPLATES_STATUS } from './templatesTypes';

const INITIAL_STATE = {
  evaluationTemplates: []
};

export default function evaluationTemplatesReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_TEMPLATES:
      return {
        ...state,
        evaluationTemplates: payload
      };
    case GET_TEMPLATES_STATUS:
      return {
        ...state,
        evaluationTemplatesStatuses: payload
      };
    default:
      return state;
  }
}
