/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import MultipleChoiceQuestion from 'src/components/Common/MultipleChoiceQuestion';

const ViewModal = ({
  questionFields,
  statusOptions,
  viewOnly = true,
  optionsCount
}) => {
  return (
    <Formik>
      {errors => (
        <Form id="userForm">
          {/* Modal Head Section | Start */}
          <h4 className="text-[22px] flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
            Question Details
          </h4>
          {/* Modal Head Section | End */}

          {/* Profile section | End */}
          <div className="p-[31px_30px_28px_30px] rounded-[3px] mb-5">
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-1 flex gap-5">
                  {/* 1st column */}
                  <div className="w-full">
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Type"
                          type="text"
                          name="type"
                          required
                          value={questionFields.type}
                          disabled={viewOnly}
                        />
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Points"
                          required
                          name="points"
                          type="number"
                          placeholder=""
                          // errorMessage="Field Required"
                          value={
                            questionFields.points
                              ? questionFields.points.toString()
                              : ''
                          }
                          readOnly={viewOnly}
                        />
                        <ErrorMessage
                          name="points"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Skill"
                          type="text"
                          value={questionFields.skill_name}
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="skill_id"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Difficulty"
                          placeholder="Select Difficulty"
                          type="text"
                          name="level"
                          required
                          value={questionFields.level}
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="level"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          className="mb-[22px]"
                          label="Status"
                          type="text"
                          name="status"
                          value={
                            statusOptions?.filter(
                              data => data.value === questionFields.status
                            )?.[0]?.label ?? 'Blanks'
                          }
                          disabled
                        />
                      </FormCard>
                      <FormCard className="flex items-center">
                        <label className="custom__checkbox inline-block align-middle text-[0] cursor-pointer">
                          <input
                            type="checkbox"
                            checked={
                              questionFields.is_multi_answer ? 'checked' : false
                            }
                            name="is_multi_answer"
                            disabled
                            hidden
                          />
                          <span
                            className={`inline-block align-middle w-5 h-5 bg-white border-solid border-[1px] border-[#eaeaea] rounded ease duration-200 relative 
                            ${
                              viewOnly ? '!border-[#eaeaea] !bg-[#F7F7F7]' : ''
                            }`}
                          >
                            <em
                              className={`absolute block w-[5px] h-0.5 top-[9px] left-[3px] rotate-[40deg] rounded ${
                                !questionFields.is_multi_answer
                                  ? '!bg-transparent'
                                  : '!bg-white'
                              }`}
                            />
                            <em
                              className={`absolute block w-2.5 h-0.5 top-2 left-1.5 rotate-[-40deg] rounded ${
                                !questionFields.is_multi_answer
                                  ? '!bg-transparent'
                                  : '!bg-white'
                              }`}
                            />
                          </span>
                          <i className="text-sm font-stolzlBook inline-block align-middle cursor-pointer ml-2.5 not-italic">
                            Multiple Answer
                          </i>
                        </label>
                      </FormCard>
                    </div>
                    <div className="w-full">
                      <label className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                        Question
                        <span className="text-[#E43B26]">*</span>
                      </label>
                      <textarea
                        className="block w-full border-solid border-[1px] rounded text-[14px] text-[#222222] font-normal font-stolzlBook leading-[20px] h-[110px] px-3 py-[5px] resize-none"
                        name="content"
                        value={questionFields.content ?? ''}
                        disabled={viewOnly}
                      />
                      <ErrorMessage
                        name="question"
                        component="div"
                        className="text-[10px] text-[#E43B26] font-stolzlBook"
                      />
                    </div>
                  </div>

                  <MultipleChoiceQuestion
                    viewOnly={viewOnly}
                    question={questionFields}
                    errors={errors}
                    optionsCount={optionsCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ViewModal;
