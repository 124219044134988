import PropTypes from 'prop-types';
import { cn, averageColor } from 'src/helpers/utils';

const AverageLabel = ({ className = '', value }) => {
  const average = parseFloat(value);

  return (
    <span
      className={cn(className, value !== undefined && averageColor(average))}
    >
      {value !== undefined ? `${average.toFixed()}%` : '-'}
    </span>
  );
};

AverageLabel.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string
};

export default AverageLabel;
