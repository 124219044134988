import {
  getQuizItemsService,
  getAllQuizListService
} from 'src/api/modules/quiz';
import { GET_QUIZZES, ALL_QUIZZES } from './quizTypes';

export function setQuizDataList(payload) {
  return { type: GET_QUIZZES, payload };
}

export function setQuizzes(payload) {
  return { type: GET_QUIZZES, payload };
}

export function setAllQuizList(payload) {
  return { type: ALL_QUIZZES, payload };
}

export function getAllQuiz(objectList) {
  return function setQuizDataListAction(dispatch) {
    dispatch(setQuizDataList(objectList));
  };
}

export function getQuizzes() {
  return async function fetchALlQuiz(dispatch) {
    const response = await getQuizItemsService();
    if (response?.success && response?.data)
      dispatch(setQuizzes(response.data));
  };
}

export function getAllQuizList() {
  return async function setQuizList(dispatch) {
    const response = await getAllQuizListService();
    if (response?.success) dispatch(setAllQuizList(response.data.items));
  };
}
