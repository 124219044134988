import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchFilter from 'src/components/v1/Common/SearchFilter';
import { getEvaluationSchedules } from 'src/redux/v1/modules/evaluations/schedules/schedulesActions';
import { updateEvaluationScheduleStatusByIdService } from 'src/api/v1/modules/evaluation';

const EvaluationSchedule = () => {
  const dispatch = useDispatch();
  const { evaluationSchedules, evaluationSchedulesStatuses } = useSelector(
    state => state.evaluationSchedule
  );

  useEffect(() => {
    dispatch(getEvaluationSchedules());
  }, []);

  const scheduleColumns = [
    {
      key: 'scheduleName',
      label: 'Evaluation Name',
      readOnly: true
    },
    {
      key: 'evaluation_start_date',
      label: 'Start Date',
      readOnly: true
    },
    {
      key: 'evaluation_end_date',
      label: 'End Date',
      readOnly: true
    },
    {
      key: 'scheduleStatus',
      label: 'Status',
      type: 'select',
      placeholder: 'Select Status',
      selectOptions: evaluationSchedulesStatuses?.map(status => {
        return {
          id: status.id,
          label: status.name,
          value: status.name
        };
      }),
      onChangeFn: async dataValues => {
        const { data, selectOptions } = dataValues;

        const payload = {
          id: data.id,
          is_included: 1,
          schedule_name: data.scheduleName,
          evaluation_end_date: data.evaluation_end_date,
          evaluation_start_date: data.evaluation_start_date,
          schedule_description: data.scheduleDescription,
          status_id: selectOptions.find(
            option =>
              option.value.toLowerCase() === data.scheduleStatus.toLowerCase()
          )?.id
        };

        const { success } = await updateEvaluationScheduleStatusByIdService(
          payload
        );
        if (success) {
          dispatch(getEvaluationSchedules());
        }
      }
    }
  ];

  const evaluationScheduleData =
    evaluationSchedules?.map(schedule => {
      return {
        id: schedule.id,
        scheduleName: schedule.schedule_name,
        evaluation_start_date: schedule.evaluation_start_date,
        evaluation_end_date: schedule.evaluation_end_date,
        scheduleStatus: schedule.evaluation_schedule_statuses.name,
        scheduleDescription: schedule.description
      };
    }) ?? [];

  return (
    <SearchFilter
      searchInputPlaceholder="Search Schedules"
      buttonName="Schedule"
      buttonLink="/evaluations/schedules/add"
      title="Evaluation Schedules List"
      data={evaluationScheduleData}
      columns={scheduleColumns}
      pathName="/evaluations/schedules"
      shouldDisplayEditable
      inputs={[
        {
          label: 'Evaluation Start Date',
          type: 'datetime',
          name: 'evaluation_start_date'
        },
        {
          label: 'Evaluation End Date',
          type: 'datetime',
          name: 'evaluation_end_date'
        }
      ]}
    />
  );
};

export default EvaluationSchedule;
