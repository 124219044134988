import React from 'react';

import { Breadcrumbs } from 'src/components/Common';
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

import useEvaluationResultSingle from 'src/hooks/Pages/admin/evaluation/useEvaluationResultSingle';
import { useUserAccess } from 'src/hooks/useUserAccess';

const EvaluationResultSingle = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const {
    resultItemInfo,
    inputs,
    form,
    hasMore,
    printPDF,
    scheduleResultColumns,
    scheduleResultItems,
    getResultDetails,
    submitFilter,
    handlePDFDownload
  } = useEvaluationResultSingle();

  const dowloadButton = {
    name: 'Download',
    clickHandler: handlePDFDownload,
    type: 'button',
    disabled: printPDF
  };

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Evaluation' },
          { name: 'Evaluation Results', link: '/evaluation-results' },
          { name: resultItemInfo?.name }
        ]}
      />
      <div className="mt-5">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Name, Cost Center, Template, Evaluator"
            showActionBtn={false}
            showDeleteBtn={false}
            inputs={inputs}
            buttonLink={false}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            customButton={dowloadButton}
          />
          <InfiniteScroll
            dataLength={scheduleResultItems?.length ?? 0}
            next={getResultDetails}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                <b>
                  {scheduleResultItems?.length !== 0
                    ? 'No more data to load'
                    : 'No records found'}
                </b>
              </p>
            }
          >
            {scheduleResultColumns.length === 0 ? null : (
              <Datatable
                shouldDisplayEditable={false}
                clickableRows={false}
                headingColumns={scheduleResultColumns}
                datasource={scheduleResultItems || []}
                title="Evaluation Result Single"
                actions={[]}
                access={access}
                keyField="id"
                noPadding
                shouldEllipsis
                onExport={false}
                isExport={false}
              />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

EvaluationResultSingle.propTypes = {
  menuCode: PropTypes.string
};

export default EvaluationResultSingle;
