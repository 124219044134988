import {
  getUserProjectsHistoryByID,
  getProjectUsersService,
  getProjectList
} from 'src/api/v1/modules/projects';
import {
  SET_PROJECT_USERS,
  SET_USER_PROJECT_DETAILS_BY_ID,
  SET_PROJECT_LIST
} from './projectTypes';

export function setUserProjectsHistory(payload) {
  return { type: SET_USER_PROJECT_DETAILS_BY_ID, payload };
}

export function setProjectUsers(payload) {
  return { type: SET_PROJECT_USERS, payload };
}

export function setProjectList(payload) {
  return { type: SET_PROJECT_LIST, payload };
}

export function getUserProjectsHistory(userID) {
  return async function (dispatch) {
    const response = await getUserProjectsHistoryByID(userID);
    if (response?.data) dispatch(setUserProjectsHistory(response.data));
  };
}

export function getProjectUsers() {
  return async function (dispatch) {
    const response = await getProjectUsersService();
    if (response?.data) dispatch(setProjectUsers(response.data));
  };
}

export function getProjectListAction() {
  return async function (dispatch) {
    const response = await getProjectList();
    if (response?.data) dispatch(setProjectList(response.data));
  };
}
