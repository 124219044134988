import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'src/components/Common/Tab';
import Frontend from 'src/components/Parts/Reports/Frontend';
import 'src/assets/css/availableResource.scss';

const ReportsAvailableResource = () => {
  const navigate = useNavigate();

  const tabs = [
    {
      title: 'FRONTEND',
      component: <Frontend />
    },
    {
      title: 'BACKEND',
      component: ''
    },
    {
      title: 'DEV OPS',
      component: ''
    },
    {
      title: 'QA',
      component: ''
    },
    {
      title: 'DESIGNERS',
      component: ''
    }
  ];

  return (
    <>
      <div className="available-resource__container">
        <h1 className="available-resource__heading available-resource__heading--inner">
          <button
            type="button"
            className="back-button"
            onClick={() => navigate(-1)}
          />
          Available Resources
        </h1>
        <div className="available-resource__breadcrumbs">
          <ul className="available-resource__breadcrumbs-list">
            <li className="available-resource__breadcrumbs-item">
              <Link to="/reports">Report</Link>
            </li>
            <li className="available-resource__breadcrumbs-item">
              Available Resources
            </li>
          </ul>
        </div>
      </div>
      <Tab tabs={tabs} />
    </>
  );
};

export default ReportsAvailableResource;
