import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Input from 'src/components/v1/Common/ProjectInput';
import Box from 'src/components/v1/Common/Box';
import Button from 'src/components/v1/Common/Button';
import 'src/assets/v1/css/projects.scss';
import {
  getAllMembersService,
  getAllRolesService,
  getAllSkillsService,
  getProjectMemberByIDService,
  updateMemberService
} from 'src/api/v1/modules/projects';

const ProjectsEditMember = () => {
  const [Members, setMembers] = useState();
  const [Roles, setRoles] = useState([]);
  const [Skills, setSkills] = useState([]);
  const [ResourcePercList, setResourcePercList] = useState([]);
  const [MemberID, setMemberID] = useState(0);
  const [RoleName, setRoleName] = useState('Select Role');
  const [SkillID, setSkillID] = useState(0);
  const [ResourcePerc, setResourcePerc] = useState(10);
  const [AssignStartDate, setAssignStartDate] = useState('');
  const [AssignEndDate, setAssignEndDate] = useState('');
  const [Message, setMessage] = useState('');
  const navigate = useNavigate();
  const Param = useParams();
  const [RoleVisbility, setRoleVisbility] = useState('visible');
  const [SkillVisbility, setSkillVisbility] = useState('visible');
  const [AssignStartDateVisbility, setAssignStartDateVisbility] =
    useState('visible');
  const [AssignEndDateVisbility, setAssignEndDateVisbility] =
    useState('visible');
  const [AfterActionMessage, setAfterActionMessage] = useState('');

  useEffect(() => {
    fetchData();
    getProjectMemberId();
  }, []);

  async function fetchData() {
    const MembersList = await getAllMembersService();
    const RolesList = await getAllRolesService();
    const SkillsList = await getAllSkillsService();

    const selectMember = {
      value: 0,
      label: 'Select Member'
    };

    const selectRole = {
      value: 'Select Role',
      label: 'Select Role'
    };

    const selectSkill = {
      value: 0,
      label: 'Select Skill'
    };

    const valMembersList = MembersList.data.data.map(p => ({
      value: p.id,
      label: p.fullname
    }));
    valMembersList.unshift(selectMember);
    setMembers(valMembersList);

    const valRolesList = RolesList.data.data.map(p => ({
      value: p.name,
      label: p.name
    }));
    valRolesList.unshift(selectRole);
    setRoles(valRolesList);

    const valSkillsList = SkillsList.data.data.map(p => ({
      value: p.id,
      label: p.name
    }));
    valSkillsList.unshift(selectSkill);
    setSkills(valSkillsList);

    const valResourcePercList = [
      {
        index: 0,
        value: 10,
        label: '10%'
      },
      {
        index: 1,
        value: 20,
        label: '20%'
      },
      {
        index: 2,
        value: 30,
        label: '30%'
      },
      {
        index: 3,
        value: 40,
        label: '40%'
      },
      {
        index: 4,
        value: 50,
        label: '50%'
      },
      {
        index: 5,
        value: 60,
        label: '60%'
      },
      {
        index: 6,
        value: 70,
        label: '70%'
      },
      {
        index: 7,
        value: 80,
        label: '80%'
      },
      {
        index: 8,
        value: 90,
        label: '90%'
      },
      {
        index: 9,
        value: 100,
        label: '100%'
      }
    ];

    setResourcePercList(valResourcePercList);
  }

  const handleSave = async () => {
    if (MemberID !== 0 && SkillID !== 0 && RoleName !== 'Select Role') {
      const data = {
        skill_id: Number(SkillID),
        assignment_start_date: AssignStartDate,
        assignment_end_date: AssignEndDate,
        resource_perc: ResourcePerc,
        role: RoleName
      };
      const result = await updateMemberService(data, param.memberid);
      if (result.data.data === '*Project member successfully updated') {
        setAfterActionMessage('member-added-success');
        setMessage(result.data.data);
      } else {
        setAfterActionMessage('member-added-fail');
        setMessage(result.data.data);
      }
      setMessage(result.data.data);
    } else {
      setAfterActionMessage('member-added-fail');
      setMessage('*Please fill required field');
    }
  };

  const param = useParams();
  const getProjectMemberId = async () => {
    const result = await getProjectMemberByIDService(Number(param.memberid));
    setMemberID(result.data.data[0].user_id);
    setRoleName(
      result.data.data[0].role === null
        ? 'Select Role'
        : result.data.data[0].role
    );
    setRoleVisbility('not-visible');
    setSkillID(
      result.data.data[0].skill_id === null ? 0 : result.data.data[0].skill_id
    );
    setSkillVisbility('not-visible');
    setResourcePerc(result.data.data[0].resource_perc);
    setAssignStartDate(
      result.data.data[0].assignment_start_date === null
        ? ''
        : result.data.data[0].assignment_start_date
    );
    setAssignEndDate(
      result.data.data[0].assignment_end_date === null
        ? ''
        : result.data.data[0].assignment_end_date
    );
  };

  return (
    <div className="projects__add-member-container">
      <h1 className="page-title has-back-btn">
        <button
          type="button"
          className="back-button"
          onClick={() => navigate(`/project/${Param.id}`)}
        />
        Edit Project Member
      </h1>
      <div className="evaluation__breadcrumbs">
        <ul className="evaluation__breadcrumbs-list">
          <li className="evaluation__breadcrumbs-item">
            <Link to="/project">Project</Link>
          </li>
          <li className="evaluation__breadcrumbs-item">
            <Link to="/project">Project Details</Link>
          </li>
          <li className="evaluation__breadcrumbs-item">Edit Project Members</li>
        </ul>
      </div>

      <Box modifier="projects__add-member-form" padding="30px 90px 35px 30px">
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input-select"
              selectOptions={Members}
              value={MemberID}
              label="Member"
              disable
            />
          </div>
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input-select"
              selectOptions={Roles}
              value={RoleName}
              onChange={value => {
                setRoleName(value);
                if (value === 'Select Role') {
                  setRoleVisbility('visible');
                } else {
                  setRoleVisbility('not-visible');
                }
              }}
              label="Role"
            />
            <div className={RoleVisbility}>*required</div>
          </div>
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input-select"
              selectOptions={ResourcePercList}
              value={ResourcePerc}
              onChange={value => {
                setResourcePerc(value);
              }}
              label="Occupancy"
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="select"
              modifier="projects__input-select"
              selectOptions={Skills}
              value={SkillID}
              onChange={value => {
                setSkillID(value);
                if (Number(value) === 0) {
                  setSkillVisbility('visible');
                } else {
                  setSkillVisbility('not-visible');
                }
              }}
              label="Required Skillset"
            />
            <div className={SkillVisbility}>*required</div>
          </div>
          <div className="projects__column">
            <Input
              type="date"
              value={AssignStartDate}
              onChange={value => {
                setAssignStartDate(value);
              }}
              label="Assignment Start Date"
            />
          </div>
          <div className="projects__column">
            <Input
              type="date"
              value={AssignEndDate}
              onChange={value => {
                setAssignEndDate(value);
              }}
              label="Assignment End Date"
            />
          </div>
        </div>
      </Box>

      <div className="projects__add-member-button-wrapper">
        <div />
        <Button
          name="Save"
          modifier="button__save dark"
          onClick={() => handleSave()}
        />
      </div>
      <div className={AfterActionMessage}>
        <p>{Message}</p>
      </div>
    </div>
  );
};

export default ProjectsEditMember;
