import PropTypes from 'prop-types';
import { useBulkDeleteAssessmentModal } from 'src/hooks/Pages/LearningManagement/Assessment/useBulkDeleteAssessmentModal';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';

const BulkDeleteAssessmentModal = ({ handleBulkDeleteModal }) => {
  const { assessmentTitle, handleDelete } = useBulkDeleteAssessmentModal({
    handleBulkDeleteModal
  });

  return (
    <DeleteModalUI
      label={assessmentTitle}
      submit={() => handleDelete()}
      cancel={() => handleBulkDeleteModal(null)}
    />
  );
};
BulkDeleteAssessmentModal.propTypes = {
  handleBulkDeleteModal: PropTypes.func
};
export default BulkDeleteAssessmentModal;
