import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import 'src/assets/css/summaryreports.scss';
import ReportsAssignmentSummary from 'src/Pages/admin/reports/ReportsAssignmentSummary';

const ReportDetails = () => {
  const params = useParams();

  switch (params.id) {
    case '1':
      return <div>Section Managers Report</div>;

    case '2':
      return <div>Project Managers Report</div>;

    case '3':
      return <ReportsAssignmentSummary />;

    default:
      return <div>Reports Here</div>;
  }
};

export default ReportDetails;
