import React from 'react';
import { PageTitle, SearchFilter, Breadcrumbs } from 'src/components/Common';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import InfiniteScroll from 'react-infinite-scroll-component';
import useEvaluationResult from 'src/hooks/Pages/admin/evaluation/useEvaluationResult';
import PropTypes from 'prop-types';

const EvaluationResult = ({ menuCode }) => {
  const {
    evaluationResultList,
    evaluationResultMemo,
    hasMore,
    evaluationResultColumns,
    form,
    inputs,
    access,
    fetchData,
    submitFilter,
    handleViewPage
  } = useEvaluationResult();

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Evaluation' }, { name: 'Evaluation Results' }]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Schedule Name"
            showActionBtn={false}
            showDeleteBtn={false}
            inputs={inputs}
            buttonName=""
            buttonLink={false}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={evaluationResultMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            style={{ overflow: 'visible' }}
            endMessage={
              <p className="text-center mt-5">
                <b>
                  {evaluationResultMemo?.length !== 0
                    ? 'No more data to load'
                    : 'No records found'}
                </b>
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={evaluationResultMemo || []}
              clickableRows={false}
              headingColumns={evaluationResultColumns}
              title="Evaluation Result"
              handleViewModal={handleViewPage}
              actions={['view']}
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

EvaluationResult.propTypes = {
  menuCode: PropTypes.string
};

export default EvaluationResult;
