import _ from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, cssTransition } from 'react-toastify';
import { useContent } from 'src/hooks/components/useContent';
import Routers from 'src/Routers';

const Content = () => {
  const { location } = useContent();
  const bounce = cssTransition({
    enter: 'swing-in-top-fwd',
    exit: 'swing-out-top-bck'
  });
  return (
    <>
      {location.pathname.includes('v1') && (
        <div
          style={{
            backgroundColor: 'red',
            textAlign: 'center',
            color: 'white',
            padding: '8px 0',
            fontWeight: 'bold',
            position: 'fixed',
            top: '0',
            width: '100%',
            zIndex: '10'
          }}
        >
          HRIS - Version 1
        </div>
      )}
      <Routers />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
        transition={bounce}
      />
    </>
  );
};
export default Content;
