import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getLowerCasedValue,
  filterData,
  formatDateYMD
} from 'src/helpers/utils';
import { getAssessmentsService } from 'src/api/modules/assessment';
import { toast } from 'react-toastify';
import { STATUS_OPTIONS } from 'src/helpers/constants';

const useAssessmentIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: undefined });
  const assessmentDataList = useSelector(
    state => state?.assessment?.assessmentList
  );
  const [assessmentList, setAssessmentList] = useState([]);
  const [assessmentTitle, setAssessmentTitle] = useState(null);
  const [modalAssessmentId, setModalAssessmentId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);

  const assessmentColumns = [
    { key: 'id', label: 'ID' },
    { key: 'title', label: 'Title' },
    { key: 'code', label: 'Code' },
    { key: 'skill_name', label: 'Skill' },
    { key: 'status', label: 'Status' },
    { key: 'created_at', label: 'Created Date' }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status'
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-assessments-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-assessments-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);
      const res = await getAssessmentsService(savedPageIndex, params);
      const newList =
        savedPageIndex !== 1
          ? assessmentList.concat(res.data.items)
          : res.data.items;

      setAssessmentList(
        newList.map(o => {
          return {
            id: o.id,
            title: o.title,
            code: o.code,
            skill_name: o.skill.name,
            status: o.status,
            skill: o.skill,
            created_at: formatDateYMD(o.created_at)
          };
        })
      );

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAssessmentsService,
    setAssessmentList,
    setPage,
    setHasMore,
    assessmentList
  ]);

  const assessmentListMemo = useMemo(() => {
    return assessmentList ?? [];
  }, [assessmentList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-assessments-params', searchParams);
  };

  const handleDeleteModal = useCallback(
    ({ modal, id, name }) => {
      setShowDeleteModal(modal);
      setModalAssessmentId(modal ? id : null);
      setAssessmentTitle(modal ? name : null);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setModalAssessmentId, setAssessmentTitle]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-assessments-params');
    const savedPageIndex = localStorage.getItem('hris-assessments-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      localStorage.setItem('hris-assessments-page-no', JSON.stringify(1));
    } else {
      // set default status
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      setPage(1);
      localStorage.setItem('hris-assessments-page-no', JSON.stringify(1));
      setHasMore(true);
      fetchData();
    }
  }, [location.search, assessmentDataList]);

  useEffect(() => {
    localStorage.setItem('hris-assessments-page-no', JSON.stringify(page));
  }, [page]);

  return {
    showDeleteModal,
    assessmentList,
    assessmentTitle,
    modalAssessmentId,
    page,
    hasMore,
    assessmentColumns,
    form,
    assessmentListMemo,
    submitFilter,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    inputs
  };
};

export default useAssessmentIndex;
