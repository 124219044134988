import { memo } from 'react';
import Box from 'src/components/Common/Box';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';
import TemplateSectionItem from './TemplateSectionItem';

const TemplateSectionList = ({
  loading,
  templateID,
  sectionFields,
  isEditTemplate,
  setSectionFields
}) => {
  const isNewSection = sectionFields?.some(sec => sec.isNewSection === true);

  const handleAddSection = templateDetailID => {
    setSectionFields(prevState => [
      ...prevState,
      {
        title: '',
        description: '',
        sub_sections: [],
        isNewSection: true,
        evaluator_level: 1,
        evaluation_form_id: parseInt(templateDetailID, 10),
        g1: 0,
        g2: 0,
        g3: 0,
        sg1: 0,
        sg2: 0,
        sg3: 0
      }
    ]);
  };

  return (
    <>
      <div className="evaluation__template-section-list">
        {sectionFields?.length ? (
          sectionFields
            ?.sort((a, b) => a.id - b.id)
            ?.map((_, sectionIndex) => (
              <TemplateSectionItem
                key={sectionFields.id}
                sectionIndex={sectionIndex}
                sectionFields={sectionFields}
                isEditTemplate={isEditTemplate}
                setSectionFields={setSectionFields}
              />
            ))
        ) : (
          <div className="evaluation__template-section">
            {loading ? (
              <FaSpinner className="spinner" />
            ) : (
              <Box outline>No Sections available</Box>
            )}
          </div>
        )}
      </div>

      {loading ? null : (
        <div
          className={`evaluation__template-add-section${
            isNewSection ? ' disabled' : ''
          }`}
        >
          <button
            type="button"
            className="button button__add-white dark"
            onClick={() => handleAddSection(templateID)}
            disabled={isNewSection}
          >
            <span className="button__text">Add Section</span>
          </button>
        </div>
      )}
    </>
  );
};

TemplateSectionList.propTypes = {
  loading: PropTypes.bool,
  templateID: PropTypes.number,
  sectionFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isEditTemplate: PropTypes.bool,
  setSectionFields: PropTypes.func
};

export default memo(TemplateSectionList);
