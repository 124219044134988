import { GET_QUESTIONS, GET_ALL_QUESTIONS } from './questionTypes';

const INITIAL_STATE = {
  questionList: [],
  allQuestionsList: []
};

export default function userReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_QUESTIONS:
      return {
        ...state,
        questionList: payload
      };
    case GET_ALL_QUESTIONS:
      return {
        ...state,
        allQuestionsList: payload
      };
    default:
      return state;
  }
}
