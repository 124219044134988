import axios from 'axios';
import { auth, ENV } from 'src/api/config';
import {
  EMPLOYEE,
  SALARY_GRADES,
  GENDERS,
  CIVIL_STATUS,
  EMPLOYMENT_STATUS,
  EMPLOYEE_IMAGE_UPDATE,
  CREATE_EMPLOYEE_USER,
  POSITION,
  TEAM
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getEmployeesService = async (page, searchParams) => {
  try {
    let endpoint = `${EMPLOYEE}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

// export const getAllEmployeesService = async () => {
//   try {
//     return await fetchAPI({
//       method: 'GET',
//       endpoint: `${EMPLOYEE}/all`
//     });
//   } catch (error) {
//     return error;
//   }
// };

export const getSalaryGradeService = async data => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SALARY_GRADES}`
    });
  } catch (error) {
    return error;
  }
};

export const getGenderService = async data => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${GENDERS}`
    });
  } catch (error) {
    return error;
  }
};

export const getCivilStatusService = async data => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${CIVIL_STATUS}`
    });
  } catch (error) {
    return error;
  }
};

export const getEmploymentStatusService = async data => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${EMPLOYMENT_STATUS}`
    });
  } catch (error) {
    return error;
  }
};

export const updateEmployeeImage = async (data, progressEvent) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: EMPLOYEE_IMAGE_UPDATE,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: data,
      onUploadProgress: progressEvent
    });
  } catch (error) {
    return error;
  }
};

export const createEmployeeUserService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}/${CREATE_EMPLOYEE_USER}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const addEmployeeService = async data => {
  try {
    const res = await axios.post(`${ENV.test}/${EMPLOYEE}`, data, auth());
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getEmployeeInfoService = async employeeId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${EMPLOYEE}/${employeeId}`
    });
  } catch (error) {
    return error;
  }
};

export const updateEmployeeInfoService = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${EMPLOYEE}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const deleteEmployeeService = async employeeId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${EMPLOYEE}/${employeeId}`
    });
  } catch (error) {
    return error;
  }
};

export const getAllEmployeesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${EMPLOYEE}/all`
    });
  } catch (error) {
    return error;
  }
};
export const getTeamsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEAM}`
    });
  } catch (error) {
    return error;
  }
};

export const getPositionsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${POSITION}`
    });
  } catch (error) {
    return error;
  }
};
