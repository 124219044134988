import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getMyEmployeeEvaluationsByIdService,
  getEvaluationResultItemByIdService
} from 'src/api/modules/evaluation';

const useMyEvaluationSingle = () => {
  const [employeeDetail, setEmployeeDetail] = useState({
    employee_name: '',
    cost_center_code: '',
    salary_grade: '',
    template_name: '',
    schedule_name: '',
    evaluation_date: ''
  });
  const [evaluationGroupData, setEvaluationGroupData] = useState([]);
  const evaluationEntryId = useParams().id || null;
  const [scoreMultiInputsForm, setScoreMultiInputsForm] = useState({});
  const dispatch = useDispatch();

  const infoDataColumns = [
    { key: 'employee_name', label: 'Employee Name' },
    { key: 'cost_center_code', label: 'Cost Center' },
    { key: 'salary_grade', label: 'Salary Grade' },
    { key: 'template_name', label: 'Template Name' },
    { key: 'schedule_name', label: 'Schedule Name' },
    { key: 'evaluation_date', label: 'Evaluate Date' }
  ];

  const evaluationDataColumns = [
    { key: 'name', label: '' },
    { key: 'boss_score', label: 'Boss Score' },
    { key: 'comment', label: 'Comment' }
  ];

  const getEmployeeInfo = useCallback(async () => {
    try {
      const res = await getMyEmployeeEvaluationsByIdService(evaluationEntryId);
      const evaluationMaster = res.data.employee_evaluation_master;
      const evaluationDetails = res.data.employee_evaluation_details;
      const evaluation_schedule = await getEvaluationResultItemByIdService(
        evaluationMaster.evaluation_schedule.id
      );
      const tempGroupData = [];
      const scoreGroup = {};

      setEmployeeDetail(prevState => ({
        ...prevState,
        employee_name: `${evaluationMaster.employee.employee_info.first_name} ${
          evaluationMaster.employee.employee_info.last_name
        } ${evaluationMaster.employee.employee_info.suffix || ''}`,
        cost_center_code: evaluationMaster.employee.cost_center_code,
        salary_grade: evaluationMaster.employee.salary_grade.type,
        template_name: evaluationMaster.evaluation_template.name,
        schedule_name: evaluationMaster.evaluation_schedule.name,
        evaluation_date: `${evaluation_schedule.data.start_date} - ${evaluation_schedule.data.end_date}`
      }));

      evaluationDetails?.forEach((item, i) => {
        const no = i + 1;
        const evaluationData = {
          group_name: `${no}. ${item.template_section?.name}`,
          evaluator_id: item.evaluator?.id,
          section_id: item.id,
          type: item.template_section.type,
          details: [],
          table: []
        };
        const subScoreGroup = {};

        item.criteria_details?.forEach((sub_item, sub_i) => {
          let bossComment = '';
          switch (item.template_section.type) {
            case 'T':
              bossComment = sub_item.evaluator_value?.how_to_achieve;
              evaluationData.table = [
                { key: 'name', label: '' },
                { key: 'target_details', label: 'Target Details' },
                { key: 'boss_score', label: 'Boss Score' },
                { key: 'boss_comment', label: 'Boss Comment' }
              ];
              break;
            case 'C':
              bossComment = sub_item.evaluator_value?.question;
              evaluationData.table = [
                { key: 'name', label: '' },
                { key: 'boss_score', label: 'Boss Score' },
                { key: 'boss_comment', label: 'Boss Comment' }
              ];
              break;
            default:
              bossComment = '';
              evaluationData.table = [
                { key: 'name', label: '' },
                { key: 'boss_score', label: 'Boss Score' },
                { key: 'comment', label: 'Comment' }
              ];
              break;
          }

          const evaluationSingle = {
            name: `${
              typeof sub_item.template_criteria?.fields?.title !== 'undefined'
                ? `${no}-${sub_i + 1}. ${
                    sub_item.template_criteria?.fields?.title
                  }`
                : `${sub_item.template_criteria?.fields?.question.replaceAll(
                    '\n',
                    '<br>'
                  )}`
            }`,
            max_score: sub_item.max_rating,
            self_score: sub_item.self_score,
            boss_score: sub_item.boss_score,
            target_details: sub_item.evaluator_value?.target_details || '',
            boss_comment: bossComment || '',
            comment: sub_item.evaluator_value?.comment || '',
            description: sub_item.template_criteria.fields?.description || ''
          };

          const scores = {
            max_score: sub_item.max_rating,
            self_score: sub_item.self_score,
            boss_score: sub_item.boss_score,
            target_details: sub_item.evaluator_value?.target_details || '',
            boss_comment: bossComment || '',
            comment: sub_item.evaluator_value?.comment || ''
          };

          subScoreGroup[`criteria_id-${sub_item.id}`] = scores;
          evaluationData.details.push(evaluationSingle);
        });

        scoreGroup[`evaluation_section_id-${item.id}`] = subScoreGroup;
        tempGroupData.push(evaluationData);
      });

      setScoreMultiInputsForm(scoreGroup);
      setEvaluationGroupData(tempGroupData);
    } catch (error) {
      toast.error(error);
    }
  }, [
    employeeDetail,
    setEmployeeDetail,
    evaluationEntryId,
    getMyEmployeeEvaluationsByIdService
  ]);

  useEffect(() => {
    getEmployeeInfo();
  }, []);

  return {
    infoDataColumns,
    scoreMultiInputsForm,
    employeeDetail,
    evaluationGroupData,
    evaluationDataColumns
  };
};

export default useMyEvaluationSingle;
