import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getLowerCasedValue,
  filterData,
  autoCapitalize
} from 'src/helpers/utils';
import { getSubSectionList } from 'src/api/modules/subsections';

const useSubSection = () => {
  const dataList = useSelector(state => state.subSections.subSections.items);
  const [sectionList, setsectionList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [uniqueCode, setuniqueCode] = useState(null);
  const [uniqueCodeName, setUniqueCodeName] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();

  const sectionColumns = [
    { key: 'id', label: 'ID' },
    { key: 'sub_section_code', label: 'Sub Section Code' },
    { key: 'sub_section_name', label: 'Sub Section Name' },
    { key: 'sub_section_head', label: 'Sub Section Head' }
  ];

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-division-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-users-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }

      const res = await getSubSectionList(savedPageIndex, params);
      const newList =
        savedPageIndex !== 1
          ? sectionList.concat(res.data.items)
          : res.data.items;

      setsectionList(
        newList.map(o => {
          return {
            id: o.id,
            sub_section_code: o.sub_section_code,
            sub_section_name: autoCapitalize(o.sub_section_name),
            sub_section_head: autoCapitalize(o.sub_section_head)
          };
        })
      );

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getSubSectionList,
    setsectionList,
    setPage,
    setHasMore,
    sectionList
  ]);

  const subSectionListMemo = useMemo(() => {
    return sectionList ?? [];
  }, [sectionList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-division-params', searchParams);
  };

  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setuniqueCode(modal ? id : null);

      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setuniqueCode]
  );

  const handleViewModal = useCallback(
    (modal, id = null) => {
      setShowViewModal(modal);
      setuniqueCode(modal ? id : null);

      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setuniqueCode]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, sub_section_code }) => {
      setShowDeleteModal(modal);
      setuniqueCode(modal ? id : null);
      setUniqueCodeName(modal ? sub_section_code : null);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setuniqueCode, setUniqueCodeName]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-division-params');
    const savedPageIndex = localStorage.getItem('hris-users-page-no');
    if (savedPageIndex) {
      setPage(1);
      localStorage.setItem('hris-users-page-no', JSON.stringify(1));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-users-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      pushQuery({});
      fetchData();
    } else {
      // set default status
      form.reset({});
      pushQuery({});
    }
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-users-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, dataList]);

  useEffect(() => {
    localStorage.setItem('hris-users-page-no', JSON.stringify(page));
  }, [page]);

  return {
    dataList,
    showModal,
    showViewModal,
    showDeleteModal,
    uniqueCode,
    uniqueCodeName,
    sectionList,
    subSectionListMemo,
    page,
    hasMore,
    form,
    sectionColumns,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    submitFilter,
    showBulkDeleteModal
  };
};

export default useSubSection;
