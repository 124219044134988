import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getQuizItemsService,
  getQuizByIDService,
  deleteQuizByIdService
} from 'src/api/modules/quiz';
import { getAllQuiz } from 'src/redux/modules/quiz/quizActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

export const useDeleteQuizModal = ({ handleDeleteModal, id, modal }) => {
  const dispatch = useDispatch();
  const [quizTitle, setQuizTitle] = useState(null);

  const fetchNewQuizList = async () => {
    getQuizItemsService(1)
      .then(res => {
        dispatch(getAllQuiz(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };
  useEffect(() => {
    if (id) {
      getQuizByIDService(id).then(res => {
        setQuizTitle(`${res.data.code.trimEnd()}: ${res.data.title.trimEnd()}`);
      });
    }
  }, []);
  const handleDelete = quizId => {
    if (quizId) {
      deleteQuizByIdService(quizId).then(res => {
        if (res.success) {
          fetchNewQuizList();
          handleDeleteModal(modal);

          toast.success('Successfully Deleted!', {
            icon: <CgCheckO />,
            toastId: id
          });
        } else {
          toast.error('Unable to delete!', { icon: <CgCloseO /> });
        }
      });
    }
  };
  return {
    quizTitle,
    handleDelete
  };
};
