import { useSubmitQuizModal } from 'src/hooks/Pages/LearningManagement/TakeQuiz/useSubmitQuizModal';
import ConfirmModalUI from 'src/components/Common/ConfirmModalUI';
import PropTypes from 'prop-types';

const SubmitQuizModal = ({
  handleSubmitQuizModal,
  handleConfirmSubmitQuiz,
  handleCancelSubmitQuiz,
  id,
  modal
}) => {
  const { handleConfirm, handleCancel } = useSubmitQuizModal({
    handleSubmitQuizModal,
    handleConfirmSubmitQuiz,
    handleCancelSubmitQuiz,
    modal
  });

  return (
    <ConfirmModalUI
      title="Submit Quiz"
      message="Are you sure you want to submit this Quiz."
      confirmBtnLabel="Proceed"
      confirm={() => handleConfirm(id)}
      cancel={() => handleCancel()}
    />
  );
};

SubmitQuizModal.propTypes = {
  handleConfirmSubmitQuiz: PropTypes.func,
  handleCancelSubmitQuiz: PropTypes.func,
  handleSubmitQuizModal: PropTypes.func,
  id: PropTypes.number,
  modal: PropTypes.string
};

export default SubmitQuizModal;
