import axios from 'axios';
import { fetchAPI } from 'src/api/fetchAPI';
import {
  TEMPLATESV2,
  TEMPLATES,
  SCHEDULES,
  EVALUATION_SCHEDULES,
  SCHEDULES_VERIFICATION,
  USER_EVALUATION_STATUS,
  USER_EVALUATION_HISTORY,
  USER_EVALUATION_LIST,
  EMPLOYEE_EVALUATIONS,
  EMPLOYEE_FOR_EVALUATIONS
} from '../endpoints';
import { ENV, auth } from '../config';

// Templates
export const getEvaluationTemplatesService = async (page, searchParams) => {
  try {
    let endpoint = `${TEMPLATESV2}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      const checkSearchParams = searchParamsString
        ? `&${searchParamsString}`
        : '';
      endpoint += checkSearchParams;
    }

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getAllEvaluationTemplatesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATESV2}?all=true`
    });
  } catch (error) {
    return error;
  }
};

export const getActiveEvaluationTemplatesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATESV2}/all`
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationTemplateService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${TEMPLATESV2}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getEvaluationTemplateByIdService = async templateId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATESV2}/${templateId}`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationTemplateStatusesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATES}/statuses`
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationTemplateByIdService = async (
  endpoint,
  id,
  data
) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${endpoint}/${id}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const deleteEvaluationTemplateByIdService = async id => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${TEMPLATESV2}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteBulkTemplateService = async (ids, endpoint) => {
  try {
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateEvaluationTemplateStatusByIdService = async data => {
  const newData = { ...data };
  const templateId = newData.id;
  delete newData.id;
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${TEMPLATES}/${templateId}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

// Schedules
export const getEvaluationSchedulesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: SCHEDULES
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationSchedulesService = async data => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: SCHEDULES,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationScheduleByIdService = async scheduleId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SCHEDULES}/${scheduleId}`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationSchedulesStatusesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SCHEDULES}/statuses`
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationScheduleByIdService = async (scheduleId, data) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleId}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationScheduleStatusByIdService = async data => {
  const newData = { ...data };
  const scheduleID = newData.id;
  delete newData.id;
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleID}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const deleteEvaluationScheduleByIdService = async scheduleId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${SCHEDULES}/${scheduleId}`
    });
  } catch (error) {
    return error;
  }
};

// My Evaluation
export const getUserEvaluationHistoryService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_EVALUATION_HISTORY
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationScheduleUsersByIdService = async scheduleId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SCHEDULES}/${Number(scheduleId)}/evaluations`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationScheduleVerificationService =
  async is_included_exempted_users => {
    try {
      return await fetchAPI({
        method: 'GET',
        endpoint: `${SCHEDULES_VERIFICATION}?is_included=${is_included_exempted_users}`
      });
    } catch (error) {
      return error;
    }
  };

export const updateEvaluationScheduleUserStatusService = async (
  scheduleId,
  evaluationId,
  payload
) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleId}/evaluations/${evaluationId}`,
      body: { ...payload }
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluatorPerSectionByIdService = async (
  scheduleId,
  payload
) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleId}/evaluations/sections`,
      body: { ...payload }
    });
  } catch (error) {
    return error;
  }
};

// For Evaluation
export const getUserEvaluationListService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_EVALUATION_LIST
    });
  } catch (error) {
    return error;
  }
};

export const getUserEvaluationScheduleStatusService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_EVALUATION_STATUS
    });
  } catch (error) {
    return error;
  }
};

export const getAllEmployeeEvaluationsService = async (page, searchParams) => {
  try {
    let endpoint = `${EMPLOYEE_FOR_EVALUATIONS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

// For Evaluation Section
export const updateEvaluationTemplateSectionService = async (
  templateId,
  sectionId,
  data
) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${TEMPLATESV2}/${templateId}/sections/${sectionId}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const getEmployeeEvaluationsByIdService = async employeeId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${EMPLOYEE_FOR_EVALUATIONS}/${employeeId}`
    });
  } catch (error) {
    return error;
  }
};

export const updateEmployeeEvaluationsByIdService = async (
  evaluationId,
  data
) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${EMPLOYEE_FOR_EVALUATIONS}/${evaluationId}/answers`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationTemplateSectionService = async (
  templateId,
  data
) => {
  try {
    const response = await axios.post(
      `${ENV.test}${TEMPLATESV2}/${templateId}/sections`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteEvaluationTemplateSectionService = async ids => {
  try {
    const { id, sectionId } = ids;
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${TEMPLATESV2}/${id}/sections/${sectionId}`
    });
  } catch (error) {
    return error;
  }
};

export const getAllMyEvaluationsService = async (page, searchParams) => {
  try {
    let endpoint = `${EMPLOYEE_EVALUATIONS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

// For Evaluation Criteria
export const updateEvaluationTemplateCriteriaService = async (ids, data) => {
  try {
    const { id, sectionId, criteriaId } = ids;
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${TEMPLATESV2}/${id}/sections/${sectionId}/criterias/${criteriaId}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const getMyEmployeeEvaluationsByIdService = async employeeId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${EMPLOYEE_EVALUATIONS}/${employeeId}`
    });
  } catch (error) {
    return error;
  }
};

export const getAllEvaluationsResultsService = async (page, searchParams) => {
  try {
    let endpoint = `${EVALUATION_SCHEDULES}?page=${page}&status=F`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationTemplateCriteriaService = async (ids, data) => {
  try {
    const { id, sectionId } = ids;
    const response = await axios.post(
      `${ENV.test}${TEMPLATESV2}/${id}/sections/${sectionId}/criterias`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteEvaluationTemplateCriteriaService = async ids => {
  try {
    const { id, sectionId, criteriaId } = ids;
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${TEMPLATESV2}/${id}/sections/${sectionId}/criterias/${criteriaId}`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationResultItemByIdService = async resultId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${EVALUATION_SCHEDULES}/${resultId}`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationResultByIdService = async (
  page,
  searchParams,
  resultId
) => {
  try {
    let endpoint = `${EVALUATION_SCHEDULES}/${resultId}/employee?page=${page}&status=F`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationScheduleDownloadByIdService = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: `${EVALUATION_SCHEDULES}/${id}/employee/download`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

// For Evaluation Weights
export const updateEvaluationTemplateWeightService = async (ids, data) => {
  try {
    const { id, sectionId, criteriaId } = ids;
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${TEMPLATESV2}/${id}/sections/${sectionId}/criterias/${criteriaId}/weights`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationTemplateService = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: `${TEMPLATESV2}/${id}/approve/`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};
