/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getLowerCasedValue } from 'src/helpers/utils';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getAllEmployeeEvaluationsService } from 'src/api/modules/evaluation';
import { useEmployees } from 'src/hooks/EvaluationSchedule/helpers';

const useEvaluationEntry = () => {
  const [evaluationEntryList, setEvaluationEntryList] = useState([]);
  const [evaluationEntryColumns, setEvaluationEntryColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();
  const rawDataList = useRef([]);
  const columnCount = useRef(0);
  const entryItems = useRef([]);

  const { employeeName } = useEmployees();

  const evaluationEntryBaseColumns = [
    { key: 'name', label: 'Name' },
    { key: 'cost_center', label: 'Cost Center' },
    { key: 'template', label: 'Template' },
    { key: 'status', label: 'Status' }
  ];

  const access = {
    can_add: true,
    can_approve: true,
    can_delete: true,
    can_edit: true,
    can_generate: true,
    can_print: true,
    can_view: true
  };

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-evaluation-entry-search-params', searchParams);
  };

  const getEvaluatorEntryStatus = criteriaDetails => {
    if (!criteriaDetails) return 'N';
    const count = criteriaDetails.length;
    const hasEntryCount = criteriaDetails.filter(
      ({ evaluator_value }) => evaluator_value !== null
    ).length;
    if (hasEntryCount <= 0) return 'N';
    if (count === hasEntryCount) return 'F';
    return 'A';
  };

  const generateColumns = useCallback(
    async sections => {
      if (sections) {
        const evaluatorColumns = Array.from({ length: sections }, (_, i) => ({
          key: `evaluator_${i + 1}`,
          label: `Evaluator ${i + 1}`
        }));
        setEvaluationEntryColumns(headers => [
          ...headers,
          ...evaluationEntryBaseColumns.slice(0, 3),
          ...evaluatorColumns,
          ...evaluationEntryBaseColumns.slice(-1)
        ]);
      }
    },
    [evaluationEntryList]
  );

  const getList = useCallback(
    maxCount => {
      entryItems.current = [
        ...(evaluationEntryList?.map(
          ({
            id,
            status,
            employee,
            evaluation_template,
            employee_evaluation_sections: sections
          }) => {
            const { cost_center_code = '', employee_info } = employee || {};
            const sectionCount = sections.length;
            const missing = maxCount ? maxCount - sectionCount : 0;
            const item = {
              id,
              name: employeeName(employee_info),
              status,
              cost_center: cost_center_code,
              template: evaluation_template?.name,
              templateId: evaluation_template?.id,
              sections: sections || [],
              sectionCount
            };

            if (missing > 0) {
              sections.push(
                ...Array.from({ length: missing }, () => ({
                  id: null,
                  section_id: null,
                  evaluator: null
                }))
              );
            }

            const evaluation_sections = sections?.map(
              ({ evaluator, criteria_details }, index) => ({
                [`evaluator_${index + 1}`]: employeeName(
                  evaluator?.employee_info
                ),
                [`eval_entry_status_${index + 1}`]:
                  getEvaluatorEntryStatus(criteria_details)
              })
            );

            return evaluation_sections?.reduce(
              (object, current) => ({ ...object, ...current }),
              { ...item }
            );
          }
        ) || [])
      ];
    },
    [evaluationEntryList]
  );

  const evaluationListMemo = useMemo(() => {
    return entryItems.current || [];
  }, [evaluationEntryList, entryItems.current]);

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-entry-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-evaluation-entry-page-no'),
        10
      );

      const newSearchParams = savedSearchParams
        ?.replace('status=A&', '')
        ?.replace('status=A', '');

      const newPageIndex =
        savedPageIndex > totalPage ? savedPageIndex - 1 : savedPageIndex;

      const params = new URLSearchParams(newSearchParams);
      const res = await getAllEmployeeEvaluationsService(
        newPageIndex || page,
        params
      );

      // console.log('res: ', res);

      const newList =
        savedPageIndex !== 1
          ? evaluationEntryList.concat(res.data.items)
          : res.data.items;
      setTotalPage(res.data.total_pages);

      rawDataList.current = newList;
      setEvaluationEntryList(newList);

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAllEmployeeEvaluationsService,
    setEvaluationEntryList,
    setPage,
    setHasMore,
    evaluationEntryList
  ]);

  const handleViewPage = useCallback((modal, id = null) => {
    navigate(`/evaluation-entry/${id}`);
  }, []);

  function getMaxSectionCount(items) {
    if (!items) return 0;
    return items.reduce((max, item) => {
      if (!item || !item.employee_evaluation_sections) {
        throw new Error('Invalid item');
      }
      return Math.max(max, item.employee_evaluation_sections.length);
    }, 0);
  }

  useEffect(() => {
    columnCount.current = getMaxSectionCount(evaluationEntryList); // temp maxCount
    if (columnCount.current) {
      generateColumns(columnCount.current);
      getList(columnCount.current);
    }
  }, [evaluationEntryList]);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-entry-search-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-entry-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-evaluation-entry-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      fetchData();
    } else {
      // set default status
      form.reset({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    const newLocation = { ...location, search: '' };
    navigate(newLocation);
  }, []);

  useEffect(() => {
    const newSearch = location.search
      ?.replace('status=A&', '')
      ?.replace('status=A', '');
    const newLocation = { ...location, search: newSearch };
    setPage(1);
    localStorage.setItem('hris-evaluation-entry-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
    navigate(newLocation);
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('hris-evaluation-entry-page-no', JSON.stringify(page));
  }, [page]);

  return {
    page,
    form,
    hasMore,
    access,
    evaluationEntryColumns,
    evaluationEntryList,
    evaluationListMemo,
    fetchData,
    submitFilter,
    handleViewPage
  };
};

export default useEvaluationEntry;
