import PropTypes from 'prop-types';
import { useBulkDeleteModal } from 'src/hooks/components/Parts/User/useBulkDeleteModal';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';

const BulkDeleteModal = ({ handleBulkDeleteModal, endpoint, menuCode }) => {
  const { handleDelete } = useBulkDeleteModal({
    handleBulkDeleteModal,
    endpoint
  });

  return (
    <DeleteModalUI
      label="the selected item"
      submit={() => handleDelete()}
      cancel={() => handleBulkDeleteModal(null)}
    />
  );
};
BulkDeleteModal.propTypes = {
  handleBulkDeleteModal: PropTypes.func,
  endpoint: PropTypes.string,
  menuCode: PropTypes.string
};
export default BulkDeleteModal;
