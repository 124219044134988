import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserAccess } from 'src/hooks/useUserAccess';
import { PAGES_WITH_SUB } from 'src/helpers/constants';

export const useSidebar = ({ menuItems = [], isSidebarOpen }) => {
  const { menus } = useUserAccess();
  const pageNameSplit = useLocation().pathname.split('/');

  const firstString = pageNameSplit?.slice(1)[0];
  const lastString = pageNameSplit?.slice(-1)[0];

  const pageName = PAGES_WITH_SUB.includes(firstString)
    ? lastString
    : firstString;
  const [isHovered, setIsHoveredState] = useState(false);
  const isFullWidth = isSidebarOpen || (!isSidebarOpen && isHovered);
  const [activeDropDown, setActiveDropDown] = useState(undefined);

  const filterMenuItem = list => {
    return list?.filter(menuItem => {
      const item = menuItem;
      const menu = menus[item.menu_code];

      item.submenus = filterMenuItem(item.submenu);
      item.isActive =
        pageName === item.path.substring(item.path.lastIndexOf('/') + 1) ||
        !!item.submenus?.find(i => i.isActive);

      return (
        item.submenus?.length ||
        (menu && menu.status === 'A' && menu.user_group_access?.can_view)
      );
    });
  };

  const items = (menuItems?.length && filterMenuItem(menuItems)) || [];

  return {
    isFullWidth,
    isHovered,
    setIsHoveredState,
    activeDropDown,
    setActiveDropDown,
    items
  };
};
