import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { deleteQuestionService } from 'src/api/modules/quiz';
import { getQuestionByIDService } from 'src/api/modules/question';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

export const useDeleteQuestionModal = ({ handleDeleteModal, id, modal }) => {
  const [questionTitle, setQuestionTitle] = useState(null);

  useEffect(() => {
    if (id) {
      getQuestionByIDService(id).then(res => {
        setQuestionTitle(`${res.data.content.trimEnd()}`);
      });
    }
  }, []);
  const handleDelete = (quizId, questionId) => {
    const ids = [questionId];
    if (ids) {
      deleteQuestionService(quizId, ids).then(res => {
        if (res.success) {
          handleDeleteModal(modal);
          toast.success('Successfully Deleted!', {
            icon: <CgCheckO />,
            toastId: id
          });
        } else {
          toast.error('Unable to delete!', { icon: <CgCloseO /> });
        }
      });
    }
  };
  return {
    questionTitle,
    handleDelete
  };
};
