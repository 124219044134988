/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import Chart from 'chart.js';
import 'chartjs-gauge';
import 'chartjs-plugin-datalabels';

import AverageLabel from 'src/components/Common/AverageLabel';

const GaugeChart = ({ dataSet, result, dataLabels = [] }) => {
  useEffect(() => {
    if (dataSet && result) {
      const generateConfig = () => {
        return {
          type: 'gauge',
          data: {
            labels: dataLabels,
            datasets: [
              {
                data: dataSet,
                value: result,
                backgroundColor: ['red', 'orange', '#00bbff', '#23B53A'],
                borderWidth: 2
              }
            ]
          },
          options: {
            responsive: true,
            layout: {
              padding: {
                bottom: 30
              }
            },
            needle: {
              radiusPercentage: 2,
              widthPercentage: 3.2,
              lengthPercentage: 80,
              color: 'rgba(0, 0, 0, 1)'
            },
            valueLabel: {
              display: false
            },
            plugins: {
              datalabels: {
                display: true,
                formatter(value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
                color: 'rgba(0, 0, 0, 0.45)',
                backgroundColor: null,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            }
          }
        };
      };

      const ctx = document.getElementById('chart').getContext('2d');
      const myGauge = new Chart(ctx, generateConfig());

      return () => {
        myGauge.destroy();
      };
    }
    return null;
  }, [dataSet, result]);

  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-[374px] h-[187px]">
        <canvas id="chart" />
      </div>
      <AverageLabel
        className="text-[30px] text-center text-[#23B53A] w-full font-stolzlMedium"
        value={result}
      />
    </div>
  );
};

export default GaugeChart;
