import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getQuestionItemsService,
  getQuestionByIDService,
  deleteQuestionByIdService
} from 'src/api/modules/question';
import { getAllQuestion } from 'src/redux/modules/questions/questionActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

export const useDeleteQuestionModal = ({
  handleDeleteModal,
  id,
  modal,
  quizId
}) => {
  const dispatch = useDispatch();
  const [questionTitle, setQuestionTitle] = useState(null);

  const fetchNewQuestionList = async () => {
    const savedSearchParams = localStorage.getItem('hris-question-params');
    getQuestionItemsService(1, savedSearchParams)
      .then(res => {
        dispatch(getAllQuestion(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };
  useEffect(() => {
    if (id) {
      getQuestionByIDService(id).then(res => {
        setQuestionTitle(res.data.content);
      });
    }
  }, []);
  const handleDelete = questionId => {
    if (questionId) {
      deleteQuestionByIdService(questionId).then(res => {
        if (res.success) {
          fetchNewQuestionList();
          handleDeleteModal(modal);

          toast.success('Successfully Deleted!', {
            icon: <CgCheckO />,
            toastId: id
          });
        } else {
          toast.error('Unable to delete!', { icon: <CgCloseO /> });
        }
      });
    }
  };
  return {
    questionTitle,
    handleDelete
  };
};
