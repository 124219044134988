import axios from 'axios';
import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import { ALL_SECTIONS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getAllSectionService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_SECTIONS
    });
  } catch (error) {
    return error;
  }
};

export const getSectionsService = async (page, searchParams) => {
  try {
    let endpoint = `${ALL_SECTIONS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getSectionByIDService = async (code, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${ALL_SECTIONS}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const addSectionService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${ALL_SECTIONS}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateSectionById = async (code, data) => {
  try {
    const response = await axios.put(
      `${ENV.test}${ALL_SECTIONS}/${code}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllItemSectionService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ALL_SECTIONS}?all=true`
    });
  } catch (error) {
    return error;
  }
};
