export const evaluatorLevel = [
  {
    value: 0,
    label: 'Project'
  },
  {
    value: 1,
    label: '1 Level Higher'
  },
  {
    value: 2,
    label: '2 Level Higher'
  },
  {
    value: 3,
    label: '3 Level Higher'
  }
];
