import { getAssessmentsService } from 'src/api/modules/assessment';
import { GET_ASSESSMENTS, GET_MYASSESSMENT } from './assessmentTypes';

export function setAssessmentDataList(payload) {
  return { type: GET_ASSESSMENTS, payload };
}

export function setAssessments(payload) {
  return { type: GET_ASSESSMENTS, payload };
}

export function setMyAssessment(payload) {
  return { type: GET_MYASSESSMENT, payload };
}

export function getAllAssessments(objectList) {
  return function setAssessmentDataListAction(dispatch) {
    dispatch(setAssessmentDataList(objectList));
  };
}

export function getAssessments() {
  return async function fetchALlAssessment(dispatch) {
    const response = await getAssessmentsService();
    if (response?.success && response?.data)
      dispatch(setAssessments(response.data));
  };
}
