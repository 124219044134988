import React, { useState, useEffect, Fragment } from 'react';
import {
  useNavigate,
  useSearchParams,
  useLocation,
  createSearchParams
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FaSpinner, FaFileExport } from 'react-icons/fa';
import _ from 'lodash';

import defaultPicture from 'src/assets/icons/defaultProfile2.png';
import Input from './Input';
import IconButton from './IconButton';
import Pagination from './Pagination';
import Button from './Button';

const dateFormat = date => {
  return DateTime.fromJSDate(new Date(date)).toLocaleString();
};

const Datatable = ({
  datasource,
  headingColumns,
  title = null,
  breakOn = 'medium',
  link,
  keyField = 'id',
  clickableRows,
  openNewTab,
  editable = false,
  allowDelete = false,
  pageSize = 10,
  isTemplate = false,
  onRowDelete,
  onExport,
  onRowUpdate = () => {},
  isSelectedValue = false,
  shouldDisplayEditable = false,
  isExport = false
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const queryParamsList = Object.fromEntries([...queryParams]);

  const [columns, setColumns] = useState(headingColumns);
  const [editing, setEditing] = useState({ state: false, row: null });
  const [selectedRow, setSelectedRow] = useState(null);
  const [multipleSelectedItems, setMultipleSelectedItems] = useState([]);
  const [multiSelectLabel, setMultiSelectLabel] = useState('');

  const [data, setData] = useState();
  const [paginationKey, setPaginationKey] = useState(1);
  const [order, setOrder] = useState('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState();
  const pageCount = data ? Math.ceil(data.length / pageSize) : 0;
  const pages = _.range(1, pageCount + 1);
  const [isCurrentValue, setIsCurrentValue] = useState(false);
  const [loading, setLoading] = useState(true);

  let tableClass = 'sort-table__container-table';

  useEffect(() => {
    setOnLoadColumns();
    const searchParamsObject = { ...queryParamsList };

    if (searchParamsObject.page) {
      setCurrentPage(Number(searchParamsObject.page));
    }
  }, []);

  useEffect(() => {
    setPaginationKey(paginationKey + 1);
    setData(datasource);
    setPaginatedData(_(data).slice(0).take(pageSize).value());

    if (editable) setSelectedRow('');
    if (!!datasource?.length || datasource?.length === 0) setLoading(false);
  }, [datasource]);

  function setOnLoadColumns() {
    if (editable) {
      setColumns([...columns, 'actions']);
    }
    if (allowDelete) {
      setColumns([...columns, 'delete-actions']);
    }
    if (editable && allowDelete) {
      setColumns([...columns, 'actions', 'delete-actions']);
    }
  }

  if (editable) {
    datasource?.map((cell, index) => {
      const isEditing = editing.state && cell[keyField] === editing.row;
      const updateCell = { ...cell };
      updateCell.actions = (
        <div>
          {editable ? (
            <IconButton
              key={cell.id}
              icon={isEditing ? 'save' : 'edit'}
              color={isEditing ? 'dark' : 'light'}
              onClick={() => {
                if (isEditing) {
                  onRowUpdate({
                    ...updateCell,
                    ...selectedRow,
                    selectedItems: multipleSelectedItems
                  });
                }

                setEditing({
                  state: !isEditing,
                  row: isEditing ? null : updateCell[keyField]
                });

                if (!editing.state) return;
                setSelectedRow(updateCell);

                if (isSelectedValue) {
                  setIsCurrentValue(false);
                }
              }}
            />
          ) : null}
        </div>
      );

      return updateCell;
    });
  }

  if (allowDelete) {
    datasource?.map((cell, index) => {
      const updateCell = { ...cell };
      updateCell['delete-actions'] = (
        <div>
          {allowDelete ? (
            <IconButton
              key={cell.id}
              icon="delete"
              color="light"
              onClick={() => {
                onRowDelete(updateCell);
              }}
            />
          ) : null}
        </div>
      );
      return updateCell;
    });
  }

  const pagination = (pageNumber, source) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * pageSize;
    const paginatedPost = _(source).slice(startIndex).take(pageSize).value();
    setPaginatedData(paginatedPost);
  };

  const sorting = (column, pageNumber) => {
    if (order === 'ASC') {
      const asc = [
        ...data.sort((a, b) => {
          const isNum =
            typeof a[column] === 'number' && typeof b[column] === 'number';
          if (isNum) {
            return a[column] > b[column] ? 1 : -1;
          }
          return a[column.toLowerCase()] > b[column.toLowerCase()] ? 1 : -1;
        })
      ];
      pagination(pageNumber, asc);
      setOrder('DSC');
    }
    if (order === 'DSC') {
      const desc = [
        ...data.sort((a, b) => {
          const isNum =
            typeof a[column] === 'number' && typeof b[column] === 'number';
          if (isNum) {
            return a[column] < b[column] ? 1 : -1;
          }
          return a[column.toLowerCase()] < b[column.toLowerCase()] ? 1 : -1;
        })
      ];
      pagination(pageNumber, desc);
      setOrder('ASC');
    }
  };

  const cellData = (row, column) => {
    const value = row[column];

    if (column === 'actions' || column === 'delete-actions') {
      return value;
    }

    const isEditing = editing.state && row[keyField] === editing.row;
    const [
      {
        key,
        type,
        readOnly,
        onSelect,
        selectOptions,
        placeholder,
        userIconKey,
        onChangeFn
      }
    ] = headingColumns.filter(col => col.key === column);
    let currentValue;
    const keyId = type ? `${row.id}-${column}-${type}` : `${row.id}-${column}`;

    if ((isEditing && !readOnly) || (shouldDisplayEditable && !readOnly)) {
      return (
        <div className="sort-table__container-edit">
          {userIconKey ? (
            <div className="sort-table__container-image">
              <img
                className="sort-table__container-image-data"
                src={row[userIconKey] || defaultPicture}
                alt="User Icon"
              />
            </div>
          ) : null}

          <Input
            id={keyId}
            value={value}
            currentValue={value}
            type={type}
            placeholder={placeholder}
            selectOptions={selectOptions}
            isTemplate={isTemplate}
            onSelect={selectData => {
              if (type === 'templateMultiSelect') {
                if (selectData.selected) {
                  setMultipleSelectedItems(item => {
                    return [...item, selectData];
                  });
                  return;
                }

                if (!selectData.selected) {
                  const selectedItemIndex = multipleSelectedItems.findIndex(
                    item => item.value === selectData.value
                  );
                  setMultipleSelectedItems(
                    multipleSelectedItems.splice(1, selectedItemIndex)
                  );
                  return;
                }
                return;
              }

              setSelectedRow(rowSelected => {
                return {
                  ...rowSelected,
                  [column]: selectData
                };
              });
            }}
            onChange={itemValue => {
              if (type !== 'select') return;
              const dataIndex = datasource.findIndex(
                dataFind => dataFind.id === row.id
              );
              const updatedDatasource = { ...datasource };
              if (updatedDatasource[dataIndex]) {
                updatedDatasource[dataIndex][column] = itemValue;
              }

              onChangeFn({
                data: updatedDatasource[dataIndex],
                selectOptions,
                key
              });

              if (isSelectedValue) {
                setIsCurrentValue(true);
              }
            }}
            getLabel={setMultiSelectLabel}
          />
        </div>
      );
    }
    switch (type) {
      case 'date':
        return dateFormat(value);
      case 'multiselect':
        return multiSelectLabel || value;
      case 'select':
        return selectOptions.find(x => x.value === value)?.label || value;
      default:
        if (!userIconKey) {
          if (!value) return '-';
          return value.toString().toLowerCase();
        }
        return (
          <div className="sort-table__container-multi-data">
            <div>
              <img
                className="sort-table__container-image-data"
                src={row[userIconKey] || defaultPicture}
                alt="User Icon"
              />
            </div>
            <div className="sort-table__container-data">{value}</div>
          </div>
        );
    }
  };

  const rowData = source => {
    if (source?.length === 0) {
      return (
        <tr>
          <td colSpan="100%">No records found</td>
        </tr>
      );
    }

    return source?.map((row, srcIndex) => {
      const isEditing = editing.state && row[keyField] === editing.row;
      const cols = Object.entries(row)
        .filter(([key]) => columns.find(e => (e.key || e) === key))
        .map(([key]) => key);

      return (
        <Fragment key={row.id}>
          <tr
            className={isEditing ? 'is-editing' : ''}
            onClick={event => {
              const tagname = event.target.tagName.toLowerCase();
              if (
                (editable || shouldDisplayEditable) &&
                clickableRows &&
                tagname === 'td'
              )
                rowClick(row[keyField] ?? srcIndex);
              if (!editable && !shouldDisplayEditable && clickableRows)
                rowClick(row[keyField] ?? srcIndex);
            }}
          >
            {cols.map(col => (
              <td key={col}>{cellData(row, col)}</td>
            ))}
          </tr>
        </Fragment>
      );
    });
  };

  const rowClick = key => {
    if (clickableRows && !_.isEmpty(key.toString())) {
      if (openNewTab) {
        window.open(`${link}/${key}`, '_blank');
      } else {
        navigate(`${link}/${key}`);
      }
    }
  };

  switch (breakOn) {
    case 'small':
      tableClass += ' sort-table-container__table--break-sm';
      break;
    case 'medium':
      tableClass += ' sort-table-container__table--break-md';
      break;
    default:
      tableClass += ' sort-table-container__table--break-lg';
      break;
  }

  if (!datasource) return null;
  if (loading) return <FaSpinner className="spinner" />;
  const className = `${tableClass} clickable_result`;
  return (
    <div className="sort-table__container">
      {title ? (
        <div
          className={`sort-table__container-title${isExport ? ' flex' : ''}`}
        >
          <h2>{title}</h2>
          {isExport ? (
            <Button
              name="Export Score"
              modifier="button__default dark flex"
              type="button"
              onClick={onExport}
            >
              <FaFileExport />
            </Button>
          ) : null}
        </div>
      ) : null}
      <table className={clickableRows === true ? className : tableClass}>
        <thead>
          <tr>
            {columns.map((column, i) => {
              const { key, label } = column;
              if (column === 'actions' || column === 'delete-actions') {
                return (
                  <th
                    key={key}
                    data-key={column}
                    className={`action-column ${
                      editable && allowDelete ? 'multiple-action-column' : ''
                    }`}
                  />
                );
              }

              return (
                <th
                  key={key}
                  data-key={key}
                  onClick={() => sorting(key, currentPage)}
                >
                  {label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{pages < 2 ? rowData(data) : rowData(paginatedData)}</tbody>
      </table>

      {pageCount >= 2 && (
        <Pagination
          key={paginationKey}
          data={data}
          pages={pages}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
Datatable.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape({})),
  headingColumns: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  breakOn: PropTypes.string,
  link: PropTypes.string,
  keyField: PropTypes.string,
  clickableRows: PropTypes.bool,
  openNewTab: PropTypes.bool,
  editable: PropTypes.bool,
  allowDelete: PropTypes.bool,
  pageSize: PropTypes.number,
  isTemplate: PropTypes.bool,
  onRowDelete: PropTypes.bool,
  onExport: PropTypes.bool,
  onRowUpdate: PropTypes.bool,
  isSelectedValue: PropTypes.bool,
  shouldDisplayEditable: PropTypes.bool,
  isExport: PropTypes.bool
};

export default Datatable;
