import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getEmployeeInfoService,
  createEmployeeUserService
} from 'src/api/modules/employee';
import { CgCheckO } from 'react-icons/cg';
import { setUserListUpdate } from 'src/redux/modules/users/userActions';

export const useCreateModal = ({ handleCreateModal, id }) => {
  const dispatch = useDispatch();
  const [employeeName, setEmployeeName] = useState(null);
  const [apiError, setApiError] = useState('');
  const [employeeData, setEmployeeData] = useState({ employee_id: '' });
  const [employeeInfo, setEmployeeInfo] = useState(null);

  useEffect(() => {
    if (id) {
      getEmployeeInfoService(id).then(res => {
        const employee = `${res.data.employee_info.first_name} ${
          res.data.employee_info.last_name
        } ${res.data.employee_info.suffix ?? ''}`;
        setEmployeeName(employee.trimEnd());
        setEmployeeData({ employee_id: id });
        setEmployeeInfo(res.data);
      });
    }
  }, []);
  const handleCreate = async employeeId => {
    if (employeeId) {
      createEmployeeUserService(employeeData)
        .then(res => {
          if (res.data.statusCode === 201) {
            const { user } = res.data.data;
            dispatch(
              setUserListUpdate({
                id: employeeId,
                payload: {
                  ...employeeInfo,
                  user: {
                    id: user.id,
                    status: user.status
                  }
                }
              })
            );
            toast.success('Email invitation sent!', { icon: <CgCheckO /> });
            handleCreateModal(null);
          }
          if (res.data.statusCode === 409) {
            setApiError('Email already exists!');
          }
        })
        .catch(error => {
          return error;
        });
    }
  };

  return {
    employeeName,
    apiError,
    handleCreate
  };
};
