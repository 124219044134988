import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getEvaluationTemplatesService,
  getEvaluationTemplateByIdService,
  deleteEvaluationTemplateByIdService
} from 'src/api/modules/evaluation';
import { getAllEvaluationTemplates } from 'src/redux/modules/evaluations/templates/templatesActions';
import { CgCheckO } from 'react-icons/cg';
import { setIdDeletion } from 'src/redux/modules/datatable/datatableActions';
import { TOAST_OPTIONS } from 'src/helpers/constants';

export const useDeleteModal = ({ handleDeleteModal, id, modal }) => {
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState(null);

  const fetchNewTemplateList = async () => {
    getEvaluationTemplatesService(1)
      .then(res => {
        dispatch(getAllEvaluationTemplates(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };
  useEffect(() => {
    if (id) {
      getEvaluationTemplateByIdService(id).then(res => {
        const evalTemplate = res.data.name;
        setTemplateName(evalTemplate.trimEnd());
      });
    }
  }, []);
  const handleDelete = templateId => {
    if (templateId) {
      deleteEvaluationTemplateByIdService(templateId).then(res => {
        fetchNewTemplateList();
        dispatch(setIdDeletion([templateId]));
        handleDeleteModal(modal);
        toast.success('Sucessfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          toastId: id
        });
      });
    }
  };
  return {
    templateName,
    handleDelete
  };
};
