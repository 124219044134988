import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getLowerCasedValue,
  filterData,
  formatDateYMD
} from 'src/helpers/utils';
import { getQuizItemsService } from 'src/api/modules/quiz';
import { toast } from 'react-toastify';
import { STATUS_OPTIONS } from 'src/helpers/constants';

const useQuizManagerIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: undefined });
  const quizDataList = useSelector(state => state?.quiz?.quizList);
  const [quizList, setQuizList] = useState([]);
  const [quizTitle, setQuizTitle] = useState(null);
  const [modalQuizId, setModalQuizId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);

  const quizManagerColumns = [
    { key: 'id', label: 'ID' },
    { key: 'title', label: 'Title' },
    { key: 'code', label: 'Code' },
    { key: 'duration', label: 'Time' },
    { key: 'question_count', label: 'Questions' },
    { key: 'status', label: 'Status' },
    { key: 'created_at', label: 'Created Date' }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status'
    }
  ];

  const formatItem = item => ({
    id: item.id,
    title: item.title,
    code: item.code,
    duration: `${item.duration} ${item.duration > 1 ? ' mins' : ' min'}`,
    status: item.status,
    question_count: `${item.questions.length} ${
      item.questions.length > 1 ? ' Items' : ' Item'
    }`,
    created_at: formatDateYMD(item.created_at)
  });

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-quiz-manager-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-quiz-manager-page-no'),
        10
      );
      const params = new URLSearchParams(savedSearchParams);
      const res = await getQuizItemsService(savedPageIndex, params);
      const formattedItems = res.data.items.map(formatItem);
      const updatedQuizList =
        savedPageIndex > 1 ? [...quizList, ...formattedItems] : formattedItems;

      setQuizList(updatedQuizList);

      if (
        res.data.items.length !== 0 &&
        res.data.current_page < res.data.total_pages
      ) {
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error(`Error fetching data`);
    }
  }, [page, getQuizItemsService, setQuizList, setPage, setHasMore, quizList]);

  const quizListMemo = useMemo(() => {
    return quizList ?? [];
  }, [quizList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-quiz-manager-params', searchParams);
  };

  const handleDeleteModal = useCallback(
    ({ modal, id, name }) => {
      setShowDeleteModal(modal);
      setModalQuizId(modal ? id : null);
      setQuizTitle(modal ? name : null);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setModalQuizId, setQuizTitle]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-quiz-manager-params');
    const savedPageIndex = localStorage.getItem('hris-quiz-manager-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      localStorage.setItem('hris-quiz-manager-page-no', JSON.stringify(1));
    } else {
      // set default status
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      setPage(1);
      localStorage.setItem('hris-quiz-manager-page-no', JSON.stringify(1));
      setHasMore(true);
      fetchData();
    }
  }, [location.search, quizDataList]);

  useEffect(() => {
    localStorage.setItem('hris-quiz-manager-page-no', JSON.stringify(page));
  }, [page]);

  return {
    showDeleteModal,
    quizList,
    quizTitle,
    modalQuizId,
    page,
    hasMore,
    quizManagerColumns,
    form,
    quizListMemo,
    submitFilter,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    inputs
  };
};

export default useQuizManagerIndex;
