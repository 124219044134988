import { useCreateModal } from 'src/hooks/components/Parts/Employee/useCreateModal';
import CreateModalUI from 'src/components/Common/CreateModalUI';
import PropTypes from 'prop-types';

const CreateModal = ({ handleCreateModal, id, name }) => {
  const { employeeName, apiError, handleCreate } = useCreateModal({
    handleCreateModal,
    id
  });

  return (
    <CreateModalUI
      label={name || employeeName}
      error={apiError}
      submit={() => handleCreate(id)}
      cancel={() => handleCreateModal(null)}
    />
  );
};

CreateModal.propTypes = {
  handleCreateModal: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string
};

export default CreateModal;
