export const useTakeQuizModal = ({
  handleTakeQuizModal,
  handleConfirmTakeQuiz,
  handleCancelTakeQuiz
}) => {
  const handleConfirm = quizId =>
    handleConfirmTakeQuiz(quizId, handleTakeQuizModal);

  const handleCancel = () => handleCancelTakeQuiz();

  return {
    handleConfirm,
    handleCancel
  };
};
