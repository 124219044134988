/* eslint-disable react/prop-types */
import React from 'react';

import { LabelHolder } from 'src/components/Common';

const OptionRadio = ({
  currentQuestion,
  option,
  handleOptionChange,
  selectedBg,
  defaultBg
}) => {
  return (
    <button
      type="button"
      className={`custom__radio flex items-center p-[30px] rounded rounded-[4px] ${
        option.selected
          ? selectedBg || 'bg-[#E3ECFF]'
          : defaultBg || 'bg-[#F7F7F7]'
      }`}
      onClick={() => handleOptionChange(option.id)}
    >
      <input
        className="relative w-[20px] h-[20px] hidden"
        type="radio"
        id={`q-${currentQuestion}-option${option.id}`}
        key={`q-${currentQuestion}-option${option.id}`}
        name="quizOptions"
        value={option.id}
        checked={option.selected}
        onChange={() => true}
      />
      <label
        className="ml-[10px] flex items-center text-[16px] font-stolzlBook pointer-events-none"
        htmlFor={`q-${currentQuestion}-option${option.id}`}
      >
        <span className="w-4 h-4 inline-block mr-3 rounded-full border border-[#bcbcbc]" />
        {option.content ? option.content : <LabelHolder />}
      </label>
    </button>
  );
};

export default OptionRadio;
