import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { updateProjectDetails, getProject } from 'src/api/v1/modules/projects';
import Datatable from 'src/components/v1/Common/Datatable';
import SectionTitle from 'src/components/v1/Common/SectionTitle';
import Button from 'src/components/v1/Common/Button';
import Box from 'src/components/v1/Common/Box';
import Input from 'src/components/v1/Common/Input';
import Breadcrumbs from 'src/components/v1/Common/Breadcrumbs';
import PageTitle from 'src/components/v1/Common/PageTitle';
import 'src/assets/v1/css/projectDetails.scss';
import 'src/assets/v1/css/projects.scss';

const ProjectEditDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.id;

  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectClientName, setProjectClientName] = useState('');
  const [projectClientRepresentative, setProjectClientRepresentative] =
    useState('');
  const [projectStartDate, setProjectStartDate] = useState('');
  const [projectEndDate, setProjectEndDate] = useState('');
  const [projectTechnicalDirector, setProjectTechnicalDirector] = useState('');
  const [projectManager, setProjectManager] = useState('');
  const [projectBridgeEngineer, setProjectBridgeEngineer] = useState('');
  const [projectManagerCebu, setProjectManagerCebu] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const [projectIndustry, setProjectIndustry] = useState('');
  const [ProjectMemberList, setProjectMemberList] = useState([]);

  const dateFormat = date => {
    return DateTime.fromJSDate(new Date(date)).toLocaleString();
  };

  const handleUpdateProjectName = value => {
    setProjectName(value);
  };
  const handleUpdateProjectDescription = value => {
    setProjectDescription(value);
  };
  const handleUpdateProjectClientName = value => {
    setProjectClientName(value);
  };
  const handleUpdateProjectClientRepresentative = value => {
    setProjectClientRepresentative(value);
  };
  const handleUpdateProjectStartDate = value => {
    setProjectStartDate(value);
  };
  const handleUpdateProjectEndDate = value => {
    setProjectEndDate(value);
  };
  const handleUpdateProjectTechnicalDirector = value => {
    setProjectTechnicalDirector(value);
  };
  const handleUpdateProjectManager = value => {
    setProjectManager(value);
  };
  const handleUpdateProjectBridgeEngineer = value => {
    setProjectBridgeEngineer(value);
  };
  const handleUpdateProjectManagerCebu = value => {
    setProjectManagerCebu(value);
  };
  const handleUpdateProjectStatus = value => {
    setProjectStatus(value);
  };
  const handleUpdateProjectIndustry = value => {
    setProjectIndustry(value);
  };

  useEffect(() => {
    async function fetchData() {
      GetProjectDetails();
    }
    fetchData();
  }, []);

  const GetProjectDetails = () => {
    getProject(params.id).then(res => {
      setProjectName(res.data.data.name);
      setProjectDescription(res.data.data.description);
      setProjectClientName(res.data.data.client_name);
      setProjectClientRepresentative(res.data.data.client_representative);
      setProjectStartDate(dateFormat(res.data.data.start_date));
      setProjectEndDate(dateFormat(res.data.data.end_date));
      setProjectTechnicalDirector(res.data.data.TechnicalDirector.last_name);
      setProjectManager(res.data.data.ProjectManager.last_name);
      setProjectBridgeEngineer(res.data.data.BridgeEngineer.last_name);
      setProjectManagerCebu(res.data.data.ProjectManagerCebu.last_name);
      setProjectStatus(res.data.data.status);
      setProjectMemberList(
        res.data.data.members.map(p => ({
          id: p.id,
          member: `${p.last_name}, ${p.first_name}`,
          userIcon: 'icons/profile.svg',
          occupancy: p.occupancy,
          role: p.role,
          skill: p.skill.name,
          start_date: p.start_date,
          end_date: p.end_date
        }))
      );
    });
  };
  const onSubmitProjectDetail = () => {
    const data = {
      name: projectName,
      description: projectDescription,
      client_name: projectClientName,
      client_representative: projectClientRepresentative,
      start_date: projectStartDate,
      end_date: projectEndDate,
      technical_director: projectTechnicalDirector,
      project_manager: projectManager,
      bridge_engineer: projectBridgeEngineer,
      members: [
        {
          id: 8,
          occupancy: 50,
          role: 'Frontend Engineer',
          start_date: '2021-01-03',
          end_date: null,
          skill_id: 1
        },
        {
          occupancy: 50,
          role: 'Backend Engineer',
          start_date: '2021-01-03',
          end_date: null,
          skill_id: 1
        }
      ]
    };
    updateProjectDetails(projectId, data).then(res => {
      setProjectName(res.data.data.name);
      setProjectDescription(res.data.data.description);
    });
  };
  const ProjectDetailColumns = [
    {
      key: 'member',
      label: 'Member',
      type: 'input',
      userIconKey: 'userIcon'
    },
    { key: 'occupancy', label: 'Occupancy', type: 'input' },
    {
      key: 'role',
      label: 'Role',
      type: 'select',
      selectOptions: ['Designer', 'Frontend', 'Backend']
    },
    {
      key: 'skill',
      label: 'Skill Set',
      type: 'input'
    },
    {
      key: 'start_date',
      label: 'Assign Start Date',
      type: 'date'
    },
    {
      key: 'end_date',
      label: 'Assign End Date',
      type: 'date'
    }
  ];

  return (
    <div className="projects__add-project-container">
      <div className="project-details__heading">
        <div className="project-details__heading--title">
          <PageTitle
            title="Edit Project Details"
            backButton
            backPath="/project/1"
          />
          <div className="project-header-right-align-items">
            <div>Status &nbsp;</div>
            <div>
              <Input
                type="select"
                name="projectstatus"
                modifier="projects__input-select"
                value={projectStatus}
                selectOptions={[
                  { label: 'Cancelled', value: '0' },
                  { label: 'Active', value: '1' },
                  { label: 'Archived', value: '2' }
                ]}
                onChange={handleUpdateProjectStatus}
              />
            </div>
          </div>
        </div>
        <Breadcrumbs
          crumbs={[
            { link: '/project', name: 'Projects' },
            { link: '/project/1', name: 'Project Details' },
            'Edit'
          ]}
        />
      </div>

      <div className="projects__add-project-heading">
        <SectionTitle title="Information" />
        <Button
          name="Save"
          modifier="button__save dark"
          onClick={() => onSubmitProjectDetail()}
        />
      </div>

      <Box modifier="projects__add-project-form" padding="30px 90px 64px 30px">
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Project Name"
              name="projectName"
              value={projectName}
              onChange={handleUpdateProjectName}
            />
          </div>
          <div className="projects__column row">
            <div className="projects__column">
              <Input
                type="date"
                modifier="projects__input"
                label="Start Date"
                value={projectStartDate}
                onChange={handleUpdateProjectStartDate}
              />
            </div>
            <div className="projects__column">
              <Input
                type="date"
                modifier="projects__input"
                label="End Date"
                value={projectEndDate}
                onChange={handleUpdateProjectEndDate}
              />
            </div>
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Client Name"
              name="projectClientName"
              value={projectClientName}
              onChange={handleUpdateProjectClientName}
            />
          </div>
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Client Representative"
              name="projectClientRepresentative"
              value={projectClientRepresentative}
              onChange={handleUpdateProjectClientRepresentative}
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column projects__column-one">
            <Input
              type="textarea"
              modifier="projects__input"
              label="Description"
              name="projectDescription"
              value={projectDescription}
              onChange={handleUpdateProjectDescription}
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Project Manager"
              name="projectTechnicalDirector"
              value={projectManager}
              onChange={handleUpdateProjectManager}
            />
          </div>
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Technical Director"
              name="projectTechnicalDirector"
              value={projectTechnicalDirector}
              onChange={handleUpdateProjectTechnicalDirector}
            />
          </div>
        </div>
        <div className="projects__row">
          <div className="projects__column">
            <Input
              type="input"
              modifier="projects__input"
              label="Project Manager Cebu"
              name="projectManagerCebu"
              value={projectManagerCebu}
              onChange={handleUpdateProjectManagerCebu}
            />
          </div>
          <div className="projects__column row projects__column-two-unequal">
            <div className="projects__column">
              <Input
                type="select"
                modifier="projects__input-select"
                value={projectStatus}
                selectOptions={['Healthcare']}
                label="Industry"
                onChange={handleUpdateProjectIndustry}
              />
            </div>
            <div className="projects__column projects__checkbox-wrapper">
              <label
                className="projects__checkbox_custom-checkbox"
                htmlFor="outsideCody"
              >
                <input
                  type="checkbox"
                  className="projects__input-checkbox"
                  id="outsideCody"
                  name="outsideCody"
                  value="Outside Cody"
                />
                <span className="projects__checkbox-checkmark">
                  Outside Cody
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="projects__row ">
          <div className="projects__column projects__column-one">
            <Input
              type="input"
              modifier="projects__input"
              label="Bridge Engineer"
              name="projectBridgeEngineer"
              value={projectBridgeEngineer}
              onChange={handleUpdateProjectBridgeEngineer}
            />
          </div>
        </div>
      </Box>
      <div>
        <div className="projects__add-project-heading">
          <SectionTitle title="Project Members" />
          <Button
            name="Add Member"
            modifier="button__add-white dark"
            onClick={() => navigate('/project/add-member')}
          />
        </div>
      </div>

      <div className="project-members__content">
        <Datatable
          datasource={ProjectMemberList}
          editable
          keyField="id"
          headingColumns={ProjectDetailColumns}
        />
      </div>
    </div>
  );
};

export default ProjectEditDetails;
