import { useState, useEffect, useRef } from 'react';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getUserGroupByIdService,
  deleteUserGroupService,
  getUserGroupsService
} from 'src/api/modules/userGroups';
import { setUserGroups } from 'src/redux/modules/userGroups/userGroupActions';

export const useDeleteModal = ({ handleDeleteModal, id, modal, joinGroup }) => {
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState(null);

  const fetchNewGroupList = async () => {
    getUserGroupsService(1)
      .then(res => {
        dispatch(setUserGroups(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (id) {
      getUserGroupByIdService(id).then(res => {
        setGroupName(res.data.user_group_name);
      });
    }
  }, []);

  const handleDelete = userId => {
    if (joinGroup[id]) {
      toast.error('Unable to delete!', {
        icon: <CgCloseO />,
        toastId: id
      });
      handleDeleteModal(modal);
    } else if (userId) {
      deleteUserGroupService(userId).then(res => {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: id
        });
        fetchNewGroupList();
        handleDeleteModal(modal);
      });
    }
  };

  return {
    groupName,
    handleDelete
  };
};
