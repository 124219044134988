import axios from 'axios';
import { store } from '../redux';

export const ENV = {
  test: 'https://hris.cody.inc'
};

export const api = axios.create({
  baseURL: ENV.test
});

export const auth = headers => {
  const { user } = store.getState();

  return {
    headers: {
      Authorization: `Bearer ${user?.user?.token}`,
      ...headers
    }
  };
};
