import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getDivisionByIDService,
  getDivisionItemsService,
  deleteDivisionById
} from 'src/api/modules/divisions';
import { getDivisions } from 'src/redux/modules/divisions/divisionsActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { MdOutlineClear } from 'react-icons/md';

export const useDeleteModal = ({
  handleDeleteModal,
  editModal,
  code,
  modal
}) => {
  const dispatch = useDispatch();
  const [divisionDetail, setDivisionDetail] = useState(null);

  const fetchNewUserList = async () => {
    getDivisionItemsService(1)
      .then(res => {
        dispatch(getDivisions(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (code) {
      getDivisionByIDService(code).then(res => {
        const codeDetail = `Division Code: ${res.data.division_code}`;
        setDivisionDetail(codeDetail);
      });
    }
  }, []);

  const handleDelete = async codeId => {
    if (codeId) {
      const res = await deleteDivisionById(codeId, 'DELETE');
      if (res.success) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: codeId
        });
        fetchNewUserList();
      } else if (res.response.status === 405)
        toast.error('Unable to delete!', {
          icon: <CgCloseO />,
          toastId: codeId
        });
      handleDeleteModal(modal);
      if (editModal) {
        editModal(null);
      }
    }
  };
  return {
    divisionDetail,
    handleDelete
  };
};
