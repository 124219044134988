import React, { useState, useEffect } from 'react';
import ActiveProjects from 'src/components/Parts/User/ActiveProjects';
import 'src/assets/css/projects.scss';

import { USER_PROJECTS } from 'src/api/endpoints';
import { api, auth } from 'src/api/config';
import PageTitle from 'src/components/Common/PageTitle';

const ProjectsUser = () => {
  const initialProjectStatus = {
    data: {
      occupancy_ratio: 0,
      occupancy_status: ''
    }
  };

  const [projectStatus, setProjectStatus] = useState(initialProjectStatus);
  const [projectList, setProjectList] = useState([]);

  const fetchProjectStatus = async () => {
    try {
      const { status, statusText, data } = await api.get(USER_PROJECTS, auth());
      if (status === 200 && statusText === 'OK') {
        setProjectStatus(data);
        setProjectList(data.data.project_history);
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  useEffect(() => {
    fetchProjectStatus();
  }, []);

  const {
    data: { occupancy_ratio = '', occupancy_status = '' }
  } = projectStatus;

  // ToDo: commented this line at the moment. Will get back and fix this.
  // const renderProjectHistoryList = projectList?.map((projects, i) => {
  //   const { name, start_date, end_date, Members } = projects;
  //   let memberPercentage;
  //   let memberRole;
  //   let currentUserID;

  //   Members?.map(({ resource_perc, role }) => {
  //     memberPercentage = `${resource_perc}%`;
  //     memberRole = role;
  //   });

  //   return {
  //     userID: currentUserID,
  //     projectName: name,
  //     role: memberRole,
  //     startDate: start_date,
  //     endDate: end_date,
  //     percentage: memberPercentage
  //   };
  // });

  return (
    <div className="projects">
      <PageTitle title="PROJECTS" />

      <div className="projects__info-container">
        <h2>Information</h2>

        <div className="projects__info-inner">
          <div className="projects__occupation-status">
            <p>Occupancy Status</p>
            <span
              className={`status-${occupancy_status
                .charAt(0)
                .toLowerCase()}${occupancy_status.slice(1)}`}
            >
              {occupancy_status}
            </span>
          </div>
          <div className="projects__occupation-ratio">
            <p>Occupancy Ratio</p>
            <span>{Math.round(occupancy_ratio)}%</span>
          </div>
        </div>
      </div>
      <ActiveProjects />

      {/* {renderProjectHistoryList?.length ? (
        <Datatable
          datasource={[...renderProjectHistoryList]}
          clickableRows
          openNewTab
          link="/project"
          title="Project History"
          headingColumns={[
            { key: 'projectName', label: 'Project Name' },
            { key: 'role', label: 'Role' },
            { key: 'startDate', label: 'Project Start' },
            { key: 'endDate', label: 'Project End' },
            { key: 'percentage', label: 'Resource' }
          ]}
        />
      ) : null} */}
    </div>
  );
};

export default ProjectsUser;
