import React from 'react';
import { useNavigate } from 'react-router-dom';

// Common components
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import ModalCenter from 'src/components/Common/ModalCenter';
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import PropTypes from 'prop-types';
import ScheduleModalUI from 'src/components/Common/ScheduleModalUI';

// Assets
import 'src/assets/css/user.scss';

// Hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useEvaluationScheduleList from 'src/hooks/EvaluationSchedule/useEvaluationScheduleList';

const EvaluationSchedule = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  const {
    form,
    inputs,
    hasMore,
    deleteId,
    showModal,
    showViewModal,
    showDeleteModal,
    scheduleColumns,
    scheduleListMemo,
    scheduleListMemoTwo,
    submitFilter,
    fetchScheduleData,
    handleDeleteModal,
    detectDateOnChange,
    setShowDeleteModal,
    handleDeleteSchedule
  } = useEvaluationScheduleList(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Evaluation' }, { name: 'Evaluation Schedules' }]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Schedule Name" // "Schedule Name, Description, Created By"
            buttonName="Schedule"
            inputs={inputs}
            buttonLink={false}
            deleteModal="DeleteModal"
            modalName="AddEdit"
            buttonOnClick={() => navigate('/evaluation-schedule/add')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            dateOnChange={detectDateOnChange}
          />
          <InfiniteScroll
            dataLength={scheduleListMemo?.length ?? 0}
            next={fetchScheduleData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            style={{ overflow: 'visible' }}
            endMessage={
              <p className="text-center mt-5">
                {scheduleListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
          >
            <Datatable
              isSchedule
              shouldDisplayEditable={false}
              datasource={scheduleListMemo || []}
              clickableRows={false}
              headingColumns={scheduleColumns}
              title="Evaluation Schedule"
              actions={['view', 'delete']}
              actionLinks={{
                view: '/evaluation-schedule/view'
              }}
              showModal={showModal}
              showViewModal={showViewModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteModal"
              keyField="id"
              shouldEllipsis
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <ScheduleModalUI
            header="Delete"
            type="delete"
            submit={() => handleDeleteSchedule(deleteId.id)}
            cancel={() => setShowDeleteModal(null)}
          >
            <div className="text-center font-stolzlRegular text-[14px] mt-7">
              Are you sure you want to delete
            </div>
            <div className="text-center font-stolzlBold mt-2 capitalize">
              {deleteId.name}?
            </div>
          </ScheduleModalUI>
        )}
      </ModalCenter>
    </div>
  );
};

EvaluationSchedule.propTypes = {
  menuCode: PropTypes.string
};

export default EvaluationSchedule;
