import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Input from 'src/components/Common/Input';
import Search from 'src/components/Common/Search';
import AvailableResources from 'src/components/Parts/Reports/AvailableResourceDetails';
import 'src/assets/css/availableResource.scss';

const ReportsAvailableResourceFilter = () => {
  const navigate = useNavigate();

  const availableResources = [
    {
      id: '1',
      name: 'Samantha Mae Tagli',
      designation: 'Designer',
      previousProject: 'Bizmake',
      idleMonths: '1',
      skills: [
        'HTML',
        'CSS',
        'Javascript',
        'DOM Manipulation',
        'JSON',
        'AJAX',
        'Node.JS'
      ],
      ratings: ['07', '08', '07', '05', '04', '07', '01'],
      previousProjects: [
        'Arise - Healthcare',
        'Adqlone',
        'Cody Corporate Site',
        'HRIS',
        'Iioffice',
        '9lione',
        'CTC - Tocaro'
      ],
      projectTimespan: [
        '01 months',
        '01 months',
        '05 months',
        '05 months',
        '04 months',
        '06 months',
        '06 months'
      ]
    },
    {
      id: '2',
      name: 'Samantha Mae Tagli',
      designation: 'Designer',
      previousProject: 'Bizmake',
      idleMonths: '1',
      skills: [
        'HTML',
        'CSS',
        'Javascript',
        'DOM Manipulation',
        'JSON',
        'AJAX',
        'Node.JS'
      ],
      ratings: ['07', '08', '07', '05', '04', '07', '01'],
      previousProjects: [
        'Arise - Healthcare',
        'Adqlone',
        'Cody Corporate Site',
        'HRIS',
        'Iioffice',
        '9lione',
        'CTC - Tocaro'
      ],
      projectTimespan: [
        '01 months',
        '01 months',
        '05 months',
        '05 months',
        '04 months',
        '06 months',
        '06 months'
      ]
    },
    {
      id: '3',
      name: 'Samantha Mae Tagli',
      designation: 'Designer',
      previousProject: 'Bizmake',
      idleMonths: '1',
      skills: [
        'HTML',
        'CSS',
        'Javascript',
        'DOM Manipulation',
        'JSON',
        'AJAX',
        'Node.JS'
      ],
      ratings: ['07', '08', '07', '05', '04', '07', '01'],
      previousProjects: [
        'Arise - Healthcare',
        'Adqlone',
        'Cody Corporate Site',
        'HRIS',
        'Iioffice',
        '9lione',
        'CTC - Tocaro'
      ],
      projectTimespan: [
        '01 months',
        '01 months',
        '05 months',
        '05 months',
        '04 months',
        '06 months',
        '06 months'
      ]
    }
  ];

  return (
    <div className="available-resource__container">
      <h1 className="available-resource__heading available-resource__heading--inner">
        <button
          type="button"
          className="back-button"
          onClick={() => navigate(-1)}
        />
        Available Resources
      </h1>

      <div className="available-resource__breadcrumbs">
        <ul className="available-resource__breadcrumbs-list">
          <li className="available-resource__breadcrumbs-item">
            <Link to="/reports">Report</Link>
          </li>
          <li className="available-resource__breadcrumbs-item">
            Available Resources
          </li>
        </ul>
      </div>

      <div className="available-resource__section">
        <div className="available-resource-filter__content">
          <div className="available-resource-filter__content--search">
            <Search />
          </div>
          <h3 className="available-resource-filter__title">Filter Search:</h3>
          <div className="available-resource-filter__content--filter">
            <div className="available-resource-filter-item">
              <Input type="select" selectOptions={['All']} label="Project" />
            </div>
            <div className="available-resource-filter-item">
              <Input type="select" selectOptions={['All']} label="Position" />
            </div>
            <div className="available-resource-filter-item">
              <Input type="select" selectOptions={['All']} label="Skillset" />
            </div>
          </div>
        </div>
      </div>
      <div className="available-resource__section">
        <h2 className="available-resource__section--heading">All Resources</h2>
        <AvailableResources availableResources={availableResources} />
      </div>
    </div>
  );
};

export default ReportsAvailableResourceFilter;
