import { auth, ENV } from 'src/api/config';
import axios from 'axios';
import {
  USER_ALL,
  USER_INFO,
  UPDATE_PASSWORD,
  USER_DETAILS_BY_ID,
  USER_SKILLS_BY_ID,
  USER_INFO_BY_ID_MY_TEAM,
  USER_IMAGE_UPDATE,
  ADMIN_SUB_SECTIONS,
  ADMIN_PROJECT_TEAM_INFO,
  UPDATE_USER_PASSWORD,
  ADMIN_USER_INFO_EDIT_IS_EXEMPTED,
  USER_FOR_EVALUATIONS_BY_ID,
  USER_EVALUATIONS_BY_ID,
  RESET_PASSWORD,
  CREATE_NEW_PASSWORD,
  ACTIVATE_ACCOUNT,
  ADD_USER
} from 'src/api/endpoints';
import { fetchAPI } from 'src/api/fetchAPI';

export const userInfoService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_INFO
    });
  } catch (error) {
    return error;
  }
};

export const getAllUsers = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_ALL}`
    });
  } catch (error) {
    return error;
  }
};

export const getAllUsersListService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_ALL}/all`
    });
  } catch (error) {
    return error;
  }
};
export const userResetPassword = async ({ email }) => {
  try {
    const response = await axios.post(`${ENV.test}${RESET_PASSWORD}`, {
      email
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userCreateNewPassword = async (
  { newPassword: new_password, confirmPassword: confirm_password },
  token
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const requestData = { new_password, confirm_password };
    const response = await axios.post(
      `${ENV.test}${CREATE_NEW_PASSWORD}`,
      requestData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userActivateAccount = async (
  { password, confirmPassword: confirm_password },
  token
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    const requestData = { password, confirm_password };
    const response = await axios.post(
      `${ENV.test}${ACTIVATE_ACCOUNT}`,
      requestData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userChangePassword = async ({
  old_password,
  new_password,
  confirm_password
}) => {
  let response = null;
  try {
    response = await axios.put(
      `${ENV.test}${UPDATE_PASSWORD}`,
      {
        old_password,
        new_password,
        confirm_password
      },
      auth()
    );
  } catch (error) {
    response = error.response;
  }
  return response;
};

export const getUserDetailsByID = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_DETAILS_BY_ID}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserSkillsByID = async skillID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_SKILLS_BY_ID}/${skillID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserInfoByIDMyTeam = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_INFO_BY_ID_MY_TEAM}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserSectionByCode = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_SUB_SECTIONS}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getAdminProjectTeamInfoService = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_PROJECT_TEAM_INFO}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserSkillsByIdService = async skillId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_SKILLS_BY_ID}/${skillId}`
    });
  } catch (error) {
    return error;
  }
};

export const addUser = async data => {
  try {
    const response = await axios.post(`${ENV.test}${ADD_USER}`, data, auth());
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUserService = async (userID, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${USER_ALL}/${userID}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getUserByIDService = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_ALL}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteUserService = async userID => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${USER_ALL}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteBulkUserService = async (ids, endpoint) => {
  try {
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateUserPassword = async (userID, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${UPDATE_USER_PASSWORD}/${userID}/login-information`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateUserImage = async (data, progressEvent) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: USER_IMAGE_UPDATE,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: data,
      onUploadProgress: progressEvent
    });
  } catch (error) {
    return error;
  }
};

export const updateUserExemptionForEvaluation = async (userID, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${ADMIN_USER_INFO_EDIT_IS_EXEMPTED}/${userID}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllUsersService = async (page, searchParams) => {
  try {
    let endpoint = `${USER_ALL}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getUserForEvaluationByID = async evaluationID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_FOR_EVALUATIONS_BY_ID}/${evaluationID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserEvaluationByID = async evaluationID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_EVALUATIONS_BY_ID}/${evaluationID}`
    });
  } catch (error) {
    return error;
  }
};

export const patchUserEvaluationAnswer = async (evaluationID, data) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${USER_FOR_EVALUATIONS_BY_ID}/${evaluationID}/answers`,
      body: data
    });
  } catch (error) {
    return error;
  }
};
