import React from 'react';
import { Link } from 'react-router-dom';
import 'src/assets/v1/css/breadcrumbs.scss';
import PropTypes from 'prop-types';

const crumbsModel = [{ link: '/', name: '' }];

const Breadcrumbs = ({ crumbs = crumbsModel }) => {
  let keyCounter = 0;

  const generateUniqueKey = () => {
    keyCounter += 1;
    return `key-${keyCounter}`;
  };

  const renderBreadcrumbItem = item => {
    if (typeof item === 'string') {
      return item;
    }
    if (item.link) {
      return <Link to={item.link}>{item.name}</Link>;
    }
    return item.name;
  };

  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs-list">
        {crumbs.map(item => (
          <li key={generateUniqueKey()} className="breadcrumbs-item">
            {renderBreadcrumbItem(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.string
};

export default Breadcrumbs;
