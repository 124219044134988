import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  createQuestionService,
  getQuestionByIDService,
  updateQuestionByIdService
} from 'src/api/modules/question';

// Others
import { toast } from 'react-toastify';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

import { getAllSkillsList } from 'src/redux/modules/skill/skillActions';
import { getAllStatusList } from 'src/redux/modules/status/statusActions';

const useQuestionAction = (questionId, viewOnly) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultQuestionFields = {
    type: 'Multiple Choice',
    status: 'N',
    content: '',
    points: '',
    level: '',
    is_multi_answer: false,
    skill_id: null,
    answers: [
      {
        content: '',
        correct: false
      },
      {
        content: '',
        correct: false
      },
      {
        content: '',
        correct: false
      },
      {
        content: '',
        correct: false
      }
    ]
  };

  const form = useForm({ defaultValues: undefined });
  const [questionFields, setQuestionFields] = useState(defaultQuestionFields);
  const statusList = useSelector(state => state.status.statuses);
  const skillsList = useSelector(state => state.skill.skills);
  const [optionsCount, setOptionsCount] = useState(
    questionFields.answers.length
  );

  const removeContentAndIdFromAnswers = answers => {
    return answers.map(answer => {
      const { status, id, ...rest } = answer;
      return rest;
    });
  };

  const handleSelectChange = useCallback((value, name) => {
    setQuestionFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setQuestionFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setQuestionFields(prevState => ({
      ...prevState,
      answers: questionFields.answers.map(answer => ({
        ...answer,
        correct: false
      })),
      [name]: checked
    }));
  };

  const validationQuestionSchema = Yup.object({
    content: Yup.string()
      .required('Question content is required')
      .max(255, 'Maximum 255 characters allowed'),
    type: Yup.string().required('Type is required'),
    points: Yup.number().required('Points is required'),
    level: Yup.string().required('Difficulty is required'),
    skill_id: Yup.number().required('Skill is required'),
    answers: Yup.array()
      .test(
        'atLeastTwoContent',
        'At least two answers is required',
        answers => {
          if (answers.length < 2) return false;
          const notEmptyContentCount = answers.filter(
            answer => answer.content !== undefined
          ).length;
          if (notEmptyContentCount >= 2) {
            return true;
          }
          return false;
        }
      )
      .test(
        'atLeastOneCorrect',
        'At least one answer must be correct',
        answers => {
          return answers.some(answer => answer.correct === true); // Check if at least one answer is correct
        }
      )
      .test(
        'correctWithContent',
        'Correct answers must have content',
        answers => {
          const notEmptyAnswers = answers.filter(
            answer => answer.content !== undefined
          );
          return notEmptyAnswers.every(answer => {
            if (!answer.correct) return true; // Skip non-correct answers
            return answer.content && answer.content.trim() !== ''; // Check if content is non-empty
          });
        }
      )
      .test('uniqueContent', 'Answer content must be unique', answers => {
        const notEmptyAnswers = answers.filter(
          answer => answer.content !== undefined
        );
        const uniqueContents = new Set();
        // eslint-disable-next-line no-restricted-syntax
        for (const answer of notEmptyAnswers) {
          if (uniqueContents.has(answer.content)) {
            return false;
          }
          uniqueContents.add(answer.content);
        }
        return true;
      })
  });

  const handleAnswerChange = e => {
    setQuestionFields(prevState => ({
      ...prevState,
      answers: e.answers
    }));
  };

  const handleAddOption = () => {
    setOptionsCount(optionsCount + 1);
    const updatedQuestion = { ...questionFields };
    updatedQuestion.answers.push({
      content: '',
      correct: false
    });
    handleAnswerChange(updatedQuestion);
  };

  const handleRemoveOption = index => {
    const updatedQuestion = { ...questionFields };
    updatedQuestion.answers.splice(index, 1);
    setOptionsCount(optionsCount - 1);
    handleAnswerChange(updatedQuestion);
  };

  const handleQuestionSubmit = useCallback(async question_id => {
    try {
      const filteredAnswers = questionFields.answers.filter(
        answer => answer.content !== '' && answer.content !== undefined
      );

      const updatedQuestionData = {
        ...questionFields,
        answers: filteredAnswers
      };
      if (question_id) {
        const updateResponse = await updateQuestionByIdService(
          question_id,
          updatedQuestionData
        );
        if (updateResponse.statusCode === 200) {
          toast.success('Successfully Updated!', { icon: <CgCheckO /> });
          navigate(`/questions`);
        } else {
          toast.error('Unable to update!', { icon: <CgCloseO /> });
        }
      } else {
        const addResponse = await createQuestionService(updatedQuestionData);
        if (addResponse.status === 201) {
          toast.success('Successfully Added!', { icon: <CgCheckO /> });
          navigate(`/questions`);
        } else {
          toast.error('Add Quiz Failed', { icon: <CgCloseO /> });
        }
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  });

  const getQuestionInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getQuestionByIDService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;

        const questionData = {
          type: responseData.type,
          status: responseData.status,
          content: responseData.content,
          points: responseData.points,
          level: responseData.level,
          is_multi_answer: responseData.is_multi_answer,
          skill_id: responseData.skill?.id,
          answers: removeContentAndIdFromAnswers(responseData.answers)
        };

        setQuestionFields(questionData);
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  };

  const statusOptions = statusList
    .filter(status => status.code !== 'F')
    .map(status => ({
      value: status.code,
      label: status.name,
      hidden: false
    }));

  const skillOptions = skillsList.map(skill => ({
    value: skill.id,
    label: skill.name,
    hidden: false
  }));

  useEffect(() => {
    if (questionId) {
      getQuestionInfo(questionId);
    }
  }, [viewOnly]);

  useEffect(() => {
    setOptionsCount(questionFields.answers.length);
  }, [questionFields.answers]);

  useEffect(() => {
    dispatch(getAllSkillsList());
    dispatch(getAllStatusList());
  }, []);

  return {
    getQuestionInfo,
    handleChange,
    handleSelectChange,
    handleCheckboxChange,
    setQuestionFields,
    handleQuestionSubmit,
    validationQuestionSchema,
    form,
    questionFields,
    handleAnswerChange,
    statusOptions,
    skillOptions,
    handleAddOption,
    handleRemoveOption,
    optionsCount
  };
};

export default useQuestionAction;
