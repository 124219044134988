import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAssessmentByIDService } from 'src/api/modules/assessment';
import { toast } from 'react-toastify';
import { CgCloseO } from 'react-icons/cg';

const useAssesmentUserListAction = (assessment_id, viewOnly) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');

  const getAssessmentInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getAssessmentByIDService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        setTitle(responseData.title);
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  };

  useEffect(() => {
    if (assessment_id) {
      getAssessmentInfo(assessment_id);
    }
  }, [viewOnly]);

  return {
    getAssessmentInfo,
    title
  };
};

export default useAssesmentUserListAction;
