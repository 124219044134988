import React from 'react';
import AvailableResources from './AvailableResourceDetails';

const Frontend = () => {
  const availableResources = [
    {
      id: '1',
      name: 'Samantha Mae Tagli',
      designation: 'Designer',
      previousProject: 'Bizmake',
      idleMonths: '1',
      skills: [
        'Skills Name 1',
        'Skills Name 2',
        'Skills Name Longer 3',
        'Skills Name Longer 4',
        'Skills Name 5',
        'Skills Name Longer 4',
        'SKills Name 5'
      ],
      ratings: ['00', '00', '00', '00', '00', '00', '00'],
      previousProjects: [
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name'
      ],
      projectTimespan: [
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months'
      ]
    },
    {
      id: '2',
      name: 'Samantha Mae Tagli',
      designation: 'Designer',
      previousProject: 'Bizmake',
      idleMonths: '1',
      skills: [
        'Skills Name 1',
        'Skills Name 2',
        'Skills Name Longer 3',
        'Skills Name Longer 4',
        'Skills Name 5',
        'Skills Name Longer 4',
        'SKills Name 5'
      ],
      ratings: ['00', '00', '00', '00', '00', '00', '00'],
      previousProjects: [
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name'
      ],
      projectTimespan: [
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months'
      ]
    },
    {
      id: '3',
      name: 'Samantha Mae Tagli',
      designation: 'Designer',
      previousProject: 'Bizmake',
      idleMonths: '1',
      skills: [
        'Skills Name 1',
        'Skills Name 2',
        'Skills Name Longer 3',
        'Skills Name Longer 4',
        'Skills Name 5',
        'Skills Name Longer 4',
        'SKills Name 5'
      ],
      ratings: ['00', '00', '00', '00', '00', '00', '00'],
      previousProjects: [
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name',
        'Project Name'
      ],
      projectTimespan: [
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months',
        '00 months'
      ]
    }
  ];

  return <AvailableResources availableResources={availableResources} />;
};

export default Frontend;
