import React from 'react';
import { useParams } from 'react-router-dom';

// Common Components
import Breadcrumbs from 'src/components/Common/Breadcrumbs';

// Hooks
import useQuestionAction from 'src/hooks/Pages/LearningManagement/Question/useQuestionAction';

// Quiz Components
import AddQuestion from 'src/Pages/LearningManagement/Question/components/AddQuestion';

// eslint-disable-next-line react/prop-types
const HandleQuestion = ({ menuCode, viewOnly }) => {
  const { question_id } = useParams();

  const {
    handleChange,
    handleSelectChange,
    questionFields,
    handleQuestionSubmit,
    validationQuestionSchema,
    handleCheckboxChange,
    handleAnswerChange,
    statusOptions,
    skillOptions,
    handleAddOption,
    handleRemoveOption,
    optionsCount
  } = useQuestionAction(question_id, viewOnly);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'Questions', link: '/questions' },
          {
            name: `${
              !question_id ? 'Add New Question' : 'Question Information'
            }`
          }
        ]}
      />

      <div className="mt-5">
        <AddQuestion
          questionId={question_id}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          handleQuestionSubmit={handleQuestionSubmit}
          viewOnly={viewOnly}
          menuCode={menuCode}
          statusOptions={statusOptions}
          skillOptions={skillOptions}
          questionFields={questionFields}
          validationQuestionSchema={validationQuestionSchema}
          handleCheckboxChange={handleCheckboxChange}
          handleAnswerChange={handleAnswerChange}
          handleAddOption={handleAddOption}
          handleRemoveOption={handleRemoveOption}
          optionsCount={optionsCount}
        />
      </div>
    </div>
  );
};

export default HandleQuestion;
