/* eslint-disable no-console */
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  useParams,
  useLocation,
  useNavigate,
  createSearchParams
} from 'react-router-dom';
// Services
import {
  startEvaluationService,
  closeEvaluationService,
  excludeEvaluationService,
  generateEvaluationService,
  getEvaluationResultByIdService,
  createEvaluationTemplateService,
  getEvaluationScheduleByIdService,
  updateEvaluationTemplateByIdService,
  bulkUpdateEmployeeEvaluationScheduleService
} from 'src/api/modules/evaluationSchedule';

// Redux
import { EVALUATIONS_SCHEDULES } from 'src/api/endpoints';

// Utilities
import {
  isEqual,
  filterData,
  formatDate,
  costCenterTree
} from 'src/helpers/utils';

// Others
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';

// helpers
import {
  log,
  merge,
  useEmployees,
  createDictionary,
  isArrayHasLength,
  isObjectHasLength,
  sortBySortedItems,
  useActiveTemplates,
  filterDuplicatesById,
  useRemoveSelectedItems,
  removeDefaultEvaluators
} from 'src/hooks/EvaluationSchedule/helpers';

import { STATUS_OPTIONS, TOAST_OPTIONS } from 'src/helpers/constants';

const useEvaluationSchedule = (templateId, viewOnly) => {
  const evaluationResultId = useParams().id || null;
  const location = useLocation();
  const navigate = useNavigate();

  const { unSelectItems } = useRemoveSelectedItems();
  const { employees, allEmployees, employeeName } =
    useEmployees(evaluationResultId);
  const { activeScheduleTemplates } = useActiveTemplates();

  const validationTemplateSchema = Yup.object().shape({
    name: Yup.string().required('Field Required'),
    start_date: Yup.string().required('Field Required'),
    end_date: Yup.string().required('Field Required'),
    description: Yup.string().required('Field Required')
  });

  const defaultTemplateFields = {
    name: '',
    start_date: '',
    end_date: '',
    description: ''
  };

  const form = useForm({ defaultValues: undefined });

  const costCenterList = useSelector(
    state => state.costCenter.costCenterAll.items
  );

  const { ids } = useSelector(state => state.datatable);
  const [evaluationStarted, setEvaluationStarted] = useState(false);
  const [scheduleResultColumns, setScheduleResultColumns] = useState([]);
  const [isScheduleItemActive, setIsScheduleItemActive] = useState(false);
  const [isScheduleItemOnhold, setIsScheduleItemOnhold] = useState(false);
  const [templateFields, setTemplateFields] = useState(defaultTemplateFields);
  const [isResultItemsGenerated, setIsResultItemsGenerated] = useState(false);
  const [isScheduleItemFulfilled, setIsScheduleItemFulfilled] = useState(false);
  const [evaluationResultItemList, setEvaluationResultItemList] = useState([]);
  const [showStartEvaluationModal, setShowStartEvaluationModal] =
    useState(null);

  const [totalResultItems, setTotalResultItems] = useState(0);
  const [evaluatorsData, setEvaluatorsData] = useState([]);
  const [hasActiveCancelledItems, setHasActiveCancelledItems] = useState(false);
  const [isScheduleNameExist, setIsScheduleNameExist] = useState(false);

  const currentSearchParams = useRef({});
  const evaluatorsPayload = useRef({});
  const defaultRawData = useRef([]);
  const defaultEvaluatorsData = useRef([]);
  const defaultEvaluators = useRef({});
  const pristineData = useRef([]);
  const selectedIds = useRef([]);
  const filteredItems = useRef([]);
  const once = useRef({
    onMount: true,
    onFilter: false
  });

  const defaultTemplates = useRef({});
  const bulkTemplatesPayload = useRef({});
  const [defaultTemplatesRaw, setDefaultTemplatesRaw] = useState([]);
  const [bulkTemplatesData, setBulkTemplatesData] = useState([]);
  const [generateExclude, setGenerateExclude] = useState({
    all: false,
    active: false,
    cancelled: false
  });

  const maxSections = useRef({
    once: true,
    count: 0
  });

  const [hasData, setHasData] = useState(false);

  const currentSaveParams = useRef(null);

  const SCHEDULE_STATUS = STATUS_OPTIONS.filter(option => option.value !== 'O');

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(SCHEDULE_STATUS, 'label', 'value'),
      multiOptions: SCHEDULE_STATUS,
      name: 'status',
      label: 'Status',
      defaultValue: []
    },
    {
      type: 'date-range',
      options: [
        {
          label: 'Hired Date (From)',
          name: 'hired_date_from'
        },
        {
          label: 'Hired Date (To)',
          name: 'hired_date_to'
        }
      ]
    }
  ];

  const handleChange = (
    e,
    part,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    const { name, value, checked, type, dataset } = e.target;
    const { sectionindex, criteriaindex, weightindex } = dataset;
    const fieldValue = type === 'checkbox' ? checked : value;

    if (part === 'template') {
      setTemplateFields(prevState => ({
        ...prevState,
        [name]: fieldValue
      }));
    }

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const currentTemplateInfo = useRef({
    id: null,
    template_id: null,
    schedule_id: null,
    template_name: null,
    is_template_active: null,
    sections: []
  });

  function getDefaultDetails(list, onUpdate) {
    if (!isArrayHasLength(list)) return;
    list.forEach(data => {
      const { id, evaluation_template, employee_evaluation_sections } = data;
      employee_evaluation_sections?.forEach(({ section_id, evaluator }) => {
        defaultEvaluatorsData.current = [
          ...defaultEvaluatorsData.current,
          {
            evaluation_id: id,
            section_id,
            evaluator_id: evaluator?.id
          }
        ];
      });

      setDefaultTemplatesRaw(prevState => [
        ...prevState,
        {
          evaluation_id: id,
          template_id: evaluation_template?.id,
          template_name: evaluation_template?.name
        }
      ]);

      setTemplatePayload(id);
    });
    // console.log(
    //   '%cfetchData',
    //   'color: white; background: orange',
    //   `[${onUpdate}]`
    // );
  }

  const getScheduleItemDetail = item => {
    if (!item) return;
    const {
      id,
      templateId: currentTemplateId,
      template,
      sections: currentSections,
      is_template_active
    } = item;

    currentTemplateInfo.current = {
      ...currentTemplateInfo.current,
      id,
      schedule_id: id,
      template_id: currentTemplateId,
      template_name: template,
      sections: currentSections,
      is_template_active
    };
  };

  const resetCurrentTemplateInfo = () => {
    currentTemplateInfo.current = {
      ...currentTemplateInfo.current,
      id: null,
      template_id: null,
      template_name: null,
      schedule_id: null,
      is_template_active: null,
      sections: []
    };
  };

  // ** BASE COLUMNS
  const scheduleResultBaseColumns = [
    {
      key: 'id',
      label: 'ID',
      hidden: isScheduleItemActive // evaluationStarted
    },
    { key: 'name', label: 'Name', readOnly: true },
    { key: 'cost_center_code', label: 'Cost Center', readOnly: true },
    { key: 'hired_date', label: 'Hired Date', readOnly: true },
    {
      key: 'template',
      label: 'Template',
      type: 'select',
      placeholder: 'Select Template',
      selectOptions: activeScheduleTemplates.map(({ id, name }) => ({
        value: id,
        label: name
      })),
      onChangeFn: async ({ data, key }) => {
        setTimeout(() => setEvaluatorsData([]), 0);
        const isSelected = selectedIds.current.some(id => id === data.id);
        const selectedItems = defaultRawData.current
          .filter(item => selectedIds.current.some(id => id === item.id))
          .map(item => item.id);

        if (selectedIds.current.length > 1 && isSelected) {
          selectedItems.forEach(id => updateTemplate(data[key], id));
        } else {
          updateTemplate(data[key], data.id);
        }
      }
    },
    { key: 'status', label: 'Status' }
  ];

  const totalColumns = useRef(0);

  // ** GENERATE COLUMNS
  const generateColumns = useCallback(
    async sections => {
      if (sections) {
        const evaluatorColumns = Array.from({ length: sections }, (_, i) => ({
          key: `evaluator_${i + 1}`,
          label: `Evaluator ${i + 1}`,
          type: 'select',
          selectOptions: employees
            ?.filter(({ status }) => status === 'A')
            .map(({ id, name }) => ({
              value: id,
              label: name
            })),
          placeholder: 'Select Evaluator',
          onChangeFn: async ({ data, key }) => {
            const isSelected = selectedIds.current.some(id => id === data.id);
            const selectedItems = defaultRawData.current
              .filter(item => selectedIds.current.some(id => id === item.id))
              .map(item => item.id);

            if (selectedIds.current.length > 1 && isSelected) {
              selectedItems.forEach(id => updateEvaluator(data, key, id));
            } else {
              updateEvaluator(data, key, data.id);
            }
          }
        }));

        const isEvaluated = isScheduleItemFulfilled || isScheduleItemActive;
        const start = isEvaluated ? 1 : 0;
        const items = isEvaluated ? 3 : 4;

        const total =
          maxSections.current.count + scheduleResultBaseColumns.length;

        const checkTotal = [
          ...scheduleResultBaseColumns.slice(start, items),
          ...evaluatorColumns,
          ...scheduleResultBaseColumns.slice(-2)
        ].length;

        if (checkTotal === total && !isEvaluated) {
          setScheduleResultColumns([]);
          setScheduleResultColumns(prevState => [
            ...prevState,
            ...scheduleResultBaseColumns.slice(start, items),
            ...evaluatorColumns,
            ...scheduleResultBaseColumns.slice(-2)
          ]);
        } else {
          setScheduleResultColumns(prevState => [
            ...prevState,
            ...scheduleResultBaseColumns.slice(start, items),
            ...evaluatorColumns,
            ...scheduleResultBaseColumns.slice(-2)
          ]);
        }
      }
    },
    [isScheduleItemFulfilled, isScheduleItemActive]
  );

  const columns = useMemo(() => {
    return scheduleResultColumns || [];
  }, [evaluationResultItemList, maxSections.current.count]);

  // ** SCHEDULE LIST
  const scheduleResultItems = useMemo(() => {
    if (!evaluationResultId) return null;
    if (!evaluationResultItemList) return [];

    const maxCount = maxSections.current.count;

    return [
      ...(evaluationResultItemList?.map(
        ({
          id,
          status,
          employee,
          notSelected,
          evaluation_template,
          employee_evaluation_sections
        }) => {
          const {
            cost_center_code = '',
            date_hired = '',
            employee_info = {}
          } = employee || {};

          const sectionCount = employee_evaluation_sections.length;
          const missing = maxCount - sectionCount;
          const item = {
            id,
            status,
            name: employeeName(employee_info),
            cost_center_code,
            cost_center_tree: costCenterTree(
              employee?.cost_center_code,
              costCenterList
            ),
            hired_date: date_hired ? formatDate(date_hired) : '-',
            template: evaluation_template?.name,
            is_template_active: activeScheduleTemplates.some(
              template => template.name === evaluation_template?.name
            ),
            templateId: evaluation_template?.id,
            sections: employee_evaluation_sections || [],
            notSelected
          };

          if (missing > 0) {
            employee_evaluation_sections.push(
              ...Array.from({ length: missing }, () => ({
                id: null,
                section_id: null,
                evaluator: null
              }))
            );
          }

          const evaluation_sections = employee_evaluation_sections?.map(
            ({ id: evaluationSectionId, evaluator }, index) => ({
              [`evaluator_${index + 1}`]:
                // eslint-disable-next-line no-nested-ternary
                evaluationSectionId && evaluator?.id
                  ? employees?.find(cody => cody?.id === evaluator?.id)?.name
                  : evaluationSectionId === null
                  ? '-‎'
                  : '-'
            })
          );

          return evaluation_sections?.reduce(
            (object, current) => ({ ...object, ...current }),
            { ...item }
          );
        }
      ) || [])
    ];
  }, [evaluationResultItemList, maxSections.current.count]);

  // ** EVALUATORS PAYLOAD
  const payloadDictionary = useMemo(() => {
    return createDictionary(evaluatorsData);
  }, [evaluatorsData]);

  // ** TEMPLATE PAYLOAD
  const templatesPayloadDictionary = useMemo(() => {
    return createDictionary(bulkTemplatesData, 'templates');
  }, [bulkTemplatesData]);

  // ** DEFAULT EVALUATORS
  const defaultEvaluatorsDictionary = useMemo(() => {
    return createDictionary(defaultEvaluatorsData.current);
  }, [defaultEvaluatorsData.current]);

  // ** DEFAULT TEMPLATES
  const defaultTemplatesDictionary = useMemo(() => {
    return createDictionary(defaultTemplatesRaw, 'templates');
  }, [defaultTemplatesRaw]);

  // ** API (List) **
  const getResultDetails = useCallback(
    async (onUpdate = null) => {
      if (!once.current.onMount) return;
      if (!evaluationResultId) return;

      once.current.onMount = false;

      pristineData.current = [];
      setDefaultTemplatesRaw([]);
      defaultEvaluatorsData.current = [];

      const params = checkParams();

      const resultDataItems = await getEvaluationResultByIdService(
        params,
        evaluationResultId
      );

      const { items, total_items, max_section, for_evaluation } =
        resultDataItems.data || {};

      if (total_items) {
        setHasData(true);
      }

      if (max_section) {
        if (maxSections.current.once) {
          maxSections.current = {
            ...maxSections.current,
            once: false,
            count: max_section
          };
        }
      }

      const hasActiveAndCancelled = items.some(({ status }) =>
        ['A', 'C'].includes(status)
      );

      const filterNullEmployee = items
        .filter(item => item.employee !== null)
        .map(item => ({
          ...item,
          notSelected: false
        }));

      pristineData.current = [
        ...merge(pristineData.current, filterNullEmployee)
      ];

      if (params.size && onUpdate === 'onFilter') {
        defaultRawData.current = [];
        filteredItems.current = [];
        filteredItems.current = [
          ...merge(filteredItems.current, filterNullEmployee)
        ];
        defaultRawData.current = [
          ...merge(defaultRawData.current, filteredItems.current)
        ];
      } else {
        filteredItems.current = [];
        defaultRawData.current = pristineData.current;
      }

      setTotalResultItems(total_items);
      setIsResultItemsGenerated(for_evaluation);

      setEvaluationResultItemList(filterNullEmployee);

      setHasActiveCancelledItems(hasActiveAndCancelled);
      getDefaultDetails(defaultRawData.current, onUpdate);
    },
    [viewOnly, scheduleResultItems]
  );

  const handleGenerateEvaluation = async () => {
    const hasIds = isArrayHasLength(selectedIds.current);
    if (hasIds) {
      unSelectItems();
      const payload = { evaluation_id: ids };
      const res = await generateEvaluationService(payload, templateId);

      if (hasIds) {
        deleteParams('unselectItems');
        unSelectItems();
      }

      if (res?.data?.statusCode === 201 || res?.data?.statusCode === 207) {
        once.current.onMount = true;
        getResultDetails('onGenerate');
        toast.success('Successfully Generated Evaluation!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          toastId: evaluationResultId
        });
      } else {
        toast.error('Method Not Allowed', {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: evaluationResultId
        });
      }
    }
  };

  const handleExcludeEvaluation = async () => {
    const hasIds = isArrayHasLength(selectedIds.current);
    if (hasIds) {
      unSelectItems();
      const res = await excludeEvaluationService(
        {
          evaluation_id: ids
        },
        templateId
      );

      if (hasIds) {
        deleteParams('unselectItems');
        unSelectItems();
      }

      if (res?.data?.statusCode === 200 || res?.data?.statusCode === 207) {
        once.current.onMount = true;
        getResultDetails('onExclude');
        toast.success('Successfully Excluded Evaluation!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          toastId: evaluationResultId
        });
      } else {
        toast.error('Failed Evaluation Exclusion', {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: evaluationResultId
        });
      }
    }
  };

  const handleStartEvaluation = async () => {
    setShowStartEvaluationModal(null);
    const res = await startEvaluationService(templateId);
    if (res?.data?.statusCode === 200) {
      toast.success('Successfully Started Evaluation!', {
        ...TOAST_OPTIONS,
        icon: <CgCheckO />,
        toastId: evaluationResultId
      });
      setEvaluationStarted(true);
      getResultDetails('onStartEvaluation');
    } else if (res?.response?.status === 409) {
      toast.error(res?.response?.data?.message, {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: evaluationResultId
      });
      getResultDetails('onErrorStartEvaluation');
    } else {
      toast.error('Failed to Start Evaluation', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: evaluationResultId
      });
    }
  };

  const handleCloseEvaluation = async () => {
    const res = await closeEvaluationService(templateId);
    if (res?.data?.statusCode === 200) {
      setIsScheduleItemFulfilled(res?.data?.data?.status === 'F');
      getResultDetails('onCloseEvaluation');
      toast.success('Successfully Closed Evaluation!', {
        ...TOAST_OPTIONS,
        icon: <CgCheckO />,
        toastId: evaluationResultId
      });
      setEvaluationStarted(true);
    } else if (res?.response?.status === 409) {
      toast.error(res?.response?.data?.message, {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: evaluationResultId
      });
      getResultDetails('onErrorCloseEvaluation');
    } else {
      toast.error('Failed to Close Evaluation', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: evaluationResultId
      });
    }
  };

  const handleConfirmModal = type => {
    switch (type) {
      case 'StartEvaluation':
        setShowStartEvaluationModal('StartEvaluation');
        break;
      default:
        break;
    }
  };

  const handleCloseConfirmModal = type => {
    switch (type) {
      case 'StartEvaluation':
        setShowStartEvaluationModal(null);
        break;
      default:
        break;
    }
  };

  const submitFilter = form.handleSubmit(params => pushQuery(params));

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    if (params.hired_date_from === '') {
      delete searchParamsObject.hired_date_from;
    }

    if (params.hired_date_to === '') {
      delete searchParamsObject.hired_date_to;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value === '' || value === null) {
        delete searchParamsObject[key];
      }
    });

    if ('hired_date_from' in searchParamsObject) {
      currentSearchParams.current = {
        ...currentSearchParams.current,
        hired_date_from: searchParamsObject.hired_date_from
      };
    } else {
      delete currentSearchParams.current.hired_date_from;
    }

    if ('hired_date_to' in searchParamsObject) {
      currentSearchParams.current = {
        ...currentSearchParams.current,
        hired_date_to: searchParamsObject.hired_date_to
      };
    } else {
      delete currentSearchParams.current.hired_date_to;
    }

    const searchParams = createSearchParams(searchParamsObject).toString();

    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem(
      'hris-evaluation-schedule-search-params',
      searchParams
    );

    if (searchParams) currentSaveParams.current = searchParams;
    else currentSaveParams.current = null;

    once.current = {
      onMount: true,
      onFilter: true
    };
  };

  const handleScheduleSubmit = useCallback(async () => {
    try {
      await validationTemplateSchema.validate(templateFields, {
        abortEarly: false
      });

      if (evaluationResultId) {
        const { name, start_date, end_date, description } = templateFields;
        const dataValue = {
          name,
          start_date,
          end_date,
          description
        };
        const updateResponse = await updateEvaluationTemplateByIdService(
          EVALUATIONS_SCHEDULES,
          evaluationResultId,
          dataValue
        );
        if (updateResponse.statusCode === 200) {
          toast.success('Successfully Edited Evaluation Schedule!', {
            ...TOAST_OPTIONS,
            icon: <CgCheckO />,
            toastId: evaluationResultId
          });
        }
      } else {
        const addResponse = await createEvaluationTemplateService(
          templateFields,
          templateId
        );

        if (addResponse && addResponse.data.statusCode === 201) {
          const { evaluation_schedule } = addResponse.data.data;
          const { id: scheduleId } = evaluation_schedule;
          toast.success('Successfully Added Evaluation Schedule!', {
            ...TOAST_OPTIONS,
            icon: <CgCheckO />,
            toastId: evaluationResultId
          });
          navigate(`/evaluation-schedule/view/${scheduleId}`);
        } else {
          setIsScheduleNameExist(true); // 'Failed to Add Evaluation Schedule!'
        }
      }
    } catch (error) {
      setIsScheduleNameExist(true); // `Failed to ${id ? 'Edit' : 'Add'} Evaluation Schedule`
    }
  }, [templateFields]);

  const getEvaluationInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getEvaluationScheduleByIdService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        const templateData = {
          name: responseData.name,
          start_date: responseData.start_date,
          end_date: responseData.end_date,
          description: responseData.description,
          status: !['N', 'A'].includes(responseData.status)
            ? responseData.status
            : null,
          orig_status: responseData.status
        };

        setTemplateFields(templateData);
        setIsScheduleItemOnhold(responseData.status === 'O');
        setIsScheduleItemActive(responseData.status === 'A');
        setIsScheduleItemFulfilled(responseData.status === 'F');
      }
    } catch (error) {
      toast.error('Error fetching data:', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: evaluationResultId
      });
    }
  };

  // * UPDATE ROW
  const handleUpdateRow = async ({ id: scheduleId }, closeItem) => {
    const isIdSelected = selectedIds.current.some(id => id === scheduleId);
    if (selectedIds.current.length > 1 && isIdSelected) {
      if (isIdSelected) bulkUpdate(closeItem);
      else closeItem();
    } else {
      updateRow(scheduleId, closeItem);
    }
  };

  // ! CLOSE RESET
  const handleResetScheduleItem = (onUpdate, scheduleId) => {
    const isExact = isEqual(defaultRawData.current, pristineData.current);

    unSelectIds('unselectItems');

    if (onUpdate === 'onUpdate') {
      resetCurrentTemplateInfo();
      defaultEvaluatorsData.current = [];
      getResultDetails(onUpdate, scheduleId);
    }

    if (['onCancel', 'onFail'].includes(onUpdate)) {
      if (!isExact) {
        setEvaluatorsData([]);
        resetCurrentTemplateInfo();
        defaultRawData.current = pristineData.current;
        filteredItems.current = pristineData.current;
        setEvaluationResultItemList(pristineData.current);
      }
    }
  };

  const handleDateOnChange = e => {
    const searchParams = new URLSearchParams(location.search);
    const { name, value } = e.target;
    if (Object.keys(currentSearchParams.current).length === 1 && value === '') {
      const paramsList = currentSaveParams.current.split('&');
      const updatedParamsList = paramsList.filter(
        param => !param.startsWith(name.toString())
      );

      const updatedParams = updatedParamsList.join('&');

      navigate({
        pathname: location.pathname,
        search: updatedParams.toString()
      });

      localStorage.setItem(
        'hris-evaluation-schedule-search-params',
        updatedParams
      );

      searchParams.delete(name);

      once.current = {
        onMount: true,
        onFilter: true
      };
    }
  };

  function setTemplatePayload(scheduleId) {
    const { evaluation_template } = defaultRawData.current.find(
      scheduleItem => scheduleItem.id === scheduleId
    );

    setBulkTemplatesData(prevState => [
      ...prevState,
      {
        evaluation_id: scheduleId,
        template_id: evaluation_template?.id,
        template_name: evaluation_template?.name
      }
    ]);
  }

  function activeCancelledStatus(selectedItems) {
    if (isArrayHasLength(selectedItems)) {
      const filteredNoNew = defaultRawData.current
        .filter(data => ids.some(id => id === data.id))
        .every(({ status }) => status !== 'N');

      const filteredHasNew = defaultRawData.current
        .filter(data => ids.some(id => id === data.id))
        .some(data => data.status === 'N');

      const activeItem = defaultRawData.current
        .filter(item => selectedIds.current.some(id => id === item.id))
        .every(item => item.status === 'A');

      const cancelledItem = defaultRawData.current
        .filter(item => selectedIds.current.some(id => id === item.id))
        .every(item => item.status === 'C');

      if (filteredHasNew) {
        setGenerateExclude(prevState => ({
          ...prevState,
          active: false,
          cancelled: false
        }));
      }

      if (filteredNoNew) {
        setGenerateExclude(prevState => ({
          ...prevState,
          all: filteredNoNew
        }));
      } else {
        setGenerateExclude(prevState => ({
          ...prevState,
          all: false
        }));
      }

      if (activeItem) {
        setGenerateExclude(prevState => ({
          ...prevState,
          active: activeItem
        }));
      } else {
        setGenerateExclude(prevState => ({
          ...prevState,
          active: false
        }));
      }

      if (cancelledItem) {
        setGenerateExclude(prevState => ({
          ...prevState,
          cancelled: cancelledItem
        }));
      } else {
        setGenerateExclude(prevState => ({
          ...prevState,
          cancelled: false
        }));
      }
    }

    if (!isArrayHasLength(selectedIds.current)) {
      setGenerateExclude({
        all: false,
        active: false,
        cancelled: false
      });
    }
  }

  function checkSelectedIds(selectedItems) {
    const savedSearchParams = currentSaveParams.current;
    const allDefault = ['', null].includes(savedSearchParams);
    const allFiltered = savedSearchParams === 'status=N%2CA%2CC%2CF';
    const allStatusFiltered = filteredItems.current.every(({ status }) =>
      ['N', 'A', 'C'].includes(status)
    );
    const hasNoFulfilled = defaultRawData.current
      .filter(({ id }) => selectedItems.includes(id))
      .some(({ status }) => ['N', 'A', 'C'].includes(status)); // 'N'
    const isDefaultAndFilteredAll =
      (!isArrayHasLength(selectedItems) && allDefault) ||
      (!isArrayHasLength(selectedItems) && allFiltered) ||
      (isArrayHasLength(selectedItems) && !hasNoFulfilled) ||
      (!isArrayHasLength(selectedItems) && allStatusFiltered);

    if (isArrayHasLength(selectedItems) && hasNoFulfilled) {
      // ! FILTER APPLIED
      if (isArrayHasLength(filteredItems.current)) {
        const updateFilteredNotSelected = filteredItems.current.map(item => {
          if (!selectedItems.includes(item.id)) {
            return {
              ...item,
              notSelected: true
            };
          }
          return {
            ...item,
            notSelected: false
          };
        });
        defaultRawData.current = updateFilteredNotSelected;
        setEvaluationResultItemList(updateFilteredNotSelected);
      }

      // ! NO FILTER APPLIED (ALL ITEMS)
      if (
        !isArrayHasLength(filteredItems.current) ||
        (isArrayHasLength(selectedItems) && allFiltered)
      ) {
        const updateNotSelected = defaultRawData.current.map(item => {
          if (!selectedItems.includes(item.id)) {
            return {
              ...item,
              notSelected: true
            };
          }
          return {
            ...item,
            notSelected: false
          };
        });
        defaultRawData.current = updateNotSelected;
        setEvaluationResultItemList(updateNotSelected);
      }
    }

    if (isDefaultAndFilteredAll) {
      const revertNotSelected = defaultRawData.current.map(item => ({
        ...item,
        notSelected: false
      }));
      defaultRawData.current = revertNotSelected;
      setEvaluationResultItemList(revertNotSelected);
    }
  }

  function updateSortedData(sortedData) {
    const sortedIds = sortedData.map(({ id }) => id);
    const sorted = sortBySortedItems(defaultRawData.current, sortedIds);
    defaultRawData.current = sorted;
    if (checkParams().size) filteredItems.current = sorted;
  }

  function checkParams() {
    return new URLSearchParams(
      localStorage.getItem('hris-evaluation-schedule-search-params')
    );
  }

  function deleteParams(param) {
    if (!param) return;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(param)) {
      setTimeout(() => {
        searchParams.delete(param);
        navigate({
          pathname: location.pathname,
          search: location.search.toString()
        });
      }, 0);
    }
  }

  function unSelectIds(param) {
    if (!param) return;
    if (isArrayHasLength(selectedIds.current)) {
      deleteParams(param);
      unSelectItems();
    }
  }

  const [editCurrentId, setEditCurrentId] = useState(undefined);

  function updateTemplate(value, scheduleId) {
    const { id, name, sections } =
      activeScheduleTemplates.find(
        template => template.name.trim() === value.trim()
      ) || {};

    if (sections.length > maxSections.current.count) {
      setHasData(false);
      maxSections.current.count = sections.length;
      setEditCurrentId(scheduleId);
      generateColumns(maxSections.current.count);
      setTimeout(() => setHasData(true), 0);
    }

    const updateSections =
      defaultRawData.current.map(scheduleItem => {
        if (scheduleItem.id === scheduleId) {
          return {
            ...scheduleItem,
            employee_evaluation_sections: [
              ...scheduleItem.employee_evaluation_sections.map(
                (section, index) => {
                  const { section_id, evaluator } =
                    sections.find((_, itemIndex) => index === itemIndex) || {};

                  currentTemplateInfo.current = {
                    ...currentTemplateInfo.current,
                    template_id: id,
                    template_name: name,
                    sections
                  };

                  setTimeout(() => {
                    setEvaluatorsData(prevState => [
                      ...prevState,
                      {
                        evaluation_id: scheduleId,
                        section_id,
                        evaluator_id: evaluator?.id
                      }
                    ]);
                    setTimeout(() => {
                      if (
                        isObjectHasLength(evaluatorsPayload.current) &&
                        !isArrayHasLength(selectedIds.current)
                      ) {
                        removeDefaultEvaluators({
                          schedule_id: scheduleId,
                          defaultEvaluators: defaultEvaluators.current,
                          evaluatorsPayload: evaluatorsPayload.current,
                          updatePayload: setEvaluatorsData
                        });
                      }
                    }, 0);
                  }, 0);

                  return { ...section, section_id, evaluator };
                }
              )
            ],
            evaluation_template: { id, name }
          };
        }
        return scheduleItem;
      }) || [];

    defaultRawData.current = updateSections;
    filteredItems.current = updateSections;
    setEvaluationResultItemList(updateSections);

    setTemplatePayload(scheduleId);
  }

  function updateEvaluator(data, key, scheduleId) {
    let sectionId;
    const fullName = data[key];
    const sectionIndex = key.split('evaluator_').at(-1) - 1;
    const evaluator = employees?.find(({ name }) => name === fullName);
    const { sections: activeSections } = activeScheduleTemplates.find(
      template => template.name.trim() === data.template.trim()
    );

    if (activeSections) {
      activeSections.forEach((section, index) => {
        if (sectionIndex === index) {
          sectionId = section?.section_id;
        }
      });
    }

    setEvaluatorsData(prevState => [
      ...prevState,
      {
        evaluation_id: scheduleId,
        section_id: sectionId,
        evaluator_id: evaluator.id
      }
    ]);

    setTimeout(() => {
      if (
        isObjectHasLength(evaluatorsPayload.current) &&
        !isArrayHasLength(selectedIds.current)
      ) {
        removeDefaultEvaluators({
          schedule_id: scheduleId,
          defaultEvaluators: defaultEvaluators.current,
          evaluatorsPayload: evaluatorsPayload.current,
          updatePayload: setEvaluatorsData
        });
      }
    }, 0);

    const selectedEvaluator = allEmployees.find(
      ({ id }) => id === evaluator?.id
    );

    const updateEvaluators = defaultRawData.current.map(scheduleData => {
      if (scheduleData?.id === scheduleId) {
        return {
          ...scheduleData,
          employee_evaluation_sections: [
            ...(scheduleData.employee_evaluation_sections.map(
              (section, index) => {
                const { id, first_name, last_name, suffix } = selectedEvaluator;
                if (sectionIndex === index) {
                  return {
                    ...section,
                    evaluator: {
                      id,
                      employee_info: {
                        first_name,
                        last_name,
                        suffix
                      }
                    }
                  };
                }
                return section;
              }
            ) || [])
          ]
        };
      }
      return scheduleData;
    });

    defaultRawData.current = updateEvaluators;
    filteredItems.current = updateEvaluators;
    setEvaluationResultItemList(updateEvaluators);
  }

  function updateRow(scheduleId, closeItem) {
    const evaluatorsPayloadAPI = evaluatorsPayload.current;
    const { template_id: currentTemplateId } = currentTemplateInfo.current;
    const hasEvaluators = Object.keys(evaluatorsPayload.current).length >= 1;
    const items = evaluatorsPayloadAPI[scheduleId];

    if (items === undefined) {
      closeItem();
      return;
    }

    let payload = null;

    if (hasEvaluators && items) {
      payload = [
        {
          evaluation_id: scheduleId,
          template_id: currentTemplateId,
          sections: [...items]
        }
      ];

      if (payload[0].sections.length === 0) {
        closeItem();
        return;
      }

      update(payload, closeItem);
    }

    if (Object.keys(evaluatorsPayload.current).length === 1) {
      if (evaluatorsPayload.current[scheduleId].length === 0) {
        handleResetScheduleItem();
        setEvaluatorsData([]);
        closeItem();
      }
    }

    if (!hasEvaluators) {
      handleResetScheduleItem();
      setEvaluatorsData([]);
      closeItem();
    }

    unSelectIds('unselectItems');
  }

  async function update(data, closeItem) {
    try {
      const response = await bulkUpdateEmployeeEvaluationScheduleService(data);

      log(response, {
        name: 'response',
        color: 'green',
        enable: false
      });

      if (response?.success && response?.statusCode === 200) {
        toast.success('Successfully Updated!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          autoClose: 1000,
          toastId: evaluationResultId
        });
        once.current.onMount = true;
        getResultDetails('onUpdate');
        setEvaluatorsData([]);
      }

      if (
        response.response.data.statusCode === 400 ||
        response.response.data.statusCode === 404 ||
        response.response.data.statusCode === 409
      ) {
        toast.error(
          `${response?.response?.statusText} / ${response?.response?.data?.message}!`,
          {
            ...TOAST_OPTIONS,
            icon: <MdOutlineErrorOutline />,
            toastId: evaluationResultId
          }
        );
        handleResetScheduleItem('onFail');
      }
    } catch (error) {
      toast.error('Update Failed!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: evaluationResultId
      });
    }
    closeItem();
  }

  async function bulkUpdate(closeItem) {
    let bulkPayload = [];
    const templatesPayload = bulkTemplatesPayload.current;
    const evaluatorsBulkPayload = evaluatorsPayload.current;

    Object.keys(evaluatorsBulkPayload).forEach(payloadScheduleId => {
      const [_, template] = Object.entries(templatesPayload).find(
        ([key]) => key === payloadScheduleId
      );

      const evaluatorSections = evaluatorsBulkPayload[payloadScheduleId].map(
        payloadItem => payloadItem
      );

      const item = {
        evaluation_id: +payloadScheduleId,
        template_id: template[0].template_id,
        sections: [...evaluatorSections]
      };

      bulkPayload = [...bulkPayload, item];
    });

    if (isArrayHasLength(bulkPayload)) {
      log(bulkPayload, {
        name: 'BULK Payload',
        color: 'green',
        enable: false
      });

      try {
        const response = await bulkUpdateEmployeeEvaluationScheduleService(
          bulkPayload
        );

        log(response, { name: 'bulk-response', enable: false });

        unSelectIds('unselectItems');

        if (response?.success && response?.statusCode === 200) {
          toast.success('Successfully Updated!', {
            ...TOAST_OPTIONS,
            icon: <CgCheckO />,
            autoClose: 1000,
            toastId: evaluationResultId
          });

          setEvaluatorsData([]);

          bulkPayload = [];

          once.current.onMount = true;

          setTimeout(() => getResultDetails('onBulkUpdate'), 0);
        }

        if (
          response.response.data.statusCode === 400 ||
          response.response.data.statusCode === 404 ||
          response.response.data.statusCode === 409
        ) {
          toast.error(
            `${response?.response?.statusText} / ${response?.response?.data?.message}!`,
            {
              ...TOAST_OPTIONS,
              icon: <MdOutlineErrorOutline />,
              toastId: evaluationResultId
            }
          );
          handleResetScheduleItem('onFail');
        }
      } catch (error) {
        toast.error('Update Failed!', {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: evaluationResultId
        });
        handleResetScheduleItem('onFail');
      }
      closeItem();
    }
  }

  useEffect(() => {
    if (evaluatorsData.length === 0) {
      evaluatorsPayload.current = {};
    } else {
      evaluatorsPayload.current = {
        ...evaluatorsPayload.current,
        ...payloadDictionary
      };
    }

    log(evaluatorsPayload.current, {
      name: 'Evaluators Payload:',
      color: 'red',
      enable: false
    });
  }, [evaluatorsData]);

  useEffect(() => {
    defaultEvaluators.current = {
      ...defaultEvaluators.current,
      ...defaultEvaluatorsDictionary
    };
  }, [defaultEvaluatorsData.current]);

  useEffect(() => {
    if (defaultTemplatesRaw.length === 0) {
      defaultTemplates.current = {};
    } else {
      defaultTemplates.current = {
        ...defaultTemplates.current,
        ...defaultTemplatesDictionary
      };
    }

    log(defaultTemplates.current, {
      name: 'Default Templates:',
      color: 'blue',
      enable: false
    });
  }, [defaultTemplatesRaw]);

  useEffect(() => {
    if (bulkTemplatesData.length === 0) {
      bulkTemplatesPayload.current = {};
    } else {
      bulkTemplatesPayload.current = {
        ...bulkTemplatesPayload.current,
        ...templatesPayloadDictionary
      };
    }

    log(bulkTemplatesPayload.current, {
      name: 'Templates Payload:',
      color: 'orange',
      enable: false
    });

    log(bulkTemplatesPayload.current, {
      name: 'Templates Payload:',
      color: 'orange',
      enable: false
    });

    log(isEqual(bulkTemplatesPayload.current, defaultTemplates.current), {
      name: 'isSame (template) [default = payload]',
      color: 'green',
      enable: false
    });
  }, [bulkTemplatesData]);

  useEffect(() => {
    if (maxSections.current.count) generateColumns(maxSections.current.count);
  }, [scheduleResultItems]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('unselectItems') && once.current.onFilter) {
      once.current.onFilter = false;
      getResultDetails('onFilter');
    }
  }, [location.search]);

  useEffect(() => {
    selectedIds.current = ids;
    checkSelectedIds(selectedIds.current);
    activeCancelledStatus(selectedIds.current);
  }, [ids, selectedIds.current]);

  useEffect(() => {
    return () => {
      // Cleanup hris-evaluation-schedule-search-params
      localStorage.setItem('hris-evaluation-schedule-search-params', '');
      setEvaluatorsData([]);
      setDefaultTemplatesRaw([]);
      defaultEvaluators.current = {};
    };
  }, [navigate]);

  useEffect(() => {
    if (once.current.onMount) getResultDetails('onMount');
    if (templateId) getEvaluationInfo(templateId);

    return () => {
      // Cleanup hris-evaluation-schedule-search-params
      localStorage.setItem('hris-evaluation-schedule-search-params', '');
      setEvaluatorsData([]);
      defaultEvaluators.current = {};
    };
  }, []);

  const actions =
    isScheduleItemFulfilled ||
    evaluationStarted ||
    (isScheduleItemActive && isResultItemsGenerated)
      ? []
      : ['editRow'];

  return {
    // * identifiers
    ids,
    form,
    inputs,
    actions,
    columns,
    templateFields,
    totalResultItems,
    showStartEvaluationModal,
    evaluationStarted,
    isScheduleItemActive,
    isScheduleItemOnhold,
    isResultItemsGenerated,
    hasActiveCancelledItems,
    isScheduleNameExist,
    scheduleResultItems,
    generateExclude,
    isScheduleItemFulfilled,
    hasData,
    editCurrentId,
    // * functions
    handleChange,
    submitFilter,
    handleUpdateRow,
    getEvaluationInfo,
    handleDateOnChange,
    handleConfirmModal,
    handleScheduleSubmit,
    handleCloseEvaluation,
    handleStartEvaluation,
    getScheduleItemDetail,
    handleExcludeEvaluation,
    handleCloseConfirmModal,
    handleResetScheduleItem,
    handleGenerateEvaluation,
    updateSortedData
  };
};

export default useEvaluationSchedule;
