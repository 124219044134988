/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Button from 'src/components/Common/Button';
import { useUserAccess } from 'src/hooks/useUserAccess';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CgClose } from 'react-icons/cg';

const AddAssessment = ({
  assessmentId,
  handleChange,
  handleSelectChange,
  handleAssessmentSubmit,
  viewOnly,
  menuCode,
  assessmentFields,
  validationAssessmentSchema,
  statusOptions,
  typeOptions,
  skillOptions,
  handleSearchQuiz,
  searchQuiz,
  handleAddQuiz,
  addedQuiz,
  quizRef,
  handleDeleteQuiz,
  handleSearchUser,
  searchUser,
  handleAddUser,
  addedUser,
  usersRef,
  handleDeleteUser,
  handleDeadlineChange,
  statusData
}) => {
  const classDisabled = viewOnly ? 'bg-[#f7f7f7]' : 'bg-white';
  const viewEditTitle = viewOnly ? 'View' : 'Edit';
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={assessmentFields}
      validationSchema={validationAssessmentSchema}
      onSubmit={() => handleAssessmentSubmit(assessmentId)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="addAssessmentForm">
          <div className="border-solid border-[1px] border-[#dedede] p-[31px_30px_28px_30px] rounded-[3px] mb-5">
            <div className="flex justify-between w-auto pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {!assessmentId
                  ? 'Add Assessment'
                  : `${viewEditTitle} Assessment Details`}
              </h4>
              {viewOnly ? (
                <Button
                  name="Edit"
                  modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[100px] hover:bg-gray-500"
                  spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/edit_icn.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                  type="submit"
                  disabled={!access.can_edit}
                  onClick={e => {
                    e.preventDefault();
                    navigate(`/assessments/edit/${assessmentId}`);
                  }}
                />
              ) : (
                <Button
                  name="Save"
                  modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[100px] hover:bg-gray-500"
                  spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    (assessmentId && !access.can_edit) ||
                    !access.can_add
                  }
                />
              )}
            </div>
            <div className="flex">
              <div className="w-[1090px] max-w-full">
                <div className="grid grid-cols-2 flex gap-5">
                  {/* 1st column */}
                  <div className="w-full">
                    <div className="w-full">
                      <FormCard>
                        <FormField
                          label="Title"
                          required
                          name="title"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={assessmentFields.title ?? ''}
                          error={errors.title && touched.title}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Code"
                          required
                          name="code"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={assessmentFields.code ?? ''}
                          error={errors.code && touched.code}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError,
                              false
                            )
                          }
                          disabled={viewOnly}
                        />
                        <ErrorMessage
                          name="code"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Type"
                          type="select"
                          required
                          options={typeOptions}
                          onChangeValue={value =>
                            handleSelectChange(value, 'type')
                          }
                          selectedValue={assessmentFields.type}
                          errors={errors?.type}
                          disabled={assessmentId && viewOnly}
                        />
                        <ErrorMessage
                          name="type"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                    </div>
                    <div className="w-full grid grid-cols-2 flex gap-5">
                      <FormCard>
                        <FormField
                          label="Skill"
                          type="select"
                          required
                          options={skillOptions}
                          onChangeValue={value =>
                            handleSelectChange(value, 'skill_id')
                          }
                          selectedValue={assessmentFields.skill_id}
                          errors={errors?.skill_id}
                          disabled={assessmentId && viewOnly}
                          placeholder="Select Skill"
                        />
                        <ErrorMessage
                          name="skill_id"
                          component="div"
                          className="text-[10px] text-[#E43B26] font-stolzlBook"
                        />
                      </FormCard>
                      <FormCard>
                        <FormField
                          className="mb-[22px]"
                          label="Status"
                          type="text"
                          name="status"
                          value={
                            statusOptions?.filter(
                              data => data.value === assessmentFields.status
                            )?.[0]?.label ?? ''
                          }
                          disabled
                        />
                      </FormCard>
                    </div>
                    <div className="w-full">
                      <label className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] leading-[14px]">
                        Content
                        <span className="text-[#E43B26]">*</span>
                      </label>
                      <textarea
                        className={`${
                          errors.content
                            ? 'border-[#e43b26] placeholder-[#e43b26] text-[10px]'
                            : 'border-[#eaeaea]'
                        } ${classDisabled} block w-full border-solid border-[1px] rounded text-[14px] text-[#222222] font-normal font-stolzlBook leading-[20px] h-[110px] px-3 py-[5px] resize-none`}
                        name="content"
                        value={assessmentFields?.content ?? ''}
                        onChange={e =>
                          handleChange(
                            e,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        disabled={viewOnly}
                      />
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="text-[10px] text-[#E43B26] font-stolzlBook"
                      />
                    </div>
                  </div>
                  {/* 2nd column */}
                  <div className="w-full">
                    {/* Start Assessment Quiz */}
                    <div className="mb-4 relative">
                      <label
                        className="block mb-2.5 text-[12px] text-[#414141] leading-[14px] font-stolzlBook"
                        htmlFor="quizzes"
                      >
                        Quiz
                        <div className="relative">
                          <input
                            className={`block w-full border-solid border-[1px] mt-[5px] ${
                              errors.quizzes
                                ? 'border-[#E43B26] placeholder-[#E43B26]'
                                : 'border-[#eaeaea] text-[14px]'
                            } ${classDisabled} rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[10px_10px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                            type="text"
                            name="quizzes"
                            id="quizzes"
                            ref={quizRef}
                            placeholder="Search Quiz"
                            autoComplete="off"
                            onChange={e =>
                              handleSearchQuiz(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            disabled={viewOnly}
                          />
                          {errors.quizzes ? (
                            <p className="text-[10px] text-[#E43B26] font-stolzlBook">
                              {errors.quizzes}
                            </p>
                          ) : null}
                        </div>
                      </label>
                      {searchQuiz.length ? (
                        <div className="absolute top-62px left-0 w-full bg-white z-20 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                          <ul className="max-h-40 overflow-auto custom-scrollbar">
                            {searchQuiz.map((item, index) => (
                              <li
                                key={item.id}
                                className="flex items-center text-[14px] text-[#222222] font-normal leading-[14px] h-10 px-2.5 hover:bg-[#E8F1FF60] ease duration-200 capitalize font-stolzlBook"
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                role="button"
                                tabIndex={0}
                                onClick={() => handleAddQuiz(item)}
                                onKeyDown={event => {
                                  if (
                                    event.key === 'Enter' ||
                                    event.key === ' '
                                  ) {
                                    handleAddQuiz(item);
                                  }
                                }}
                              >
                                <strong>{item.code}</strong>: {item.title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      {/* Added Quiz | Start */}
                      <div className="mb-[31px] mt-2.5">
                        <div className="flex flex-row items-center gap-[5px] border-solid border-b-[1px] border-[#eaeaea] pb-2.5 mt-5 mb-[18px]">
                          <div className="basis-[15%]">
                            <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              Code
                            </h5>
                          </div>
                          <div className="basis-[60%]">
                            <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              Quiz Title
                            </h5>
                          </div>
                          <div className="basis-[20%]">
                            <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              Duration
                            </h5>
                          </div>
                          <div className="flex-none w-5" />
                        </div>
                        <div className="max-h-[240px] overflow-auto custom-scrollbar">
                          {addedQuiz?.length ? (
                            addedQuiz.map((quiz, index) => (
                              <div
                                className="flex flex-row items-center gap-[5px] mb-[3px]"
                                key={quiz.id}
                              >
                                <div className="basis-[15%]">
                                  <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px] capitalize">
                                    {quiz.code}
                                  </p>
                                </div>
                                <div className="basis-[60%]">
                                  <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                                    {quiz.title}
                                  </p>
                                </div>
                                <div className="basis-[20%]">
                                  <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                                    {quiz.duration} mins
                                  </p>
                                </div>
                                <div className="flex w-5">
                                  {!viewOnly && (
                                    <button
                                      type="button"
                                      className="relative inline-block align-super w-5 h-3.5"
                                      name={quiz.id}
                                      disabled={viewOnly}
                                      onClick={() => handleDeleteQuiz(quiz.id)}
                                    >
                                      <CgClose
                                        color={viewOnly ? `#d8dadc` : `#444444`}
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              No quiz selected.
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Added Quiz | End */}
                    </div>
                    {/* End Assessment Quiz */}

                    {/* Assessment User Section | Start */}
                    <div className="mb-4 relative">
                      <label
                        className="block mb-2.5 text-[12px] text-[#414141] leading-[14px] font-stolzlBook"
                        htmlFor="users"
                      >
                        Users
                        <div className="relative">
                          <input
                            className={`block w-full ${classDisabled} border-solid border-[1px] mt-[5px] border-[#eaeaea] text-[14px] rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[10px_10px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                            type="text"
                            name="users-search"
                            id="users-search"
                            ref={usersRef}
                            placeholder="Search User"
                            autoComplete="off"
                            onChange={e =>
                              handleSearchUser(
                                e,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            disabled={viewOnly}
                          />
                        </div>
                      </label>
                      {searchUser.length ? (
                        <div className="absolute top-62px left-0 w-full bg-white z-20 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                          <ul className="max-h-40 overflow-auto custom-scrollbar">
                            {searchUser.map(item => (
                              <li
                                key={item.id}
                                className="flex items-center text-[14px] text-[#222222] font-normal leading-[14px] h-10 px-2.5 hover:bg-[#E8F1FF60] ease duration-200 capitalize font-stolzlBook"
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                role="button"
                                tabIndex={0}
                                onClick={() => handleAddUser(item)}
                                onKeyDown={event => {
                                  if (
                                    event.key === 'Enter' ||
                                    event.key === ' '
                                  ) {
                                    handleAddUser(item);
                                  }
                                }}
                              >
                                {item.first_name} {item.middle_name}{' '}
                                {item.last_name} {item.suffix}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                      {/* Added Users | Start */}
                      <div className="mb-[31px] mt-2.5">
                        <div className="flex flex-row items-center gap-[5px] border-solid border-b-[1px] border-[#eaeaea] pb-2.5 mt-5 mb-[18px]">
                          <div className="basis-[45%]">
                            <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              User Name
                            </h5>
                          </div>
                          <div className="basis-[20%]">
                            <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              Status
                            </h5>
                          </div>
                          <div className="basis-[30%]">
                            <h5 className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              Deadline
                            </h5>
                          </div>
                          <div className="flex-none w-5" />
                        </div>
                        <div className="max-h-[240px] overflow-auto custom-scrollbar">
                          {addedUser?.length ? (
                            addedUser.map((user, inx) => (
                              <div
                                className="flex flex-row items-center gap-[5px] mb-[3px]"
                                key={user.id}
                              >
                                <div className="basis-[45%]">
                                  <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                                    {user.first_name} {user.middle_name}{' '}
                                    {user.last_name} {user.suffix}
                                  </p>
                                </div>
                                <div className="basis-[20%]">
                                  <p className="text-[14px] text-[#232932] font-stolzlBook leading-[17px]">
                                    {statusData(user.user_assessments.status)}
                                  </p>
                                </div>
                                <div className="basis-[30%]">
                                  <FormField
                                    name={`users[${inx}].end_date`}
                                    type="date"
                                    error={
                                      errors.users &&
                                      errors.users[inx]?.end_date
                                    }
                                    placeholder=""
                                    value={
                                      user.user_assessments?.end_date
                                        ? moment(
                                            user.user_assessments.end_date
                                          ).format('YYYY-MM-DD')
                                        : ''
                                    }
                                    max="2050-12-31"
                                    onChange={e =>
                                      handleDeadlineChange(e, user)
                                    }
                                    readOnly={
                                      viewOnly ||
                                      ['F', 'A'].includes(
                                        user.user_assessments.status
                                      )
                                    }
                                  />
                                  {errors.users ? (
                                    <p className="text-[10px] text-[#E43B26] font-stolzlBook">
                                      {errors.users[inx]?.end_date}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="flex items-center w-5">
                                  {!viewOnly && (
                                    <button
                                      type="button"
                                      className={`relative inline-block align-super w-5 h-3.5 ${
                                        viewOnly ||
                                        ['F', 'A'].includes(
                                          user.user_assessments.status
                                        )
                                          ? 'cursor-not-allowed'
                                          : 'cursor-pointer'
                                      }`}
                                      name={user.id}
                                      disabled={
                                        viewOnly ||
                                        ['F', 'A'].includes(
                                          user.user_assessments.status
                                        )
                                      }
                                      onClick={() => handleDeleteUser(user.id)}
                                    >
                                      <CgClose
                                        color={
                                          viewOnly ||
                                          ['F', 'A'].includes(
                                            user.user_assessments.status
                                          )
                                            ? `#d8dadc`
                                            : `#444444`
                                        }
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p className="text-[12px] text-[#414141] font-stolzlBook leading-[14px]">
                              No user selected.
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Added Users | End */}
                    </div>
                    {/* Assessment Users Section | End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddAssessment;
