import { useRef } from 'react';

export const useSidebarItem = ({ item, menuIndex, activeDropDown }) => {
  const ref = useRef(null);
  const isOpen =
    menuIndex === activeDropDown ||
    (activeDropDown === undefined && item.isActive);

  return {
    ref,
    isOpen
  };
};
