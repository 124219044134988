import React from 'react';
import SkillsList from 'src/components/v1/Parts/Skills/SkillsList';
import PageTitle from 'src/components/v1/Common/PageTitle';

const SkillsAndTraining = () => {
  return (
    <div>
      <PageTitle title="SKILLS" />
      <div>
        <SkillsList />
      </div>
    </div>
  );
};

export default SkillsAndTraining;
