import React, { Fragment } from 'react';
import Modal from 'src/components/v1/Common/Modal';
import Button from 'src/components/v1/Common/Button';
import PropTypes from 'prop-types';

const EvaluationSchedulesVerification = ({ data, isOpen, onClose }) => {
  if (!data) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} modifier="evaluation_modal">
      <div className="evaluation_modal_content">
        <div className="evaluation_modal_heading">
          Evaluation Schedule Generated
        </div>
        <br />
        <p>Schedule: {data.evaluation_schedule.schedule_name}</p>
        <p className="evaluation_modal_text_margin">
          {data.count.success_user_evaluation} out of {data.count.all_users}{' '}
          users generated
        </p>
        <br />
        <p className="evaluation_modal_subheading">
          {data.count.exempted_user} users exempt
        </p>
        <div className="evaluation_modal_details">
          {data.exempted_user.map((users, index) => (
            <Fragment key={users.user_fullname}>
              {(index ? ', ' : '') + users.user_fullname.toLowerCase()}
            </Fragment>
          ))}
        </div>

        <br />
        <div>
          <p className="evaluation_modal_subheading">
            {data.error.no_user_evaluations.length} failed{' '}
            {data.error.no_user_evaluations.length > 0 &&
              '(No User Evaluation)'}
          </p>

          <div className="evaluation_modal_details">
            {data.error.no_user_evaluations.map((users, index) => (
              <Fragment key={users.user_fullname}>
                {(index ? ', ' : '') + users.user_fullname.toLowerCase()}
              </Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="evaluation_modal_button">
        <Button
          name="OK"
          modifier="button__default default"
          type="button"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

EvaluationSchedulesVerification.propTypes = {
  data: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default EvaluationSchedulesVerification;
