import axios from 'axios';
import { ENV } from '../config';
import { LOGIN } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const loginService = async ({ email, password }) => {
  try {
    return await axios.post(`${ENV.test}${LOGIN}`, {
      email,
      password
    });
  } catch (error) {
    return error.response;
  }
};
