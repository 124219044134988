import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

export const useContent = () => {
  // let userID;
  // let userRole;
  const [isSidebarOpen, setSidebarState] = useState(true);

  const location = useLocation();
  const currentUser = useSelector(({ user }) => user.user);
  // if (!isEmpty(currentUser)) {
  //   // userRole = user.user.role[0].role_name;
  //   userID = currentUser.user.id;
  // }

  return {
    location,
    currentUser,
    // userRole,
    isSidebarOpen,
    setSidebarState
  };
};
