/* eslint-disable react/prop-types */
import DeleteModalUI from 'src/components/Common/DeleteModalUI';
import PropTypes from 'prop-types';

const DeleteTemplateModal = ({
  handleDeleteTemplateModal,
  data,
  deleteTemplateSubmit,
  deleteSectionSubmit,
  deleteCriteriaSubmit
}) => {
  const { templateId, part, sectionIndex, criteriaIndex } = data;

  const submitDelete = () => {
    if (part === 'template') {
      deleteTemplateSubmit(templateId);
    } else if (part === 'section') {
      deleteSectionSubmit(templateId, sectionIndex);
    } else if (part === 'criteria') {
      deleteCriteriaSubmit(templateId, sectionIndex, criteriaIndex);
    }
  };
  return (
    <DeleteModalUI
      label={`this ${part}`}
      submit={() => submitDelete()}
      cancel={() => handleDeleteTemplateModal({})}
    />
  );
};

// DeleteModal.propTypes = {
//   handleDeleteTemplateModal: PropTypes.func,
//   data: PropTypes.arrayOf(Object)
// };

export default DeleteTemplateModal;
