import 'src/assets/css/breadcrumbs.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const crumbsModel = [{ link: '/', name: '' }];

const Breadcrumbs = ({ crumbs = crumbsModel }) => {
  let keyCounter = 0;
  const defaultCrumbs = [];

  const generateUniqueKey = () => {
    keyCounter += 1;
    return `key-${keyCounter}`;
  };

  const renderBreadcrumbItem = item => {
    if (typeof item === 'string') {
      return item;
    }
    if (item.link) {
      return (
        <Link className="text-gray-0 hover:text-gray-400" to={item.link}>
          {item.name}
        </Link>
      );
    }
    return item.name;
  };

  return (
    <ul className="flex list-none">
      {defaultCrumbs.concat(crumbs).map((item, i, arr) => (
        <li
          key={generateUniqueKey()}
          className="flex items-center whitespace-nowrap text-[22px] text-[#222222] font-stolzlMedium leading-[27px] first:text-gray-0 last:text-gray-400 capitalize last:whitespace-nowrap last:overflow-hidden last:text-ellipsis last:w-[40%]"
        >
          {renderBreadcrumbItem(item)}
          {i + 1 !== arr.length && (
            <img
              className="inline-block px-[8.46px] py-0 h-[7px]"
              src="/icons/right-arrow.svg"
              alt=""
            />
          )}
        </li>
      ))}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(Object)
};

export default Breadcrumbs;
