import {
  getUserEvaluationHistoryService,
  getUserEvaluationListService
} from 'src/api/v1/modules/evaluation';
import {
  GET_USER_EVALUATION_HISTORY,
  GET_USER_EVALUATION_LIST
} from './userEvaluationTypes';

export function setEvaluationByUser(data) {
  return { type: GET_USER_EVALUATION_HISTORY, payload: data };
}

export function getUserEvaluationHistory() {
  return async function (dispatch) {
    const response = await getUserEvaluationHistoryService();
    if (response?.success && response?.data)
      dispatch(setEvaluationByUser(response.data));
  };
}

export function setUserEvaluationList(data) {
  return { type: GET_USER_EVALUATION_LIST, payload: data };
}

export function getUserEvaluationList() {
  return async function (dispatch) {
    const response = await getUserEvaluationListService();
    if (response?.success && response?.data)
      dispatch(setUserEvaluationList(response.data));
  };
}
