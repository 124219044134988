import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getEmployeeInfoService,
  getEmployeesService,
  deleteEmployeeService
} from 'src/api/modules/employee';
import { setEmployees } from 'src/redux/modules/employee/employeeActions';
import { setIdDeletion } from 'src/redux/modules/datatable/datatableActions';
import { CgCloseO, CgCheckO } from 'react-icons/cg';

export const useDeleteModal = ({ handleDeleteModal, id, modal }) => {
  const dispatch = useDispatch();
  const [employeeName, setEmployeeName] = useState(null);

  const fetchNewGroupList = async () => {
    getEmployeesService(1)
      .then(res => {
        dispatch(setEmployees(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (id) {
      getEmployeeInfoService(id).then(res => {
        const employee = `${res.data.employee_info.first_name} ${
          res.data.employee_info.last_name
        } ${res.data.employee_info.suffix ?? ''}`;
        setEmployeeName(employee.trimEnd());
      });
    }
  }, []);
  const handleDelete = userId => {
    if (userId) {
      deleteEmployeeService(userId).then(res => {
        toast.success('Successfully Deleted!', { icon: <CgCheckO /> });
        dispatch(setIdDeletion([userId]));
        fetchNewGroupList();
        handleDeleteModal(modal);
      });
    }
  };
  return {
    employeeName,
    handleDelete
  };
};
