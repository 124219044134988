import { getAllStatusService } from 'src/api/modules/status';
import { FETCH_STATUSES } from './statusTypes';

export function setStatusList(payload) {
  return { type: FETCH_STATUSES, payload };
}

export function getAllStatusList() {
  return async function fetchStatusAll(dispatch) {
    const response = await getAllStatusService();
    if (response?.data) dispatch(setStatusList(response.data));
  };
}
