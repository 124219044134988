import {
  TEMPLATES,
  SCHEDULES,
  SCHEDULES_VERIFICATION,
  USER_EVALUATION_STATUS,
  USER_EVALUATION_HISTORY,
  USER_EVALUATION_LIST
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';

// Templates
export const getEvaluationTemplatesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATES}?rows=all`
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationTemplateService = async (endpoint, data) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationTemplateByIdService = async templateId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATES}/${templateId}`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationTemplateStatusesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${TEMPLATES}/statuses`
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationTemplateByIdService = async (
  endpoint,
  id,
  data
) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${endpoint}/${id}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const deleteEvaluationTemplateByIdService = async (endpoint, id) => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${endpoint}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationTemplateStatusByIdService = async data => {
  const newData = { ...data };
  const templateId = newData.id;
  delete newData.id;
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${TEMPLATES}/${templateId}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

// Schedules
export const getEvaluationSchedulesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: SCHEDULES
    });
  } catch (error) {
    return error;
  }
};

export const createEvaluationSchedulesService = async data => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: SCHEDULES,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationScheduleByIdService = async scheduleId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SCHEDULES}/${scheduleId}`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationSchedulesStatusesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SCHEDULES}/statuses`
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationScheduleByIdService = async (scheduleId, data) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleId}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluationScheduleStatusByIdService = async data => {
  const newData = { ...data };
  const scheduleID = newData.id;
  delete newData.id;
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleID}`,
      body: { ...data }
    });
  } catch (error) {
    return error;
  }
};

export const deleteEvaluationScheduleByIdService = async scheduleId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${SCHEDULES}/${scheduleId}`
    });
  } catch (error) {
    return error;
  }
};

// My Evaluation
export const getUserEvaluationHistoryService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_EVALUATION_HISTORY
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationScheduleUsersByIdService = async scheduleId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SCHEDULES}/${Number(scheduleId)}/evaluations`
    });
  } catch (error) {
    return error;
  }
};

export const getEvaluationScheduleVerificationService =
  async is_included_exempted_users => {
    try {
      return await fetchAPI({
        method: 'GET',
        endpoint: `${SCHEDULES_VERIFICATION}?is_included=${is_included_exempted_users}`
      });
    } catch (error) {
      return error;
    }
  };

export const updateEvaluationScheduleUserStatusService = async (
  scheduleId,
  evaluationId,
  payload
) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleId}/evaluations/${evaluationId}`,
      body: { ...payload }
    });
  } catch (error) {
    return error;
  }
};

export const updateEvaluatorPerSectionByIdService = async (
  scheduleId,
  payload
) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${SCHEDULES}/${scheduleId}/evaluations/sections`,
      body: { ...payload }
    });
  } catch (error) {
    return error;
  }
};

// For Evaluation
export const getUserEvaluationListService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_EVALUATION_LIST
    });
  } catch (error) {
    return error;
  }
};

export const getUserEvaluationScheduleStatusService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_EVALUATION_STATUS
    });
  } catch (error) {
    return error;
  }
};
