import axios from 'axios';
import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import { ENV, auth } from '../config';
import {
  ALL_COST_CENTER,
  COST_CENTER,
  UPDATE_COST_CENTER_BY_USER
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getCostCenterService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_COST_CENTER
    });
  } catch (error) {
    return error;
  }
};

export const getAllCostCenterItemService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ALL_COST_CENTER}?all=true`
    });
  } catch (error) {
    return error;
  }
};

export const getCostCenterDataItems = async (page, searchParams) => {
  try {
    let endpoint = `${COST_CENTER}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getAllCostCenterDataItems = async () => {
  try {
    const endpoint = `${COST_CENTER}?all=true`;

    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getCostCenterByIDService = async code => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${COST_CENTER}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const updateCostCenterByUserId = async data => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: UPDATE_COST_CENTER_BY_USER,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateCostCenterService = async (code, data) => {
  try {
    // return await fetchAPI({
    //   method: 'PUT',
    //   endpoint: `${COST_CENTER}/${code}`,
    //   body: data
    // });
    const response = await axios.put(
      `${ENV.test}${COST_CENTER}/${code}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const addCostCenterService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${COST_CENTER}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteCostCenterService = async code => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${COST_CENTER}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteBulkCostCenter = async (ids, endpoint) => {
  try {
    const requestBody = {
      ids
    };
    const response = await axios.delete(`${ENV.test}${endpoint}`, {
      data: requestBody,
      ...auth()
    });

    return response.data;
  } catch (error) {
    return error.response;
  }
};
