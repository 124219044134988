import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import axios from 'axios';
import { DIVISIONS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getDivisionsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: DIVISIONS
    });
  } catch (error) {
    return error;
  }
};

export const addDivisionService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${DIVISIONS}`,
      data,
      auth({
        'menu-code': MENU_CODES.DIVISION,
        'menu-action': MENU_ACTIONS.CRUD
      })
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDivisionItemsService = async (page, searchParams) => {
  try {
    let endpoint = `${DIVISIONS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getDivisionByIDService = async code => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${DIVISIONS}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteDivisionById = async code => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${DIVISIONS}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const updateDivisionById = async (code, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${DIVISIONS}/${code}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllDivisionItemService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${DIVISIONS}?all=true`
    });
  } catch (error) {
    return error;
  }
};
