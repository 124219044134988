import React from 'react';

// Common components
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

// hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useAssessmentUserList from 'src/hooks/Pages/LearningManagement/Assessment/useAssessmentUserList';

const Index = ({ menuCode, assessmentId }) => {
  const { access } = useUserAccess(menuCode);

  const {
    userListMemo,
    hasMore,
    userListColumns,
    form,
    submitFilter,
    fetchData,
    inputs,
    averageFormatter
  } = useAssessmentUserList(assessmentId, menuCode);

  return (
    <div className="user__container">
      <div className="mt-5">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Search"
            inputs={inputs}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            showActionBtn={false}
          />
          <InfiniteScroll
            dataLength={userListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {userListMemo.length ? <b /> : <b>No records found</b>}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={userListMemo || []}
              clickableRows={false}
              headingColumns={userListColumns}
              title="User List"
              actions={['view']}
              actionLinks={{
                view: `/assessments/users/${assessmentId}/result`
              }}
              disableActions={{
                view: status => status !== 'F'
              }}
              columnFormatter={{
                average: value => averageFormatter(value)
              }}
              keyField="id"
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string,
  assessmentId: PropTypes.string
};

export default Index;
