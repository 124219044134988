import { FETCH_STATUSES } from './statusTypes';

const INITIAL_STATE = {
  statuses: []
};

export default function userReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case FETCH_STATUSES:
      return {
        ...state,
        statuses: payload
      };
    default:
      return state;
  }
}
