import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isEqual } from 'src/helpers/v1/utils';
import { SUB_SECTIONS } from 'src/api/v1/endpoints';
import {
  createEvaluationTemplate,
  updateEvaluationTemplateById,
  deleteEvaluationTemplateById
} from 'src/redux/v1/modules/evaluations/templates/templatesActions';
import Box from 'src/components/v1/Common/Box';
import KebabMenu from 'src/components/v1/Common/KebabMenu';
import useKeypressEscape from 'src/hooks/v1/useKeypressEscape';
import LabeledText from 'src/components/v1/Common/LabeledText';
import PropTypes from 'prop-types';
import { sectionTypeOption } from './TemplateOptions';
import TemplateScoreWeights from './TemplateScoreWeights';
import TemplateFieldList from './TemplateFieldList';
import {
  numberOnly,
  setScoreWeights,
  getScoreWeights,
  detectSalaryGrade
} from './TemplateHelpers';

const TemplateSubSectionItem = ({
  sectionIndex,
  isSectionEdit,
  subSectionIndex,
  subSectionFields,
  setSubSectionFields
}) => {
  const dispatch = useDispatch();
  const subSectionRef = useRef(null);
  const subSections = [...subSectionFields];
  const subSection = subSections[subSectionIndex];
  const isNewSubSection = subSection?.isNewSubSection;
  const [copy, setCopy] = useState(null);
  const [fields, setFields] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isRatingSection, setIsRatingSection] = useState(false);
  const [isEditSubSection, setIsEditSubSection] = useState(true);
  const [subSectionWeights, setSubSectionWeights] = useState(null);

  useKeypressEscape({
    isEdit: isEditSubSection,
    cancelEdit: () => {
      if (isNewSubSection) {
        if (sub_section_type_id === 2) subSection.max_rating = 10;
        subSection.title = '';
        subSection.description = '';
        subSection.max_rating = null;
        subSectionRef.current.focus();
        setSubSectionFields(subSections);
      }

      if (!isNewSubSection) {
        if (
          !isNewSubSection &&
          subSection?.id !== null &&
          subSection?.id !== undefined
        ) {
          const {
            id,
            evaluation_form_id,
            evaluation_section_id,
            isEdit,
            ...formSubSectionData
          } = subSection;

          let formData;
          if (sub_section_type_id === 2)
            formData = { ...formSubSectionData, max_rating: 10 };

          if (!isEqual(formData, copy)) {
            toast.warn('Changes not saved! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
            if (sub_section_type_id === 2) subSection.max_rating = 10;
            subSection.title = copy?.title;
            subSection.description = copy?.description;
            subSection.max_rating = null;
            subSection.g1 = copy?.g1;
            subSection.g2 = copy?.g2;
            subSection.g3 = copy?.g3;
            subSection.sg1 = copy?.sg1;
            subSection.sg2 = copy?.sg2;
            subSection.sg3 = copy?.sg3;
            setSubSectionFields(subSections);
          }
        }
        setIsEditSubSection(false);
      }
    }
  });

  useEffect(() => {
    if (isNewSubSection) subSectionRef?.current.focus();
    copyData();
    if (subSection?.sub_section_type_id === 2) setIsRatingSection(true);
    const { g1, g2, g3, sg1, sg2, sg3 } = subSection;
    const weights = { g1, g2, g3, sg1, sg2, sg3 };
    setSubSectionWeights(Object.values(weights));
  }, []);

  useEffect(() => {
    setIsEditSubSection(isNewSubSection);

    if (subSection.fields?.length) {
      const subSectionsFieldsAPI = subSection?.fields;
      const subSectionsFieldsItemAPI = subSectionsFieldsAPI && [
        ...subSectionsFieldsAPI.map(
          (
            {
              id,
              fields: { title, description, question },
              field_weights,
              ...rest
            },
            _
          ) => ({
            id,
            title: title !== undefined ? title : null,
            description: description !== undefined ? description : question,
            sub_section_id: subSection?.id,
            evaluation_form_id,
            isNewField: false,
            sectionType: subSection?.sub_section_type_id,
            g1: getScoreWeights('G1', field_weights),
            g2: getScoreWeights('G2', field_weights),
            g3: getScoreWeights('G3', field_weights),
            sg1: getScoreWeights('SG1', field_weights),
            sg2: getScoreWeights('SG2', field_weights),
            sg3: getScoreWeights('SG3', field_weights),
            ...rest
          })
        )
      ];

      if (subSectionsFieldsItemAPI) {
        const sorted = subSectionsFieldsItemAPI?.sort((a, b) => a.id - b.id);
        setFields(sorted);

        if (subSection?.sub_section_type_id === 2) setIsRatingSection(true);
        else setIsRatingSection(false);
      }
    }
  }, [subSection]);

  const changeSectionType = useCallback(() => {
    const typeSection = subSection?.sub_section_type_id;

    if (typeSection === 2) {
      setIsRatingSection(true);
      subSection.max_rating = 1;
    } else {
      setIsRatingSection(false);
      subSection.max_rating = null;
    }
  }, [subSection]);

  async function handleRemoveSubSection(subSectionID) {
    try {
      const { success } = await dispatch(
        deleteEvaluationTemplateById(SUB_SECTIONS, subSectionID)
      );
      if (success) {
        toast.success('Subsection successfully deleted! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
        setSubSectionFields(subSections?.filter(s => s?.id !== subSectionID));
      } else {
        toast.error('Something went wrong! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSaveLoading(true);

    const {
      id,
      isEdit,
      max_rating,
      isNewSubSec,
      evaluation_form_id,
      sub_section_weights,
      sub_section_type_id,
      evaluation_section_id,
      g1,
      g2,
      g3,
      sg1,
      sg2,
      sg3,
      ...subSectionFormData
    } = subSection;

    const weightData = { g1, g2, g3, sg1, sg2, sg3 };

    if (isNewSubSec && id === null) {
      const newSubSection =
        sub_section_type_id === 2
          ? {
              ...subSectionFormData,
              evaluation_section_id,
              max_rating: 10,
              sub_section_type_id
            }
          : {
              ...subSectionFormData,
              evaluation_section_id,
              sub_section_type_id
            };

      try {
        const { data, success } = await dispatch(
          createEvaluationTemplate(SUB_SECTIONS, newSubSection)
        );
        if (success) {
          toast.success('Subsection successfully created! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          subSection.isNewSubSection = false;
          subSection.id = data?.id;
          setSubSectionFields(subSections);
          setSaveLoading(false);
          setIsEditSubSection(false);
          copyData();
          setScoreWeights(evaluation_form_id, 'sub-section', data?.id, {
            ...weightData
          });
        } else {
          toast.error('Something went wrong! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
        }
      } catch (error) {
        return error;
      }

      return undefined;
    }

    if (!isNewSubSec) {
      const updateSubSection =
        sub_section_type_id === 2
          ? {
              ...subSectionFormData,
              sub_section_type_id,
              max_rating: 10
            }
          : { ...subSectionFormData, sub_section_type_id };

      const weightCopy = {
        g1: copy?.g1,
        g2: copy?.g2,
        g3: copy?.g3,
        sg1: copy?.sg1,
        sg2: copy?.sg2,
        sg3: copy?.sg3
      };

      const formData = {
        ...subSectionFormData,
        sub_section_type_id,
        max_rating: 10,
        ...weightData
      };

      if (isEqual(formData, copy)) {
        setSaveLoading(false);
        setIsEditSubSection(false);
      } else {
        try {
          const { success } = await dispatch(
            updateEvaluationTemplateById(SUB_SECTIONS, id, updateSubSection)
          );
          if (success) {
            toast.success('Subsection successfully updated! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
            setSaveLoading(false);
            setIsEditSubSection(false);
            copyData();
            if (isEqual(weightData, weightCopy)) return undefined;
            setScoreWeights(evaluation_form_id, 'sub-section', id, {
              ...weightData
            });
          } else {
            toast.error('Something went wrong! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
          }
        } catch (error) {
          return error;
        }
      }
    }

    return undefined;
  }

  function getSectionType(sectionTypeID) {
    switch (sectionTypeID) {
      case 1:
        return 'Target / Result';
      case 2:
        return 'Rating';
      case 3:
        return 'Comment';
      default:
        return 'Error';
    }
  }

  const handleChange = useCallback(({ target: { name, value, maxLength } }) => {
    const getValue = numberOnly(name) ? Number(value) : value;
    subSection[name] = getValue;
    setSubSectionFields(subSections);

    if (detectSalaryGrade(name)) {
      const integer = Number(value.slice(0, maxLength));
      const positiveOnly = integer >= 0 ? integer : 0;
      const hundredOnly = positiveOnly <= 100 ? positiveOnly : 100;
      subSection[name] = parseInt(hundredOnly, 10);
      setSubSectionFields(subSections);
    }
  });

  function copyData() {
    setCopy({
      title: subSection?.title,
      description: subSection?.description,
      sub_section_type_id: subSection?.sub_section_type_id,
      max_rating: sub_section_type_id === 2 ? 10 : null,
      g1,
      g2,
      g3,
      sg1,
      sg2,
      sg3
    });
  }

  if (!subSection) return null;

  const {
    id,
    max_rating,
    title: TITLE,
    description: DESCRIPTION,
    sub_section_type_id: TYPE_ID,
    isNewSubSection: NEW_SUB_SECTION,
    g1,
    g2,
    g3,
    sg1,
    sg2,
    sg3,
    evaluation_form_id
  } = subSection;

  const title = TITLE === '' ? '' : TITLE;
  const sub_section_type_id = TYPE_ID === 1 ? 1 : TYPE_ID;
  const description = DESCRIPTION === '' ? '' : DESCRIPTION;
  const scoreWeights =
    Boolean(g1 === 0 ? true : g1) &&
    Boolean(g2 === 0 ? true : g2) &&
    Boolean(g3 === 0 ? true : g3) &&
    Boolean(sg1 === 0 ? true : sg1) &&
    Boolean(sg2 === 0 ? true : sg2) &&
    Boolean(sg3 === 0 ? true : sg3);
  const isFormFilled =
    (Boolean(sub_section_type_id) &&
      Boolean(title) &&
      Boolean(description) &&
      scoreWeights) ||
    (Boolean(sub_section_type_id) &&
      Boolean(title) &&
      Boolean(description) &&
      Boolean(max_rating) &&
      scoreWeights);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="evaluation__row --nowrap">
          <div className="evaluation__column evaluation__column--two">
            <div className="evaluation__heading">
              <h3>Sub-section {subSectionIndex + 1}</h3>
            </div>
          </div>
          <div className="evaluation__column evaluation__column--two reverse">
            {isEditSubSection ? (
              <div className="evaluation__button evaluation__button--save-section">
                <button
                  type="submit"
                  className={`button button__save dark evaluation__save-button${
                    !isFormFilled ? ' disabled' : ''
                  }`}
                  disabled={!isFormFilled}
                >
                  <span className="button__text">
                    {saveLoading ? <FaSpinner className="spinner" /> : 'Save'}
                  </span>
                </button>
              </div>
            ) : (
              <KebabMenu
                evaluation
                options={[
                  {
                    label: 'Edit Sub-section',
                    onClick: () => setIsEditSubSection(true)
                  },
                  {
                    label: 'Delete Sub-section',
                    onClick: () => handleRemoveSubSection(id)
                  }
                ]}
              />
            )}
          </div>
        </div>

        <div className="evaluation__template-section-content">
          <Box>
            <div className="evaluation__row">
              <div className="evaluation__column evaluation__column--three">
                <label
                  className="evaluation__label"
                  htmlFor={`sub_section_type_${sectionIndex}${subSectionIndex}`}
                >
                  Sub-section Type
                  {isEditSubSection && NEW_SUB_SECTION ? (
                    <select
                      className="evaluation__input evaluation__input--select"
                      id={`sub_section_type_${sectionIndex}${subSectionIndex}`}
                      name="sub_section_type_id"
                      value={sub_section_type_id}
                      disabled={saveLoading}
                      onChange={event => {
                        handleChange(event);
                        changeSectionType();
                      }}
                    >
                      {sectionTypeOption?.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <LabeledText text={getSectionType(sub_section_type_id)} />
                  )}
                </label>
              </div>
            </div>

            {isRatingSection ? (
              <div className="evaluation__row">
                <div className="evaluation__column evaluation__column--two seventy">
                  <label
                    className="evaluation__label"
                    htmlFor={`section_title_${sectionIndex}${subSectionIndex}`}
                  >
                    Sub-section Name
                    {isEditSubSection ? (
                      <input
                        className="evaluation__input"
                        id={`section_title_${sectionIndex}${subSectionIndex}`}
                        name="title"
                        type="text"
                        value={title}
                        ref={subSectionRef}
                        disabled={saveLoading}
                        onChange={handleChange}
                      />
                    ) : (
                      <LabeledText text={title} />
                    )}
                  </label>
                </div>
                <div className="evaluation__column evaluation__column--three">
                  <label
                    className="evaluation__label"
                    htmlFor={`sub_section_rating_${sectionIndex}${subSectionIndex}`}
                  >
                    Max Rating
                    {isEditSubSection ? (
                      <input
                        className="evaluation__input"
                        id={`sub_section_rating_${sectionIndex}${subSectionIndex}`}
                        name="max_rating"
                        value="10"
                        disabled
                      />
                    ) : (
                      <LabeledText text="10" />
                    )}
                  </label>
                </div>
              </div>
            ) : (
              <div className="evaluation__row">
                <div className="evaluation__column">
                  <label
                    className="evaluation__label"
                    htmlFor={`section_title_${sectionIndex}${subSectionIndex}`}
                  >
                    Sub-section Name
                    {isEditSubSection ? (
                      <input
                        className="evaluation__input"
                        id={`section_title_${sectionIndex}${subSectionIndex}`}
                        name="title"
                        type="text"
                        value={title}
                        ref={subSectionRef}
                        disabled={saveLoading}
                        onChange={handleChange}
                      />
                    ) : (
                      <p className="evaluation__template-information-value">
                        {title}
                      </p>
                    )}
                  </label>
                </div>
              </div>
            )}

            <div className="evaluation__row">
              <div className="evaluation__column">
                <label
                  className="evaluation__label"
                  htmlFor={`section_description_${sectionIndex}${subSectionIndex}`}
                >
                  Description
                  {/** Section Description */}
                  {isEditSubSection ? (
                    <textarea
                      className="evaluation__input evaluation__input--textarea"
                      id={`section_description_${sectionIndex}${subSectionIndex}`}
                      name="description"
                      value={description}
                      onChange={handleChange}
                      disabled={saveLoading}
                    />
                  ) : (
                    <LabeledText text={description} templateDescription />
                  )}
                </label>
              </div>
            </div>

            <div className="evaluation__row">
              <div className="evaluation__column evaluation__column--sixty">
                <TemplateScoreWeights
                  type="sub_section"
                  item={subSection}
                  loading={saveLoading}
                  isEdit={isEditSubSection}
                  weights={subSectionWeights}
                  itemIndex={subSectionIndex}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </Box>
        </div>
      </form>

      <TemplateFieldList
        fields={fields}
        setFields={setFields}
        subSections={subSections}
        sectionIndex={sectionIndex}
        isSectionEdit={isSectionEdit}
        templateID={evaluation_form_id}
        subSectionIndex={subSectionIndex}
        isEditSubSection={isEditSubSection}
      />
    </>
  );
};

TemplateSubSectionItem.propTypes = {
  sectionIndex: PropTypes.number,
  isSectionEdit: PropTypes.bool,
  subSectionIndex: PropTypes.number,
  subSectionFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setSubSectionFields: PropTypes.func
};

export default memo(TemplateSubSectionItem);
