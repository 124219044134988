export function createDictionary(list, type = 'evaluators') {
  // export function createDictionary(list, excludeIDS = []) {
  return list.reduce((accumulator, currentValue) => {
    if (type === 'evaluators') {
      const { evaluation_id, section_id, evaluator_id } = currentValue;

      if (
        section_id !== null &&
        section_id !== undefined &&
        evaluator_id !== null &&
        evaluator_id !== undefined
      ) {
        if (!accumulator[evaluation_id]) {
          accumulator[evaluation_id] = [];
        }

        const existingEntry = accumulator[evaluation_id].find(
          entry => entry.section_id === section_id
        );

        if (existingEntry) {
          // Update existing entry
          existingEntry.evaluator_id = evaluator_id;
        } else {
          // Add new entry
          accumulator[evaluation_id].push({
            section_id,
            evaluator_id
          });
        }
      }
    }

    if (type === 'templates') {
      const { evaluation_id, template_id, template_name } = currentValue;

      if (
        template_id !== null &&
        template_id !== undefined &&
        template_name !== null &&
        template_name !== undefined
      ) {
        if (!accumulator[evaluation_id]) {
          accumulator[evaluation_id] = [];
        }
        accumulator[evaluation_id] = [{ template_id, template_name }];
      }
    }

    return accumulator;
  }, {});
}
