import { getSalaryGradesService } from 'src/api/modules/salaryGrades';
import { GET_ADMIN_SALARY_GRADES_LIST } from './salaryGradesTypes';

export function setSalaryGrades(data) {
  return { type: GET_ADMIN_SALARY_GRADES_LIST, payload: data };
}

export function getSalaryGrades() {
  return async function (dispatch) {
    const response = await getSalaryGradesService();
    if (response?.success && response?.data)
      dispatch(setSalaryGrades(response.data));
  };
}
