/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

const MultipleChoiceQuestion = ({
  viewOnly,
  question,
  onUpdateQuestion, // eslint-disable-next-line react/prop-types
  errors,
  // eslint-disable-next-line react/prop-types
  optionsCount,
  // eslint-disable-next-line react/prop-types
  handleAddOption,
  // eslint-disable-next-line react/prop-types
  handleRemoveOption
}) => {
  const maxOptions = 5;
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = e => {
    if (e) {
      const { name, value } = e.target;
      const index = Number(name.split('_')[1]);
      const updatedQuestion = { ...question };

      const updatedOption = updatedQuestion?.answers[index];
      if (updatedOption) {
        updatedOption.content = value;
      }
      onUpdateQuestion(updatedQuestion);
    }
  };

  const handleCheckboxChange = e => {
    if (!e) return;

    const { name, value } = e.target;
    const index = Number(name.split('_')[1]);
    const updatedQuestion = { ...question };

    if (updatedQuestion.is_multi_answer) {
      const updatedOption = updatedQuestion.answers[index];
      updatedOption.correct = !updatedOption.correct;
    } else {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      updatedQuestion.answers.forEach(option => (option.correct = false));

      const updatedOption = updatedQuestion.answers[index];
      updatedOption.correct = true;
    }
    setSelectedOption(index);
    onUpdateQuestion(updatedQuestion);
  };

  const renderInput = (is_checkbox, option, index) => {
    if (index < optionsCount) {
      return (
        <div className="flex flex-row items-center">
          <input
            className={`flex-1 inline-block h-10 px-3 mr-1.5 placeholder-[#bdbec1] focus:outline-none focus:border-[#000] ${
              errors.answers
                ? `border-[#E43B26] placeholder-[#f19d94] 'text-[14px]' : 'text-[12px] '
                  } focus:outline-none focus:border-[#E43B26]`
                : 'border-[#eaeaea] text-[14px] placeholder-[rgba(35,41,50,0.3)]'
            } `}
            type="text"
            name={`option_${index}`}
            value={`${option.content}`}
            placeholder=""
            onChange={e => handleChange(e)}
            disabled={viewOnly}
          />
          <input
            className="inline-block"
            type={`${!is_checkbox ? 'radio' : 'checkbox'}`}
            name={`check_${index}`}
            onChange={handleCheckboxChange}
            checked={option.correct}
            disabled={viewOnly}
          />

          <button
            type="button"
            onClick={() => handleRemoveOption(index)}
            className={`bg-[url('/src/assets/icons/x-mark.svg')] text-sm text-red-500 font-bold cursor-pointer inline-block w-[18px] h-[18px] ml-2 ${
              viewOnly ? 'hidden' : ''
            }`}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 flex gap-5 gap-y-0">
        {question?.answers?.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="w-full mb-5">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="block mb-[5px] text-[#414141] font-stolzlBook text-[12px] font-bold leading-[14px]">
              {String.fromCharCode(65 + index)}.
            </label>
            {/* eslint-disable-next-line react/prop-types */}
            {renderInput(question.is_multi_answer, option, index)}
          </div>
        ))}
        <div />
      </div>

      <ErrorMessage
        name="answers"
        component="div"
        className="text-[10px] text-[#E43B26] font-stolzlBook"
      />
      <div className="w-full">
        <button
          type="submit"
          onClick={e => {
            e.preventDefault();
            handleAddOption();
          }}
          hidden={viewOnly || optionsCount > maxOptions}
          disabled={viewOnly || optionsCount > maxOptions}
          className="text-sm text-blue-500 font-bold cursor-pointer underline"
        >
          + Add another answer
        </button>
      </div>
    </div>
  );
};

MultipleChoiceQuestion.propTypes = {
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        correct: PropTypes.bool.isRequired
      })
    ).isRequired
  }).isRequired,
  onUpdateQuestion: PropTypes.func,
  viewOnly: PropTypes.bool,
  errors: PropTypes.shape()
};

export default MultipleChoiceQuestion;
