import axios from 'axios';
import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import { ADMIN_SUB_SECTIONS, ALL_SUB_SECTIONS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { ENV, auth } from '../config';

export const getSubSectionsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_SUB_SECTIONS
    });
  } catch (error) {
    return error;
  }
};

export const getSubSectionList = async (page, searchParams) => {
  try {
    let endpoint = `${ALL_SUB_SECTIONS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getSubSectionByIDService = async code => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_SUB_SECTIONS}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const getSubSectionService = async (code, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${ALL_SUB_SECTIONS}/${code}`
    });
  } catch (error) {
    return error;
  }
};

export const addSubSectionService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${ALL_SUB_SECTIONS}`,
      data,
      auth({
        'menu-code': MENU_CODES.SUB_SECTION,
        'menu-action': MENU_ACTIONS.CRUD
      })
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateSubSectionService = async (code, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${ALL_SUB_SECTIONS}/${code}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllSubSectionService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ALL_SUB_SECTIONS}?all=true`
    });
  } catch (error) {
    return error;
  }
};
