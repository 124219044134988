import { auth, ENV } from 'src/api/config';
import axios from 'axios';
import {
  USER_ALL,
  USER_INFO,
  UPDATE_PASSWORD,
  USER_DETAILS_BY_ID,
  USER_SKILLS_BY_ID,
  USER_INFO_BY_ID_MY_TEAM,
  ADMIN_SUB_SECTIONS,
  ADMIN_PROJECT_TEAM_INFO,
  ADMIN_ADD_USER,
  UPDATE_USER_PASSWORD,
  ADMIN_USER_IMAGE_UPDATE,
  ADMIN_USER_INFO_EDIT_IS_EXEMPTED,
  USER_FOR_EVALUATIONS_BY_ID,
  USER_EVALUATIONS_BY_ID
} from 'src/api/endpoints';
import { fetchAPI } from 'src/api/fetchAPI';

export const userInfoService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_INFO
    });
  } catch (error) {
    return error;
  }
};

export const getAllUsers = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_ALL}`
    });
  } catch (error) {
    return error;
  }
};

export const userChangePassword = async ({
  old_password,
  new_password,
  confirm_password
}) => {
  let response = null;
  try {
    response = await axios.put(
      `${ENV.test}${UPDATE_PASSWORD}`,
      {
        old_password,
        new_password,
        confirm_password
      },
      auth()
    );
  } catch (error) {
    response = error.response;
  }
  return response;
};

export const getUserDetailsByID = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_DETAILS_BY_ID}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserSkillsByID = async skillID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_SKILLS_BY_ID}/${skillID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserInfoByIDMyTeam = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_INFO_BY_ID_MY_TEAM}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserSectionByCode = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_SUB_SECTIONS}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getAdminProjectTeamInfoService = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_PROJECT_TEAM_INFO}/${userID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserSkillsByIdService = async skillId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_SKILLS_BY_ID}/${skillId}`
    });
  } catch (error) {
    return error;
  }
};

export const addUser = async data => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: ADMIN_ADD_USER,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateUserPassword = async (userID, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${UPDATE_USER_PASSWORD}/${userID}/login-information`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateUserImage = async (data, progressEvent) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: ADMIN_USER_IMAGE_UPDATE,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: data,
      onUploadProgress: progressEvent
    });
  } catch (error) {
    return error;
  }
};

export const updateUserExemptionForEvaluation = async (userID, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${ADMIN_USER_INFO_EDIT_IS_EXEMPTED}/${userID}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllUsersService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_ALL
    });
  } catch (error) {
    return error;
  }
};

export const getUserForEvaluationByID = async evaluationID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_FOR_EVALUATIONS_BY_ID}/${evaluationID}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserEvaluationByID = async evaluationID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_EVALUATIONS_BY_ID}/${evaluationID}`
    });
  } catch (error) {
    return error;
  }
};

export const patchUserEvaluationAnswer = async (evaluationID, data) => {
  try {
    return await fetchAPI({
      method: 'PATCH',
      endpoint: `${USER_FOR_EVALUATIONS_BY_ID}/${evaluationID}/answers`,
      body: data
    });
  } catch (error) {
    return error;
  }
};
