import {
  getUserDetailsByID,
  getUserSkillsByID,
  getUserSkillsByIdService,
  userInfoService,
  getUserInfoByIDMyTeam,
  getAdminProjectTeamInfoService,
  getUserForEvaluationByID,
  patchUserEvaluationAnswer,
  getAllUsersListService,
  getUserByIDService,
  getUserEvaluationByID,
  updateUserService
} from 'src/api/modules/user';
import {
  SET_USER,
  REMOVE_USER,
  SET_USER_INFO,
  SET_USER_INFO_BYID,
  FETCH_DATA,
  SET_USER_BY_IMAGE,
  GET_USER_SKILLS_BYID,
  FETCH_ALL_USERS,
  SET_USER_BYID,
  GET_ALL,
  SET_LIST_UPDATE,
  SET_USER_IMAGE_LIST
} from './userTypes';

export function setUser(payload) {
  return { type: SET_USER, payload };
}

export function setUserDataList(payload) {
  return { type: GET_ALL, payload };
}

export function setUserListUpdate(payload) {
  return { type: SET_LIST_UPDATE, payload };
}

export function setUserByID(payload) {
  return { type: SET_USER_BYID, payload };
}

export function setUserInfo(payload) {
  return { type: SET_USER_INFO, payload };
}

export function removeUser(payload) {
  return { type: REMOVE_USER, payload };
}

export function setUserInfoById(payload) {
  return { type: SET_USER_INFO_BYID, payload };
}

export function setUserSkillsById(payload) {
  return { type: GET_USER_SKILLS_BYID, payload };
}
export function fetchDataLoad() {
  return { type: FETCH_DATA };
}

export function setUserByImage(payload) {
  return { type: SET_USER_BY_IMAGE, payload };
}

export function setAllUsers(payload) {
  return { type: FETCH_ALL_USERS, payload };
}

export function getUserAction() {
  return async function fetchUserInfo(dispatch) {
    const response = await userInfoService();
    if (response?.data) dispatch(setUserInfo(response.data));
  };
}

export function getAllUsers(objectList) {
  return function setUserDataListAction(dispatch) {
    dispatch(setUserDataList(objectList));
  };
}

export function getAllUsersList() {
  return async function fetchUserAll(dispatch) {
    const response = await getAllUsersListService();
    if (response?.data) dispatch(setAllUsers(response.data));
  };
}

export function getUserByID(id) {
  return async function fetchUserByID(dispatch) {
    const response = await getUserByIDService(id);
    if (response?.data) dispatch(setUserByID(response.data));
  };
}

export function updateUser(id) {
  return async function updateUserInfo(dispatch) {
    const response = await updateUserService(id);
    if (response?.data) dispatch(setUserByID(response.data));
  };
}

export function getUserInfo() {
  return async function fetchUserInfo(dispatch) {
    const response = await userInfoService();
    if (response?.data) dispatch(setUserInfo(response.data));
  };
}

export function getUserDetails(userID) {
  return async function fetchUserDetails(dispatch) {
    const response = await getUserDetailsByID(userID);
    if (response?.data) dispatch(setUserInfoById(response.data));
  };
}

export function getUserInfoMyTeam(userID) {
  return async function fetchUserInfoMyTeam(dispatch) {
    const response = await getUserInfoByIDMyTeam(userID);
    if (response?.success && response?.data)
      dispatch(setUserInfoById(response.data));
  };
}

export function getAdminProjectTeamInfo(userID) {
  return async function fetchAdminProjectTeamInfo() {
    return await getAdminProjectTeamInfoService(userID);
  };
}

export function getUserSkills(skillID) {
  return async function fetchUserSkills(dispatch) {
    const response = await getUserSkillsByID(skillID);
    if (response?.data) dispatch(setUserSkillsById(response.data));
  };
}

export function getUserSkillsById(skillId) {
  return async function fetchUserSkillsById() {
    return await getUserSkillsByIdService(skillId);
  };
}

export function getUserBossEvaluation(ID) {
  return async function fetchUserBossEvaluation() {
    return await getUserForEvaluationByID(ID);
  };
}

export function getUserEvaluation(ID) {
  return async function fetchUserEvaluation() {
    return await getUserEvaluationByID(ID);
  };
}

export function patchEvaluationAnswer(evaluationID, data) {
  return async function updateEvaluationAnswer() {
    return await patchUserEvaluationAnswer(evaluationID, data);
  };
}
