import { ALL_STATUSES } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getAllStatusService = async () => {
  try {
    const test = await fetchAPI({
      method: 'GET',
      endpoint: ALL_STATUSES
    });
    return test;
  } catch (error) {
    return error;
  }
};
