import React, { useState, useEffect, useRef } from 'react';
import UserEvaluationDetails from 'src/components/Parts/Evaluation/UserEvaluationDetails';
import 'src/assets/css/userEvaluation.scss';
import 'src/assets/css/bossEvaluation.scss';
import { useDispatch, useSelector } from 'react-redux';
import EvaluationSectionTitle from 'src/components/Common/EvaluationSectionTitle';
import EvaluationSubSectionTitle from 'src/components/Common/EvaluationSubSectionTitle';
import { useParams } from 'react-router-dom';
import { getUserBossEvaluation } from 'src/redux/modules/users/userActions';
// eslint-disable-next-line import/no-named-as-default
import Button from 'src/components/Common/Button';
import { USER_FOR_EVALUATIONS_BY_ID } from 'src/api/endpoints';
import { fetchAPI } from 'src/api/fetchAPI';
import { toInteger } from 'lodash';
import { toast } from 'react-toastify';

const BossEvaluation = () => {
  const { id } = useParams();
  const { user } = useSelector(state => state.user.selectedUserDetails);
  const [evaluationID, setEvaluationID] = useState('');
  const [errors, setErrors] = useState(['', '']);
  const [disable, setDisable] = useState(false);
  const [editScore, setEditScore] = useState(['', '']);
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(['', false]);
  const dispatch = useDispatch();
  const [bossEvaluation, setBossEvaluation] = useState(null);
  const templateName =
    (bossEvaluation && bossEvaluation.eval_form_name) || null;
  const userID = user.id;
  const formEvaluationID = (bossEvaluation && bossEvaluation.id) || null;
  const scores = [];

  useEffect(() => {
    setEvaluationID(id);
  }, [id]);

  useEffect(() => {
    const haveZero = scores.every(score => score >= 1);
    setDisable(haveZero);
  }, [bossEvaluation]);

  const fetchBossEvaluation = async () => {
    try {
      const { data, success } = await dispatch(getUserBossEvaluation(id));
      if (success) {
        setBossEvaluation(data.evaluation);
      }
    } catch (error) {
      return error;
    }
    return null;
  };

  useEffect(() => {
    fetchBossEvaluation();
  }, []);

  const submitAnswer = async () => {
    try {
      const { success } = await fetchAPI({
        method: 'POST',
        endpoint: `${USER_FOR_EVALUATIONS_BY_ID}/${formEvaluationID}/submit`
      });

      if (success) {
        toast.success('Evaluation submitted!');
        // setBossEvaluation(null);
        fetchBossEvaluation();
      }
    } catch (error) {
      return error;
    }
    return null;
  };

  const handleBlur = async (event, subTypeID, currentScore, fieldID) => {
    const value = Number(event.target.value);
    const idField = event.target.name;
    const newErrors = [...errors];
    newErrors[idField] = value > 10 ? 'Max of 10 score only' : '';
    setErrors(newErrors);

    if (value === 0) {
      newErrors[idField] = value === 0 ? 'Not allowed' : '';
      setErrors(newErrors);
    } else if (value <= 10 && value > 0) {
      const falseEdit = [...editScore];
      falseEdit[idField] = false;
      setEditScore(falseEdit);

      if (value !== currentScore) {
        const newLoading = [...loading];
        newLoading[idField] = true;
        setLoading(newLoading);

        const subTypePayloads = [
          null,
          { target: '-', target_details: '-', how_to_achieve: '-' },
          { score: '-' },
          { question: '-' }
        ];

        const payload = {
          evaluation_field_id: fieldID,
          field_answers: {
            ...subTypePayloads[subTypeID]
          },
          score: value || currentScore
        };

        try {
          const { success } = await fetchAPI({
            method: 'PATCH',
            endpoint: `${USER_FOR_EVALUATIONS_BY_ID}/${evaluationID}/answers`,
            body: payload
          });

          if (success) {
            fetchBossEvaluation();
            newLoading[idField] = false;
            setLoading(newLoading);
          } else {
            toast.error('failed!');
            setLoading(false);
          }
        } catch (error) {
          return error;
        }
      }
    }
    return null;
  };

  const bossDetails = [
    {
      id: bossEvaluation?.user?.id || '-',
      name:
        (bossEvaluation &&
          bossEvaluation.user &&
          `${bossEvaluation.user.first_name} ${bossEvaluation.user.last_name}`) ||
        '-',
      jobTitle: bossEvaluation?.user?.Position?.name || '-',
      latestProject: bossEvaluation?.project?.name || '-',
      salaryGrade: bossEvaluation?.salary_grades?.type || '-'
    }
  ];

  return (
    <div className="boss-evaluation__container">
      <h1 className="boss-evaluation__heading">Evaluations</h1> <br />
      <h2 className="boss-evaluation__heading__inner">
        Operation Template({templateName || '-'})
      </h2>
      <br />
      <div className="boss-evaluation__wrapper">
        <div className="boss-evaluation__wrapper_inner">
          <UserEvaluationDetails userDetails={bossDetails} />

          <div className="boss-evaluation__contents">
            {bossEvaluation ? (
              <div>
                {bossEvaluation.user_evaluation_section
                  ?.sort((a, b) => a.id - b.id)
                  .map((data, sectionKey) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="user-evaluation-section" key={sectionKey}>
                      <div className="general-evaluation-flex">
                        <div className="general-evaluation-section__container">
                          <EvaluationSectionTitle
                            title={data.form_name}
                            weight="50%"
                          />
                          <div className="general-evaluation-sub-section-actual-score">
                            {bossEvaluation.user_evaluation_status.name ===
                            'Done'
                              ? `- ${data.actual_score}`
                              : ''}
                          </div>
                        </div>
                      </div>

                      {data.user_evaluation_sub_section?.map(
                        (subSectionData, index) => (
                          <div
                            className="general-evaluation-sub-section"
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                          >
                            <div className="general-evaluation-flex">
                              <EvaluationSubSectionTitle
                                title={subSectionData.form_name}
                                weight="50%"
                              />
                              <div className="general-evaluation-sub-section-actual-score">
                                {bossEvaluation.user_evaluation_status.name ===
                                'Done'
                                  ? `- ${subSectionData.actual_score}`
                                  : ''}
                              </div>
                            </div>

                            <div className="general-evaluation-sub-section__content--title">
                              <div className="general-evaluation-sub-section__column-title--1">
                                <div className="general-evaluation-sub-section__label">
                                  Self Score
                                </div>
                              </div>
                              <div className="general-evaluation-sub-section__column-title--2">
                                <div className="general-evaluation-sub-section__label">
                                  Boss Score
                                </div>
                              </div>
                            </div>
                            {subSectionData.user_evaluation_field
                              ?.sort((a, b) => a.id - b.id)
                              ?.map((evaluationField, key) => {
                                scores.push(evaluationField?.boss_score);
                                return (
                                  <div
                                    className="general-evaluation-sub-section__content"
                                    key={evaluationField.id}
                                  >
                                    <div className="general-evaluation-sub-section__row">
                                      <div className="general-evaluation-sub-section__column">
                                        <div className="general-evaluation-sub-section__label">
                                          {evaluationField.field.title}
                                          <i className="img__description" />
                                          <div className="general-evaluation-sub-section__description">
                                            <div className="tooltip__description">
                                              {
                                                evaluationField.field
                                                  .description
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="general-evaluation-sub-section__row">
                                      <div className="general-evaluation-sub-section__column">
                                        <div className="general-evaluation-sub-section__label weight__bold">{`${evaluationField.weight_percentage}%`}</div>
                                      </div>
                                    </div>

                                    <div className="general-evaluation-sub-section__row--wrapper">
                                      <div className="general-evaluation-sub-section__row">
                                        <div className="general-evaluation-sub-section__column">
                                          <div className="general-evaluation-sub-section__item">
                                            <div className="general-evaluation-sub-section__item user-score">
                                              {evaluationField.self_score}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="general-evaluation-sub-section__row">
                                        <div className="general-evaluation-sub-section__column">
                                          <div className="general-evaluation-sub-section__item center">
                                            {data.evaluator.id !== userID ? (
                                              evaluationField.boss_score
                                            ) : (
                                              <>
                                                <input
                                                  type="number"
                                                  className={`input__item boss-evaluation__input ${
                                                    errors[
                                                      toInteger(
                                                        `${sectionKey}${index}${key}`
                                                      )
                                                    ]
                                                      ? 'error'
                                                      : ''
                                                  }`}
                                                  max="10"
                                                  onBlur={event =>
                                                    handleBlur(
                                                      event,
                                                      subSectionData.sub_section_type_id,
                                                      evaluationField.boss_score,
                                                      evaluationField.id,
                                                      toInteger(
                                                        `${sectionKey}${index}${key}`
                                                      )
                                                    )
                                                  }
                                                  name={toInteger(
                                                    `${sectionKey}${index}${key}`
                                                  )}
                                                  ref={el =>
                                                    inputRefs.current.push(el)
                                                  }
                                                  placeholder={
                                                    evaluationField.boss_score
                                                  }
                                                />

                                                {evaluationField.boss_score >
                                                0 ? (
                                                  <div className="boss-score">
                                                    {evaluationField.boss_score}
                                                  </div>
                                                ) : (
                                                  ''
                                                )}

                                                {loading[
                                                  toInteger(
                                                    `${sectionKey}${index}${key}`
                                                  )
                                                ] ? (
                                                  <div className="loader-2 center">
                                                    <span />
                                                  </div>
                                                ) : (
                                                  ''
                                                )}

                                                {errors[
                                                  toInteger(
                                                    `${sectionKey}${index}${key}`
                                                  )
                                                ] ? (
                                                  <span className="general-evaluation-sub-section__error">
                                                    {
                                                      errors[
                                                        toInteger(
                                                          `${sectionKey}${index}${key}`
                                                        )
                                                      ]
                                                    }
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )
                      )}
                    </div>
                  ))}
              </div>
            ) : (
              <div className="user-evaluation-section">
                <div className="general-evaluation-section__container">
                  <EvaluationSectionTitle
                    title="NO RECORD FOUND!"
                    weight="50%"
                  />
                </div>
              </div>
            )}
          </div>

          {bossEvaluation != null ? (
            <div className="boss-evaluation__button">
              <Button
                disabled={!disable}
                name="Submit"
                modifier="button__save dark account__submit-image"
                type="submit"
                onClick={submitAnswer}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default BossEvaluation;
