import { memo } from 'react';
import PropTypes from 'prop-types';
import TemplateFieldItem from './TemplateFieldItem';

const TemplateFieldList = ({
  fields,
  setFields,
  templateID,
  subSections,
  sectionIndex,
  isSectionEdit,
  subSectionIndex,
  isEditSubSection
}) => {
  const isNewField = fields?.some(state => state.isNewField === true);

  function handleAddField(index) {
    const { id: subSectionID, sub_section_type_id: sectionType } =
      subSections[index];
    setFields(prevState => [
      ...prevState,
      {
        id: null,
        sectionType,
        description: '',
        isNewField: true,
        sub_section_id: subSectionID,
        evaluation_form_id: templateID,
        title: sectionType === 3 ? null : '',
        g1: 0,
        g2: 0,
        g3: 0,
        sg1: 0,
        sg2: 0,
        sg3: 0
      }
    ]);
  }

  return (
    <div className="evaluation__template-subsection-container">
      {fields?.length ? <h4>Questions</h4> : null}
      <ul
        className={`evaluation__template-subsection-list${
          fields?.length ? ' pb20' : ''
        }`}
      >
        {fields?.map((_, fieldIndex) => (
          <li key={fields.id} className="evaluation__template-subsection-item">
            <TemplateFieldItem
              fields={fields}
              setFields={setFields}
              fieldIndex={fieldIndex}
              sectionIndex={sectionIndex}
              subSectionIndex={subSectionIndex}
            />
          </li>
        ))}
      </ul>
      <div
        className={`evaluation__template-add-field${
          isEditSubSection || isSectionEdit || isNewField ? ' disabled' : ''
        }`}
      >
        <button
          type="button"
          className="button button__add default"
          onClick={() => handleAddField(subSectionIndex)}
          disabled={isEditSubSection || isSectionEdit || isNewField}
        >
          <span className="button__text">Add Question</span>
        </button>
      </div>
    </div>
  );
};

TemplateFieldList.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setFields: PropTypes.func,
  templateID: PropTypes.number,
  subSections: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  sectionIndex: PropTypes.number,
  isSectionEdit: PropTypes.bool,
  subSectionIndex: PropTypes.number,
  isEditSubSection: PropTypes.bool
};

export default memo(TemplateFieldList);
