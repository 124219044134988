import React from 'react';

const TextHolder = () => {
  return (
    <div className="space-y-2.5 animate-pulse max-w-lg my-4">
      <div className="flex items-center w-full">
        <div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-700 w-32" />
        <div className="h-3.5 ms-2 bg-gray-400 rounded-full dark:bg-gray-600 w-24" />
        <div className="h-3.5 ms-2 bg-gray-400 rounded-full dark:bg-gray-600 w-full" />
      </div>
    </div>
  );
};

export default TextHolder;
