import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserMenus } from 'src/redux/modules/userMenus/userMenuActions';

export const useUserAccess = (menuCode = undefined) => {
  const dispatch = useDispatch();
  const apiMenus = useSelector(state => state.userMenu.menus);

  const menus =
    apiMenus?.reduce((obj, menu) => {
      const newObj = obj;
      newObj[menu.menu_code] = menu;
      return newObj;
    }, {}) || {};

  useEffect(() => {
    dispatch(getUserMenus());
  }, [dispatch]);

  return {
    menus,
    access: menus[menuCode]?.user_group_access ?? {}
  };
};
