import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import '../../../assets/v1/css/pageTitle.scss';

const PageTitle = ({ backButton = false, backPath = '', title = '' }) => {
  const navigate = useNavigate();

  return (
    <h1 className={`page-title${backButton ? ' has-back-btn' : ''}`}>
      {backButton ? (
        <button
          type="button"
          className="back-button"
          onClick={() => navigate(backPath)}
        />
      ) : null}
      {title}
    </h1>
  );
};

PageTitle.propTypes = {
  backButton: PropTypes.bool,
  backPath: PropTypes.string,
  title: PropTypes.string
};

export default PageTitle;
