import _ from 'lodash';
import { store } from 'src/redux/v1';

export const isEqual = (a, b) => {
  return _.isEqual(a, b);
};

export const filterData = (array, key) => {
  return array
    ?.map(value => value[key])
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .filter(test => test != null || test !== undefined);
};

export const arrayFillKeys = (keys, value) => {
  const retObj = [];
  Object.keys(keys).forEach(e => {
    retObj[keys[e]] = value;
  });
  return retObj;
};

export const joinValuesByKey = (array, key) => {
  if (!array.length) return '';
  const values = [];
  array.forEach(arr => values.push(arr[key]));
  return values.join(', ');
};

export const formatDate = date => {
  const newDate = new Date(date);
  const year = newDate.toLocaleDateString('default', { year: 'numeric' });
  const month = newDate.toLocaleDateString('default', { month: '2-digit' });
  const day = newDate.toLocaleDateString('default', { day: '2-digit' });
  return `${month}/${day}/${year}`;
};

export const getCostCenter = (type, costCenterCode = null) => {
  const state = store.getState();
  if (!state) return null;
  const costCenterList = state?.costCenter?.costCenterList?.sort(
    (a, b) => a - b
  );
  const { divisions } = state?.divisions || null;
  const { departments } = state?.departments || null;
  const { sections } = state?.sections || null;
  const { subSections } = state?.subSections || null;

  switch (type) {
    case 'name':
      return getCostCenterInfo(costCenterCode);
    case 'head':
      return getCostCenterInfo(costCenterCode);
    case 'division':
      return divisions?.sort((a, b) => (a?.id || null) - (b?.id || null));
    case 'department':
      return departments?.sort((a, b) => (a?.id || null) - (b?.id || null));
    case 'section':
      return sections?.sort((a, b) => (a?.id || null) - (b?.id || null));
    case 'sub-section':
      return subSections?.sort((a, b) => (a?.id || null) - (b?.id || null));
    default:
      return costCenterList;
  }

  function getCostCenterInfo(costCenterValue) {
    const cost_center_code = handleDisplayData(
      costCenterList,
      'cost_center_code',
      costCenterValue,
      ''
    );

    if (!cost_center_code) return null;
    const getCostCenterCode = cost_center_code?.match(/(..?)/g);
    const costCenterLength = getCostCenterCode?.length;
    const lastItem = getCostCenterCode[costCenterLength - 1];
    let result;

    switch (costCenterLength) {
      case 1: {
        const division_name = handleDisplayData(
          divisions,
          'division_code',
          lastItem,
          'division_name'
        );
        const division_head = handleDisplayData(
          divisions,
          'division_code',
          lastItem,
          'division_head'
        );
        if (type === 'name') result = division_name;
        if (type === 'head') result = division_head;
        return result ?? null;
      }
      case 2: {
        const department_name = handleDisplayData(
          departments,
          'department_code',
          lastItem,
          'department_name'
        );
        const user = handleDisplayData(
          departments,
          'department_code',
          lastItem,
          'User'
        );
        if (type === 'name') result = department_name;
        if (type === 'head') result = user?.full_name;
        return result ?? null;
      }
      case 3: {
        const section_name = handleDisplayData(
          sections,
          'section_code',
          lastItem,
          'section_name'
        );
        const section_head = handleDisplayData(
          sections,
          'section_code',
          lastItem,
          'SectionHead'
        );
        if (type === 'name') result = section_name;
        if (type === 'head') result = section_head?.full_name;
        return result ?? null;
      }
      case 4: {
        const sub_section_name = handleDisplayData(
          subSections,
          'sub_section_code',
          lastItem,
          'sub_section_name'
        );
        const sub_section_head = handleDisplayData(
          subSections,
          'sub_section_code',
          lastItem,
          'SubSectionHead'
        );
        if (type === 'name') result = sub_section_name;
        if (type === 'head') result = sub_section_head?.full_name;
        return result ?? null;
      }
      default:
        return '';
    }
  }

  function handleDisplayData(arr, arr_field, data, arr_field_to_return) {
    if (!arr_field) return null;
    const result = arr?.find(arrs => arrs[arr_field] === data);
    if (!result) return null;
    if (
      !arr_field_to_return === undefined ||
      !arr_field_to_return ||
      arr_field_to_return.length === 0
    ) {
      return result[arr_field];
    }

    return result[arr_field_to_return];
  }
};

export function getCostCenterFull() {
  const state = store.getState();
  const { costCenterList } = state?.costCenter || null;
  return costCenterList?.map(costCenter => {
    const { cost_center_code } = costCenter;
    const costCenterName = getCostCenter('name', cost_center_code);
    return { cost_center_code: `${cost_center_code} - ${costCenterName}` };
  });
}

export function getDifference(oldData, newData) {
  const data = _.uniq([...Object.keys(oldData), ...Object.keys(newData)]);
  const keys = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key of data) {
    if (!isEqual(oldData[key], newData[key])) {
      keys.push(key);
    }
  }
  return keys;
}
