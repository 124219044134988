import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchFilter from 'src/components/v1/Common/SearchFilter';
import { filterData } from 'src/helpers/v1/utils';
import {
  getEvaluationTemplates,
  getEvaluationTemplateStatus
} from 'src/redux/v1/modules/evaluations/templates/templatesActions';
import { updateEvaluationTemplateStatusByIdService } from 'src/api/v1/modules/evaluation';

const EvaluationTemplate = () => {
  const dispatch = useDispatch();

  const { evaluationTemplates, evaluationTemplatesStatuses } = useSelector(
    state => state.evaluationTemplate
  );
  const cost_center = useSelector(state => state?.costCenter);

  const templateColumns = [
    {
      key: 'id',
      label: 'Sheet ID',
      readOnly: true
    },
    {
      key: 'form_name',
      label: 'Template Name',
      readOnly: true
    },
    {
      key: 'cost_center_code',
      label: 'Cost Center',
      readOnly: true
    },
    {
      key: 'form_description',
      label: 'Details',
      readOnly: true
    },
    {
      key: 'evaluation_form_statuses',
      label: 'Status',
      type: 'select',
      placeholder: 'Select Status',
      selectOptions: evaluationTemplatesStatuses?.map(status => {
        return {
          id: status.id,
          label: status.name,
          value: status.name
        };
      }),
      onChangeFn: async dataValues => {
        const { data, selectOptions } = dataValues;

        const payload = {
          template_name: data.form_name,
          template_description: data.form_description,
          cost_center_code: data.cost_center_code,
          id: data.id,
          status_id: selectOptions.find(
            option =>
              option.value.toLowerCase() ===
              data.evaluation_form_statuses.toLowerCase()
          )?.id
        };

        const { success } = await updateEvaluationTemplateStatusByIdService(
          payload
        );
        if (success) {
          dispatch(getEvaluationTemplates());
        }
      }
    }
  ];

  useEffect(() => {
    dispatch(getEvaluationTemplates());
    dispatch(getEvaluationTemplateStatus());
  }, []);

  const evaluationTemplateData =
    evaluationTemplates?.rows?.map(template => ({
      id: template.id,
      form_name: template.form_name,
      cost_center_code: template?.cost_center_list[0]?.cost_center_code,
      form_description: template.form_description,
      evaluation_form_statuses: template.evaluation_form_statuses?.name
    })) ?? [];

  return (
    <SearchFilter
      searchInputPlaceholder="Search Templates"
      buttonName="Template"
      inputs={[
        {
          label: 'Status',
          type: 'checkbox',
          options: filterData(evaluationTemplatesStatuses, 'name'),
          name: 'evaluation_form_statuses'
        },
        {
          label: 'Cost Center',
          type: 'dropdown',
          options: filterData(cost_center.costCenterList, 'cost_center_code'),
          name: 'cost_center_code'
        }
      ]}
      buttonLink="/evaluations/templates/add"
      title="Evaluation Templates List"
      data={evaluationTemplateData}
      columns={templateColumns}
      pathName="/evaluations/templates"
      shouldDisplayEditable
    />
  );
};

export default EvaluationTemplate;
