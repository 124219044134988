import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useEmployees(evaluationId) {
  const { allEmployees } = useSelector(state => state.employees);
  const employees = useMemo(
    () =>
      allEmployees?.map(({ id, first_name, last_name, suffix, status }) => {
        const checkSuffix = ['', null].every(nullish => nullish !== suffix);
        const hasSuffix = checkSuffix ? ` ${suffix}` : '';
        const name = `${first_name} ${last_name}${hasSuffix}`;
        return { id, name, status };
      }),
    [evaluationId]
  );

  const employeeName = info => {
    const { first_name, last_name, middle_name, suffix } = info || {};
    const checkSuffix = ['', null].every(nullish => nullish !== suffix);
    const hasSuffix = checkSuffix ? ` ${suffix ?? ''}` : '';

    const empty =
      first_name === undefined &&
      last_name === undefined &&
      middle_name === undefined &&
      suffix === undefined;

    const name = empty
      ? '-'
      : `${first_name ?? '-'} ${middle_name ? `${middle_name} ` : ''}${
          last_name ?? ''
        }${hasSuffix}`;

    return name;
  };

  return { employees, allEmployees, employeeName };
}
