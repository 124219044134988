import { getProjectsService } from 'src/api/modules/projects';
import { FETCH_PROJECTS, SET_LIST_UPDATE } from './projectTypes';

export function setProjectList(payload) {
  return { type: FETCH_PROJECTS, payload };
}

export function setProjectListUpdate(payload) {
  return { type: SET_LIST_UPDATE, payload };
}

export function getAllUsersList() {
  return async function fetchUserAll(dispatch) {
    const response = await getProjectsService();
    if (response?.data) dispatch(setProjectList(response.data));
  };
}

export function getAllProjects(objectList) {
  return function setUserDataListAction(dispatch) {
    dispatch(setProjectList(objectList));
  };
}
