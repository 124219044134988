/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Button from 'src/components/Common/Button';
import { useUserAccess } from 'src/hooks/useUserAccess';
import FormCard from 'src/components/Common/FormCard';
import FormField from 'src/components/Common/FormField';

// Hooks
import useEvaluationSchedule from 'src/hooks/EvaluationSchedule/useEvaluationSchedule';

const SchedulePart = ({
  viewOnly,
  menuCode,
  templateId,
  handleChange,
  templateFields,
  isScheduleNameExist,
  handleScheduleSubmit
}) => {
  const { validationTemplateSchema } = useEvaluationSchedule();
  const { access } = useUserAccess(menuCode);
  const classDisabled = viewOnly ? 'bg-[#f7f7f7]' : 'bg-white';

  function formatScheduleDate(date) {
    const localDate = new Date(date);
    const offset = localDate.getTimezoneOffset() * 60000;
    const localISODate = new Date(localDate.getTime() - offset).toISOString();
    return localISODate.split('T')[0];
  }

  const formattedStartDate = templateFields.start_date
    ? formatScheduleDate(templateFields.start_date)
    : 'N/A';

  const minStartDate = new Date().toISOString().split('T')[0];

  const formattedEndDate = templateFields.end_date
    ? formatScheduleDate(templateFields.end_date)
    : 'N/A';

  return (
    <Formik
      enableReinitialize
      initialValues={templateFields}
      validationSchema={validationTemplateSchema}
      onSubmit={values => handleScheduleSubmit(templateId, values)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        setFieldError
      }) => (
        <Form id="evaluationTemplateForm">
          <div className="border-solid border-[1px] border-[#dedede] p-[20px] rounded-[3px] mb-5">
            <div className="flex">
              <div className="w-[1440px] max-w-[100%]">
                <div className="flex gap-4">
                  <div className="flex basis-1/2">
                    <div className="flex flex-col w-full gap-4">
                      <div>
                        <FormField
                          label="Name"
                          required
                          name="name"
                          type="text"
                          placeholder=""
                          errorMessage="Field Required"
                          value={templateFields.name ?? ''}
                          error={errors.name && touched.name}
                          onChange={e =>
                            handleChange(
                              e,
                              'template',
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                          disabled={viewOnly}
                        />
                        {isScheduleNameExist && (
                          <div className="text-[10px] text-[#E43B26] font-stolzlBook">
                            Schedule name already exists
                          </div>
                        )}
                      </div>
                      <div className="flex w-full gap-4">
                        <div className="basis-1/2">
                          <FormField
                            label="Start Date"
                            required
                            name="start_date"
                            type="date"
                            errorMessage="Field Required"
                            value={formattedStartDate}
                            max="9999-12-31"
                            // min={minStartDate}
                            error={errors.start_date}
                            onChange={e =>
                              handleChange(
                                e,
                                'template',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            disabled={viewOnly}
                          />
                        </div>
                        <div className="basis-1/2">
                          <FormField
                            label="End Date"
                            name="end_date"
                            type="date"
                            value={formattedEndDate}
                            max="9999-12-31"
                            // min={minStartDate}
                            error={errors.end_date && touched.end_date}
                            onChange={e =>
                              handleChange(
                                e,
                                'template',
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              )
                            }
                            disabled={viewOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex basis-1/2">
                    <div className="w-full">
                      <FormField
                        className="min-h-[115px]"
                        label="Schedule Description"
                        required
                        name="description"
                        type="textarea"
                        placeholder=""
                        errorMessage="Field Required"
                        value={templateFields?.description ?? ''}
                        error={
                          errors.template_description &&
                          touched.template_description
                        }
                        max="255"
                        onChange={e =>
                          handleChange(
                            e,
                            'template',
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                        disabled={viewOnly}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-[10px] text-[#E43B26] font-stolzlBook"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    {!viewOnly ? (
                      <div className="self-start ml-auto mt-[19px]">
                        <Button
                          name="Save"
                          modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-[130px] hover:bg-gray-500"
                          spanModifier="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                          type="submit"
                          disabled={
                            isSubmitting ||
                            (templateFields.orig_status !== undefined &&
                              templateFields.orig_status !== 'N') ||
                            (templateId && !access.can_edit) ||
                            !access.can_add
                          }
                        />
                      </div>
                    ) : (
                      <div className="opacity-0 pointer-events-none self-start ml-auto mt-[19px] w-[130px]" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo(SchedulePart);
