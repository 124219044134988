import React from 'react';
import PropTypes from 'prop-types';

const Box = ({ children, outline, modifier, small, padding }) => {
  const boxClass = small ? 'box small' : 'box';
  const bordered = outline ? ' outline' : '';

  return (
    <div>
      {padding ? (
        <div
          className={`box--padding${bordered} ${modifier}`}
          style={{ padding }}
        >
          {children}
        </div>
      ) : (
        <div className={`${boxClass}${bordered} ${modifier}`}>{children}</div>
      )}
    </div>
  );
};

/* USAGE:
 * Default: <Box>Test</Box>
 * Small: <Box small>Test</Box>
 * Outline: <Box outline>Test</Box>
 * Combine: <Box small outline>Test</Box>
 *
 * Padding:
 *    <Box padding="20px">Test</Box>
 *    <Box padding="20px 30px">Test</Box>
 *    <Box padding="20px 30px 10px">Test</Box>
 *    <Box padding="20px 25px 30px 35px">Test</Box>
 *
 * Padding+Outline:
 *    <Box padding="20px" outline>Test</Box>
 */

Box.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({ Object })),
  outline: PropTypes.string,
  modifier: PropTypes.string,
  small: PropTypes.string,
  padding: PropTypes.string
};
export default Box;
