import React from 'react';
import PageTitle from 'src/components/Common/PageTitle';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import SlideModal from 'src/components/Common/SlideModal';
import AddModal from 'src/components/Parts/User/AddModal';
import ViewEditModal from 'src/components/Parts/User/ViewEditModal';
import ModalCenter from 'src/components/Common/ModalCenter';
import DeleteModal from 'src/components/Parts/User/DeleteModal';
import BulkDeleteModal from 'src/components/Parts/User/BulkDeleteModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import useUsersIndex from 'src/hooks/Pages/admin/users/useUsersIndex';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import PropTypes from 'prop-types';
import { USER_COLUMNS } from 'src/helpers/constants';
import { useUserAccess } from 'src/hooks/useUserAccess';
import { USER_BULK_DELETE } from 'src/api/endpoints';
import 'src/assets/css/user.scss';

const UsersIndex = ({ menuCode }) => {
  const {
    userListMemo,
    dataList,
    showModal,
    showViewModal,
    showDeleteModal,
    showBulkDeleteModal,
    userId,
    userData,
    userName,
    hasMore,
    inputs,
    form,
    isOpen,
    submitFilter,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    handleBulkDeleteModal,
    fetchData
  } = useUsersIndex();
  const { access } = useUserAccess(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs crumbs={[{ name: 'System' }, { name: 'User List' }]} />

      <div className="mt-5">
        <div className="filter__content--search overflow-visible">
          <Filter
            searchInputPlaceholder="User Name, Email"
            buttonName="New User"
            inputs={inputs}
            buttonLink={false}
            deleteModal="DeleteModal"
            modalName="AddEdit"
            buttonOnClick={() => handleModal('AddEdit')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
            isOpen={isOpen}
          />
          <InfiniteScroll
            dataLength={userListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            style={{ overflow: 'visible' }}
            endMessage={
              <p className="text-center mt-5">
                {userListMemo.length ? (
                  <b>No more data to load</b>
                ) : (
                  <b>No records found</b>
                )}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={userListMemo || []}
              clickableRows={false}
              headingColumns={USER_COLUMNS}
              title="User List"
              actions={['view', 'delete']}
              showModal={showModal}
              showViewModal={showViewModal}
              handleModal={handleModal}
              handleViewModal={handleViewModal}
              handleDeleteModal={handleDeleteModal}
              modalName="View"
              deleteModal="DeleteModal"
              keyField="id"
              shouldEllipsis
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <SlideModal
        showModal={showModal}
        modalName="AddEdit"
        handleModal={handleModal}
      >
        {showModal && (
          <AddModal
            handleModal={handleModal}
            userId={userId}
            dataList={dataList}
          />
        )}
      </SlideModal>
      <SlideModal
        showModal={showViewModal}
        modalName="View"
        handleModal={handleViewModal}
      >
        {showViewModal && (
          <ViewEditModal
            handleModal={handleViewModal}
            userId={userId}
            userData={userData}
            menuCode={menuCode}
          />
        )}
      </SlideModal>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            id={userId}
            name={userName}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter showModal={showBulkDeleteModal} modalName="BulkDeleteModal">
        {showBulkDeleteModal && (
          <BulkDeleteModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={USER_BULK_DELETE}
          />
        )}
      </ModalCenter>
    </div>
  );
};

UsersIndex.propTypes = {
  menuCode: PropTypes.string
};

export default UsersIndex;
