import { useDeleteQuizModal } from 'src/hooks/Pages/LearningManagement/QuizManager/useDeleteQuizModal';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';
import PropTypes from 'prop-types';

const DeleteQuizModal = ({ handleDeleteModal, id, name, modal }) => {
  const { quizTitle, handleDelete } = useDeleteQuizModal({
    handleDeleteModal,
    id,
    modal
  });

  return (
    <DeleteModalUI
      label={name || quizTitle}
      submit={() => handleDelete(id)}
      cancel={() => handleDeleteModal(modal)}
    />
  );
};

DeleteQuizModal.propTypes = {
  handleDeleteModal: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  modal: PropTypes.string
};

export default DeleteQuizModal;
