import { useNavigate } from 'react-router-dom';

export const useTakeAssessmentModal = ({ handleTakeAssessment, modal }) => {
  const navigate = useNavigate();

  const handleConfirm = userAssessmentId => {
    if (userAssessmentId) {
      navigate(`/my-assessments/take-quiz/${userAssessmentId}`);
    }
  };

  return {
    handleConfirm
  };
};
