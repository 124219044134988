import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filterData } from 'src/helpers/v1/utils';
import SearchFilter from 'src/components/v1/Common/SearchFilter';
import { getUserEvaluationHistory } from 'src/redux/v1/modules/evaluations/userEvaluation/userEvaluationActions';
import { userEvaluationColumns } from './columns';

const EvaluationTemplate = () => {
  const dispatch = useDispatch();
  const [lists, setLists] = useState(null);
  const [codies, setCodies] = useState(null);
  const [maxSections, setMaxSections] = useState(0);
  const allUsers = useSelector(({ user }) => user?.users);
  const {
    user: { user }
  } = useSelector(state => state.user);
  const {
    userEvaluationHistory: { evaluations }
  } = useSelector(state => state.userEvaluation);
  const { userEvaluationScheduleStatus } = useSelector(
    state => state.evaluationSchedule
  );

  let GENERATING = true;
  const tableColumns = [...userEvaluationColumns];

  if (evaluations?.length === 0) GENERATING = false;

  useEffect(() => {
    dispatch(getUserEvaluationHistory());
  }, []);

  useEffect(() => {
    if (!evaluations) return;
    const list = evaluations?.sort((a, b) => (a?.id || 0) - (b?.id || 0));
    const sectionCount = [
      list?.map(({ id, ...rest }) => {
        const { user_evaluation_section: sections } = rest;
        return sections?.length;
      })
    ];
    const maxCount = Math.max(...sectionCount);
    const results = [
      list?.map(
        ({
          id,
          eval_form_name,
          evaluation_schedule,
          user_evaluation_status,
          ...rest
        }) => {
          const { evaluation_start_date, evaluation_schedule_statuses } =
            evaluation_schedule;
          const { user_evaluation_section: user_sections } = rest;
          const evaluationScheduleStatus = evaluation_schedule_statuses?.name;
          const status = user_evaluation_status?.name;
          let rowData;

          if (
            evaluationScheduleStatus === 'Ongoing' ||
            evaluationScheduleStatus === 'Archive'
          ) {
            const employee = codies?.find(
              employeeInfo => employeeInfo?.id === user?.id
            );
            const item = {
              id: employee?.employee_id_number ?? '—',
              name: `${user?.first_name} ${user?.last_name}`,
              position: user?.Position?.name ?? '—',
              template: eval_form_name,
              date: evaluation_start_date,
              sections: user_sections?.length,
              evaluation_id: id
            };

            const missing = (maxCount || 0) - (item?.sections || 0);
            if (missing !== 0 && missing >= 1) {
              for (let x = 1; x <= missing; x += 1) {
                user_sections?.push({ evaluator: null });
              }
            }

            const sections = user_sections
              ?.sort((a, b) => (a?.id || 0) - (b?.id || 0))
              ?.map(({ evaluator }, index) => ({
                [`section${index + 1}`]: evaluator
                  ? `${evaluator?.first_name} ${evaluator?.last_name}`
                  : '—'
              }));

            const rows = sections?.reduce(
              (result, current) => Object.assign(result, current),
              { ...item }
            );
            rowData = { ...rows, status };
          }
          return rowData;
        }
      )
    ];
    setLists(results);
  }, [codies, evaluations, user]);

  useEffect(() => {
    if (!lists) return;
    const evaluationSections = lists?.map(({ sections }) => sections);
    setMaxSections(Math.max(...evaluationSections));
  }, [lists]);

  useEffect(() => {
    const members = [
      ...new Map(allUsers?.map(item => [item.first_name, item])).values()
    ];
    const uniqueCodies = [
      members?.map(member => {
        const { id, employee_id_number } = member;
        return { id, employee_id_number };
      })
    ];
    setCodies(uniqueCodies);
  }, [allUsers]);

  function generateHeaders() {
    for (let i = 1; i <= maxSections; i += 1) {
      tableColumns?.push({
        key: `section${i}`,
        label: `Section ${i}`,
        readOnly: true
      });
      if (i === maxSections) {
        GENERATING = false;
      }
    }
    return tableColumns;
  }

  return (
    <>
      <pre style={{ display: 'none' }}>
        {JSON.stringify(generateHeaders(), null, 4)}
      </pre>
      {GENERATING ? null : (
        <SearchFilter
          keyField="evaluation_id"
          searchInputPlaceholder="Search Templates"
          showActionBtn={false}
          pathName="/evaluations"
          title="All Evaluation Sheets"
          data={lists}
          inputs={[
            {
              label: 'Status',
              type: 'checkbox',
              options: filterData(userEvaluationScheduleStatus, 'name'),
              name: 'status'
            }
          ]}
          columns={[
            ...new Map(
              generateHeaders()?.map(item => [item.key, item])
            ).values(),
            {
              key: 'status',
              label: 'Status',
              readOnly: true
            }
          ]}
        />
      )}
    </>
  );
};

export default EvaluationTemplate;
