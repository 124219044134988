import { useSelector } from 'react-redux';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { deleteQuestionService } from 'src/api/modules/quiz';

export const useBulkDeleteQuestionModal = ({ handleBulkDeleteModal }) => {
  const { ids } = useSelector(state => state.datatable);

  const handleDelete = async quizId => {
    if (ids) {
      const res = await deleteQuestionService(quizId, ids);
      if (res.statusCode === 200) {
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
      } else {
        toast.error('Unauthorized Deletion of Data.', {
          icon: <CgCloseO />
        });
      }
      handleBulkDeleteModal(null);
    }
  };
  return {
    handleDelete
  };
};
