import { auth, ENV } from 'src/api/config';
import axios from 'axios';
import { USER_GROUPS } from 'src/api/endpoints';
import { fetchAPI } from 'src/api/fetchAPI';

export const getUserGroupsService = async (page, searchParams) => {
  try {
    let endpoint = `${USER_GROUPS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const createUserGroupService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${USER_GROUPS}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateUserGroupService = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${USER_GROUPS}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getUserGroupByIdService = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${USER_GROUPS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteUserGroupService = async id => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${USER_GROUPS}/${id}`
    });
  } catch (error) {
    return error;
  }
};
