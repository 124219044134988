import {
  getEvaluationTemplatesService,
  createEvaluationTemplateService,
  getEvaluationTemplateByIdService,
  updateEvaluationTemplateByIdService,
  deleteEvaluationTemplateByIdService,
  getEvaluationTemplateStatusesService,
  getUserEvaluationHistoryService,
  updateEvaluationTemplateService
} from 'src/api/modules/evaluation';
import { GET_TEMPLATES, GET_TEMPLATES_STATUS } from './templatesTypes';

export function setTemplateDataList(payload) {
  return { type: GET_TEMPLATES, payload };
}

export function setEvaluationTemplates(data) {
  return { type: GET_TEMPLATES, payload: data };
}

export function setEvaluationTemplateStatuses(data) {
  return { type: GET_TEMPLATES_STATUS, payload: data };
}

export function getAllEvaluationTemplates(objectList) {
  return function setTemplateDataListAction(dispatch) {
    dispatch(setTemplateDataList(objectList));
  };
}

export function getEvaluationTemplates() {
  return async function (dispatch) {
    const response = await getEvaluationTemplatesService();
    if (response?.success && response?.data)
      dispatch(setEvaluationTemplates(response.data));
  };
}

export function getEvaluationTemplateById(templateId) {
  return async function () {
    return await getEvaluationTemplateByIdService(templateId);
  };
}

export function getEvaluationTemplateStatus() {
  return async function (dispatch) {
    const response = await getEvaluationTemplateStatusesService();
    if (response?.data) dispatch(setEvaluationTemplateStatuses(response?.data));
  };
}

export function createEvaluationTemplate(endpoint, data) {
  return async function () {
    return await createEvaluationTemplateService(endpoint, data);
  };
}

export function updateEvaluationTemplateById(endpoint, id, data) {
  return async function () {
    return await updateEvaluationTemplateByIdService(endpoint, id, data);
  };
}

export function deleteEvaluationTemplateById(endpoint, id) {
  return async function () {
    return await deleteEvaluationTemplateByIdService(endpoint, id);
  };
}

export function setEvaluationByUser(data) {
  return { type: GET_TEMPLATES_STATUS, payload: data };
}

export function getEvaluationById() {
  return async function (dispatch) {
    const response = await getUserEvaluationHistoryService();
    if (response?.success && response?.data)
      dispatch(setEvaluationTemplates(response.data));
  };
}

export function updateEvaluationTemplateServiceById(endpoint, id, data) {
  return async function () {
    return await updateEvaluationTemplateService(endpoint, id, data);
  };
}
