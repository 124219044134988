import axios from 'axios';
import { QUESTIONS, BULK_DELETE_QUESTIONS, QUIZZES } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getQuestionItemsService = async (page, searchParams) => {
  try {
    let endpoint = `${QUESTIONS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getQuestionByIDService = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${QUESTIONS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const createQuestionService = async data => {
  try {
    const response = await axios.post(`${ENV.test}${QUESTIONS}`, data, auth());
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteQuestionByIdService = async id => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${QUESTIONS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateQuestionByIdService = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${QUESTIONS}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllQuestionItemService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${QUESTIONS}/all`
    });
  } catch (error) {
    return error;
  }
};

export const bulkDeleteQuestionService = async ids => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: BULK_DELETE_QUESTIONS,
      params: { data: { ids } }
    });
  } catch (error) {
    return error;
  }
};
