import {
  ALL_COST_CENTER,
  COST_CENTER,
  UPDATE_COST_CENTER_BY_USER
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getCostCenterService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_COST_CENTER
    });
  } catch (error) {
    return error;
  }
};

export const getCostCenterByIDService = async costCenterId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${COST_CENTER}/${costCenterId}`
    });
  } catch (error) {
    return error;
  }
};

export const updateCostCenterByUserId = async data => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: UPDATE_COST_CENTER_BY_USER,
      body: data
    });
  } catch (error) {
    return error;
  }
};
