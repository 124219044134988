import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import {
  createProjectService,
  getProjectRolesService,
  getProjectsByIdService,
  getProjectsService,
  updateProjectService
} from 'src/api/modules/projects';
import {
  getAllProjects,
  setProjectListUpdate
} from 'src/redux/modules/projects/projectActions';
import { getAllIndustry } from 'src/api/modules/industry';
import { getAllEmployees } from 'src/redux/modules/employee/employeeActions';
import { CgCloseO, CgCheckO } from 'react-icons/cg';

export const useAddEditModal = ({ handleModal, projectId, dataList }) => {
  const dispatch = useDispatch();
  const employeeRef = useRef(null);
  const employeeList = useSelector(state => state.employees.allEmployees);
  const [searchEmployee, setSearchEmployee] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [projectRoleOptions, setProjectRoleOptions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [startDate, setStartDate] = useState();
  const [end_Date, setEnd_Date] = useState();
  const [formLoading, setFormLoading] = useState(false);

  const initialFormData = {
    color_hex: '#BEBCBC',
    client: '',
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    manpower: 0,
    industry_id: '',
    project_role_id: '',
    status: 'A',
    project_members: []
  };
  const [formData, setFormData] = useState(initialFormData);
  const initialDummyData = {
    color_hex: '#E01F1F',
    client: 'Client Test',
    name: 'Test Name',
    description: 'Description Test',
    start_date: '2023-06-28',
    end_date: '2023-06-30',
    manpower: 0,
    industry_id: '1',
    project_role_id: '1',
    status: 'A',
    project_members: []
  };

  const validationSchema = Yup.object().shape({
    client: Yup.string()
      .max(255, 'Maximum of 255 characters allowed')
      .required('Required')
      .trim(),
    name: Yup.string()
      .max(255, 'Maximum of 255 characters allowed')
      .required('Required')
      .trim(),
    description: Yup.string()
      .max(255, 'Maximum of 255 characters allowed')
      .required('Required')
      .trim(),
    start_date: Yup.date().required('Required'),
    end_date: Yup.date()
      .required('Required')
      .test(
        'is-greater',
        'End Date should be greater than Start Date',
        function (endDate) {
          if (!startDate || !end_Date) {
            // Return true if either date is not provided, as they are both required
            return true;
          }
          // Compare the inputted dates
          return end_Date > startDate;
        }
      ),
    industry_id: Yup.string().required('Required'),
    project_members: Yup.array().of(
      Yup.object({
        resource_perc: Yup.number()
          .required('Required')
          .min(1, 'Min of 1')
          .max(150, 'Max of 150'),
        assignment_start_date: Yup.date()
          .when('start_date', ([start_date], schema) => {
            if (start_date) {
              return Yup.date().min(start_date, 'Must be after Start Date');
            }
            return schema;
          })
          .when('end_date', ([end_date], schema) => {
            if (end_date) {
              return Yup.date().min(end_date, 'Must be before End Date');
            }
            return schema;
          }),
        assignment_end_date: Yup.date()
          .when('start_date', ([start_date], schema) => {
            if (start_date) {
              return Yup.date().min(start_date, 'Must be after Start Date');
            }
            return schema;
          })
          .when('end_date', ([end_date], schema) => {
            if (end_date) {
              return Yup.date().min(end_date, 'Must be before End Date');
            }
            return schema;
          })
      })
    )
  });

  const getLowerCasedValue = value =>
    typeof value === 'string' ? value.toLowerCase() : value;

  const fetchProject = async projectID => {
    const response = await getProjectsByIdService(projectID);

    if (response.data) {
      const responseData = response.data;
      Object.keys(response.data).forEach(formKey => {
        if (typeof formData[formKey] === 'undefined') {
          delete responseData[formKey];
        }
      });
      responseData.start_date = DateTime.fromFormat(
        responseData.start_date,
        'MM/dd/yyyy'
      ).toFormat('yyyy-MM-dd');
      responseData.end_date = DateTime.fromFormat(
        responseData.end_date,
        'MM/dd/yyyy'
      ).toFormat('yyyy-MM-dd');

      const modifiedProjectMembers = responseData.project_members.map(
        member => ({
          ...member,
          assignment_start_date: DateTime.fromFormat(
            member.assignment_start_date,
            'MM/dd/yyyy'
          ).toFormat('yyyy-MM-dd'),
          assignment_end_date: DateTime.fromFormat(
            member.assignment_end_date,
            'MM/dd/yyyy'
          ).toFormat('yyyy-MM-dd')
        })
      );
      responseData.project_members = modifiedProjectMembers;
      setFormData(responseData);
    }
  };

  const fetchIndustries = async () => {
    const response = await getAllIndustry();
    const industries = response.data.map(industry => ({
      label: industry.name,
      value: industry.id
    }));
    setIndustryOptions(industries);
  };

  const fetchProjectRoles = async () => {
    const response = await getProjectRolesService();
    const projectRoles = response.data.map(role => ({
      label: role.description,
      value: role.id
    }));
    setProjectRoleOptions(projectRoles);
  };

  const fetchNewProjectList = async () => {
    getProjectsService(1)
      .then(res => {
        dispatch(getAllProjects(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleColorChange = color => {
    setFormData(prevState => ({
      ...prevState,
      color_hex: color
    }));
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    const initialValue = value.match(/^\s/) !== null;

    if (
      (name === 'client' && initialValue) ||
      (name === 'name' && initialValue) ||
      (name === 'description' && initialValue)
    ) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value.trim()
      }));
    } else {
      let modifiedValue = value;

      if (name === 'project_role_id') {
        modifiedValue = parseInt(value, 10);
      }

      setFormData(prevState => ({
        ...prevState,
        [name]: modifiedValue
      }));
    }

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const handSelectChange = useCallback(
    (value, name, setFieldTouched, setFieldError) => {
      let modifiedName = name;
      let modifiedValue = value;

      if (name === 'industry_id') {
        modifiedName = 'industry_id';
        modifiedValue = parseInt(value, 10);
      } else if (name === 'status') {
        modifiedName = 'status';
      } else if (name === 'project_role_id') {
        // Add this block
        modifiedName = 'project_role_id';
        modifiedValue = parseInt(value, 10);
      }

      setFormData(prevState => ({
        ...prevState,
        [modifiedName]: modifiedValue
      }));

      setFieldTouched(modifiedName, true);
      setFieldError(modifiedName, '');
    },
    [setFormData]
  );

  const handleLookupChange = (e, names, values) => {
    if (!names && !values) {
      const {
        target: { name, value }
      } = e;

      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [names]: values
      }));
    }
  };

  const handleSubmit = useCallback(async () => {
    if (projectId) {
      // EDIT
      const payload = {};
      payload.color_hex = formData.color_hex;
      payload.client = formData.client;
      payload.name = formData.name;
      payload.description = formData.description;
      payload.start_date = formData.start_date;
      payload.end_date = formData.end_date;
      payload.manpower = formData.project_members.length;
      payload.industry_id = formData.industry_id;
      payload.status = formData.status;

      const project_members =
        formData?.project_members.map(item => ({
          employee_id: item.employee.employee_info.employee_id,
          project_role_id: item.project_role_id,
          is_project_lead: item.is_project_lead,
          resource_perc: item.resource_perc,
          assignment_start_date: item.assignment_start_date
            ? item.assignment_start_date
            : formData.start_date,
          assignment_end_date: item.assignment_end_date
            ? item.assignment_end_date
            : formData.end_date
        })) || [];
      payload.project_members = project_members;
      setFormLoading(true);
      const response = await updateProjectService(projectId, payload);
      if (response.status === 200) {
        fetchNewProjectList();
        setTimeout(() => {
          setIsEdit(false);
          setFormLoading(false);
        }, 500);
        dispatch(
          setProjectListUpdate({ id: projectId, payload: response.data.data })
        );
      } else {
        toast.error('Update project failed', { icon: <CgCloseO /> });
        setIsEdit(false);
        setFormLoading(false);
      }
    } else {
      // ADD
      const payload = {};
      payload.color_hex = formData.color_hex;
      payload.client = formData.client;
      payload.name = formData.name;
      payload.description = formData.description;
      payload.start_date = formData.start_date;
      payload.end_date = formData.end_date;
      payload.manpower = formData.project_members.length;
      payload.industry_id = formData.industry_id;
      payload.status = formData.status;

      const project_members =
        formData?.project_members.map(item => ({
          employee_id: item.employee.employee_info.employee_id,
          project_role_id: item.project_role_id,
          is_project_lead: item.is_project_lead,
          resource_perc: item.resource_perc,
          assignment_start_date: item.assignment_start_date
            ? item.assignment_start_date
            : formData.start_date,
          assignment_end_date: item.assignment_end_date
            ? item.assignment_end_date
            : formData.end_date
        })) || [];
      payload.project_members = project_members;

      const response = await createProjectService(payload);

      if (response.status === 201) {
        fetchNewProjectList();
        toast.success('Successfully Added!', {
          icon: <CgCheckO />
        });
        handleModal(null);
        setFormData(formData);
      }
    }
  });

  const handleSearchMember = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    let foundEmployee = [];
    const {
      target: { name, value }
    } = e;
    if (value.length) {
      foundEmployee = employeeList.filter(
        data =>
          `${getLowerCasedValue(data.first_name)} ${getLowerCasedValue(
            data.last_name
          )}`.includes(getLowerCasedValue(e.target.value)) ||
          `${getLowerCasedValue(data.last_name)} ${getLowerCasedValue(
            data.first_name
          )}`.includes(getLowerCasedValue(e.target.value))
      );
    }
    setSearchEmployee(foundEmployee);
    setFieldValue('search_members', value);
    setFieldTouched('search_members', true);
    setFieldError('search_members', '');
  };

  const handleAddMember = (
    member,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    const isMemberAlreadyAdded = formData?.project_members.some(
      data => data.employee.employee_info.employee_id === member.id
    );

    if (isMemberAlreadyAdded) {
      setSearchEmployee([]);
      return;
    }

    setFieldValue('members', member.first_name);
    setFieldTouched('members', true);
    setFieldError('members', '');

    const memberInfo = {
      project_role_id: 1,
      is_project_lead: false,
      resource_perc: 100,
      assignment_start_date: '',
      assignment_end_date: '',
      employee: {
        employee_info: {
          first_name: member.first_name,
          last_name: member.last_name,
          employee_id: member.id
        }
      }
    };

    handleLookupChange(null, 'project_members', [
      ...formData.project_members,
      memberInfo
    ]);

    setSearchEmployee([]);
    employeeRef.current.value = null;
  };

  const handleUpdateMember = (event, memberId, memberIndex) => {
    const { name, value, type, checked } = event ? event.target : {};

    let parsedValue = value;
    let parsedLabel = value;

    if (name === 'project_role_id') {
      parsedValue = value ? parseInt(value, 10) : null;
      parsedLabel = projectRoleOptions.find(
        role => role.value === parsedValue
      )?.label;
    }

    if (name === 'is_project_lead' && type === 'checkbox') {
      const updatedProjectMembers = formData.project_members.map(
        (member, index) => {
          if (index === memberIndex) {
            return {
              ...member,
              [name]: checked
            };
          }
          return {
            ...member,
            is_project_lead: false
          };
        }
      );

      setFormData(prevState => ({
        ...prevState,
        project_members: updatedProjectMembers
      }));
    } else {
      const updatedProjectMembers = formData.project_members.map(
        (member, index) => {
          if (index === memberIndex) {
            return {
              ...member,
              [name]: parsedValue
            };
          }
          return member;
        }
      );

      setFormData(prevState => ({
        ...prevState,
        project_members: updatedProjectMembers
      }));
    }
  };

  const handleDeleteMember = memberId => {
    const updatedMembers = formData?.project_members.filter(member => {
      return member.employee.employee_info.employee_id !== memberId;
    });

    setFormData(prevState => ({
      ...prevState,
      project_members: updatedMembers
    }));
  };

  const statusOptions = [
    {
      value: 'A',
      label: 'Active'
    },
    {
      value: 'N',
      label: 'New'
    },
    {
      value: 'C',
      label: 'Cancelled'
    },
    {
      value: 'F',
      label: 'Fulfilled'
    },
    {
      value: 'O',
      label: 'Onhold'
    }
  ];

  useEffect(() => {
    if (projectId) {
      fetchProject(projectId);
    }
  }, []);

  useEffect(() => {
    fetchIndustries();
    fetchProjectRoles();
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    setStartDate(formData.start_date);
    setEnd_Date(formData.end_date);
  }, [setFormData, formData]);

  return {
    employeeRef,
    formData,
    searchEmployee,
    industryOptions,
    initialDummyData,
    validationSchema,
    projectRoleOptions,
    statusOptions,
    isEdit,
    handleColorChange,
    setIsEdit,
    handleChange,
    handleSubmit,
    handleAddMember,
    handSelectChange,
    handleSearchMember,
    handleUpdateMember,
    handleDeleteMember,
    formLoading
  };
};
