import PropTypes from 'prop-types';
import { useBulkDeleteQuizModal } from 'src/hooks/Pages/LearningManagement/QuizManager/useBulkDeleteQuizModal';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';

const BulkDeleteQuizModal = ({ handleBulkDeleteModal }) => {
  const { questionTitle, handleDelete } = useBulkDeleteQuizModal({
    handleBulkDeleteModal
  });

  return (
    <DeleteModalUI
      label={questionTitle}
      submit={() => handleDelete()}
      cancel={() => handleBulkDeleteModal(null)}
    />
  );
};
BulkDeleteQuizModal.propTypes = {
  handleBulkDeleteModal: PropTypes.func
};
export default BulkDeleteQuizModal;
