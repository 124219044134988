/* eslint-disable react/prop-types */
const ProgressBar = ({ percentage, color }) => {
  const validatedPercentage = Math.max(0, Math.min(100, percentage));
  const progressBarWidth = `${validatedPercentage}%`;

  return (
    <div className="w-full h-[14px] bg-[#eeeeee] rounded-md overflow-hidden">
      <div
        className={`h-full ${color} opacity-100`}
        style={{
          width: progressBarWidth,
          transition: 'width 1s ease-in-out'
        }}
      />
    </div>
  );
};

export default ProgressBar;
