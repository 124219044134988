import React from 'react';
import PropTypes from 'prop-types';

const ConfirmModalUI = ({
  title,
  message,
  confirm,
  cancel,
  confirmBtnLabel,
  hideCancel
}) => {
  return (
    <>
      <div className="font-stolzlBold text-[15px] p-[14px] border-b-[1px] border-[#DEDEDE] relative">
        {title}
        {!hideCancel && (
          <button
            className="w-[12px] h-[12px] absolute right-[15px] top-1/2 transform -translate-y-1/2 after:content-[''] after:w-full after:h-[2px] after:left-0 before:left-0 after:bg-[#999999] after:absolute after:rotate-45 after:rounded before:rounded before:content-[''] before:w-full before:h-[2px] before:bg-[#999999] before:absolute before:-rotate-45"
            type="button"
            onClick={cancel}
          />
        )}
      </div>
      <div className="text-center font-stolzlBook text-[14px] mt-7 mx-4">
        {message}
      </div>
      <div className="flex justify-center w-[210px] mt-10 m-auto">
        <div className="">
          <button
            form="userGrpForm"
            type="submit"
            className="bg-[#232932] text-[12px] text-white leading-[100%] hover:bg-gray-500 border-none rounded w-[100px] h-10"
            onClick={confirm}
          >
            <span>{confirmBtnLabel}</span>
          </button>
        </div>
      </div>
    </>
  );
};

ConfirmModalUI.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  confirmBtnLabel: PropTypes.string,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  hideCancel: PropTypes.bool
};

export default ConfirmModalUI;
