import { useContent } from 'src/hooks/components/useContent';
import Header from 'src/components/Common/Header';
import Sidebar from 'src/components/Common/Sidebar';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { cn } from 'src/helpers/utils';

const MainView = ({ children }) => {
  const { location, isSidebarOpen, setSidebarState } = useContent();
  const { pathname } = useLocation();
  const isSchedulePage = pathname.split('/').includes('evaluation-schedule');

  return (
    <div className="flex flex-col h-screen">
      <Header setSidebarState={setSidebarState} />
      <div
        className={cn(
          'flex',
          (pathname === '/evaluation-templates/add' || isSchedulePage) &&
            'h-screen'
        )}
        style={{
          marginTop: location.pathname.includes('v1') ? '35px' : '0'
        }}
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setSidebarState={setSidebarState}
        />
        <main
          className={`mainView relative min-h-fit mt-[60px] p-[14px_18px_39px_30px] overflow-auto w-full ${
            isSidebarOpen ? 'ml-[250px]' : 'ml-[50px]'
          }`}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainView;

MainView.propTypes = {
  children: PropTypes.element
};
