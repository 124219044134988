import {
  GET_DATATABLE_SELECTED_IDS,
  GET_DATATABLE_DELETED_IDS
} from './datatableTypes';

export function setIdSelection(data) {
  return { type: GET_DATATABLE_SELECTED_IDS, payload: data };
}

export function setIdDeletion(data) {
  return { type: GET_DATATABLE_DELETED_IDS, payload: data };
}
