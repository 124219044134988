import { GET_ASSESSMENTS, GET_MYASSESSMENT } from './assessmentTypes';

const INITIAL_STATE = {
  assessmentList: [],
  myAssessment: []
};

export default function userReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_ASSESSMENTS:
      return {
        ...state,
        assessmentList: payload
      };
    case GET_MYASSESSMENT:
      return {
        ...state,
        myAssessment: payload
      };
    default:
      return state;
  }
}
