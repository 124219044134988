/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CgCloseO, CgCheckO } from 'react-icons/cg';
import { EMAILREGEX } from 'src/static/regex';

import {
  addUser,
  getAllUsersService,
  getUserByIDService,
  updateUserService,
  updateUserImage
} from 'src/api/modules/user';
import { ENV } from 'src/api/config';
import { toast } from 'react-toastify';
import {
  getAllUsers,
  setUserListUpdate
} from 'src/redux/modules/users/userActions';
import * as Yup from 'yup';
import { IoWarningOutline } from 'react-icons/io5';
import { getCostCenter } from 'src/helpers/utils';
import validationYupSchema from 'src/helpers/v1/validationYupSchema';

export const useViewEditModal = ({ handleModal, userId, userData }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    suffix: '',
    cost_center_code: '',
    image: '',
    employee_id_no: '',
    email: '',
    status: 'A',
    is_system_admin: false,
    password: 'test'
  });

  const costcenter = useSelector(state => state.costCenter.all);
  const userByID = useSelector(state => state?.user?.user);
  const userLoggedInID = useSelector(state => state.user.user.user.id);
  const [userImage, setUserImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isAdminCheckboxDisabled, setIsAdminCheckboxDisabled] = useState(false);
  const [user, setUser] = useState('');
  const [imageUpdate, setImageUpdate] = useState(false);
  const [emailValidation, setEmailValidation] = useState('');
  const [employeeIdValidation, setEmployeeIdValidation] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const initialDummyData = {
    first_name: 'John',
    middle_name: null,
    last_name: 'Doe',
    suffix: null,
    employee_id_no: 'abc123',
    email: 'john.doe@cody.inc',
    cost_center_code: '010104',
    userId: '1',
    image: 'https://example.com/sample-image.jpg'
  };

  const image = useMemo(() => {
    if (userData && formData.image) {
      return `${formData.image}`;
    }
    return null;
  }, [userData, formData.image]);

  const renderUserInfo = useMemo(() => {
    if (userId) {
      return (
        <div className="pl-[8px]">
          <div
            className={`text-[22px] mb-1 mt-[17px] font-bold font-stolzlMedium ${
              formData?.first_name || formData?.last_name
                ? ''
                : 'text-[#EAEAEA]'
            }`}
          >
            {formData?.first_name || formData?.last_name || formData?.suffix
              ? `${formData?.first_name} ${formData?.last_name} ${
                  formData?.suffix || ''
                }`
              : 'Auto Input Name'}
          </div>
          <div className="text-[14px] mb-[10px] font-stolzlRegular">
            {formData?.email || 'Auto Input Email Address'}
          </div>
        </div>
      );
    }
    return (
      <div className="pl-[8px]">
        <div
          className={`text-[22px] mb-1 mt-[17px] font-bold font-stolzlMedium ${
            formData?.first_name || formData?.last_name ? '' : 'text-[#EAEAEA]'
          }`}
        >
          {formData?.first_name || formData?.last_name || formData?.suffix
            ? `${formData?.first_name} ${formData?.last_name}  ${formData?.suffix}`
            : 'Auto Input Name'}
        </div>
        <div
          className={`text-[14px] mb-[10px] font-stolzlRegular ${
            formData?.email ? '' : 'text-[#EAEAEA]'
          }`}
        >
          {`${formData?.email || 'Auto Input Email Address'}`}
        </div>
      </div>
    );
  }, [userId, formData]);

  const handleStatusChange = useCallback(
    (value, name, setFieldValue, setFieldTouched, setFieldError) => {
      setFormData(prevState => ({
        ...prevState,
        cost_center_code: value
      }));
      setFieldValue(name, value);
      setFieldTouched(name, true);
      setFieldError(name, '');
    },
    [setFormData]
  );

  const fetchUser = async userID => {
    const response = await getUserByIDService(userID);

    if (response.data) {
      const responseData = response.data;
      Object.keys(response.data).forEach(formKey => {
        if (typeof formData[formKey] === 'undefined') {
          delete responseData[formKey];
        }
      });
      setUser(response.data);
      setFormData(response.data);
    }
  };

  const toUpperCase = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const costCenterOptions = costcenter?.map(costCenterData => {
    const costCenterLabel = getCostCenter(
      'name',
      costCenterData.cost_center_code
    );

    return {
      value: costCenterData.cost_center_code,
      label: `${costCenterData.cost_center_code} / ${costCenterLabel}`
    };
  });

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue =
      type === 'checkbox'
        ? checked
        : type === 'text' && name !== 'email'
        ? toUpperCase(value)
        : value;

    const initialValue = value.match(/^\s/) !== null;

    if (
      (name === 'first_name' && initialValue) ||
      (name === 'middle_name' && initialValue) ||
      (name === 'last_name' && initialValue) ||
      (name === 'suffix' && initialValue) ||
      (name === 'employee_id_no' && initialValue) ||
      (name === 'email' && initialValue)
    ) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value.trim()
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: fieldValue
      }));
    }

    if (name === 'email') setEmailValidation('');
    if (name === 'employee_id_no') setEmployeeIdValidation('');
    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };
  const handSelectChange = value => {
    setFormData(prevState => ({
      ...prevState,
      status: value
    }));
  };

  const customToastStyle = {
    fontSize: '14px',
    letterSpacing: '0em'
  };

  const validateFile = async file => {
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 4 * 1024 * 1024; // 4 MB
    if (!allowedTypes.includes(file.type)) {
      toast.warning(
        <div>
          File type not supported
          <span className="block text-sm">
            (Only JPEG/JPG and PNG files are allowed)
          </span>
        </div>,
        { icon: <IoWarningOutline /> }
      );
    } else if (file.size > maxSize) {
      // File size is too large
      toast.warning(
        <div>
          File is too large.
          <span className="block text-sm">(Maximum file size is 4 MB)</span>
        </div>,
        { icon: <IoWarningOutline /> }
      );
    } else {
      previewFile(file);
      setUserImage(file);
      if (userId)
        setFormData(prevState => ({
          ...prevState,
          image: file
        }));
    }
  };

  const previewFile = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
  };

  const handleImageChange = useCallback(
    (event, { setFieldValue, setFieldTouched, setFieldError }) => {
      const file = event.target.files[0];

      setFieldValue('image', file);
      setFieldTouched('image', true);
      setFieldError('image', '');
      validateFile(file);
      if (file) setImageUpdate(true);
    },
    []
  );

  const fetchNewUserList = async () => {
    getAllUsersService(1)
      .then(res => {
        dispatch(getAllUsers(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const updateUserInfo = async (id, payload) => {
    setFormLoading(true);
    updateUserService(id, payload)
      .then(response => {
        if (response.success) {
          fetchNewUserList();

          setTimeout(() => {
            setIsEdit(false);
            setFormLoading(false);
          }, 500);
          dispatch(setUserListUpdate({ id, payload: response.data }));
        } else if (response.response.status === 500)
          setEmployeeIdValidation('ID Number already exists!');
        setFormData(formData);
      })
      .catch(err => {
        toast.error(err, { icon: <CgCloseO /> });
        setIsEdit(false);
        setFormLoading(false);
      });
  };

  const handleSubmit = useCallback(async () => {
    if (userId) {
      // EDIT
      if (imageUpdate) {
        const imageData = new FormData();
        imageData.append('user_id', userId);
        imageData.append('image', userImage);
        updateUserImage(imageData).then(response => {
          if (response.success) {
            setFormData(prevState => ({
              ...prevState,
              image: response.data.image
            }));
            const payload = { ...formData, image: response.data.image };
            updateUserInfo(userId, payload);
          }
        });
      } else {
        updateUserInfo(userId, formData);
      }
    } else {
      // ADD
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (formData.email.match(emailFormat)) {
        const res = await addUser(formData);
        if (res.status === 201) {
          // update if there's image set
          if (userImage) {
            const imageData = new FormData();
            imageData.append('user_id', res.data.data.id);
            imageData.append('image', userImage);
            updateUserImage(imageData).then(response => {
              if (response.success) {
                fetchNewUserList();
                toast.success('Successfully Added!', {
                  icon: <CgCheckO />
                });
                handleModal(null);
                setFormData(formData);
              }
            });
          } else {
            fetchNewUserList();
            toast.success('Successfully Added!', {
              icon: <CgCheckO />
            });
            handleModal(null);
            setFormData(formData);
          }
        }
        setEmailValidation(res.data.errors.email);
      } else {
        setEmailValidation('Invalid email address');
      }
    }
  });

  const validationSchema = Yup.object().shape({
    first_name: validationYupSchema.textRequiredWithMax(50),
    middle_name: validationYupSchema.textNullableWithMax(50),
    last_name: validationYupSchema.textRequiredWithMax(50),
    suffix: validationYupSchema.textNullableWithMax(5),
    cost_center_code: validationYupSchema.textRequired(),
    userId: Yup.string(),
    image: validationYupSchema.image(),
    email: validationYupSchema
      .email(255)
      .test('email-unique', 'Email already exists', async value => {
        if (!value) return true;
        const searchParams = { email: value };
        const response = await getAllUsersService(1, searchParams);

        if (response.data?.items.length) {
          return false;
        }

        return true;
      })
  });

  useEffect(() => {
    if (userByID?.user.id === userId && userByID.user.is_system_admin) {
      setIsAdminCheckboxDisabled(true);
    } else if (userByID?.user.is_system_admin) {
      setIsAdminCheckboxDisabled(false);
    } else {
      setIsAdminCheckboxDisabled(true);
    }

    if (userData) {
      setFormData({
        email: userData?.email,
        employee_id_no: userData?.employee_id_no,
        first_name: userData?.first_name,
        middle_name: userData?.middle_name,
        last_name: userData?.last_name,
        suffix: userData?.suffix,
        image: userData?.image,
        cost_center_code: userData?.cost_center_code,
        status: userData?.status,
        is_system_admin: userData?.is_system_admin
      });
      setUser(userData);
    }
  }, []);

  return {
    formData,
    costcenter,
    image,
    userByID,
    userImage,
    previewImage,
    isAdminCheckboxDisabled,
    initialDummyData,
    user,
    renderUserInfo,
    isEdit,
    costCenterOptions,
    setIsEdit,
    handleChange,
    handleStatusChange,
    fetchUser,
    handSelectChange,
    validateFile,
    previewFile,
    handleImageChange,
    handleSubmit,
    validationSchema,
    userLoggedInID,
    emailValidation,
    formLoading,
    employeeIdValidation
  };
};
