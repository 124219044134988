import React from 'react';
import { useNavigate } from 'react-router-dom';

// Common components
import { Breadcrumbs, ModalCenter } from 'src/components/Common';
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

import { BULK_DELETE_QUIZZES } from 'src/api/endpoints';

// hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useQuizManagerIndex from 'src/hooks/Pages/LearningManagement/QuizManager/useQuizManagerIndex';

import BulkDeleteQuizModal from './components/BulkDeleteQuizModal';
import DeleteQuizModal from './components/DeleteQuizModal';

const Index = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  const {
    quizListMemo,
    hasMore,
    quizManagerColumns,
    showModal,
    showDeleteModal,
    form,
    submitFilter,
    handleModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    inputs,
    modalQuizId,
    quizTitle
  } = useQuizManagerIndex(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Learning Management' }, { name: 'Quiz Manager' }]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Search"
            buttonName="New Quiz"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => navigate('/quiz-manager/add')}
            bulkDeleteClick={() => handleBulkDeleteModal('BulkDeleteQuizModal')}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={quizListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {!quizListMemo.length && <b>No records found</b>}
                {quizListMemo.length >= 20 && <b>No more data to load</b>}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={quizListMemo || []}
              clickableRows={false}
              headingColumns={quizManagerColumns}
              title="Quiz Manager"
              actions={['view', 'custom', 'delete']}
              actionLinks={{
                view: '/quiz-manager/view',
                custom: '/quiz-manager/{id}/questions'
              }}
              actionLabels={{
                custom: 'Questions'
              }}
              showModal={showModal}
              handleModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteQuizModal"
              keyField="id"
              noPadding
              shouldEllipsis
              ellipsisModifier="max-w-[320px]"
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteQuizModal">
        {showDeleteModal && (
          <DeleteQuizModal
            handleDeleteModal={handleDeleteModal}
            id={modalQuizId}
            name={quizTitle}
            modal={showDeleteModal}
          />
        )}
      </ModalCenter>
      <ModalCenter
        showModal={showBulkDeleteModal}
        modalName="BulkDeleteQuizModal"
      >
        {showBulkDeleteModal && (
          <BulkDeleteQuizModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            endpoint={BULK_DELETE_QUIZZES}
          />
        )}
      </ModalCenter>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
