import { getAllSkillsService } from 'src/api/modules/skill';
import { FETCH_SKILLS } from './skillTypes';

export function setSkillsList(payload) {
  return { type: FETCH_SKILLS, payload };
}

export function getAllSkillsList() {
  return async function fetchSkillAll(dispatch) {
    const response = await getAllSkillsService();
    if (response?.data) dispatch(setSkillsList(response.data));
  };
}
