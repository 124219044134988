import { GET_ADMIN_EMPLOYMENT_STATUS_LIST } from './employmentStatusTypes';

const INITIAL_STATE = {
  employmentStatus: []
};

export default function employmentStatusReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_ADMIN_EMPLOYMENT_STATUS_LIST:
      return {
        ...state,
        employmentStatus: payload
      };
    default:
      return state;
  }
}
