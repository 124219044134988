import {
  getEmployeesService,
  addEmployeeService,
  getEmployeeInfoService,
  updateEmployeeInfoService,
  deleteEmployeeService,
  getSalaryGradeService,
  getGenderService,
  getCivilStatusService,
  getEmploymentStatusService,
  createEmployeeUserService,
  getAllEmployeesService,
  getTeamsService,
  getPositionsService
} from 'src/api/modules/employee';
import {
  GET_EMPLOYEES_LIST,
  GET_ALL_EMPLOYEES_LIST,
  GET_SALARY_GRADE,
  GET_GENDER,
  GET_CIVIL_STATUS,
  GET_EMPLOYMENT_STATUS,
  GET_ALL_EMPLOYEES,
  SET_LIST_UPDATE,
  GET_POSITIONS,
  GET_TEAMS
} from './employeeTypes';

export function setEmployees(data) {
  return { type: GET_EMPLOYEES_LIST, payload: data };
}

// export function setAllEmployees(data) {
//   return { type: GET_ALL_EMPLOYEES_LIST, payload: data };
// }
export function setEmployeeListUpdate(payload) {
  return { type: SET_LIST_UPDATE, payload };
}

export function setSalaryGrade(data) {
  return { type: GET_SALARY_GRADE, payload: data };
}
export function setGender(data) {
  return { type: GET_GENDER, payload: data };
}
export function setCivilStatus(data) {
  return { type: GET_CIVIL_STATUS, payload: data };
}
export function setEmploymentStatus(data) {
  return { type: GET_EMPLOYMENT_STATUS, payload: data };
}

export function setAllEmployees(data) {
  return { type: GET_ALL_EMPLOYEES, payload: data };
}

export function setTeams(data) {
  return { type: GET_TEAMS, payload: data };
}

export function setPositions(data) {
  return { type: GET_POSITIONS, payload: data };
}

export function getEmployees() {
  return async function (dispatch) {
    const response = await getEmployeesService();
    if (response?.success && response?.data) {
      dispatch(setEmployees(response.data));
    }
  };
}

// export function getAllEmployees() {
//   return async function (dispatch) {
//     const response = await getAllEmployeesService();
//     if (response?.success && response?.data) {
//       dispatch(setAllEmployees(response.data));
//     }
//   };
// }

export function getSalaryGrade() {
  return async function (dispatch) {
    const response = await getSalaryGradeService();
    if (response?.success && response?.data) {
      dispatch(setSalaryGrade(response.data));
    }
  };
}

export function getGender() {
  return async function (dispatch) {
    const response = await getGenderService();
    if (response?.success && response?.data) {
      dispatch(setGender(response.data));
    }
  };
}

export function getCivilStatus() {
  return async function (dispatch) {
    const response = await getCivilStatusService();
    if (response?.success && response?.data) {
      dispatch(setCivilStatus(response.data));
    }
  };
}

export function getEmploymentStatus() {
  return async function (dispatch) {
    const response = await getEmploymentStatusService();
    if (response?.success && response?.data) {
      dispatch(setEmploymentStatus(response.data));
    }
  };
}

export function addEmployee(data) {
  return async function () {
    return await addEmployeeService(data);
  };
}

export function createEmployeeUser(employeeID) {
  return async function () {
    return await createEmployeeUserService(employeeID);
  };
}
export function getEmployeeInfo(employeeId) {
  return async function () {
    return await getEmployeeInfoService(employeeId);
  };
}

export function updateEmployee(data, employeeId) {
  return async function () {
    return await updateEmployeeInfoService(data, employeeId);
  };
}

export function deleteEmployee(employeeId) {
  return async function () {
    return await deleteEmployeeService(employeeId);
  };
}

export function getAllEmployees() {
  return async function (dispatch) {
    const response = await getAllEmployeesService();

    if (response?.success && response?.data) {
      dispatch(setAllEmployees(response.data));
    }
  };
}

export function getTeams() {
  return async function (dispatch) {
    const response = await getTeamsService();

    if (response?.success && response?.data) {
      dispatch(setTeams(response.data));
    }
  };
}

export function getPositions() {
  return async function (dispatch) {
    const response = await getPositionsService();

    if (response?.success && response?.data) {
      dispatch(setPositions(response.data));
    }
  };
}
