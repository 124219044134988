export function getScheduleStatusID(status) {
  switch (status) {
    case 'User Evaluation':
      return 1;
    case 'Boss Evaluation':
      return 2;
    case 'Done':
      return 3;
    default:
      return 1;
  }
}

export function setScheduleStatus(status) {
  switch (status) {
    case 'User Evaluation':
      return { id: 1, name: 'User Evaluation' };
    case 'Boss Evaluation':
      return { id: 2, name: 'Boss Evaluation' };
    case 'Done':
      return { id: 3, name: 'Done' };
    default:
      return { id: 1, name: 'User Evaluation' };
  }
}

export function isExempted(name, checked, value) {
  if (name === 'include_exempted_user') {
    if (checked) return 1;
    return 0;
  }
  return value;
}

export function formatScheduleDate(date) {
  return new Date(date).toISOString().split('T')[0];
}
