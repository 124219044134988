import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-named-as-default
import usersReducer from './users/userReducers';
import userGroupReducer from './userGroups/userGroupReducers';
import evaluationTemplatesReducer from './evaluations/templates/templatesReducers';
import evaluationSchedulesReducer from './evaluations/schedules/schedulesReducers';
import userEvaluationReducer from './evaluations/userEvaluation/userEvaluationReducers';
import costCenterListReducer from './costcenter/costcenterReducers';
import departmentsReducer from './departments/departmentsReducers';
import datatableSelectedIds from './datatable/datatableReducers';
import divisionsReducer from './divisions/divisionsReducers';
import sectionsReducer from './sections/sectionsReducers';
import subsectionsReducer from './subsections/subsectionsReducers';
import salaryGradesReducer from './salaryGrades/salaryGradesReducers';
import employmentStatusReducer from './employmentStatus/employmentStatusReducers';
import positionsReducer from './positions/positionsReducers';
import projectReducers from './projects/projectReducers';
import employeeReducers from './employee/employeeReducers';
import menuReducer from './menus/menuReducers';
import userMenuReducers from './userMenus/userMenuReducers';
import CompanyReducers from './CompanyInformation/CompanyInfoReducers';
import evaluationScheduleReducers from './EvaluationSchedule/evaluationScheduleReducers';
import quizReducers from './quiz/quizReducers';
import statusReducers from './status/statusReducers';
import skillReducers from './skill/skillReducers';
import questionReducers from './questions/questionReducers';
import assessmentReducers from './assessment/assessmentReducers';

const reducers = combineReducers({
  user: usersReducer,
  userGroup: userGroupReducer,
  userMenu: userMenuReducers,
  userCompany: CompanyReducers,
  evaluationTemplate: evaluationTemplatesReducer,
  userEvaluation: userEvaluationReducer,
  costCenter: costCenterListReducer,
  departments: departmentsReducer,
  datatable: datatableSelectedIds,
  divisions: divisionsReducer,
  sections: sectionsReducer,
  subSections: subsectionsReducer,
  salaryGrades: salaryGradesReducer,
  employmentStatus: employmentStatusReducer,
  positions: positionsReducer,
  projects: projectReducers,
  employees: employeeReducers,
  menu: menuReducer,
  evaluationSchedule: evaluationScheduleReducers,
  quiz: quizReducers,
  questions: questionReducers,
  status: statusReducers,
  skill: skillReducers,
  assessment: assessmentReducers
});

const rootReducer = (state, action) => {
  let currentState = state;
  if (action.type === 'REMOVE_USER') {
    currentState = undefined;
  }

  return reducers(currentState, action);
};

export default rootReducer;
