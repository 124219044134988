import {
  getEvaluationSchedulesService,
  createEvaluationSchedulesService,
  getEvaluationScheduleByIdService,
  updateEvaluationScheduleByIdService,
  deleteEvaluationScheduleByIdService,
  getEvaluationScheduleUsersByIdService,
  updateEvaluationScheduleUserStatusService,
  updateEvaluatorPerSectionByIdService,
  getUserEvaluationScheduleStatusService,
  getEvaluationSchedulesStatusesService,
  getEvaluationScheduleVerificationService
} from 'src/api/v1/modules/evaluation';
import {
  GET_SCHEDULES,
  GET_USER_EVALUATION_STATUS,
  GET_SCHEDULES_STATUS,
  GET_SCHEDULES_VERIFICATION
} from './schedulesTypes';

export function setEvaluationSchedules(data) {
  return { type: GET_SCHEDULES, payload: data };
}

export function setEvaluationSchedulesStatuses(data) {
  return { type: GET_SCHEDULES_STATUS, payload: data };
}

export function getEvaluationSchedules() {
  return async function (dispatch) {
    const response = await getEvaluationSchedulesService();
    if (response?.data) dispatch(setEvaluationSchedules(response.data));
  };
}

export function createEvaluationSchedules(data) {
  return async function () {
    return await createEvaluationSchedulesService(data);
  };
}

export function getEvaluationScheduleById(scheduleId) {
  return async function () {
    return await getEvaluationScheduleByIdService(scheduleId);
  };
}

export function updateEvaluationScheduleById(scheduleId, data) {
  return async function () {
    return await updateEvaluationScheduleByIdService(scheduleId, data);
  };
}

export function deleteEvaluationScheduleById(scheduleId) {
  return async function () {
    return await deleteEvaluationScheduleByIdService(scheduleId);
  };
}

export function getEvaluationScheduleUsersById(scheduleId) {
  return async function () {
    return await getEvaluationScheduleUsersByIdService(scheduleId);
  };
}

export function updateEvaluationScheduleUserStatus(
  scheduleId,
  evaluationId,
  payload
) {
  return async function () {
    return await updateEvaluationScheduleUserStatusService(
      scheduleId,
      evaluationId,
      payload
    );
  };
}

export function updateEvaluatorPerSectionById(scheduleId, payload) {
  return async function () {
    return await updateEvaluatorPerSectionByIdService(scheduleId, payload);
  };
}

export function setUserEvaluationScheduleStatus(data) {
  return { type: GET_USER_EVALUATION_STATUS, payload: data };
}

export function getUserEvaluationScheduleStatus() {
  return async function (dispatch) {
    const response = await getUserEvaluationScheduleStatusService();
    if (response?.success && response?.data)
      dispatch(setUserEvaluationScheduleStatus(response.data));
  };
}

export function getEvaluationSchedulesStatus() {
  return async function (dispatch) {
    const response = await getEvaluationSchedulesStatusesService();
    if (response?.data) dispatch(setEvaluationSchedulesStatuses(response.data));
  };
}

export function setEvaluationScheduleVerification(data) {
  return { type: GET_SCHEDULES_VERIFICATION, payload: data };
}

export function getEvaluationScheduleVerification(is_included_exempted_users) {
  return async function (dispatch) {
    const response = await getEvaluationScheduleVerificationService(
      is_included_exempted_users
    );
    if (response?.data)
      dispatch(setEvaluationScheduleVerification(response.data));
  };
}
