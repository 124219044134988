import { GET_QUIZZES, ALL_QUIZZES } from './quizTypes';

const INITIAL_STATE = {
  quizList: [],
  allQuizzes: []
};

export default function userReducers(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_QUIZZES:
      return {
        ...state,
        quizList: payload
      };
    case ALL_QUIZZES:
      return {
        ...state,
        allQuizzes: payload
      };
    default:
      return state;
  }
}
