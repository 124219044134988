import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getAssessmentsService,
  getAssessmentByIDService,
  deleteAssessmentByIdService
} from 'src/api/modules/assessment';
import { getAllAssessments } from 'src/redux/modules/assessment/assessmentActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

export const useDeleteAssessmentModal = ({ handleDeleteModal, id, modal }) => {
  const dispatch = useDispatch();
  const [assessmentTitle, setAssessmentTitle] = useState(null);

  const fetchNewAssessmentList = async () => {
    getAssessmentsService(1)
      .then(res => {
        dispatch(getAllAssessments(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };
  useEffect(() => {
    if (id) {
      getAssessmentByIDService(id).then(res => {
        setAssessmentTitle(
          `${res.data.code.trimEnd()}: ${res.data.title.trimEnd()}`
        );
      });
    }
  }, []);
  const handleDelete = assessmentId => {
    if (assessmentId) {
      deleteAssessmentByIdService(assessmentId).then(res => {
        if (res.success) {
          fetchNewAssessmentList();
          handleDeleteModal(modal);

          toast.success('Successfully Deleted!', {
            icon: <CgCheckO />,
            toastId: id
          });
        } else {
          toast.error('Unable to delete!', { icon: <CgCloseO /> });
        }
      });
    }
  };
  return {
    assessmentTitle,
    handleDelete
  };
};
