import React from 'react';
import { useParams } from 'react-router-dom';
// Common components
import { Breadcrumbs, ModalCenter } from 'src/components/Common';
import Datatable from 'src/components/Common/Datatable';
import SlideModal from 'src/components/Common/SlideModal';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import AddQuestionModal from 'src/Pages/LearningManagement/QuizManager/components/AddQuestionModal';
import ViewQuestionModal from 'src/Pages/LearningManagement/QuizManager/components/ViewQuestionModal';

// Hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useQuizQuestionIndex from 'src/hooks/Pages/LearningManagement/QuizManager/useQuizQuestionIndex';
import { QUIZZES } from 'src/api/endpoints';
import BulkDeleteQuestionModal from './components/BulkDeleteQuestionModal';
import DeleteQuestionModal from './components/DeleteQuestionModal';

const QuizQuestion = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const { id } = useParams();
  const quiz_id = id;

  const {
    questionListMemo,
    hasMore,
    questionColumns,
    showDeleteModal,
    showModal,
    showAddModal,
    form,
    submitFilter,
    handleModal,
    handleAddModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    inputs,
    questionTitle,
    modalQuestionId,
    questionFields,
    statusOptions,
    searchQuestion,
    questionsRef,
    handleAddQuestion,
    handleSearchQuestions,
    addedQuestion,
    handleDeleteSearchModal,
    handleQuestionAddSubmit
  } = useQuizQuestionIndex(quiz_id, menuCode);

  const optionsCount = questionFields?.answers?.length;
  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'Quiz Manager', link: '/quiz-manager' },
          { name: 'Quiz Questions' }
        ]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Search"
            buttonName="Question"
            inputs={inputs}
            buttonLink={false}
            buttonOnClick={() => handleAddModal('AddEdit')}
            bulkDeleteClick={() =>
              handleBulkDeleteModal('BulkDeleteQuestionModal')
            }
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={questionListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {questionListMemo.length ? <b /> : <b>No records found</b>}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={questionListMemo || []}
              clickableRows={false}
              headingColumns={questionColumns}
              title="Question"
              actions={['view', 'delete']}
              showModal={showModal}
              handleModal={handleAddModal}
              handleViewModal={handleModal}
              handleDeleteModal={handleDeleteModal}
              modalName="AddEdit"
              deleteModal="DeleteQuestionModal"
              keyField="id"
              shouldEllipsis
              ellipsisModifier="max-w-[320px]"
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
          <SlideModal
            showModal={showAddModal}
            modalName="AddEdit"
            modalSize="large"
            handleModal={handleAddModal}
          >
            {showAddModal && (
              <AddQuestionModal
                quizId={quiz_id}
                handleModal={handleAddModal}
                questionFields={questionFields}
                statusOptions={statusOptions}
                searchQuestion={searchQuestion}
                handleAddQuestion={handleAddQuestion}
                questionsRef={questionsRef}
                handleSearchQuestions={handleSearchQuestions}
                addedQuestion={addedQuestion}
                handleDeleteSearchModal={handleDeleteSearchModal}
                handleQuestionAddSubmit={handleQuestionAddSubmit}
              />
            )}
          </SlideModal>
          <SlideModal
            showModal={showModal}
            modalName="AddEdit"
            modalSize="large"
            handleModal={handleModal}
          >
            {showModal && (
              <ViewQuestionModal
                handleModal={handleModal}
                questionFields={questionFields}
                statusOptions={statusOptions}
                optionsCount={optionsCount}
              />
            )}
          </SlideModal>
        </div>
      </div>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteQuestionModal">
        {showDeleteModal && (
          <DeleteQuestionModal
            handleDeleteModal={handleDeleteModal}
            id={modalQuestionId}
            name={questionTitle}
            modal={showDeleteModal}
            quizId={quiz_id}
          />
        )}
      </ModalCenter>
      <ModalCenter
        showModal={showBulkDeleteModal}
        modalName="BulkDeleteQuestionModal"
      >
        {showBulkDeleteModal && (
          <BulkDeleteQuestionModal
            handleBulkDeleteModal={handleBulkDeleteModal}
            quizId={quiz_id}
            endpoint={QUIZZES}
          />
        )}
      </ModalCenter>
    </div>
  );
};

QuizQuestion.propTypes = {
  menuCode: PropTypes.string
};

export default QuizQuestion;
