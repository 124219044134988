import { combineReducers } from 'redux';
import usersReducer from './users/userReducers';
import evaluationTemplatesReducer from './evaluations/templates/templatesReducers';
import evaluationSchedulesReducer from './evaluations/schedules/schedulesReducers';
import userEvaluationReducer from './evaluations/userEvaluation/userEvaluationReducers';
import costCenterListReducer from './costcenter/costcenterReducers';
import departmentsReducer from './departments/departmentsReducers';
import divisionsReducer from './divisions/divisionsReducers';
import sectionsReducer from './sections/sectionsReducers';
import subsectionsReducer from './subsections/subsectionsReducers';
import salaryGradesReducer from './salaryGrades/salaryGradesReducers';
import employmentStatusReducer from './employmentStatus/employmentStatusReducers';
import positionsReducer from './positions/positionsReducers';
import projectReducers from './projects/projectReducers';

const reducers = combineReducers({
  user: usersReducer,
  evaluationTemplate: evaluationTemplatesReducer,
  evaluationSchedule: evaluationSchedulesReducer,
  userEvaluation: userEvaluationReducer,
  costCenter: costCenterListReducer,
  departments: departmentsReducer,
  divisions: divisionsReducer,
  sections: sectionsReducer,
  subSections: subsectionsReducer,
  salaryGrades: salaryGradesReducer,
  employmentStatus: employmentStatusReducer,
  positions: positionsReducer,
  projects: projectReducers
});

export default reducers;
