import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CgCloseO } from 'react-icons/cg';
import { getAssessmentResultService } from 'src/api/modules/assessment';

const useAssessmentResult = () => {
  const navigate = useNavigate();

  const { assessment_id, user_assessment_id } = useParams();
  const [assessmentResult, setAssessmentResult] = useState([]);

  const getRemarks = average => {
    if (average >= 0 && average <= 25) return 'Beginner';
    if (average <= 50) return 'Intermediate';
    if (average <= 75) return 'Experienced';
    return 'Proficient';
  };

  const getAssessmentResult = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getAssessmentResultService(
        assessment_id,
        user_assessment_id
      );

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        setAssessmentResult(responseData);
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  };

  useEffect(() => {
    if (assessment_id && user_assessment_id) {
      getAssessmentResult(assessment_id, user_assessment_id);
    }
  }, []);

  return {
    assessmentResult,
    getRemarks
  };
};

export default useAssessmentResult;
