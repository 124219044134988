import { MENU_CODES } from 'src/static/menuCodes';

export const menuItems = [
  {
    name: 'Resource',
    path: '/#',
    icon: 'icons/info.svg',
    submenu: [
      {
        name: 'Employee Information',
        path: '/employees',
        menu_code: MENU_CODES.employees
      },
      {
        name: 'Project List',
        path: '/projects',
        menu_code: MENU_CODES.projectList
      },
      {
        name: 'Project Assignment',
        path: '/projects-assignment',
        menu_code: MENU_CODES.projectAssignment
      }
    ]
  },
  {
    name: 'Evaluation',
    path: '/#',
    icon: 'icons/evaluation.svg',
    submenu: [
      {
        name: 'Evaluation Templates',
        path: '/evaluation-templates',
        menu_code: MENU_CODES.evaluationTemplate
      },
      {
        name: 'Evaluation Schedules',
        path: '/evaluation-schedule',
        menu_code: MENU_CODES.evaluationSchedule
      },
      {
        name: 'Evaluation Entry',
        path: '/evaluation-entry',
        menu_code: MENU_CODES.evaluationEntry
      },
      {
        name: 'My Evaluation',
        path: '/my-evaluation',
        menu_code: MENU_CODES.myEvaluation
      },
      {
        name: 'Evaluation Results',
        path: '/evaluation-results',
        menu_code: MENU_CODES.evaluationResults
      },
      {
        name: 'Performance Appraisal',
        path: '/performance-appraisal',
        menu_code: MENU_CODES.performanceAppraisal
      }
    ]
  },
  {
    name: 'Skillset Management',
    path: '/#',
    icon: 'icons/skills-and-training.svg',
    submenu: [
      {
        name: 'Skillset Summary',
        path: '/employee-information',
        menu_code: MENU_CODES.skillsetSummary
      },
      {
        name: 'Skillset Roadmap',
        path: '/project-list',
        menu_code: MENU_CODES.skillsetRoadMap
      }
    ]
  },
  {
    name: 'Cost Center',
    path: '/#',
    icon: 'icons/cost-center.svg',
    submenu: [
      {
        name: 'Cost Center',
        path: '/cost-center',
        menu_code: MENU_CODES.COST_CENTER
      },
      {
        name: 'Division',
        path: '/cost-center/division',
        menu_code: MENU_CODES.DIVISION
      },
      {
        name: 'Department',
        path: '/cost-center/department',
        menu_code: MENU_CODES.DEPARTMENT
      },
      {
        name: 'Section',
        path: '/cost-center/section',
        menu_code: MENU_CODES.SECTION
      },
      {
        name: 'Sub Section',
        path: '/cost-center/sub-section',
        menu_code: MENU_CODES.SUB_SECTION
      }
    ]
  },
  {
    name: 'Learning Management',
    path: '/#',
    icon: 'icons/learning-management.svg',
    submenu: [
      {
        name: 'Assessments',
        path: '/assessments',
        menu_code: MENU_CODES.ASSESSMENTS
      },
      {
        name: 'Questions',
        path: '/questions',
        menu_code: MENU_CODES.QUESTIONS
      },
      {
        name: 'Quiz Manager',
        path: '/quiz-manager',
        menu_code: MENU_CODES.QUIZ_MANAGER
      },
      {
        name: 'My Assessments',
        path: '/my-assessments',
        menu_code: MENU_CODES.MY_ASSESSMENTS
      }
    ]
  },
  {
    name: 'System',
    path: '/#',
    icon: 'icons/projects.svg',
    submenu: [
      {
        name: 'User List',
        path: '/users',
        menu_code: MENU_CODES.userList
      },
      {
        name: 'User Access',
        path: '/users-access',
        menu_code: MENU_CODES.groupList
      },
      {
        name: 'Company Information',
        path: '/company-information',
        menu_code: MENU_CODES.companyInformation
      }
    ]
  }
];
