import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useSearchParams,
  useNavigate,
  createSearchParams
} from 'react-router-dom';
// import _, { filter } from 'lodash';
import PropTypes from 'prop-types';

import { formatDate } from 'src/helpers/utils';

import Button from './Button';
import Box from './Box';
import Datatable from './Datatable';

import '../../../assets/v1/css/search-filter.scss';

const SearchFilter = ({
  pathName = null,
  searchInputPlaceholder = 'Search',
  buttonName,
  buttonLink,
  inputs,
  data,
  title,
  columns,
  showSearchBox = true,
  showActionBtn = true,
  editable = false,
  clickableRows = true,
  keyField = 'id',
  onRowUpdate,
  onExport,
  shouldDisplayEditable = false,
  isExport = false
}) => {
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const queryParamsList = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();

  const [checkedItems, setCheckedItems] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [unfilteredData, setUnfiltereddata] = useState([]);
  const [filters, setFilters] = useState({ search: 'all', searchInput: '' });
  const [queryFilters, setQueryFilters] = useState(
    Object.fromEntries([...queryParams])
  );

  useEffect(() => {
    if (inputs?.length) {
      const inputFilters = {};
      inputs.forEach(filter =>
        Object.assign(inputFilters, { [filter.name]: 'all' })
      );

      setFilters(currentFilters => {
        return { ...currentFilters, ...inputFilters, ...queryParamsList };
      });
    }

    if (Object.entries(queryParamsList).length > 0) {
      if (queryParamsList.search) {
        const searchInput = queryParamsList.search;

        setFilters(currentFilters => {
          return {
            ...currentFilters,
            searchInput: searchInput === 'all' ? '' : searchInput
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    handleFilterData(queryParamsList);
  }, [data]);

  useEffect(() => {
    searchParamsBuilder();
  }, [filters]);

  useEffect(() => {
    handleFilterData(queryFilters);
  }, [queryFilters]);

  useEffect(() => {
    setQueryFilters(queryParamsList);
    handleFilterData(queryParamsList);
  }, [location.search]);

  const searchParamsBuilder = () => {
    const searchParamsObject = { ...queryParamsList };
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'searchInput') return;
      if (value !== 'all')
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      if (value === 'all' || (value === '' && searchParamsObject[key]))
        delete searchParamsObject[key];
    });

    navigate({
      pathname: location.pathname,
      search: createSearchParams(searchParamsObject).toString()
    });
  };

  const handleFilterData = filterList => {
    if (Object.entries(filterList).length < 1) setFilteredData(data);

    const updatedFilterList = {};
    const searchFilterList = {};
    const queryPage = filterList.page ?? null;
    let hasSearchInput = false;
    let currentFilteredData = null;

    const filterKeys = Object.keys(filterList);

    filterKeys.forEach(key => {
      const value = filterList[key];

      if (key === 'search') {
        hasSearchInput = true;
        columns.forEach(column =>
          Object.assign(searchFilterList, {
            [column.key]: [getLowerCasedValue(value)]
          })
        );

        currentFilteredData = data.filter(dataItem =>
          Object.entries(searchFilterList).some(([category, searchValue]) =>
            dataItem[category]?.toString().toLowerCase().includes(searchValue)
          )
        );
      }

      if (key !== 'search' && key !== 'searchInput' && key !== 'page') {
        Object.assign(updatedFilterList, {
          [key]: [getLowerCasedValue(value)]
        });
      }
    });

    const dataToFilter = hasSearchInput ? currentFilteredData : data;
    const dataFiltered = onDepthChecker(updatedFilterList, dataToFilter);

    setFilteredData(dataFiltered);
  };

  const onDepthChecker = (filteredList, dataToFilter) => {
    const filterKeys = Object.keys(filteredList);

    return dataToFilter?.filter(item => {
      return filterKeys.every(key => {
        if (!filteredList[key].length) return true;

        if (filteredList[key][0].includes(',')) {
          const splittedFilterValues = filteredList[key][0].split(',');
          return splittedFilterValues.some(value => {
            if (item[key])
              return (
                getLowerCasedValue(value) === getLowerCasedValue(item[key])
              );
            return false;
          });
        }

        return filteredList[key].some(value => {
          if (item[key])
            return getLowerCasedValue(value) === getLowerCasedValue(item[key]);
          return false;
        });
      });
    });
  };

  const getLowerCasedValue = value =>
    typeof value === 'string' ? value.toLowerCase() : value;

  const onSearchInput = e => {
    e.preventDefault();
    const inputValue = e.currentTarget.elements[0].value;

    setFilters(currentFilters => {
      return { ...currentFilters, search: inputValue || 'all' };
    });
  };

  const onSelectDropdown = e => {
    inputs.forEach(input => {
      const fieldType = input.type === 'dropdown' ? 'select' : 'input';
      const fieldValue = document.querySelector(
        `${fieldType}[name='${input.name}']`
      ).value;

      const formattedValue =
        input.type === 'datetime' ? formatDate(fieldValue) : fieldValue;
      setFilters(currentFilters => {
        return {
          ...currentFilters,
          [input.name]:
            fieldValue === 'all' || fieldValue === '' ? 'all' : formattedValue
        };
      });
    });
  };

  const handleFiltersDisplay = input => {
    switch (input.type) {
      case 'dropdown': {
        let defaultValue = 'all';
        if (queryParamsList[input.name]) {
          defaultValue = input.options.find(option =>
            getLowerCasedValue(option).includes(queryParamsList[input.name])
          );
        }
        let keyCounter = 0;

        const generateUniqueKey = () => {
          keyCounter += 1;
          return `key-${keyCounter}`;
        };

        return (
          <select
            className="search-filter__input search-filter__input--select"
            name={input.name}
            onChange={e => onSelectDropdown(e)}
            value={defaultValue}
          >
            <option value="all">All</option>
            {input.options?.map(option => (
              <option value={option} key={generateUniqueKey()}>
                {option}
              </option>
            ))}
          </select>
        );
      }
      case 'checkbox': {
        let selectedValues = null;
        if (queryParamsList[input.name])
          selectedValues = queryParamsList[input.name].split(',');

        const checkboxOptions = input.options?.map(item => {
          return {
            item,
            isChecked:
              selectedValues?.includes(getLowerCasedValue(item)) ?? false
          };
        });
        let keyCounter = 0;

        const generateUniqueKey = () => {
          keyCounter += 1;
          return `key-${keyCounter}`;
        };

        return (
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {checkboxOptions?.map((option, i) => (
              <div
                key={generateUniqueKey()}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <input
                  checked={option.isChecked}
                  type="checkbox"
                  id={getLowerCasedValue(option.item)}
                  name={input.name}
                  value={option.item}
                  style={{ marginRight: '5px' }}
                  onChange={onSelectCheckbox}
                />
                <label htmlFor={getLowerCasedValue(option.item)}>
                  {' '}
                  {option.item}
                </label>
              </div>
            ))}
          </div>
        );
      }
      default: {
        let value = queryParamsList[input.name] ?? '';
        if (value) {
          const splittedDate = value.split('/');
          value = `${splittedDate[2]}-${splittedDate[0]}-${splittedDate[1]}`;
        }
        return (
          <input
            className="search-filter__input search-filter__input--date"
            name={input.name}
            value={value}
            type="date"
            onChange={e => onSelectDropdown(e)}
          />
        );
      }
    }
  };
  let keyCounter = 0;

  const generateUniqueKey = () => {
    keyCounter += 1;
    return `key-${keyCounter}`;
  };

  const onSelectCheckbox = event => {
    const isChecked = event.target.checked;
    const checkboxValue = event.target.value;
    const checkboxName = event.target.name;
    const queryParamsValue = queryParamsList[checkboxName];
    const selectedCheckboxField = checkedItems[checkboxName];
    let checkedList =
      selectedCheckboxField ??
      (queryParamsValue ? queryParamsValue.split(',') : []);

    if (isChecked)
      checkedList = [...checkedList, getLowerCasedValue(checkboxValue)];
    if (!isChecked)
      checkedList.splice(
        checkedList.indexOf(getLowerCasedValue(checkboxValue)),
        1
      );

    setFilters(currentFilters => {
      return {
        ...currentFilters,
        [checkboxName]: checkedList.length ? checkedList.join(',') : 'all'
      };
    });

    setCheckedItems(currentCheckedItems => {
      return {
        ...currentCheckedItems,
        [checkboxName]: checkedList.length ? checkedList : null
      };
    });
  };

  return (
    <div className="search-filter__container">
      <Box>
        <div className="search-filter__content">
          <div className="search-filter__heading">
            <div className="search-filter__row">
              {showSearchBox ? (
                <div className="search-filter__column search-filter__column--two">
                  <form
                    className="search"
                    id="search-filter__button"
                    onSubmit={e => onSearchInput(e)}
                  >
                    <input
                      className="search__input"
                      type="text"
                      defaultValue={filters.searchInput}
                      placeholder={searchInputPlaceholder}
                    />
                    <button className="search__button" type="submit">
                      <img src="/icons/search-icon.svg" alt="" />
                    </button>
                  </form>
                </div>
              ) : null}
              {showActionBtn ? (
                <div className="search-filter__column search-filter__column--two reverse">
                  <Button
                    name={`Add ${buttonName}`}
                    modifier="button__add-white dark"
                    link={buttonLink}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="search-filter">
            <h3 className="search-filter__title">Filter Search:</h3>
            <div className="search-filter__row pt0 justify-between">
              {!!inputs?.length &&
                inputs.map(input => (
                  <div
                    className="search-filter__column search-filter__column--three"
                    key={generateUniqueKey()}
                  >
                    <label className="search-filter__label" htmlFor="inputs">
                      {input.label}
                      {handleFiltersDisplay(input)}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Box>

      <div className="search-filter__content search-filter__content--schedule">
        <div className="search-filter__table">
          <Datatable
            shouldDisplayEditable={shouldDisplayEditable}
            datasource={filteredData}
            rawDataSource={unfilteredData}
            clickableRows={clickableRows}
            link={pathName !== null ? pathName : location.pathname}
            keyField={keyField}
            headingColumns={columns}
            title={title}
            editable={editable}
            onRowUpdate={onRowUpdate}
            onExport={onExport}
            isExport={isExport}
          />
        </div>
      </div>
    </div>
  );
};

SearchFilter.propTypes = {
  pathName: PropTypes.string,
  searchInputPlaceholder: PropTypes.string,
  buttonName: PropTypes.string,
  buttonLink: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  showSearchBox: PropTypes.bool,
  showActionBtn: PropTypes.bool,
  editable: PropTypes.bool,
  clickableRows: PropTypes.bool,
  keyField: PropTypes.string,
  onRowUpdate: PropTypes.func,
  onExport: PropTypes.func,
  shouldDisplayEditable: PropTypes.bool,
  isExport: PropTypes.bool
};

export default SearchFilter;
