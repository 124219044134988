import { useEffect } from 'react';

export default function useKeypressEscape({
  isEdit = false,
  cancelEdit = null
}) {
  useEffect(() => {
    const keyDownHandler = event => {
      if (isEdit && typeof cancelEdit === 'function') {
        if (event.key === 'Escape') {
          event.preventDefault();
          cancelEdit();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  });
}
