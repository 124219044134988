import { getEmploymentStatusService } from 'src/api/modules/employmentStatus';
import { GET_ADMIN_EMPLOYMENT_STATUS_LIST } from './employmentStatusTypes';

export function setEmploymentStatus(data) {
  return { type: GET_ADMIN_EMPLOYMENT_STATUS_LIST, payload: data };
}

export function getEmploymentStatus() {
  return async function (dispatch) {
    const response = await getEmploymentStatusService();
    if (response?.success && response?.data)
      dispatch(setEmploymentStatus(response.data));
  };
}
