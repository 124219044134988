import { GET_ADMIN_SECTIONS_LIST } from './sectionsTypes';

const INITIAL_STATE = {
  sections: []
};

export default function sectionsReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_ADMIN_SECTIONS_LIST:
      return {
        ...state,
        sections: payload
      };
    default:
      return state;
  }
}
