import PropTypes from 'prop-types';
import { useBulkDeleteQuestionModal } from 'src/hooks/Pages/LearningManagement/Question/useBulkDeleteQuestionModal';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';

const BulkDeleteQuestionModal = ({ handleBulkDeleteModal, quizId }) => {
  const { questionTitle, handleDelete } = useBulkDeleteQuestionModal({
    handleBulkDeleteModal,
    quizId
  });

  return (
    <DeleteModalUI
      label={questionTitle}
      submit={() => handleDelete()}
      cancel={() => handleBulkDeleteModal(null)}
    />
  );
};
BulkDeleteQuestionModal.propTypes = {
  handleBulkDeleteModal: PropTypes.func,
  quizId: PropTypes.string
};
export default BulkDeleteQuestionModal;
