/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CgCloseO, CgCheckO } from 'react-icons/cg';

import {
  addUser,
  getAllUsersService,
  updateUserService,
  updateUserImage
} from 'src/api/modules/user';
import { ENV } from 'src/api/config';
import { toast } from 'react-toastify';
import { getAllUsers } from 'src/redux/modules/users/userActions';
import * as Yup from 'yup';
import { getCostCenter } from 'src/helpers/utils';
import { IoWarningOutline } from 'react-icons/io5';
import { EMAILREGEX } from 'src/static/regex';
import validationYupSchema from 'src/helpers/v1/validationYupSchema';

export const useAddModal = ({ handleModal }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    suffix: '',
    cost_center_code: '',
    image: '',
    employee_id_no: '',
    email: '',
    status: 'N',
    is_system_admin: false,
    password: 'test'
  });

  const costcenter = useSelector(state => state.costCenter.all);
  const { user } = useSelector(state => state?.user?.user);
  const [userImage, setUserImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isDropdownDisplayed, setDropdownDisplayed] = useState(false);
  const [isAdminCheckboxDisabled, setIsAdminCheckboxDisabled] = useState(false);
  const [imageUpdate, setImageUpdate] = useState(false);
  const [emailValidation, setEmailValidation] = useState('');
  const [employeeIdValidation, setEmployeeIdValidation] = useState('');

  const image = useMemo(() => {
    if (formData.image) {
      return `${ENV.test}/${formData.image}`;
    }
    return null;
  }, [formData.image]);

  const renderUserInfo = useMemo(() => {
    return (
      <div className="pl-[8px]">
        <div
          className={`text-[22px] mb-1 mt-[17px] font-bold font-stolzlMedium ${
            formData?.first_name || formData?.last_name ? '' : 'text-[#EAEAEA]'
          }`}
        >
          {formData?.first_name || formData?.last_name
            ? `${formData?.first_name} ${formData?.last_name} ${formData?.suffix}`
            : 'Auto Input Name'}
        </div>
        <div
          className={`text-[14px] mb-[10px] font-stolzlRegular ${
            formData?.email ? '' : 'text-[#EAEAEA]'
          }`}
        >
          {`${formData?.email || 'Auto Input Email Address'}`}
        </div>
      </div>
    );
  }, [formData]);

  const handleStatusChange = useCallback(
    (value, name, setFieldValue, setFieldTouched, setFieldError) => {
      setFormData(prevState => ({
        ...prevState,
        cost_center_code: value
      }));
      setFieldValue(name, value);
      setFieldTouched(name, true);
      setFieldError(name, '');
    },
    [setFormData]
  );

  const toUpperCase = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const costCenterOptions = costcenter?.map(costCenterData => {
    const costCenterLabel = getCostCenter(
      'name',
      costCenterData.cost_center_code
    );

    return {
      value: costCenterData.cost_center_code,
      label: `${costCenterData.cost_center_code} / ${costCenterLabel}`
    };
  });

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, checked, type } = e.target;
    const fieldValue =
      type === 'checkbox'
        ? checked
        : type === 'text' && name !== 'email'
        ? toUpperCase(value)
        : value;

    const initialValue = value.match(/^\s/) !== null;

    if (
      (name === 'first_name' && initialValue) ||
      (name === 'middle_name' && initialValue) ||
      (name === 'last_name' && initialValue) ||
      (name === 'suffix' && initialValue) ||
      (name === 'employee_id_no' && initialValue) ||
      (name === 'email' && initialValue)
    ) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value.trim()
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: fieldValue
      }));
    }

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
    if (name === 'email') setEmailValidation('');
    if (name === 'employee_id_no') setEmployeeIdValidation('');
  };
  const handSelectChange = value => {
    setFormData(prevState => ({
      ...prevState,
      status: value
    }));
  };

  const customToastStyle = {
    fontSize: '14px',
    letterSpacing: '0em'
  };

  const validateFile = async file => {
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 4 * 1024 * 1024; // 4 MB
    if (!allowedTypes.includes(file.type)) {
      toast.warning(
        <div>
          File type not supported
          <span className="block text-sm">
            (Only JPEG/JPG and PNG files are allowed)
          </span>
        </div>,
        { icon: <IoWarningOutline /> }
      );
    } else if (file.size > maxSize) {
      // File size is too large
      toast.warning(
        <div>
          File is too large.
          <span className="block text-sm">(Maximum file size is 4 MB)</span>
        </div>,
        { icon: <IoWarningOutline /> }
      );
    } else {
      previewFile(file);
      setUserImage(file);
    }
  };

  const previewFile = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
  };

  const handleImageChange = useCallback(
    (event, { setFieldValue, setFieldTouched, setFieldError }) => {
      const file = event.target.files[0];

      setFieldValue('image', file);
      setFieldTouched('image', true);
      setFieldError('image', '');
      validateFile(file);
      if (file) setImageUpdate(true);
    },
    []
  );

  const fetchNewUserList = async () => {
    getAllUsersService(1)
      .then(res => {
        dispatch(getAllUsers(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleSubmit = useCallback(async () => {
    // ADD
    const res = await addUser(formData);
    if (res.status === 201) {
      // update if there's image set
      if (userImage) {
        const imageData = new FormData();
        imageData.append('user_id', res.data.data.user.id);
        imageData.append('image', userImage);
        updateUserImage(imageData).then(response => {
          if (response.success) {
            fetchNewUserList();
            toast.success('Successfully Added!', {
              icon: <CgCheckO />
            });
            handleModal(null);
            setFormData(formData);
          }
        });
      } else {
        fetchNewUserList();
        toast.success('Successfully Added!', {
          icon: <CgCheckO />
        });
        handleModal(null);
        setFormData(formData);
      }
    } else if (!employeeIdValidation) {
      setEmployeeIdValidation('Invalid ID number');
    } else {
      setEmailValidation('Email already exists!');
    }
  });

  const validationSchema = Yup.object().shape({
    first_name: validationYupSchema.textRequiredWithMax(50),
    middle_name: validationYupSchema.textNullableWithMax(50),
    last_name: validationYupSchema.textStringOnly(50),
    suffix: validationYupSchema.textNullableWithMax(5),
    cost_center_code: validationYupSchema.textRequired(),
    image: validationYupSchema.image(),
    employee_id_no: validationYupSchema.numberID(),
    email: validationYupSchema
      .email(255)
      .test('email-unique', 'Email already exists', async value => {
        if (!value) return true;
        const searchParams = { email: value };
        const response = await getAllUsersService(1, searchParams);

        if (response.data?.items.length) {
          return false;
        }

        return true;
      })
  });

  useEffect(() => {
    if (user?.is_system_admin) {
      setIsAdminCheckboxDisabled(false);
    } else {
      setIsAdminCheckboxDisabled(true);
    }
  }, []);

  return {
    formData,
    costcenter,
    image,
    userImage,
    previewImage,
    isDropdownDisplayed,
    isAdminCheckboxDisabled,
    user,
    renderUserInfo,
    costCenterOptions,
    setDropdownDisplayed,
    handleChange,
    handleStatusChange,
    handSelectChange,
    validateFile,
    previewFile,
    handleImageChange,
    handleSubmit,
    validationSchema,
    emailValidation,
    employeeIdValidation
  };
};
