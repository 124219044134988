import {
  getQuestionItemsService,
  getAllQuestionItemService
} from 'src/api/modules/question';
import { GET_QUESTIONS, GET_ALL_QUESTIONS } from './questionTypes';

export function setQuestionDataList(payload) {
  return { type: GET_QUESTIONS, payload };
}

export function setQuestions(payload) {
  return { type: GET_QUESTIONS, payload };
}

export function setAllQuestions(payload) {
  return { type: GET_ALL_QUESTIONS, payload };
}

export function getAllQuestion(objectList) {
  return function setQuestionDataListAction(dispatch) {
    dispatch(setQuestionDataList(objectList));
  };
}

export function getQuestions() {
  return async function fetchAllQuestion(dispatch) {
    const response = await getQuestionItemsService();
    if (response?.success && response?.data)
      dispatch(setQuestions(response.data));
  };
}

export function getAllQuestions() {
  return async function fetchAllQuestionList(dispatch) {
    const response = await getAllQuestionItemService();
    if (response?.success && response?.data)
      dispatch(setAllQuestions(response.data));
  };
}
