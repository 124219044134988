import React, { Fragment } from 'react';
import Modal from 'src/components/v1/Common/Modal';
import Button from 'src/components/v1/Common/Button';
import PropTypes from 'prop-types';

const EvaluationSchedulesVerification = ({
  data,
  isOpen,
  onClose,
  onConfirm
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} modifier="evaluation_modal">
      <div className="evaluation_modal_content">
        <div className="evaluation_modal_heading">
          Evaluation Schedule Verification
        </div>
        <br />
        {Object.keys(data).map((field, index) => (
          <div key={field}>
            <p className="evaluation_modal_label">{field}</p>
            <div
              className="evaluation_modal_text_margin"
              style={{ marginLeft: '30px' }}
            >
              <span>Valid: &nbsp;</span>
              {`${data[field].valid}`}
              <div>
                <div>
                  {Object.entries(data[field].error).map(details => (
                    <div
                      className="evaluation_modal_text_margin"
                      key={data[field].error[details[0]].message}
                    >
                      {typeof data[field].error[details[0]].message !==
                        'string' &&
                        data[field].error[details[0]].message.map(message => (
                          <div
                            className="evaluation_modal_detail_heading"
                            key={message}
                          >
                            <span className="evaluation_modal_subheading" />
                            Error:&nbsp;{message}
                          </div>
                        ))}
                      {typeof data[field].error[details[0]].message ===
                        'string' && (
                        <div className="evaluation_modal_detail_heading">
                          <span className="evaluation_modal_subheading" />
                          Error:&nbsp;{data[field].error[details[0]].message}
                        </div>
                      )}
                      <div>
                        {field.toLowerCase() === 'user' &&
                          Object.entries(details[1]).map(det => (
                            <div className="evaluation_modal_details" key={det}>
                              {details[1].user.map((users, userIndex) => (
                                <Fragment key={users.user_fullname}>
                                  {(userIndex ? ', ' : '') +
                                    users.user_fullname.toLowerCase()}
                                </Fragment>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <br />
          </div>
        ))}
      </div>
      <div className="evaluation_modal_button">
        <Button
          name="Cancel"
          modifier="button__default default"
          type="button"
          onClick={onClose}
        />
        <Button
          name="Confirm"
          modifier="button__default dark"
          type="button"
          onClick={onConfirm}
        />
      </div>
    </Modal>
  );
};

EvaluationSchedulesVerification.propTypes = {
  data: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default EvaluationSchedulesVerification;
