import { useTakeAssessmentModal } from 'src/hooks/Pages/LearningManagement/MyAssessment/useTakeAssessmentModal';
import ConfirmModalUI from 'src/components/Common/ConfirmModalUI';
import PropTypes from 'prop-types';

const TakeAssessmentModal = ({
  handleTakeAssessment,
  myAssessmentInfo,
  modal
}) => {
  const { handleConfirm } = useTakeAssessmentModal({
    handleTakeAssessment,
    modal
  });

  return (
    <ConfirmModalUI
      title={
        !myAssessmentInfo.user_assessment_attempts.length
          ? 'Take Assessment'
          : 'Resume Assessment'
      }
      message={
        !myAssessmentInfo.user_assessment_attempts.length
          ? 'Are you ready to take this assessment?'
          : 'Are you sure you want to resume this assessment?'
      }
      confirmBtnLabel="Let's Start"
      confirm={() => handleConfirm(myAssessmentInfo.id)}
      cancel={() => handleTakeAssessment(null)}
    />
  );
};

TakeAssessmentModal.propTypes = {
  handleTakeAssessment: PropTypes.func,
  myAssessmentInfo: PropTypes.shape({
    id: PropTypes.number,
    user_assessment_attempts: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  modal: PropTypes.string
};

export default TakeAssessmentModal;
