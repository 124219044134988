import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getLowerCasedValue,
  filterData,
  formatDateYMD
} from 'src/helpers/utils';
import { getMyAssessmentsService } from 'src/api/modules/assessment';
import { toast } from 'react-toastify';
import { EVALUATION_STATUS } from 'src/helpers/constants';

const useMyAssessmentIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: undefined });
  const myAssessmentDataList = useSelector(
    state => state?.assessment?.myAssessmentList
  );
  const [myAssessmentList, setMyAssessmentList] = useState([]);
  const [myAssessmentTitle, setMyAssessmentTitle] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const myAssessmentColumns = [
    { key: 'title', label: 'Title' },
    { key: 'code', label: 'Code' },
    { key: 'skill_name', label: 'Skill' },
    { key: 'status', label: 'Status' },
    { key: 'duration', label: 'Duration' },
    { key: 'questions', label: 'Questions' },
    { key: 'end_date', label: 'Deadline' }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(EVALUATION_STATUS, 'label', 'value'),
      multiOptions: EVALUATION_STATUS,
      name: 'status',
      label: 'Status',
      defaultValue: ['N', 'A', 'O', 'C', 'F']
    }
  ];

  const formatItem = item => ({
    id: item.id,
    title: item.assessment.title,
    code: item.assessment.code,
    skill_name: item.assessment.skill_name,
    status: item.status,
    duration: `${item.assessment.duration} ${
      item.assessment.duration > 1 ? ' mins' : ' min'
    }`,
    questions: `${item.assessment.total_questions} ${
      item.assessment.total_questions > 1 ? ' Items' : ' Item'
    }`,
    created_at: formatDateYMD(item.start_date),
    end_date: formatDateYMD(item.end_date)
  });

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-my-assessments-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-my-assessments-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);
      const res = await getMyAssessmentsService(savedPageIndex, params);
      const formattedItems = res.data.items.map(formatItem);

      const updatedList =
        savedPageIndex > 1
          ? [...myAssessmentList, ...formattedItems]
          : formattedItems;

      setMyAssessmentList(updatedList);

      if (
        res.data.items.length !== 0 &&
        res.data.current_page < res.data.total_pages
      ) {
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getMyAssessmentsService,
    setMyAssessmentList,
    setPage,
    setHasMore,
    myAssessmentList
  ]);

  const myAssessmentListMemo = useMemo(() => {
    return myAssessmentList ?? [];
  }, [myAssessmentList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-my-assessments-params', searchParams);
  };

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-my-assessments-params'
    );
    const savedPageIndex = localStorage.getItem('hris-my-assessments-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      localStorage.setItem('hris-my-assessments-page-no', JSON.stringify(1));
    }
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      setPage(1);
      localStorage.setItem('hris-my-assessments-page-no', JSON.stringify(1));
      setHasMore(true);
      fetchData();
    }
  }, [location.search, myAssessmentDataList]);

  useEffect(() => {
    localStorage.setItem('hris-my-assessments-page-no', JSON.stringify(page));
  }, [page]);

  return {
    fetchData,
    form,
    hasMore,
    inputs,
    myAssessmentListMemo,
    myAssessmentList,
    myAssessmentTitle,
    myAssessmentColumns,
    page,
    submitFilter
  };
};

export default useMyAssessmentIndex;
