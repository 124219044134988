import {
  GET_SCHEDULES,
  GET_USER_EVALUATION_STATUS,
  GET_SCHEDULES_STATUS,
  GET_SCHEDULES_VERIFICATION
} from './schedulesTypes';

const INITIAL_STATE = {
  evaluationSchedules: [],
  evaluationSchedulesStatuses: [],
  userEvaluationScheduleStatus: [],
  evaluationSchedulesVerification: []
};

export default function evaluationSchedulesReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_SCHEDULES:
      return {
        ...state,
        evaluationSchedules: payload
      };
    case GET_SCHEDULES_STATUS:
      return {
        ...state,
        evaluationSchedulesStatuses: payload
      };
    case GET_USER_EVALUATION_STATUS:
      return {
        ...state,
        userEvaluationScheduleStatus: payload
      };
    case GET_SCHEDULES_VERIFICATION:
      return {
        ...state,
        evaluationSchedulesVerification: payload
      };
    default:
      return state;
  }
}
