import React from 'react';

const LabelHolder = () => {
  return (
    <div className="space-y-2.5 animate-pulse max-w-lg">
      <div className="flex items-center w-full">
        <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-32" />
        <div className="h-2.5 ms-2 bg-gray-600 rounded-full dark:bg-gray-600 w-24" />
      </div>
    </div>
  );
};

export default LabelHolder;
