import { useDispatch, useSelector } from 'react-redux';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { toast } from 'react-toastify';
import {
  bulkDeleteQuizService,
  getQuizItemsService,
  getQuizByIDService
} from 'src/api/modules/quiz';
import { getAllQuiz } from 'src/redux/modules/quiz/quizActions';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { useEffect, useState } from 'react';

export const useBulkDeleteQuizModal = ({ handleBulkDeleteModal }) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);
  const [questionTitle, setQuestionTitle] = useState('the selected items');

  useEffect(() => {
    if (ids.length === 1) {
      getQuizByIDService(ids[0]).then(res => {
        setQuestionTitle(
          `${res.data.code.trimEnd()}: ${res.data.title.trimEnd()}`
        );
      });
    }
  }, []);

  const fetchNewQuizList = async () => {
    getQuizItemsService(1)
      .then(res => {
        dispatch(getAllQuiz(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await bulkDeleteQuizService(ids);
      if (res.statusCode === 200) {
        dispatch(setIdSelection([]));
        fetchNewQuizList();
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
      } else {
        toast.error('Unauthorized Deletion of Data.', {
          icon: <CgCloseO />
        });
      }
      handleBulkDeleteModal(null);
    }
  };
  return {
    handleDelete,
    questionTitle
  };
};
