import React from 'react';
import { useLocation } from 'react-router-dom';
import Datatable from 'src/components/v1/Common/Datatable';
import PageTitle from 'src/components/v1/Common/PageTitle';

const ReportsIndex = () => {
  const location = useLocation();

  const reportsData = [
    {
      id: 1,
      sheetID: Number('00001'),
      templateName: 'For Section Managers',
      department: 'Operations',
      report: 'SM',
      position: 'QA',
      salaryGrade: 'G3, SG1',
      details: 'ab Lorem ipsum sit amit amor'
    },
    {
      id: 2,
      sheetID: Number('00002'),
      templateName: 'For Project Managers',
      department: 'Operations',
      report: 'PM',
      position: 'QA',
      salaryGrade: 'G3, SG1',
      details: 'bb Lorem ipsum sit amit amor'
    },
    {
      id: 3,
      sheetID: Number('00003'),
      templateName: 'Assignment Summary',
      department: 'Operations',
      report: 'assreport',
      position: '',
      salaryGrade: '',
      details: 'CODY Assignment Summary'
    }
  ];

  const reportsColumn = [
    {
      key: 'sheetID',
      label: 'Sheet ID'
    },
    {
      key: 'templateName',
      label: 'Template Name'
    },
    {
      key: 'department',
      label: 'Department'
    },
    {
      key: 'position',
      label: 'Position'
    },
    {
      key: 'salaryGrade',
      label: 'Salary Grade'
    },
    {
      key: 'details',
      label: 'Details'
    }
  ];

  return (
    <div className="reports__container">
      <PageTitle title="REPORTS" />
      <div className="reports__content">
        <div className="reports__table">
          <Datatable
            title="All Reports List"
            datasource={reportsData}
            headingColumns={reportsColumn}
            clickableRows
            link={location.pathname}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsIndex;
