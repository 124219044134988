import React, { useRef } from 'react';
import LabeledText from 'src/components/Common/LabeledText';
import PropTypes from 'prop-types';

const TemplateScoreWeights = ({
  type,
  item,
  isEdit,
  loading,
  weights,
  itemIndex,
  handleChange
}) => {
  const g1Ref = useRef(null);
  const g2Ref = useRef(null);
  const g3Ref = useRef(null);
  const sg1Ref = useRef(null);
  const sg2Ref = useRef(null);
  const sg3Ref = useRef(null);
  const readOnly = isEdit ? '' : ' read-only';
  const weightRef = [g1Ref, g2Ref, g3Ref, sg1Ref, sg2Ref, sg3Ref];
  const handleFocus = weight => weight.current.select();

  return (
    <>
      <h3 className="evaluation__label">Weight</h3>
      <div className={`evaluation__score-weights${readOnly}`}>
        {weights?.length &&
          weights.map((_, index) => {
            const salaryGradeLabel =
              index >= 3 ? `SG${index - 2}` : `G${index + 1}`;
            const salaryGradeName =
              index >= 3 ? `sg${index - 2}` : `g${index + 1}`;
            return (
              <div
                key={weightRef[index]}
                className={`evaluation__score-weights-item${readOnly}`}
              >
                {isEdit ? (
                  <div className="evaluation__weights-list pt10">
                    <div className="evaluation__weights-item">
                      <label
                        className="evaluation__label"
                        htmlFor={`weights_${type}_${itemIndex}${index}`}
                      >
                        <strong>{salaryGradeLabel}</strong>
                      </label>
                    </div>
                    <div className="evaluation__weights-item -input">
                      <input
                        type="number"
                        maxLength="3"
                        name={salaryGradeName}
                        ref={weightRef[index]}
                        onChange={handleChange}
                        value={item[salaryGradeName]}
                        disabled={loading}
                        className="evaluation__input"
                        id={`weights_${type}_${itemIndex}${index}`}
                        onFocus={() => handleFocus(weightRef[index])}
                      />
                    </div>
                    <div className="evaluation__weights-item">
                      <span
                        className="evaluation__label"
                        htmlFor={`weights_${type}_${itemIndex}${index}`}
                      >
                        <strong>%</strong>
                      </span>
                    </div>
                  </div>
                ) : (
                  <p className="evaluation__template-information-value">
                    {`${salaryGradeLabel} ${item[salaryGradeName]}%`}
                  </p>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

TemplateScoreWeights.propTypes = {
  type: PropTypes.string,
  item: PropTypes.arrayOf(PropTypes.string),
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  weights: PropTypes.objectOf(PropTypes.number),
  itemIndex: PropTypes.bool,
  handleChange: PropTypes.func
};

export default TemplateScoreWeights;
