import { ADMIN_POSITIONS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getPositionsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ADMIN_POSITIONS
    });
  } catch (error) {
    return error;
  }
};
