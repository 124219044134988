import axios from 'axios';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';
import {
  PROJECTS,
  PROJECT_LIST,
  ALL_MEMBERS,
  ALL_ROLES,
  ALL_SKILLS,
  ALL_MEMBERS_BY_ROLE,
  PROJECT_MEMBER_BY_ID,
  EDIT_MEMBER,
  REPORT_SUMMARY,
  REPORT_DETAIL,
  USER_PROJECTS,
  ADMIN_PROJECTS,
  PROJECT_ROLES
} from '../endpoints';

export const addProjectService = async data => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: PROJECTS,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateProjectDetails = async (id, data) => {
  return fetchAPI({
    method: 'PUT',
    endpoint: `${PROJECTS}/${id}`,
    body: data
  });
};

export const getProject = async id => {
  return fetchAPI({
    method: 'GET',
    endpoint: `${PROJECTS}/${id}`
  });
};

export const getAllMembersService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_MEMBERS
    });
  } catch (error) {
    return error;
  }
};

export const getAllRolesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_ROLES
    });
  } catch (error) {
    return error;
  }
};

export const getAllSkillsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_SKILLS
    });
  } catch (error) {
    return error;
  }
};

export const addProjectMemberService = async (data, id) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: `/api/admin/${id}/addmember`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllMembersByRoleService = async roleName => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ALL_MEMBERS_BY_ROLE}/${roleName}`
    });
  } catch (error) {
    return error;
  }
};

export const getProjectMemberByIDService = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${PROJECT_MEMBER_BY_ID}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateMemberService = async (data, id) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${EDIT_MEMBER}/${Number(id)}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getProjectList = async () => {
  return fetchAPI({
    method: 'GET',
    endpoint: PROJECT_LIST
  });
};

export const getReportSummaryFunction = async date => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${REPORT_SUMMARY}/?paramDate=${date}`
    });
  } catch (error) {
    return error;
  }
};

export const getReportDetailFunction = async date => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${REPORT_DETAIL}/?paramDate=${date}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserProjectsHistoryByID = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `/api/admin/users/${userID}/project-history`
    });
  } catch (error) {
    return error;
  }
};

export const getProjectUsersService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_PROJECTS
    });
  } catch (error) {
    return error;
  }
};

export const getProjectsService = async page => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_PROJECTS}?page=${page}`
    });
  } catch (error) {
    return error;
  }
};

export const getProjectsServiceList = async (page, searchParams) => {
  try {
    let endpoint = `${ADMIN_PROJECTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getProjectsByIdService = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ADMIN_PROJECTS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const deleteProjectsByIdService = async id => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${ADMIN_PROJECTS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const createProjectService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${ADMIN_PROJECTS}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getProjectRolesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${PROJECT_ROLES}`
    });
  } catch (error) {
    return error;
  }
};

export const updateProjectService = async (id, data) => {
  try {
    const response = await axios.put(
      `${ENV.test}${ADMIN_PROJECTS}/${id}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};
