/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getLowerCasedValue, filterData } from 'src/helpers/utils';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getAllMyEvaluationsService } from 'src/api/modules/evaluation';
import { useEmployees } from 'src/hooks/EvaluationSchedule/helpers';

const useMyEvaluation = () => {
  const [evaluationEntryList, setEvaluationEntryList] = useState([]);
  const [evaluationEntryColumns, setEvaluationEntryColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();
  const columnCount = useRef(0);

  const { employeeName } = useEmployees();

  const evaluationBaseColumns = [
    { key: 'name', label: 'Name' },
    { key: 'cost_center', label: 'Cost Center' },
    { key: 'template', label: 'Template' },
    { key: 'status', label: 'Status' }
  ];

  const access = {
    can_add: true,
    can_approve: true,
    can_delete: true,
    can_edit: true,
    can_generate: true,
    can_print: true,
    can_view: true
  };

  const EVALUATION_STATUS = [
    {
      value: 'A',
      label: 'Active'
    },
    {
      value: 'F',
      label: 'Fulfilled'
    }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(EVALUATION_STATUS, 'label', 'value'),
      multiOptions: EVALUATION_STATUS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A', 'F']
    }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-my-evaluation-search-params', searchParams);
  };

  const generateColumns = useCallback(async () => {
    const sections = columnCount.current;
    if (sections) {
      const evaluatorColumns = Array.from({ length: sections }, (_, i) => ({
        key: `evaluator_${i + 1}`,
        label: `Evaluator ${i + 1}`
      }));
      setEvaluationEntryColumns(headers => [
        ...headers,
        ...evaluationBaseColumns.slice(0, 3),
        ...evaluatorColumns,
        ...evaluationBaseColumns.slice(-1)
      ]);
    }
  }, [evaluationEntryList]);

  const myEvaluationListMemo = useMemo(() => {
    const sectionMaxCount = columnCount.current;
    let list = [];
    if (sectionMaxCount) {
      list = [
        ...(evaluationEntryList?.map(
          ({
            id,
            status,
            employee,
            evaluation_template,
            employee_evaluation_sections: sections
          }) => {
            const { cost_center_code = '', employee_info } = employee || {};
            const sectionCount = sections.length;
            const missing = sectionMaxCount
              ? sectionMaxCount - sectionCount
              : 0;
            const item = {
              id,
              name: employeeName(employee_info),
              status,
              cost_center: cost_center_code,
              template: evaluation_template?.name,
              templateId: evaluation_template?.id,
              sections: sections || []
            };

            if (missing > 0) {
              sections.push(
                ...Array.from({ length: missing }, () => ({
                  id: null,
                  section_id: null,
                  evaluator: null
                }))
              );
            }

            const evaluation_sections = sections?.map(
              ({ evaluator }, index) => ({
                [`evaluator_${index + 1}`]: employeeName(
                  evaluator?.employee_info
                )
              })
            );

            return evaluation_sections?.reduce(
              (object, current) => ({ ...object, ...current }),
              { ...item }
            );
          }
        ) || [])
      ];
    }
    return list;
  }, [evaluationEntryList]);

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-my-evaluation-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-my-evaluation-page-no'),
        10
      );

      const newPageIndex =
        savedPageIndex > totalPage ? savedPageIndex - 1 : savedPageIndex;

      const params = new URLSearchParams(savedSearchParams);
      const res = await getAllMyEvaluationsService(
        newPageIndex || page,
        params
      );

      const { items, total_pages, current_page } = res.data || {};

      columnCount.current = getMaxSectionCount(items);

      const newList =
        savedPageIndex !== 1 ? evaluationEntryList.concat(items) : items;
      setTotalPage(total_pages);
      setEvaluationEntryList(newList);

      if (items?.length === 0 || current_page >= total_pages) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAllMyEvaluationsService,
    setEvaluationEntryList,
    setPage,
    setHasMore,
    evaluationEntryList
  ]);

  const handleViewPage = useCallback((modal, id = null) => {
    navigate(`/my-evaluation/${id}`);
  }, []);

  function getMaxSectionCount(items) {
    if (!items) return 0;
    return items.reduce((max, item) => {
      if (!item || !item.employee_evaluation_sections) {
        throw new Error('Invalid item');
      }
      return Math.max(max, item.employee_evaluation_sections.length);
    }, 0);
  }

  useEffect(() => {
    if (columnCount.current) generateColumns();
  }, [evaluationEntryList]);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-my-evaluation-search-params'
    );
    const savedPageIndex = localStorage.getItem('hris-my-evaluation-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      localStorage.setItem('hris-my-evaluation-page-no', JSON.stringify(1));
    }
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-my-evaluation-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('hris-my-evaluation-page-no', JSON.stringify(page));
  }, [page]);

  return {
    page,
    form,
    inputs,
    hasMore,
    access,
    evaluationEntryColumns,
    evaluationEntryList,
    myEvaluationListMemo,
    fetchData,
    submitFilter,
    handleViewPage
  };
};

export default useMyEvaluation;
