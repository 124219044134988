import { fetchAPI } from '../fetchAPI';
import {
  PROJECTS,
  PROJECT_LIST,
  ALL_MEMBERS,
  ALL_ROLES,
  ALL_SKILLS,
  ALL_MEMBERS_BY_ROLE,
  PROJECT_MEMBER_BY_ID,
  EDIT_MEMBER,
  REPORT_SUMMARY,
  REPORT_DETAIL,
  USER_PROJECTS
} from '../endpoints';

export const addProjectService = async data => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: PROJECTS,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const updateProjectDetails = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${PROJECTS}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getProject = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${PROJECTS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const getAllMembersService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_MEMBERS
    });
  } catch (error) {
    return error;
  }
};

export const getAllRolesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_ROLES
    });
  } catch (error) {
    return error;
  }
};

export const getAllSkillsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_SKILLS
    });
  } catch (error) {
    return error;
  }
};

export const addProjectMemberService = async (data, id) => {
  try {
    return await fetchAPI({
      method: 'POST',
      endpoint: `/api/admin/${id}/addmember`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllMembersByRoleService = async roleName => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ALL_MEMBERS_BY_ROLE}/${roleName}`
    });
  } catch (error) {
    return error;
  }
};

export const getProjectMemberByIDService = async id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${PROJECT_MEMBER_BY_ID}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateMemberService = async (data, id) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${EDIT_MEMBER}/${Number(id)}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getProjectList = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: PROJECT_LIST
    });
  } catch (error) {
    return error;
  }
};

export const getReportSummaryFunction = async date => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${REPORT_SUMMARY}/?paramDate=${date}`
    });
  } catch (error) {
    return error;
  }
};

export const getReportDetailFunction = async date => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${REPORT_DETAIL}/?paramDate=${date}`
    });
  } catch (error) {
    return error;
  }
};

export const getUserProjectsHistoryByID = async userID => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `/api/admin/users/${userID}/project-history`
    });
  } catch (error) {
    return error;
  }
};

export const getProjectUsersService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: USER_PROJECTS
    });
  } catch (error) {
    return error;
  }
};
