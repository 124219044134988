import axios from 'axios';
import {
  ASSESSMENTS,
  BULK_DELETE_ASSESSMENTS,
  MY_ASSESSMENTS
} from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getAssessmentsService = async (page, searchParams) => {
  try {
    let endpoint = `${ASSESSMENTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getAssessmentUsersService = async (
  assessmentId,
  page,
  searchParams
) => {
  try {
    let endpoint = `${ASSESSMENTS}/${assessmentId}/users?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getAssessmentByIDService = async assessmentId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ASSESSMENTS}/${assessmentId}`
    });
  } catch (error) {
    return error;
  }
};

export const createAssessmentService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${ASSESSMENTS}`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAssessmentByIdService = async assessmentId => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${ASSESSMENTS}/${assessmentId}`
    });
  } catch (error) {
    return error;
  }
};

export const updateAssessmentByIdService = async (assessmentId, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${ASSESSMENTS}/${assessmentId}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllAssessmentItemService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ASSESSMENTS}?all=true`
    });
  } catch (error) {
    return error;
  }
};

export const bulkDeleteAssessmentService = async ids => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: BULK_DELETE_ASSESSMENTS,
      params: { data: { ids } }
    });
  } catch (error) {
    return error;
  }
};

export const getAssessmentResultService = async (
  assessment_id,
  user_assessment_id
) => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ASSESSMENTS}/${assessment_id}/users/${user_assessment_id}`
    });
  } catch (error) {
    return error;
  }
};

export const getMyAssessmentsService = async (page, searchParams) => {
  try {
    let endpoint = `${MY_ASSESSMENTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getMyAssessmentByIDService = async userAssessmentId => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${MY_ASSESSMENTS}/${userAssessmentId}`
    });
  } catch (error) {
    return error;
  }
};

export const takeQuizService = async (userAssessmentId, quizId) => {
  try {
    const data = { quiz_id: quizId };
    const response = await axios.post(
      `${ENV.test}${MY_ASSESSMENTS}/${userAssessmentId}/attempts`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const saveAnswerService = async (
  assessmentAttempt,
  answerId,
  question,
  selected
) => {
  try {
    const data = {
      question_id: question.id,
      question_answer_id: answerId,
      selected
    };
    const response = await axios.post(
      `${ENV.test}${MY_ASSESSMENTS}/${assessmentAttempt.user_assessment_id}/attempts/${assessmentAttempt.id}/answers`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const submitAttemptService = async assessmentAttempt => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${MY_ASSESSMENTS}/${assessmentAttempt.user_assessment_id}/attempts/${assessmentAttempt.id}/submit`,
      body: {}
    });
  } catch (error) {
    return error;
  }
};
