import { MENU_CODES, MENU_ACTIONS } from 'src/static/menuCodes';
import axios from 'axios';
import { ALL_DEPARTMENTS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getDepartmentsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ALL_DEPARTMENTS
    });
  } catch (error) {
    return error;
  }
};

export const addDepartmentService = async data => {
  try {
    const response = await axios.post(
      `${ENV.test}${ALL_DEPARTMENTS}`,
      data,
      auth({
        'menu-code': MENU_CODES.DEPARTMENT,
        'menu-action': MENU_ACTIONS.CRUD
      })
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getDepartmentsServiceByPage = async (page, searchParams) => {
  try {
    let endpoint = `${ALL_DEPARTMENTS}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getDepartmentByIDService = async (id, method) => {
  try {
    return await fetchAPI({
      method,
      endpoint: `${ALL_DEPARTMENTS}/${id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateDepartmentByIDService = async (code, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${ALL_DEPARTMENTS}/${code}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllDepartmentItemService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${ALL_DEPARTMENTS}?all=true`
    });
  } catch (error) {
    return error;
  }
};
