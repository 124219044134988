/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Text from './Text';

const Select = ({
  options,
  onChangeValue,
  selectedValue,
  selectName,
  currentValue,
  placeholder = 'Select Field',
  disabled = false,
  errorMessage = 'Field Required', // Default error message
  errors = null
}) => {
  const [isDropdownDisplayed, setDropdownDisplayed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedValue);
  const [statusText, setStatusText] = useState('');
  const [displayText, setDisplayText] = useState('');
  const buttonRef = useRef();
  const dropdownRef = useRef();

  const handleDropdown = () => {
    setDropdownDisplayed(!isDropdownDisplayed);
  };

  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    const selectedOptionLabel = options.find(
      option => option.value === selectedOption
    )?.label;
    setDisplayText(currentValue || selectedOptionLabel || '');
  }, [selectedOption, options]);

  useEffect(() => {
    const handleOutsideClick = event => {
      let hasDropdown = false;
      if (buttonRef?.current?.nextSibling !== null) {
        hasDropdown = buttonRef?.current?.nextSibling.contains(event.target);
      }
      if (!hasDropdown && !buttonRef?.current?.contains(event.target)) {
        setDropdownDisplayed(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleRadioChange = (event, codeName) => {
    const { optionlabel } = event.target.dataset;
    const { name, value } = event.target;

    setDropdownDisplayed(false);
    setSelectedOption(value);
    setStatusText(optionlabel);
    setDisplayText(optionlabel);
    onChangeValue(value, name, codeName);
  };

  const optionItems = options
    ?.filter(item => {
      if (selectedValue !== 'N') {
        return item.value !== 'N';
      }
      return item;
    })
    .map(option => {
      return (
        <div
          key={selectName + (option.id || option.label || option)}
          htmlFor={selectName + (option.label || option)}
          className="h-10 hover:bg-[#E8F1FF60] projects-label"
        >
          <label
            className="text-[0] cursor-pointer h-full w-full flex items-center content-center"
            htmlFor={selectName + (option.label || option)}
          >
            <input
              type="radio"
              name={selectName}
              value={option.value || option}
              data-optionlabel={option.label || option}
              id={selectName + (option.label || option)}
              checked={selectedOption === (option.value || option)}
              onChange={e => handleRadioChange(e, option.codeName)}
              hidden
            />
            <i className="inline-block align-middle cursor-pointer ml-2.5 -mt-[1px] not-italic text-[14px] text-[#222222] leading-[14px] font-stolzlBook">
              {option.label || option}
            </i>
          </label>
        </div>
      );
    });

  return (
    <div className="relative">
      <button
        type="button"
        ref={buttonRef}
        className={`font-stolzlBook w-full h-10 p-[10px] flex items-center focus:border-[#000] ${
          errors ? 'border-[#E43B26] placeholder-[#E43B26]' : 'border-[#eaeaea]'
        } 
        ${
          disabled ? 'bg-[#F7F7F7]' : 'bg-white'
        } border text-[14px] rounded capitalize bg-no-repeat bg-[center_right_18px]`}
        // onClick={() => setDropdownDisplayed(prevState => !prevState)}
        onClick={handleDropdown}
        disabled={disabled}
      >
        <span className="overflow-hidden grow text-overflow-ellipsis line-clamp-1 text-[14px] font-stolzlBook text-left">
          {errors ? (
            <Text
              tag="p"
              className="text-[12px] text-[#f19d94]"
              markup={errorMessage}
            />
          ) : (
            statusText ||
            displayText || (
              <Text
                tag="p"
                className="text-[14px] font-stolzlBook text-[#bdbec1]"
                markup={placeholder}
              />
            )
          )}
        </span>
        <img
          alt="Dropdown Icon"
          src="../icons/dropdown.svg"
          className={`${
            isDropdownDisplayed ? 'rotate-180' : ''
          } transition-transform duration-500`}
        />
      </button>
      {isDropdownDisplayed && (
        <div className="w-full max-h-[160px] bg-white overflow-auto absolute top-full left-0 z-10 shadow-md dropdown">
          {optionItems}
        </div>
      )}
    </div>
  );
};

export default Select;
