/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form } from 'formik';
import { useAddEditModal } from 'src/hooks/components/Parts/Cost-Center/Sub-section/useAddEditModal';
import {
  Button,
  ModalCenter,
  FormField,
  FormCard,
  FormGridCard,
  Text
} from 'src/components/Common';
import { useUserAccess } from 'src/hooks/useUserAccess';
import DeleteModal from './DeleteModal';

const AddEditModal = ({ handleModal, uniqueCode, dataList, menuCode }) => {
  const {
    formData,
    handleChange,
    handleSubmit,
    handleDeleteModal,
    handleTrimSpaces,
    validationSchema,
    initialDummyData,
    showDeleteModal,
    renderCostCenterInfo,
    subSectionValidation,
    userOptions,
    onChangeSelectHandler
  } = useAddEditModal({ handleModal, uniqueCode, dataList });
  const { access } = useUserAccess(menuCode);

  return (
    <>
      <Formik
        enableReinitialize={!!uniqueCode}
        validateOnMount={!!uniqueCode}
        initialValues={uniqueCode ? initialDummyData : formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          setFieldError
        }) => (
          <Form id="userForm">
            <div className="flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
              <h4 className="text-[22px] font-stolzlMedium leading-[27px]">
                {uniqueCode ? 'Edit' : 'Add'} Sub Section
              </h4>
              {uniqueCode ? (
                <Button
                  type="button"
                  disabled={!access.can_delete}
                  modifier="w-[44px] h-[40px] bg-white hover:bg-gray-700 border border-[#DEDEDE] border-solid border-1 text-white p-[10px_14px] text-[12px] leading-unset rounded-default mr-[10px]"
                  spanModifier="!p-0 w-[43px] h-[43px] inset-x-0 inset-y-0 !absolute flex justify-center items-center before:w-[15px] before:h-auto before:relative before:left-0 before:content-[url('/src/assets/icons/trash.svg')]"
                  onClick={() => {
                    handleDeleteModal('DeleteModal', 'AddEdit', uniqueCode);
                  }}
                />
              ) : (
                ''
              )}
            </div>

            <div className="mx-[35px] mt-[20px] mb-[30px] w-auto">
              {renderCostCenterInfo}
              <Text tag="error" markup={subSectionValidation} />
            </div>

            <div className="w-full">
              <div className="px-[35px] mt-5">
                {!uniqueCode ? (
                  <>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Sub Section Code"
                          required
                          name="sub_section_code"
                          type="text"
                          placeholder=""
                          value={formData.sub_section_code ?? ''}
                          error={
                            errors.sub_section_code && touched.sub_section_code
                          }
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.sub_section_code !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.sub_section_code}
                          </div>
                        )}
                      </FormCard>
                      <FormCard>
                        <FormField
                          label="Sub Section Name"
                          required
                          name="sub_section_name"
                          type="text"
                          placeholder=""
                          value={formData.sub_section_name ?? ''}
                          error={
                            errors.sub_section_name && touched.sub_section_name
                          }
                          onBlur={handleTrimSpaces}
                          onChange={e =>
                            handleChange(
                              e,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                        {errors.sub_section_name !== 'Required' && (
                          <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                            {errors.sub_section_name}
                          </div>
                        )}
                      </FormCard>
                    </FormGridCard>
                    <FormGridCard cols="2">
                      <FormCard>
                        <FormField
                          label="Sub Section Head"
                          type="select"
                          required
                          errors={
                            errors.sub_section_head && touched.sub_section_head
                          }
                          placeholder="Select Sub Section Head"
                          options={userOptions}
                          selectName="sub_section_head"
                          currentValue={formData.division_head ?? ''}
                          onChangeValue={(value, name) =>
                            onChangeSelectHandler(
                              value,
                              name,
                              setFieldValue,
                              setFieldTouched,
                              setFieldError
                            )
                          }
                        />
                      </FormCard>
                    </FormGridCard>
                  </>
                ) : (
                  <FormGridCard cols="2">
                    <FormCard>
                      <FormField
                        label="Sub Section Name"
                        required
                        name="sub_section_name"
                        type="text"
                        placeholder=""
                        errorMessage="Field Required"
                        value={formData.sub_section_name ?? ''}
                        error={
                          errors.sub_section_name && touched.sub_section_name
                        }
                        onBlur={handleTrimSpaces}
                        onChange={e =>
                          handleChange(
                            e,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                      />
                      {errors.sub_section_name !== 'Required' && (
                        <div className="text-[10px] font-stolzlBook text-[#E43B26]">
                          {errors.sub_section_name}
                        </div>
                      )}
                    </FormCard>
                    <FormCard>
                      <FormField
                        label="Sub Section Head"
                        type="select"
                        required
                        errors={
                          errors.sub_section_head && touched.sub_section_head
                        }
                        placeholder="Sub Select Section Head"
                        options={userOptions}
                        selectName="sub_section_head"
                        currentValue={formData.sub_section_head ?? ''}
                        onChangeValue={(value, name) =>
                          onChangeSelectHandler(
                            value,
                            name,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                      />
                    </FormCard>
                  </FormGridCard>
                )}
              </div>
            </div>

            <div className="flex mx-[35px] mt-[30px]">
              <div className="text-right mr-[10px]">
                <button
                  type="submit"
                  className="text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
                  disabled={isSubmitting}
                >
                  <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                    Save
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalCenter showModal={showDeleteModal} modalName="DeleteModal">
        {showDeleteModal && (
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            editModal={handleModal}
            code={uniqueCode}
          />
        )}
      </ModalCenter>
    </>
  );
};

export default AddEditModal;
