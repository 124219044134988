import { useDispatch, useSelector } from 'react-redux';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { toast } from 'react-toastify';
import {
  bulkDeleteQuestionService,
  getQuestionItemsService,
  getQuestionByIDService
} from 'src/api/modules/question';
import { getAllQuestion } from 'src/redux/modules/questions/questionActions';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { useEffect, useState } from 'react';

export const useBulkDeleteQuestionModal = ({
  handleBulkDeleteModal,
  quizId
}) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);
  const [questionTitle, setQuestionTitle] = useState('the selected items');

  useEffect(() => {
    if (ids.length === 1) {
      getQuestionByIDService(ids[0]).then(res => {
        setQuestionTitle(res.data.content);
      });
    }
  }, []);

  const fetchNewQuestionList = async () => {
    const savedSearchParams = localStorage.getItem('hris-question-params');
    getQuestionItemsService(1, savedSearchParams)
      .then(res => {
        dispatch(getAllQuestion(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await bulkDeleteQuestionService(ids);
      if (res.statusCode === 200) {
        dispatch(setIdSelection([]));
        fetchNewQuestionList();
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
      } else {
        toast.error('Unauthorized Deletion of Data.', {
          icon: <CgCloseO />
        });
      }
      handleBulkDeleteModal(null);
    }
  };
  return {
    handleDelete,
    questionTitle
  };
};
