import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getLowerCasedValue, filterData } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import { getCostCenterDataItems } from 'src/api/modules/costcenter';

const useCostCenterIndex = () => {
  const dataList = useSelector(state => state?.costCenter?.costCenterAll.items);
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [uniqueCode, setuniqueCode] = useState(null);
  const [uniqueCodeName, setUniqueCodeName] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();

  const costCenterColumns = [
    { key: 'id', label: 'ID' },
    { key: 'cost_center_code', label: 'Cost Center' },
    { key: 'division_list', label: 'Division' },
    { key: 'department_list', label: 'Department' },
    { key: 'section_list', label: 'Section' },
    { key: 'sub_section_list', label: 'Sub Section' },
    { key: 'remarks', label: 'Remarks' }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-cost-center-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-users-page-no'),
        10
      );

      let params = '';
      if (savedSearchParams) {
        params = new URLSearchParams(savedSearchParams);
      }
      const res = await getCostCenterDataItems(savedPageIndex, params);
      // const res = await getCostCenterDataItems(page);
      const newList =
        savedPageIndex !== 1
          ? costCenterList.concat(res.data.items)
          : res.data.items;
      setCostCenterList(newList);

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getCostCenterDataItems,
    setCostCenterList,
    setPage,
    setHasMore,
    costCenterList
  ]);

  const costCenterListMemo = useMemo(() => {
    return costCenterList ?? [];
  }, [costCenterList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-cost-center-params', searchParams);
  };
  const handleModal = useCallback(
    (modal, id = null) => {
      setShowModal(modal);
      setuniqueCode(modal ? id : null);

      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setuniqueCode]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, cost_center_code }) => {
      setShowDeleteModal(modal);
      setuniqueCode(modal ? id : null);
      setUniqueCodeName(modal ? cost_center_code : null);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setuniqueCode, setUniqueCodeName]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  useEffect(() => {
    const savedSearchParams = localStorage.getItem('hris-cost-center-params');
    const savedPageIndex = localStorage.getItem('hris-users-page-no');
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-users-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      fetchData();
    } else {
      // set default status
      const newLocation = { ...location, search: '' };
      navigate(newLocation);
      form.reset({});
      // pushQuery({ search: 'A' });
    }
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-users-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, dataList]);

  useEffect(() => {
    localStorage.setItem('hris-users-page-no', JSON.stringify(page));
  }, [page]);

  return {
    dataList,
    showModal,
    showDeleteModal,
    uniqueCode,
    uniqueCodeName,
    costCenterList,
    page,
    hasMore,
    costCenterColumns,
    form,
    costCenterListMemo,
    submitFilter,
    handleModal,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal
  };
};

export default useCostCenterIndex;
