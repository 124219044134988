import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useTakeQuizIndex = () => {
  const navigate = useNavigate();
  const myAssessment = useSelector(state => state?.assessment?.myAssessment);
  const [selectedMyAssessment, setSelectedMyAssessment] =
    useState(myAssessment);

  useEffect(() => {
    if (!selectedMyAssessment) {
      navigate('/my-assessments');
    }
  }, [selectedMyAssessment]);

  return {
    myAssessment
  };
};

export default useTakeQuizIndex;
