/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from 'src/components/Common/Button';
import { useUserAccess } from 'src/hooks/useUserAccess';
import { CgClose } from 'react-icons/cg';
import * as Yup from 'yup';

import { FormField, FormCard } from 'src/components/Common';

const CriteriaPart = ({
  templateId,
  handleChange,
  handleCriteriaSubmit,
  deleteCriteriaSubmit,
  templateFields,
  sectionFields,
  criteriaFields,
  totalWeights,
  weightFields,
  sectionIndex,
  salaryGrades,
  handleDeleteTemplateModal,
  viewOnly,
  menuCode
}) => {
  const criteriaArray = criteriaFields[sectionIndex] ?? [];
  const { access } = useUserAccess(menuCode);
  const classDisabled = viewOnly ? 'bg-[#f7f7f7]' : 'bg-white';

  const blankSpaceRegex = /^\S.*$/;
  const blankSpageMessage =
    'Only letters with spaces, hyphens, or periods are allowed';

  const getCriteriaValidationSchema = sectionType =>
    Yup.object({
      title: Yup.string()
        .required('Criteria is required')
        .max(255, 'Maximum 255 characters allowed')
        .matches(blankSpaceRegex, blankSpageMessage),
      max_rating: Yup.number()
        .typeError('Max rating must be a number')
        .required('Max rating is required')
        .max(100, 'Value must be an integer not exceeding 100')
        .test(
          'blank-space-validation',
          'Only letters with spaces, hyphens, or periods are allowed',
          value => /^\S.*$/.test(value)
        ),
      description: Yup.string().required('Criteria description is required')
    });

  const validationCriteriaSchema = {
    R: getCriteriaValidationSchema('R'),
    T: getCriteriaValidationSchema('T'),
    C: getCriteriaValidationSchema('C')
  };

  const validationWeightSchema = Yup.object().shape(
    salaryGrades.reduce((res, grade) => {
      res[grade.id] = Yup.object().shape({
        salary_grade_type: Yup.string().required('required'),
        salary_grade_id: Yup.number().required('required'),
        percentage: Yup.string()
          .required('required')
          .test(
            'is-valid-percentage',
            'Percentage must not exceed 100%',
            function check(value) {
              const parsedValue = parseInt(value, 10);
              if (Number.isNaN(parsedValue)) {
                return false; // Not a valid number
              }
              if (parsedValue < 0 || parsedValue > 100) {
                return false; // Value out of range
              }
              if (!value.endsWith('%')) {
                return false; // Does not end with '%'
              }
              return true;
            }
          )
      });
      return res;
    }, {})
  );

  const validationTotalWeightSchema = Yup.object().shape(
    salaryGrades.reduce((res, grade) => {
      res[grade.id] = Yup.number().max(
        100,
        'Value must be an integer not exceeding 100'
      );
      return res;
    }, {})
  );

  const initialCriteria = {
    criteria_id: 1003400,
    title: 'Criteria',
    description: 'Test',
    max_rating: 25
  };

  const initialWeights = {
    1: {
      salary_grade_type: 'G1',
      salary_grade_id: 1,
      percentage: '0%'
    },
    2: {
      salary_grade_type: 'G2',
      salary_grade_id: 2,
      percentage: '0%'
    },
    3: {
      salary_grade_type: 'G3',
      salary_grade_id: 3,
      percentage: '0%'
    },
    4: {
      salary_grade_type: 'SG1',
      salary_grade_id: 4,
      percentage: '0%'
    },
    5: {
      salary_grade_type: 'SG2',
      salary_grade_id: 5,
      percentage: '0%'
    },
    6: {
      salary_grade_type: 'SG3',
      salary_grade_id: 6,
      percentage: '0%'
    },
    7: {
      salary_grade_type: 'TL',
      salary_grade_id: 7,
      percentage: '0%'
    },
    8: {
      salary_grade_type: 'TD',
      salary_grade_id: 8,
      percentage: '0%'
    },
    9: {
      salary_grade_type: 'PM',
      salary_grade_id: 9,
      percentage: '0%'
    }
  };

  return (
    <>
      {Object.keys(criteriaArray).map((criteria, criteriaIndex) => (
        <Formik
          enableReinitialize
          initialValues={{
            criteria: initialCriteria,
            weights: initialWeights,
            totalWeights
          }}
          validationSchema={Yup.object().shape({
            criteria:
              validationCriteriaSchema[
                sectionFields[sectionIndex].orig_section_type
              ],
            weights: validationWeightSchema,
            totalWeights: validationTotalWeightSchema
          })}
          validateOnMount
          validateOnBlur={false}
          validateOnChange={false}
          // eslint-disable-next-line react/no-array-index-key
          key={`${sectionIndex}-${criteriaIndex}`}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            setFieldError
          }) => (
            <Form
              className="max-w-[1120px]"
              id={`evaluationCriteriaForm${criteriaIndex}`}
            >
              <div className="bg-white border-solid border-[1px] border-[#e0e0e0] p-[18px] rounded mb-5">
                <div className="flex">
                  <div className="w-[1120px] max-w-full">
                    <div className="flex gap-4">
                      <div className="flex-1 mb-2">
                        <div className="flex gap-4">
                          {sectionFields[sectionIndex].orig_section_type ===
                          'C' ? (
                            <>
                              <FormCard className="!mb-0">
                                <FormField
                                  label="Max Rating"
                                  required
                                  name="max_rating"
                                  type="text"
                                  placeholder=""
                                  errorMessage="Field Required"
                                  value={
                                    criteriaFields[sectionIndex][
                                      criteriaIndex
                                    ]?.max_rating.toString() ?? ''
                                  }
                                  error={
                                    errors?.criteria?.max_rating &&
                                    touched?.criteria?.max_rating
                                  }
                                  onChange={e =>
                                    handleChange(
                                      e,
                                      'criteria',
                                      sectionIndex,
                                      setFieldValue,
                                      setFieldTouched,
                                      setFieldError,
                                      criteriaIndex
                                    )
                                  }
                                  disabled={viewOnly}
                                />
                              </FormCard>
                              <FormCard className="!mb-0">
                                <FormField
                                  className="h-[40px]"
                                  label="Question"
                                  required
                                  name="question"
                                  type="textarea"
                                  placeholder=""
                                  value={
                                    criteriaFields[sectionIndex][criteriaIndex]
                                      ?.question ?? ''
                                  }
                                  error={
                                    errors?.criteria?.question &&
                                    touched?.criteria?.question
                                  }
                                  max="255"
                                  onChange={e =>
                                    handleChange(
                                      e,
                                      'criteria',
                                      sectionIndex,
                                      setFieldValue,
                                      setFieldTouched,
                                      setFieldError,
                                      criteriaIndex
                                    )
                                  }
                                  disabled={viewOnly}
                                />
                              </FormCard>
                            </>
                          ) : (
                            <>
                              {/* Start Column 1 */}
                              <div className="flex basis-1/2">
                                <div className="flex flex-col w-full gap-3">
                                  <FormCard className="!mb-0">
                                    <FormField
                                      label="Criteria"
                                      required
                                      name="title"
                                      type="text"
                                      placeholder=""
                                      errorMessage="Field Required"
                                      value={
                                        criteriaFields[sectionIndex][
                                          criteriaIndex
                                        ]?.title ?? ''
                                      }
                                      error={
                                        errors?.criteria?.title &&
                                        touched?.criteria?.title
                                      }
                                      onChange={e =>
                                        handleChange(
                                          e,
                                          'criteria',
                                          sectionIndex,
                                          setFieldValue,
                                          setFieldTouched,
                                          setFieldError,
                                          criteriaIndex
                                        )
                                      }
                                      disabled={viewOnly}
                                    />
                                  </FormCard>
                                  <FormCard className="!mb-0">
                                    <FormField
                                      label="Max Rating"
                                      required
                                      name="max_rating"
                                      type="text"
                                      placeholder=""
                                      errorMessage="Field Required"
                                      value={
                                        criteriaFields[sectionIndex][
                                          criteriaIndex
                                        ]?.max_rating.toString() ?? ''
                                      }
                                      error={
                                        errors?.criteria?.max_rating &&
                                        touched?.criteria?.max_rating
                                      }
                                      onChange={e =>
                                        handleChange(
                                          e,
                                          'criteria',
                                          sectionIndex,
                                          setFieldValue,
                                          setFieldTouched,
                                          setFieldError,
                                          criteriaIndex
                                        )
                                      }
                                      disabled={viewOnly}
                                    />
                                  </FormCard>
                                </div>
                              </div>
                              {/* End column 1 */}
                              {/* Start column 2 */}
                              <div className="flex basis-1/2">
                                <div className="w-full">
                                  <FormCard className="!mb-0">
                                    <FormField
                                      className="min-h-[111px]"
                                      label="Criteria Description"
                                      required
                                      name="description"
                                      type="textarea"
                                      placeholder=""
                                      value={
                                        criteriaFields[sectionIndex][
                                          criteriaIndex
                                        ]?.description ?? ''
                                      }
                                      error={
                                        errors?.criteria?.description &&
                                        touched?.criteria?.description
                                      }
                                      max="255"
                                      onChange={e =>
                                        handleChange(
                                          e,
                                          'criteria',
                                          sectionIndex,
                                          setFieldValue,
                                          setFieldTouched,
                                          setFieldError,
                                          criteriaIndex
                                        )
                                      }
                                      disabled={viewOnly}
                                    />
                                  </FormCard>
                                </div>
                              </div>
                              {/* End column 2 */}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full">
                    <div className="flex items-end">
                      <label className="block text-[14px] text-[#232932] font-normal font-stolzlBook leading-6 w-[135px] py-2.5">
                        Criteria Weight
                      </label>
                      <div className="block w-[calc(100%-135px)]">
                        <div className="flex gap-2">
                          {salaryGrades.map(data => (
                            <div key={data.type}>
                              <div className="block w-20">
                                <span className="block text-[14px] text-[#222222] font-stolzlBook leading-6 text-center">
                                  {data.type}
                                </span>
                                <Field
                                  className={`${
                                    errors.totalWeights?.[data.id]
                                      ? 'border-[#e43b26] placeholder-[#e43b26] text-[10px]'
                                      : 'border-[#eaeaea]'
                                  } ${classDisabled} block w-full border-solid border-[1px] rounded text-[14px] text-[#222222] font-normal font-stolzlBook leading-6 text-center h-10 px-3`}
                                  type="text"
                                  name="percentage"
                                  data-sectionindex={sectionIndex}
                                  data-criteriaindex={criteriaIndex}
                                  data-weightindex={data.id}
                                  value={
                                    weightFields[sectionIndex]?.[
                                      criteriaIndex
                                    ]?.[data.id]?.percentage ?? ''
                                  }
                                  onChange={e =>
                                    handleChange(
                                      e,
                                      'weight',
                                      sectionIndex,
                                      setFieldValue,
                                      setFieldTouched,
                                      setFieldError,
                                      criteriaIndex
                                    )
                                  }
                                  disabled={viewOnly}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {!viewOnly ? (
                        <div className="flex">
                          <Button
                            title="Save"
                            modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded hover:bg-gray-500"
                            spanModifier="before:w-[15px] before:h-[15px] before:absolute before:left-0 before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                            type="button"
                            disabled={
                              isSubmitting ||
                              criteriaFields[sectionIndex][criteriaIndex]
                                .isSaveDisabled ||
                              (templateFields.orig_status !== undefined &&
                                templateFields.orig_status !== 'N') ||
                              !access.can_edit
                            }
                            onClick={() => {
                              handleCriteriaSubmit(
                                templateId,
                                sectionIndex,
                                criteriaIndex
                              );
                            }}
                          />
                          {criteriaFields[sectionIndex][criteriaIndex]
                            .criteria_id ? (
                            <Button
                              title="Delete"
                              modifier="bg-[#e56367] text-white p-[8px_14px] text-[12px] leading-[24px] rounded ml-[10px] hover:bg-[#F29396]"
                              spanModifier="before:w-[15px] before:h-[15px] before:absolute before:left-0 before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/delete.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                              type="button"
                              disabled={
                                isSubmitting ||
                                (templateFields.orig_status !== undefined &&
                                  templateFields.orig_status !== 'N') ||
                                !access.can_edit
                              }
                              onClick={() =>
                                handleDeleteTemplateModal({
                                  name: 'DeleteModal',
                                  part: 'criteria',
                                  templateId,
                                  sectionIndex,
                                  criteriaIndex
                                })
                              }
                            />
                          ) : (
                            <Button
                              title="Cancel"
                              modifier="bg-[#eee] text-[#232932] p-[8px_14px] text-[12px] leading-[24px] rounded ml-[10px] hover:bg-opacity-75"
                              spanModifier="before:w-[15px] before:h-[15px] before:absolute before:left-0 before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/cancel.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]"
                              type="button"
                              disabled={
                                isSubmitting ||
                                (templateFields.orig_status !== undefined &&
                                  templateFields.orig_status !== 'N') ||
                                !access.can_edit
                              }
                              onClick={() =>
                                deleteCriteriaSubmit(
                                  templateId,
                                  sectionIndex,
                                  criteriaIndex
                                )
                              }
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ))}
    </>
  );
};

export default CriteriaPart;
