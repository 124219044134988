import { useDispatch, useSelector } from 'react-redux';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { toast } from 'react-toastify';
import {
  bulkDeleteAssessmentService,
  getAssessmentsService,
  getAssessmentByIDService
} from 'src/api/modules/assessment';
import { getAllAssessments } from 'src/redux/modules/assessment/assessmentActions';
import { setIdSelection } from 'src/redux/modules/datatable/datatableActions';
import { useEffect, useState } from 'react';

export const useBulkDeleteAssessmentModal = ({ handleBulkDeleteModal }) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);
  const [assessmentTitle, setAssessmentTitle] = useState('the selected items');

  useEffect(() => {
    if (ids.length === 1) {
      getAssessmentByIDService(ids[0]).then(res => {
        setAssessmentTitle(
          `${res.data.code.trimEnd()}: ${res.data.title.trimEnd()}`
        );
      });
    }
  }, []);

  const fetchNewAssessmentList = async () => {
    getAssessmentsService(1)
      .then(res => {
        dispatch(getAllAssessments(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await bulkDeleteAssessmentService(ids);
      if (res.statusCode === 200) {
        dispatch(setIdSelection([]));
        fetchNewAssessmentList();
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
      } else {
        toast.error('Unauthorized Deletion of Data.', {
          icon: <CgCloseO />
        });
      }
      handleBulkDeleteModal(null);
    }
  };
  return {
    handleDelete,
    assessmentTitle
  };
};
