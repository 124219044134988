import { evaluatorLevel } from 'src/static/v1/evaluation';
import { fetchAPI } from 'src/api/v1/fetchAPI';

export function getStatusID(status) {
  switch (status) {
    case 'Draft':
      return 1;
    case 'Active':
      return 2;
    case 'Archived':
      return 3;
    default:
      return 1;
  }
}

export function getScoreWeights(type, weights) {
  let weightScore = 0;
  if (weights?.length) {
    const weight = weights?.find(
      ({ salary_grades }) => salary_grades?.type === type
    );
    weightScore = weight?.weight_percentage ?? 0;
  } else {
    weightScore = 0;
  }
  return weightScore;
}

export async function setScoreWeights(evaluation_form_id, type, id, weights) {
  const { g1, g2, g3, sg1, sg2, sg3 } = weights;
  await fetchAPI({
    body: {
      form_type: type,
      form_id: id,
      weights: [
        {
          salary_grade_id: 4,
          percentage: g1
        },
        {
          salary_grade_id: 5,
          percentage: g2
        },
        {
          salary_grade_id: 6,
          percentage: g3
        },
        {
          salary_grade_id: 12,
          percentage: sg1
        },
        {
          salary_grade_id: 13,
          percentage: sg2
        },
        {
          salary_grade_id: 15,
          percentage: sg3
        }
      ]
    },
    method: 'PATCH',
    endpoint: `/api/admin/evaluations/templates/${evaluation_form_id}/weights`
  });
}

export function numberOnly(item) {
  return [
    'evaluator_level',
    'sub_section_type_id',
    'max_rating',
    'g1',
    'g2',
    'g3',
    'sg1',
    'sg2',
    'sg3'
  ]?.some(key => key === item);
}

export function detectSalaryGrade(item) {
  return ['g1', 'g2', 'g3', 'sg1', 'sg2', 'sg3']?.some(key => key === item);
}

export function getEvaluationLevel(level) {
  const item = evaluatorLevel?.find(({ value }) => value === level);
  return item?.label;
}
