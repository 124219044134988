import {
  GET_USER_EVALUATION_HISTORY,
  GET_USER_EVALUATION_LIST
} from './userEvaluationTypes';

const INITIAL_STATE = {
  userEvaluationHistory: [],
  userEvaluationList: []
};

export default function evaluationTemplatesReducer(
  state = INITIAL_STATE,
  { type, payload } = {}
) {
  switch (type) {
    case GET_USER_EVALUATION_HISTORY:
      return {
        ...state,
        userEvaluationHistory: payload
      };
    case GET_USER_EVALUATION_LIST:
      return {
        ...state,
        userEvaluationList: payload
      };
    default:
      return state;
  }
}
