import { SALARY_GRADES } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getSalaryGradesService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${SALARY_GRADES}`
    });
  } catch (error) {
    return error;
  }
};
