import { memo } from 'react';
import Box from 'src/components/v1/Common/Box';
import PropTypes from 'prop-types';
import TemplateSubSectionItem from './TemplateSubSectionItem';

const TemplateSubSections = ({
  sectionID,
  templateID,
  sectionIndex,
  isSectionEdit,
  subSectionFields,
  setSubSectionFields
}) => {
  const isNewSubSection = subSectionFields?.some(
    state => state.isNewSubSection === true
  );

  const handleAddSubSection = secId => {
    setSubSectionFields(prevState => [
      ...prevState,
      {
        id: null,
        title: '',
        fields: [],
        description: '',
        max_rating: null,
        isNewSubSection: true,
        sub_section_type_id: 2,
        evaluation_form_id: templateID,
        evaluation_section_id: parseInt(secId, 10),
        g1: 0,
        g2: 0,
        g3: 0,
        sg1: 0,
        sg2: 0,
        sg3: 0
      }
    ]);
  };

  return (
    <div className="evaluation__template-subsection-container">
      {subSectionFields?.length ? <h4>Sub-sections</h4> : null}
      <ul className="evaluation__template-subsection-list">
        {subSectionFields?.map((_, subSectionIndex) => (
          <li key={_.id} className="evaluation__template-subsection-item">
            <Box outline>
              <TemplateSubSectionItem
                sectionIndex={sectionIndex}
                isSectionEdit={isSectionEdit}
                subSectionIndex={subSectionIndex}
                subSectionFields={subSectionFields}
                setSubSectionFields={setSubSectionFields}
              />
            </Box>
          </li>
        ))}
      </ul>

      <div
        className={`evaluation__template-add-subsection${
          isSectionEdit || isNewSubSection ? ' disabled' : ''
        }`}
      >
        <button
          type="button"
          className="button button__add default"
          onClick={() => handleAddSubSection(sectionID)}
          disabled={isSectionEdit || isNewSubSection}
        >
          <span className="button__text">Add Subsection</span>
        </button>
      </div>
    </div>
  );
};

TemplateSubSections.propTypes = {
  sectionID: PropTypes.number,
  templateID: PropTypes.number,
  sectionIndex: PropTypes.number,
  isSectionEdit: PropTypes.bool,
  subSectionFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setSubSectionFields: PropTypes.func
};

export default memo(TemplateSubSections);
