import { ADMIN_EMPLOYMENT_STATUS } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getEmploymentStatusService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: ADMIN_EMPLOYMENT_STATUS
    });
  } catch (error) {
    return error;
  }
};
