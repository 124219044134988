import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import 'src/assets/css/summaryreports.scss';
// eslint-disable-next-line import/no-named-as-default
// eslint-disable-next-line import/no-named-as-default-member
import Datatable from 'src/components/Common/Datatable';
import {
  getReportSummaryFunction,
  getReportDetailFunction
} from 'src/api/v1/modules/projects';

const ReportsAssignmentSummary = () => {
  const [monthYear, setmonthYear] = useState();
  const selectRange = useRef(null);
  const [detailReportData, setdetailReportData] = useState([{}]);
  const [filteredDetailReportData, setfilteredDetailReportData] = useState([
    {}
  ]);

  const [summaryReportData, setsummaryReportData] = useState([{}]);
  const [
    filteredSummaryReportDataReportData,
    setfilteredSummaryReportDataReportData
  ] = useState([{}]);

  const strMonthyear = Moment(monthYear).format('MMMM-YYYY');

  const columns = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'position',
      label: 'Position'
    },
    {
      key: 'team',
      label: 'Team'
    },
    {
      key: 'level',
      label: 'Level'
    },
    {
      key: 'm1',
      label: 'Month 1'
    },
    {
      key: 'm2',
      label: 'Month 2'
    },
    {
      key: 'm3',
      label: 'Month 3'
    },
    {
      key: 'm4',
      label: 'Month 4'
    },
    {
      key: 'm5',
      label: 'Month 5'
    },
    {
      key: 'm6',
      label: 'Month 6'
    },
    {
      key: 'm7',
      label: 'Month 7'
    },
    {
      key: 'm8',
      label: 'Month 8'
    },
    {
      key: 'm9',
      label: 'Month 9'
    },
    {
      key: 'm10',
      label: 'Month 10'
    },
    {
      key: 'm11',
      label: 'Month 11'
    },
    {
      key: 'm12',
      label: 'Month 12'
    }
  ];

  const summaryColumns = [
    {
      key: 'team',
      label: 'Team'
    },
    {
      key: 'm1',
      label: 'Month 1'
    },
    {
      key: 'm2',
      label: 'Month 2'
    },
    {
      key: 'm3',
      label: 'Month 3'
    },
    {
      key: 'm4',
      label: 'Month 4'
    },
    {
      key: 'm5',
      label: 'Month 5'
    },
    {
      key: 'm6',
      label: 'Month 6'
    },
    {
      key: 'm7',
      label: 'Month 7'
    },
    {
      key: 'm8',
      label: 'Month 8'
    },
    {
      key: 'm9',
      label: 'Month 9'
    },
    {
      key: 'm10',
      label: 'Month 10'
    },
    {
      key: 'm11',
      label: 'Month 11'
    },
    {
      key: 'm12',
      label: 'Month 12'
    }
  ];

  useEffect(async () => {
    // Summary Report Data
    const summaryDetailReports = await getReportDetailFunction(
      Moment(monthYear).format('MM-YYYY')
    );
    const summaryReports = await getReportSummaryFunction(
      Moment(monthYear).format('MM-YYYY')
    );

    setdetailReportData(
      summaryDetailReports.data.data.map(user => {
        return {
          id: user.ID,
          name: user.Name,
          position: user.Position,
          team: user.Team,
          level: user.Level,
          m1: user.m1,
          m2: user.m2,
          m3: user.m3,
          m4: user.m4,
          m5: user.m5,
          m6: user.m6,
          m7: user.m7,
          m8: user.m8,
          m9: user.m9,
          m10: user.m10,
          m11: user.m11,
          m12: user.m12
        };
      })
    );

    setfilteredDetailReportData(
      detailReportData.filter(item => item.name !== undefined)
    );

    setsummaryReportData(
      summaryReports.data.data.map(summary => {
        return {
          team: summary.Team,
          m1: summary.m1,
          m2: summary.m2,
          m3: summary.m3,
          m4: summary.m4,
          m5: summary.m5,
          m6: summary.m6,
          m7: summary.m7,
          m8: summary.m8,
          m9: summary.m9,
          m10: summary.m10,
          m11: summary.m11,
          m12: summary.m12
        };
      })
    );

    setfilteredSummaryReportDataReportData(
      summaryReportData.filter(item => item.team !== undefined)
    );
  }, [Moment(monthYear).format('MM-YYYY')]);

  const GenerateReport = () => {
    alert(Moment(monthYear).format('MM-YYYY'));
  };

  return (
    <div>
      <h2>Cody Assignment Summary</h2>

      <div className="evaluation__breadcrumbs">
        <ul className="evaluation__breadcrumbs-list">
          <li className="evaluation__breadcrumbs-item">
            <Link to="/report">Reports</Link>
          </li>
          <li className="evaluation__breadcrumbs-item">
            Cody Assignment Summary
          </li>
        </ul>
      </div>

      <div className="summary-flex-container">
        <div>
          <select ref={selectRange} className="input">
            <option value=""> Select Range </option>
            <option value="1"> Six Months </option>
            <option value="2"> One Year </option>
          </select>
        </div>
        <div>
          <input
            onChange={e => setmonthYear(e.target.value)}
            type="month"
            id="start"
            className="input"
          />
        </div>
        <div>
          <input
            type="button"
            className="input summary-button"
            value="Generate"
            onClick={GenerateReport}
          />
        </div>
      </div>
      <br />
      <h3>Summary Report</h3>
      <Datatable
        datasource={filteredSummaryReportDataReportData}
        keyField="team"
        headingColumns={summaryColumns}
      />

      <h3>Detailed Report</h3>
      <Datatable
        datasource={filteredDetailReportData}
        keyField="id"
        headingColumns={columns}
      />
    </div>
  );
};

export default ReportsAssignmentSummary;
