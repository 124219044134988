import { useDispatch, useSelector } from 'react-redux';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { deleteBulkUserService } from 'src/api/modules/user';
import { getUserGroupsService } from 'src/api/modules/userGroups';
import { setUserGroups } from 'src/redux/modules/userGroups/userGroupActions';
import { getEmployeesService } from 'src/api/modules/employee';
import { getProjectsService } from 'src/api/modules/projects';
import { setEmployees } from 'src/redux/modules/employee/employeeActions';
import {
  setIdSelection,
  setIdDeletion
} from 'src/redux/modules/datatable/datatableActions';
import { setProjectList } from 'src/redux/modules/projects/projectActions';

export const useBulkDeleteModal = ({ handleBulkDeleteModal, endpoint }) => {
  const dispatch = useDispatch();
  const { ids } = useSelector(state => state.datatable);

  const fetchNewGroupList = async () => {
    getUserGroupsService(1)
      .then(res => {
        dispatch(setUserGroups(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const fetchNewEmployeeList = async () => {
    getEmployeesService(1)
      .then(res => {
        dispatch(setEmployees(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const fetchNewProjectList = async () => {
    getProjectsService(1)
      .then(res => {
        dispatch(setProjectList(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  const handleDelete = async () => {
    if (ids) {
      const res = await deleteBulkUserService(ids, endpoint);
      if (res.statusCode === 200) {
        dispatch(setIdDeletion(ids));
        fetchNewGroupList();
        fetchNewEmployeeList();
        fetchNewProjectList();
        dispatch(setIdSelection([]));
        toast.success('Successfully Deleted!', {
          icon: <CgCheckO />,
          toastId: Math.random()
        });
        handleBulkDeleteModal(null);
      } else
        toast.error('Unauthorized Deletion of Data.', {
          icon: <CgCloseO />
        });
    }
  };
  return {
    handleDelete
  };
};
