export const LOGIN = '/api/auth/signin';
export const DASHBOARD = '/api/user/dashboard';
export const USER_INFO = '/api/user/info';
export const USER_PROJECTS = '/api/user/projects';
export const USER_ALL = '/api/admin/users/all';
export const USER_SKILLS = '/api/user/skills';
export const USER_SKILL_CATEGORY_LIST = '/api/user/Skill-Category-List';
export const UPDATE_PASSWORD = '/api/user/update-password';
export const USER_DETAILS = '/api/admin/user_skills';
export const USER_DETAILS_BY_ID = '/api/admin/user/user-details';
export const USER_SKILLS_BY_ID = '/api/admin/user_skills';
export const USER_SKILL_BY_ID = '/api/admin/user_skill';
export const USER_INFO_BY_ID_MY_TEAM = '/api/admin/user/team_info';

export const INDUSTRY_ALL = '/api/admin/industry/all';
export const PROJECTS = '/api/admin/projects';
export const PROJECT_LIST = '/api/admin/projectlist';
export const PROJECT_MEMBER_BY_ID = '/api/admin/getProjectMemberByID';
export const EDIT_MEMBER = '/api/admin/editmember';
export const ALL_MEMBERS = '/api/admin/getallmembers';
export const ALL_MEMBERS_BY_ROLE = '/api/admin/getAllMembersByRole';
export const ALL_SKILLS = '/api/admin/allskills';
export const ALL_ROLES = '/api/admin/allroles';
export const REPORT_SUMMARY = '/api/dtr/getReportSummaryFunction';
export const REPORT_DETAIL = '/api/dtr/getReportDetailFunction';

// EVALUATIONS
export const USER_EVALUATION_HISTORY = 'api/user/evaluations';
export const USER_EVALUATION_LIST = 'api/user/for-evaluations';
export const TEMPLATES = '/api/admin/evaluations/templates';
export const SECTIONS = '/api/admin/evaluations/templates/sections';
export const SUB_SECTIONS =
  '/api/admin/evaluations/templates/sections/sub-sections';
export const FIELDS =
  '/api/admin/evaluations/templates/sections/sub-sections/fields';
export const SCHEDULES = '/api/admin/evaluations/schedules';
export const USER_EVALUATION_STATUS = '/api/user/evaluations/statuses';
export const SCHEDULES_VERIFICATION =
  '/api/admin/evaluations/schedules-verification';
export const USER_FOR_EVALUATIONS_BY_ID = '/api/user/for-evaluations';
export const USER_EVALUATIONS_BY_ID = '/api/user/evaluations';

export const ALL_COST_CENTER = '/api/costcenter/all';
export const COST_CENTER = '/api/costcenter';
export const ALL_DEPARTMENTS = '/api/departmentList/all';
export const DEPARTMENTS = '/api/departmentList';
export const ALL_DIVISIONS = '/api/divisions/all';
export const DIVISIONS = '/api/divisions';
export const ADMIN_SECTIONS = '/api/admin/sections';
export const ADMIN_SUB_SECTIONS = '/api/admin/sub-sections';
export const ADMIN_ROLES = '/api/admin/roles';
export const ADMIN_POSITIONS = '/api/admin/positions';
export const ADMIN_SALARY_GRADES = 'api/admin/salary-grades';
export const ADMIN_EMPLOYMENT_STATUS = '/api/admin/employment-status';
export const UPDATE_USER_PASSWORD = '/api/admin/users';
export const ADMIN_USER_IMAGE_UPDATE = '/api/admin/users/image_update';
export const UPDATE_COST_CENTER_BY_USER = '/api/user/team_info/cost_center';
export const ADMIN_ADD_USER = '/api/admin/users';
export const ADMIN_PROJECT_TEAM_INFO = '/api/admin/user/project_team_info';
export const ADMIN_USER_INFO_EDIT_IS_EXEMPTED =
  '/api/admin/users/user-info/edit-is-excempted';
