import { useCallback } from 'react';
import Checkbox from 'src/components/Common/Filter/Checkbox';
import Date from 'src/components/Common/Filter/Date';
// eslint-disable-next-line import/no-named-as-default
import CheckboxMultiSelect from 'src/components/Common/Filter/CheckboxMultiSelect';
import Dropdown from 'src/components/Common/Filter/Dropdown';

const useFilter = ({ form, submitFilter, isOpen, dateOnChange }) => {
  const handleFiltersDisplay = useCallback(
    input => {
      switch (input.type) {
        case 'checkbox': {
          return (
            <Checkbox
              form={form}
              name={input?.name}
              item={input?.options}
              submitFilter={submitFilter}
            />
          );
        }
        case 'multiselect': {
          return (
            <CheckboxMultiSelect
              form={form}
              name={input?.name}
              item={input.multiOptions}
              submitFilter={submitFilter}
              selected={input?.defaultValue || ['A']}
              isOpen={isOpen}
            />
          );
        }
        case 'date-range': {
          return (
            <Date
              form={form}
              item={input.options}
              submitFilter={submitFilter}
              dateOnChange={dateOnChange}
            />
          );
        }
        case 'dropdown': {
          return (
            <Dropdown
              form={form}
              name={input?.name}
              item={input?.options}
              submitFilter={submitFilter}
            />
          );
        }
        default: {
          return null;
        }
      }
    },
    [form, isOpen]
  );

  return {
    handleFiltersDisplay
  };
};

export default useFilter;
