import { api, auth } from './config';

export const fetchAPI = async ({
  body = null,
  params = null,
  method = '',
  endpoint = '',
  info = false,
  headers = {},
  isExport = false,
  onUploadProgress = null
}) => {
  const config = { ...auth(headers), ...params, onUploadProgress };
  const parameters = { body, params, method, endpoint, config };

  async function getMethod() {
    switch (method) {
      case 'LOGIN':
        return await api.post(`${endpoint}`, body);
      case 'POST':
        return await api.post(`${endpoint}`, body, config);
      case 'GET':
        return await api.get(`${endpoint}`, config);
      case 'PUT':
        return await api.put(`${endpoint}`, body, config);
      case 'PATCH':
        return await api.patch(`${endpoint}`, body, config);
      case 'DELETE':
        return await api.delete(`${endpoint}`, config);
      default:
        return null;
    }
  }

  async function getResponse() {
    try {
      if (isExport) {
        return await getMethod();
      }
      const { status, statusText, data, duration } = await getMethod();
      return status === 200 && statusText === 'OK'
        ? { ...data, success: true, details: { ...parameters, duration } }
        : null;
    } catch (error) {
      return error;
    }
  }

  // eslint-disable-next-line
  if (info) console.warn('[API] Parameters Details:', parameters);

  return getResponse();
};

api.interceptors.request.use(
  config => {
    const newConfig = { ...config };
    newConfig.metadata = { startTime: new Date() };
    return newConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    const newRes = { ...response };
    newRes.config.metadata.endTime = new Date();
    newRes.duration =
      newRes.config.metadata.endTime - newRes.config.metadata.startTime;
    return newRes;
  },
  error => {
    const newError = { ...error };
    newError.config.metadata.endTime = new Date();
    newError.duration =
      newError.config.metadata.endTime - newError.config.metadata.startTime;
    if (
      error.config.method === 'get' &&
      newError.response.data &&
      newError.response.data.statusCode === 401
    ) {
      window.location.href = '/access-denied';
    }
    return Promise.reject(newError);
  }
);
