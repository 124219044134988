import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'src/components/Common/Button';
import Moment from 'moment';
import { ModalCenter, AverageLabel } from 'src/components/Common';
import { cn, calculateAveragePercentage } from 'src/helpers/utils';
import { CgTimer } from 'react-icons/cg';
import TakeAssessmentModal from './TakeAssessmentModal';

const ViewMyAssessment = ({
  userAssessmentId,
  menuCode,
  myAssessmentInfo,
  showConfirmModal,
  handleTakeAssessment
}) => {
  const navigate = useNavigate();
  return (
    <div className="border-solid border-[1px] border-[#dedede] p-[31px_30px_28px_30px] rounded-[3px] mb-5">
      <div className="flex justify-between w-auto pb-[20px]">
        <h4 className="text-2xl font-stolzlMedium">
          {myAssessmentInfo.assessment.title}
        </h4>
        {['A', 'N'].includes(myAssessmentInfo.status) ? (
          <Button
            name={
              !myAssessmentInfo.user_assessment_attempts.length
                ? 'Take Assessment'
                : 'Resume Assessment'
            }
            modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-auto hover:bg-gray-500"
            spanModifier="relative before:hidden !pl-0"
            type="button"
            onClick={() =>
              handleTakeAssessment('TakeAssessmentModal', userAssessmentId)
            }
          />
        ) : (
          <Button
            name="View Results"
            modifier="bg-[#232932] text-white p-[8px_14px] text-[12px] leading-[24px] rounded w-auto hover:bg-gray-500"
            spanModifier="relative before:hidden !pl-0"
            type="button"
            onClick={() =>
              navigate(
                `/my-assessments/results/${myAssessmentInfo.assessment.id}/${userAssessmentId}`
              )
            }
          />
        )}
      </div>
      <p className="text-sm font-light font-stolzlBook leading-[20px]">
        {myAssessmentInfo.assessment.content}
      </p>
      <div className="flex gap-12 text-[#444444] text-sm font-stolzlBook leading-[14px] mt-8">
        <p>
          Date Created:
          <strong className="ml-2">
            {Moment(myAssessmentInfo.start_date).format('MMMM D YYYY')}
          </strong>
        </p>
        <p>
          Deadline:
          <strong className="ml-2">
            {Moment(myAssessmentInfo.end_date).format('MMMM D YYYY')}
          </strong>
        </p>
      </div>
      <div className="mt-6">
        {myAssessmentInfo.assessment.quizzes.map((item, i) => (
          <div
            key={`data-key-${i + 1}`}
            className={cn(
              'rounded mb-4 bg-[#f3f3f3] p-4 w-full max-w-[700px] flex justify-between items-center',
              item.user_attempt?.started_at && 'bg-[#D9F0DA]',
              item.user_attempt?.finished_at && 'bg-[#E3ECFF]'
            )}
          >
            <div>
              <small className="font-light font-stolzlBook text-[#444444]">
                Section {i + 1}
              </small>
              <h5 className="text-[18px] font-stolzlMedium">{item.title}</h5>
              <div className="flex gap-8 mt-2 text-[#444444]">
                <p className="text-[14px]">
                  <span className="font-light font-stolzlBook mr-1">
                    Questions:
                  </span>
                  <strong>{Object.keys(item.questions).length}</strong>
                </p>
                <p className="text-[14px]">
                  <span className="font-light font-stolzlBook mr-1">
                    Duration:
                  </span>
                  <strong>{item.duration} mins</strong>
                </p>
                {item.user_attempt?.finished_at &&
                  item.user_attempt.score !== null && (
                    <p className="text-[14px]">
                      <span className="font-light font-stolzlBook mr-1">
                        Score:
                      </span>
                      <strong>
                        {item.user_attempt.score}/
                        {item.user_attempt.total_quiz_points}
                      </strong>
                    </p>
                  )}
              </div>
            </div>
            <div>
              {item.user_attempt?.started_at &&
                item.user_attempt?.score === null && (
                  <CgTimer size="60" color="#266028" className="opacity-30" />
                )}
              {item.user_attempt?.finished_at &&
                item.user_attempt.score !== null && (
                  <AverageLabel
                    className="font-stolzBold text-2xl"
                    value={calculateAveragePercentage(
                      item.user_attempt.score,
                      item.user_attempt.total_quiz_points
                    )}
                  />
                )}
            </div>
          </div>
        ))}
      </div>
      <ModalCenter showModal={showConfirmModal} modalName="TakeAssessmentModal">
        {showConfirmModal && (
          <TakeAssessmentModal
            handleTakeAssessment={handleTakeAssessment}
            myAssessmentInfo={myAssessmentInfo}
            modal={showConfirmModal}
          />
        )}
      </ModalCenter>
    </div>
  );
};

ViewMyAssessment.propTypes = {
  userAssessmentId: PropTypes.string,
  menuCode: PropTypes.string,
  handleTakeAssessment: PropTypes.func,
  showConfirmModal: PropTypes.string,
  myAssessmentInfo: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    status: PropTypes.string,
    assessment: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      content: PropTypes.string,
      quizzes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          duration: PropTypes.number,
          code: PropTypes.string,
          questions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              type: PropTypes.string,
              content: PropTypes.string,
              points: PropTypes.number,
              status: PropTypes.string
            })
          )
        })
      )
    }),
    user_assessment_attempts: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

export default ViewMyAssessment;
