import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isEqual } from 'src/helpers/v1/utils';
import { FIELDS } from 'src/api/v1/endpoints';
import {
  createEvaluationTemplate,
  updateEvaluationTemplateById,
  deleteEvaluationTemplateById
} from 'src/redux/v1/modules/evaluations/templates/templatesActions';
import Box from 'src/components/v1/Common/Box';
import KebabMenu from 'src/components/v1/Common/KebabMenu';
import LabeledText from 'src/components/v1/Common/LabeledText';
import useKeypressEscape from 'src/hooks/v1/useKeypressEscape';
import PropTypes from 'prop-types';
import TemplateScoreWeights from './TemplateScoreWeights';
import {
  numberOnly,
  setScoreWeights,
  detectSalaryGrade
} from './TemplateHelpers';

const TemplateFieldItem = ({
  fields,
  setFields,
  fieldIndex,
  sectionIndex,
  subSectionIndex
}) => {
  const dispatch = useDispatch();
  const questions = [...fields];
  const question = questions[fieldIndex];
  const isNewField = question?.isNewField;
  const sectionType = question?.sectionType;
  const questionTitleRef = useRef(null);
  const questionDescriptionRef = useRef(null);
  const [copy, setCopy] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(true);
  const [questionWeights, setQuestionWeights] = useState(null);

  useKeypressEscape({
    isEdit: isEditQuestion,
    cancelEdit: () => {
      const { title, description, g1, g2, g3, sg1, sg2, sg3 } = question;
      const weightData = { g1, g2, g3, sg1, sg2, sg3 };

      if (isNewField) {
        if (sectionType === 3 && !!question.description?.length) {
          question.description = '';
          setFields(questions);
        } else {
          question.title = '';
          question.description = '';
          setFields(questions);
        }
      }

      if (!isNewField) {
        setIsEditQuestion(false);
        const typeOneTwo = { title, description, ...weightData };
        const typeThree = { question: description, ...weightData };
        if (sectionType === 3) {
          if (!isEqual(typeThree, copy)) {
            toast.warn('Changes not saved! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
            question.description = copy?.question;
            setFields(questions);
          }
        } else if (!isEqual(typeOneTwo, copy)) {
          toast.warn('Changes not saved! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          question.title = copy?.title;
          question.description = copy?.description;
          question.g1 = copy?.g1;
          question.g2 = copy?.g2;
          question.g3 = copy?.g3;
          question.sg1 = copy?.sg1;
          question.sg2 = copy?.sg2;
          question.sg3 = copy?.sg3;
          setFields(questions);
        }
      }
    }
  });

  useEffect(() => {
    copyData();
    if (isNewField) {
      if (SECTION_TYPE === 3) questionDescriptionRef?.current.focus();
      else questionTitleRef.current.focus();
    }
    const { g1, g2, g3, sg1, sg2, sg3 } = question;
    const weights = { g1, g2, g3, sg1, sg2, sg3 };
    setQuestionWeights(Object.values(weights));
  }, []);

  useEffect(() => {
    setIsEditQuestion(!isNewField);
  }, [isNewField]);

  async function handleRemoveFields(fieldID) {
    try {
      const { success } = await dispatch(
        deleteEvaluationTemplateById(FIELDS, fieldID)
      );
      if (success) {
        toast.success('Question successfully deleted! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
        setFields(questions?.filter(field => field?.id !== fieldID));
      } else {
        toast.error('Something went wrong! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }
    return undefined;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSaveLoading(true);

    const { id, title, description, sub_section_id } = question;
    const weightData = { g1, g2, g3, sg1, sg2, sg3 };

    if (isNewField && id === null) {
      const newField =
        sectionType === 3
          ? { sub_section_id, question: description }
          : { sub_section_id, title, description };

      try {
        const { data, success } = await dispatch(
          createEvaluationTemplate(FIELDS, newField)
        );
        if (success) {
          toast.success('Question successfully created! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          question.isNewField = false;
          question.id = data?.id;
          setFields(questions);
          copyData();
          setSaveLoading(false);
          setIsEditQuestion(false);
          setScoreWeights(evaluation_form_id, 'field', data?.id, {
            ...weightData
          });
        } else {
          toast.error('Something went wrong! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
        }
      } catch (error) {
        return error;
      }
    }

    if (!isNewField) {
      const weightCopy = {
        g1: copy?.g1,
        g2: copy?.g2,
        g3: copy?.g3,
        sg1: copy?.sg1,
        sg2: copy?.sg2,
        sg3: copy?.sg3
      };

      const updateField =
        sectionType === 3 ? { question: description } : { title, description };

      const formData = { ...updateField, ...weightData };

      if (isEqual(formData, copy)) {
        setSaveLoading(false);
        setIsEditQuestion(false);
      } else {
        try {
          const { success } = await dispatch(
            updateEvaluationTemplateById(FIELDS, id, updateField)
          );
          if (success) {
            toast.success('Question successfully updated! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
            setSaveLoading(false);
            setIsEditQuestion(false);
            copyData();
            if (isEqual(weightData, weightCopy)) return undefined;
            setScoreWeights(evaluation_form_id, 'field', id, { ...weightData });
          } else {
            toast.error('Something went wrong! ', {
              autoClose: 1200,
              newestOnTop: true,
              hideProgressBar: true
            });
          }
        } catch (error) {
          return error;
        }
      }
    }

    return undefined;
  }

  const handleChange = useCallback(({ target: { name, value, maxLength } }) => {
    const getValue = numberOnly(name) ? Number(value) : value;
    question[name] = getValue;
    setFields(questions);

    if (detectSalaryGrade(name)) {
      const integer = Number(value.slice(0, maxLength));
      const positiveOnly = integer >= 0 ? integer : 0;
      const hundredOnly = positiveOnly <= 100 ? positiveOnly : 100;
      question[name] = parseInt(hundredOnly, 10);
      setFields(questions);
    }
  });

  function copyData() {
    const { title, description } = question;
    if (sectionType === 3)
      setCopy({ question: description, g1, g2, g3, sg1, sg2, sg3 });
    else setCopy({ title, description, g1, g2, g3, sg1, sg2, sg3 });
  }

  if (!question) return null;

  const {
    id,
    title: TITLE,
    description: DESCRIPTION,
    sectionType: SECTION_TYPE,
    g1,
    g2,
    g3,
    sg1,
    sg2,
    sg3,
    evaluation_form_id
  } = question;

  const title = TITLE === '' ? '' : TITLE;
  const description = DESCRIPTION === '' ? '' : DESCRIPTION;
  const isDescriptionFilled = Boolean(description);
  const isFilled = Boolean(title) && Boolean(description);
  const notCommentField = SECTION_TYPE === 1 || SECTION_TYPE === 2;

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {notCommentField ? (
          <>
            <div className="evaluation__row --nowrap">
              <div className="evaluation__column evaluation__column--three xl">
                <label
                  className="evaluation__label"
                  htmlFor={`question_title_${sectionIndex}${subSectionIndex}${fieldIndex}`}
                >
                  Question {fieldIndex + 1} Title
                  {isEditQuestion ? (
                    <input
                      className="evaluation__input"
                      id={`question_title_${sectionIndex}${subSectionIndex}${fieldIndex}`}
                      name="title"
                      type="text"
                      value={title ?? ''}
                      disabled={saveLoading}
                      onChange={handleChange}
                      ref={questionTitleRef}
                    />
                  ) : (
                    <LabeledText text={title} />
                  )}
                </label>
              </div>
              <div className="evaluation__column evaluation__column--three forty" />
              <div className="evaluation__column evaluation__column--three two">
                {isEditQuestion ? (
                  <div className="evaluation__button evaluation__button--save-field">
                    <button
                      type="submit"
                      className={`button button__save dark${
                        !isFilled ? ' disabled' : ''
                      }`}
                      disabled={!isFilled}
                    >
                      {saveLoading ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        <span className="button__text" />
                      )}
                    </button>
                  </div>
                ) : (
                  <KebabMenu
                    options={[
                      {
                        label: 'Edit Question',
                        onClick: () => setIsEditQuestion(true)
                      },
                      {
                        label: 'Delete Question',
                        onClick: () => handleRemoveFields(id)
                      }
                    ]}
                    evaluation
                  />
                )}
              </div>
            </div>
            <div className="evaluation__row">
              <div className="evaluation__column evaluation__column--two seventy-six">
                <label
                  className="evaluation__label"
                  htmlFor={`question_description_${sectionIndex}${subSectionIndex}${fieldIndex}`}
                >
                  Question {fieldIndex + 1} Description
                </label>
                {isEditQuestion ? (
                  <textarea
                    className="evaluation__input evaluation__input--textarea"
                    name="description"
                    id={`question_description_${sectionIndex}${subSectionIndex}${fieldIndex}`}
                    value={description}
                    disabled={saveLoading}
                    onChange={handleChange}
                  />
                ) : (
                  <LabeledText text={description} templateDescription />
                )}
              </div>
            </div>
            <div className="evaluation__row">
              <div className="evaluation__column evaluation__column--sixty">
                <TemplateScoreWeights
                  type="field"
                  item={question}
                  loading={saveLoading}
                  itemIndex={fieldIndex}
                  isEdit={isEditQuestion}
                  weights={questionWeights}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="evaluation__row --nowrap">
              <div className="evaluation__column evaluation__column--three xl">
                <label
                  className="evaluation__label"
                  htmlFor={`question_description_${sectionIndex}${subSectionIndex}${fieldIndex}`}
                >
                  Question {fieldIndex + 1} Description
                </label>
                {isEditQuestion ? (
                  <textarea
                    className="evaluation__input evaluation__input--textarea"
                    name="description"
                    id={`question_description_${sectionIndex}${subSectionIndex}${fieldIndex}`}
                    value={description}
                    disabled={saveLoading}
                    onChange={handleChange}
                    ref={questionDescriptionRef}
                  />
                ) : (
                  <LabeledText text={description} templateDescription />
                )}
              </div>
              <div className="evaluation__column evaluation__column--three forty" />
              <div className="evaluation__column evaluation__column--three two">
                {isEditQuestion ? (
                  <div className="evaluation__button evaluation__button--save-field">
                    <button
                      type="submit"
                      className={`button button__save dark${
                        !isDescriptionFilled ? ' disabled' : ''
                      }`}
                      disabled={!isDescriptionFilled}
                    >
                      {saveLoading ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        <span className="button__text" />
                      )}
                    </button>
                  </div>
                ) : (
                  <KebabMenu
                    options={[
                      {
                        label: 'Edit Question',
                        onClick: () => setIsEditQuestion(true)
                      },
                      {
                        label: 'Delete Question',
                        onClick: () => handleRemoveFields(id)
                      }
                    ]}
                    evaluation
                  />
                )}
              </div>
            </div>
            <div className="evaluation__row">
              <div className="evaluation__column evaluation__column--sixty">
                <TemplateScoreWeights
                  type="field"
                  item={question}
                  loading={saveLoading}
                  itemIndex={fieldIndex}
                  isEdit={isEditQuestion}
                  weights={questionWeights}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </>
        )}
      </form>
    </Box>
  );
};

TemplateFieldItem.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  setFields: PropTypes.func,
  fieldIndex: PropTypes.number,
  sectionIndex: PropTypes.number,
  subSectionIndex: PropTypes.number
};

export default memo(TemplateFieldItem);
