import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import Datatable from 'src/components/Common/Datatable';
import SortTable from 'src/components/Common/SortTable';
import 'src/assets/css/skillsList.scss';
import { fetchAPI } from 'src/api/fetchAPI';

const SkillsList = () => {
  const [userSkill, setUserSkill] = useState([]);
  const [newSkill, setNewSkill] = useState([]);

  const fetchUserData = async () => {
    try {
      const { data, success } = await fetchAPI({
        method: 'GET',
        endpoint: `/api/user/skills`
      });

      if (success) {
        setUserSkill(data);
      }
    } catch (error) {
      return error;
    }

    return null;
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    const skills = [
      userSkill?.map(
        ({
          rating,
          skill: {
            SkillCategory: { name: categoryName },
            name: skillName
          }
        }) => ({
          category: categoryName,
          skill: skillName,
          rating: rating.toString()
        })
      )
    ];

    setNewSkill(skills);
  }, [userSkill]);

  const renderList = (
    <div className="skillsList__container">
      <Datatable
        datasource={newSkill}
        title="All Skillset"
        headingColumns={[
          { key: 'category', label: 'Category' },
          { key: 'skill', label: 'Skill' },
          { key: 'rating', label: 'Rating' }
        ]}
        breakOn="small"
      />
    </div>
  );

  return renderList;
};

export default SkillsList;
