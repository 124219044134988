import React from 'react';
import { PageTitle, SearchFilter, Breadcrumbs } from 'src/components/Common';
import Filter from 'src/components/Common/Filter/Filter';
import Datatable from 'src/components/Common/Datatable';
import InfiniteScroll from 'react-infinite-scroll-component';
import useEvaluationEntry from 'src/hooks/Pages/admin/evaluation/useEvaluationEntry';
import PropTypes from 'prop-types';

const EvaluationEntry = ({ menuCode }) => {
  const {
    evaluationEntryList,
    evaluationListMemo,
    hasMore,
    evaluationEntryColumns,
    form,
    access,
    fetchData,
    submitFilter,
    handleViewPage
  } = useEvaluationEntry();
  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Evaluation' }, { name: 'Evaluation Entry' }]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto">
          <Filter
            searchInputPlaceholder="Template Name, Evaluator Name, Cost Center"
            showActionBtn={false}
            showDeleteBtn={false}
            buttonName=""
            buttonLink={false}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={evaluationListMemo?.length ?? 0}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5">Loading...</h4>}
            style={{ overflow: 'visible' }}
            endMessage={
              <p className="text-center mt-5">
                <b>
                  {evaluationListMemo?.length !== 0
                    ? 'No more data to load'
                    : 'No records found'}
                </b>
              </p>
            }
          >
            {evaluationEntryColumns.length === 0 ? null : (
              <Datatable
                shouldDisplayEditable={false}
                datasource={evaluationListMemo || []}
                clickableRows={false}
                headingColumns={evaluationEntryColumns}
                title="Evaluation Entry"
                handleViewModal={handleViewPage}
                actions={['view']}
                access={access}
                shouldEllipsis
                onExport={false}
                isExport={false}
              />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

EvaluationEntry.propTypes = {
  menuCode: PropTypes.string
};

export default EvaluationEntry;
