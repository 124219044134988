import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getLowerCasedValue, filterData } from 'src/helpers/utils';

import {
  updateQuizQuestionsByIdService,
  getQuizQuestionItemsService
} from 'src/api/modules/quiz';

import { toast } from 'react-toastify';
import { STATUS_OPTIONS } from 'src/helpers/constants';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { getAllQuestions } from 'src/redux/modules/questions/questionActions';

const useQuizQuestionIndex = quiz_id => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: undefined });
  const questionsRef = useRef(null);

  const allQuestion = useSelector(state => state.questions.allQuestionsList);

  const [searchQuestion, setSearchQuestion] = useState([]);
  const [addedQuestion, setAddedQuestion] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const questionDataList = useSelector(state => state?.questions?.questionList);
  const [questionList, setQuestionList] = useState([]);
  const [questionTitle, setQuestionTitle] = useState(null);
  const [modalQuestionId, setModalQuestionId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showAddModal, setShowAddModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [questionFields, setQuestionFields] = useState([]);
  const statusOptions = [
    {
      value: 'A',
      label: 'Active'
    },
    {
      value: 'N',
      label: 'New'
    },
    {
      value: 'C',
      label: 'Cancelled'
    },
    {
      value: 'F',
      label: 'Fulfilled'
    },
    {
      value: 'O',
      label: 'Onhold'
    }
  ];

  const questionColumns = [
    { key: 'id', label: 'ID' },
    { key: 'content', label: 'Question' },
    { key: 'type', label: 'Type' },
    { key: 'points', label: 'Points' },
    { key: 'level', label: 'Difficulty' },
    { key: 'status', label: 'Status' }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status'
    }
  ];

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-question-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-quiz-question-list-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);

      const res = await getQuizQuestionItemsService(
        quiz_id,
        savedPageIndex,
        params
      );

      let questions = res.data.items.map(o => {
        return {
          id: o.id,
          content: o.content,
          skill_name: o.skill.name,
          type: o.type,
          points: o.points.toString(),
          level: o.level.toString(),
          status: o.status,
          is_multi_answer: o.is_multi_answer,
          answers: o.answers
        };
      });
      if (savedPageIndex > 1) {
        questions = questionList.concat(questions);
      }
      setQuestionList(questions);

      if (
        res.data.items.length !== 0 &&
        res.data.current_page < res.data.total_pages
      ) {
        setPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error('Error fetching data');
    }
  }, [
    page,
    getQuizQuestionItemsService,
    setQuestionList,
    setPage,
    setHasMore,
    questionList
  ]);

  const questionListMemo = useMemo(() => {
    return questionList ?? [];
  }, [questionList]);

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-question-params', searchParams);
  };
  const handleSearchQuestions = e => {
    let foundQuestion = [];
    const {
      target: { name, value }
    } = e;
    if (value.length) {
      foundQuestion = filteredQuestions.items.filter(data => {
        const contentMatches = `${getLowerCasedValue(data.content)}`.includes(
          getLowerCasedValue(e.target.value)
        );

        return contentMatches && !addedQuestion.some(q => q.id === data.id);
      });
    }

    setSearchQuestion(foundQuestion);
  };

  const handleDeleteSearchModal = id => {
    setAddedQuestion(addedQuestion.filter(question => question.id !== id));
  };

  const handleAddQuestion = e => {
    const { content, id, level, points, type, is_multi_answer, skill } = e;
    const questionInfo = {
      content,
      id,
      level,
      points,
      type,
      is_multi_answer,
      skill
    };

    setAddedQuestion(prevState => [...prevState, questionInfo]);

    setSearchQuestion([]);
    questionsRef.current.value = null;
  };

  const handleModal = useCallback(
    (modal, id = null, data = null) => {
      setShowModal(modal);
      setQuestionFields(data);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal]
  );

  const handleAddModal = useCallback(
    (modal, id = null, data = null) => {
      setAddedQuestion([]);
      setShowAddModal(modal);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowAddModal]
  );

  const handleQuestionAddSubmit = useCallback(async quizId => {
    const ids = addedQuestion.map(obj => obj.id);
    if (quizId) {
      const updateResponse = await updateQuizQuestionsByIdService(quizId, {
        ids
      });
      if (updateResponse.status === 201) {
        toast.success('Successfully Updated!', { icon: <CgCheckO /> });
        navigate(`/quiz-manager/${quizId}/questions`);
      } else {
        toast.error('Unable to update!', { icon: <CgCloseO /> });
      }
    }
    handleModal();
    handleAddModal();
    fetchData();
  });

  const handleDeleteModal = useCallback(
    ({ modal, id, name }) => {
      setModalQuestionId(modal ? id : null);
      setQuestionTitle(modal ? name : null);
      setShowDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      fetchData();
    },
    [setShowDeleteModal, setModalQuestionId, setQuestionTitle]
  );

  const handleBulkDeleteModal = useCallback(
    modal => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      fetchData();
    },
    [setShowBulkDeleteModal]
  );

  useEffect(() => {
    const questionIdsToRemove = new Set(questionList.map(q => q.id));
    const newList = allQuestion
      ? allQuestion.filter(q => !questionIdsToRemove.has(q.id))
      : [];

    // Create a new object with filtered questions
    const newQuestionsObject = {
      total_items: newList.length,
      items: newList,
      current_page: allQuestion?.current_page,
      total_pages: allQuestion?.total_pages
    };
    setFilteredQuestions(newQuestionsObject);
  }, [questionList, allQuestion]);

  useEffect(() => {
    localStorage.setItem('hris-quiz-question-list-page-no', JSON.stringify(1));
    const savedSearchParams = localStorage.getItem('hris-question-params');
    const savedPageIndex = localStorage.getItem(
      'hris-quiz-question-list-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      form.reset(Object.fromEntries(parsedSearchParams));
      // fetchData();
    }
  }, []);

  useEffect(() => {
    if (location.search !== '') {
      setPage(1);
      localStorage.setItem(
        'hris-quiz-question-list-page-no',
        JSON.stringify(1)
      );
      setHasMore(true);
      fetchData();
    }
  }, [location.search, questionDataList]);

  useEffect(() => {
    localStorage.setItem(
      'hris-quiz-question-list-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  useEffect(() => {
    dispatch(getAllQuestions());
  }, []);

  return {
    showDeleteModal,
    questionList,
    page,
    hasMore,
    questionColumns,
    form,
    questionListMemo,
    submitFilter,
    handleDeleteModal,
    fetchData,
    handleBulkDeleteModal,
    showBulkDeleteModal,
    questionTitle,
    modalQuestionId,
    inputs,
    showModal,
    questionId,
    handleModal,
    questionFields,
    statusOptions,
    showViewModal,
    handleAddModal,
    showAddModal,
    searchQuestion,
    handleAddQuestion,
    questionsRef,
    handleSearchQuestions,
    addedQuestion,
    handleDeleteSearchModal,
    handleQuestionAddSubmit
  };
};

export default useQuizQuestionIndex;
