import React from 'react';
import { useNavigate } from 'react-router-dom';

// Common components
import { Breadcrumbs } from 'src/components/Common';
import Datatable from 'src/components/Common/Datatable';
import Filter from 'src/components/Common/Filter/Filter';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

// hooks
import { useUserAccess } from 'src/hooks/useUserAccess';
import useMyAssessmentIndex from 'src/hooks/Pages/LearningManagement/MyAssessment/useMyAssessmentIndex';

const Index = ({ menuCode }) => {
  const { access } = useUserAccess(menuCode);
  const navigate = useNavigate();

  const {
    myAssessmentListMemo,
    hasMore,
    myAssessmentColumns,
    form,
    submitFilter,
    fetchData,
    inputs
  } = useMyAssessmentIndex(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[{ name: 'Learning Management' }, { name: 'My Assessments' }]}
      />

      <div className="mt-5">
        <div className="filter__content--search overflow-auto min-h-[320px]">
          <Filter
            searchInputPlaceholder="Search"
            showActionBtn={false}
            inputs={inputs}
            form={form}
            submitFilter={submitFilter}
            menuCode={menuCode}
          />
          <InfiniteScroll
            dataLength={myAssessmentListMemo?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4 className="text-center mt-5 mb-[35px]">Loading...</h4>}
            endMessage={
              <p className="text-center mt-5">
                {!myAssessmentListMemo.length && <b>No records found</b>}
                {myAssessmentListMemo.length >= 20 && (
                  <b>No more data to load</b>
                )}
              </p>
            }
          >
            <Datatable
              shouldDisplayEditable={false}
              datasource={myAssessmentListMemo || []}
              clickableRows={false}
              headingColumns={myAssessmentColumns}
              title="My Assessments"
              actions={['view']}
              actionLinks={{
                view: '/my-assessments/view'
              }}
              keyField="id"
              noPadding
              access={access}
              onExport={false}
              isExport={false}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

Index.propTypes = {
  menuCode: PropTypes.string
};

export default Index;
