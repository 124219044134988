import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import useKeypressEscape from 'src/hooks/v1/useKeypressEscape';
import {
  filterData,
  isEqual,
  formatDate,
  getCostCenter
} from 'src/helpers/v1/utils';
import { fetchAPI } from 'src/api/v1/fetchAPI';
import { SCHEDULES } from 'src/api/v1/endpoints';
import Breadcrumbs from 'src/components/v1/Common/Breadcrumbs';
import KebabMenu from 'src/components/v1/Common/KebabMenu';
import Box from 'src/components/v1/Common/Box';
import PageTitle from 'src/components/v1/Common/PageTitle';
import SectionTitle from 'src/components/v1/Common/SectionTitle';
import LabeledText from 'src/components/v1/Common/LabeledText';
import SearchFilter from 'src/components/v1/Common/SearchFilter';

import {
  getEvaluationSchedules,
  getEvaluationScheduleById,
  getEvaluationScheduleUsersById,
  updateEvaluationScheduleById,
  deleteEvaluationScheduleById,
  updateEvaluationScheduleUserStatus,
  updateEvaluatorPerSectionById
} from 'src/redux/v1/modules/evaluations/schedules/schedulesActions';
import { manageEvaluationScheduleColumns } from './columns';
import {
  isExempted,
  setScheduleStatus,
  formatScheduleDate,
  getScheduleStatusID
} from './EvaluationScheduleHelpers';

const EvaluationScheduleDetail = () => {
  const { id: evaluationScheduleID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copy, setCopy] = useState(null);
  const [users, setUsers] = useState(null);
  const [codies, setCodies] = useState(null);
  const [loading, setLoading] = useState(true);
  const [maxCount, setMaxCount] = useState(null);
  const [isChecked, isSetChecked] = useState(false);
  const [evaluators, setEvaluators] = useState(null);
  const [isEditSchedule, setIsEditSchedule] = useState(null);
  const [scheduleDataAPI, setScheduleDataAPI] = useState(null);
  const [userEvaluationList, setUserEvaluationList] = useState([]);
  const userEvaluationScheduleStatuses = useSelector(
    ({ evaluationSchedule }) => evaluationSchedule?.userEvaluationScheduleStatus
  );
  const allUsers = useSelector(({ user }) => user?.users);
  const [scheduleData, setScheduleData] = useState({
    schedule_name: '',
    schedule_description: '',
    evaluation_start_date: '1900-01-01',
    evaluation_end_date: '1900-01-01',
    include_exempted_user: 0
  });

  let GENERATING = true;
  const tableColumns = [
    ...manageEvaluationScheduleColumns,
    {
      key: 'status',
      label: 'Status',
      type: 'select',
      placeholder: 'Select Status',
      selectOptions: userEvaluationScheduleStatuses?.map(({ id, name }) => ({
        label: name,
        value: id
      })),
      onChangeFn: async ({ data: { evaluation_id, status } }) => {
        const user = userEvaluationList?.find(
          userEvaluation => userEvaluation?.id === evaluation_id
        );
        const {
          user_evaluation_status: { name: apiStatus }
        } = user;
        if (!isEqual(status, apiStatus)) {
          updateUserEvaluationStatus(evaluation_id, status, user);
        }
      }
    }
  ];

  useKeypressEscape({
    isEdit: isEditSchedule,
    cancelEdit: () => {
      const { id, ...formData } = scheduleData;
      if (!isEqual(formData, copy)) {
        toast.warn('Changes not saved! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
      const {
        schedule_name,
        schedule_description,
        evaluation_start_date,
        evaluation_end_date,
        include_exempted_user
      } = copy;
      setScheduleData(prevState => ({
        ...prevState,
        schedule_name,
        schedule_description,
        evaluation_start_date,
        evaluation_end_date,
        include_exempted_user
      }));
      setIsEditSchedule(false);
    }
  });

  const fetchUserEvaluationInfo = useCallback(() => {
    (async () => {
      const { data, success } = await dispatch(
        getEvaluationScheduleUsersById(evaluationScheduleID)
      );
      if (success) {
        const { user_evaluations_list } = data;
        setUserEvaluationList(user_evaluations_list);
        const lists = user_evaluations_list?.sort(
          (a, b) => (a?.id || 0) - (b?.id || 0)
        );
        const sectionCount = [
          ...(lists?.map(({ id, ...rest }) => {
            const { user_evaluation_section: sections } = rest;
            return sections?.length;
          }) || [])
        ];
        const maxCountEval = Math.max(...sectionCount);
        const results = [
          ...(lists?.map(
            ({
              id,
              user,
              cost_center_code,
              eval_form_name,
              user_evaluation_status,
              ...rest
            }) => {
              const { user_evaluation_section: user_sections } = rest;
              const employee = codies?.find(empl => empl?.id === user?.id);
              const item = {
                id: user?.id,
                employee_id: employee?.employee_id_number ?? '—',
                name: `${user?.first_name} ${user?.last_name}`,
                cost_center: cost_center_code,
                template: eval_form_name,
                status: user_evaluation_status?.name,
                sections: user_sections?.length,
                evaluation_id: id
              };

              const missing = maxCountEval - item.sections;
              if (missing !== 0 && missing >= 1) {
                for (let x = 1; x <= missing; x += 1) {
                  user_sections?.push({ evaluator: null });
                }
              }

              const sections = user_sections
                ?.sort((a, b) => (a?.id || 0) - (b?.id || 0))
                ?.map(({ evaluator }, index) => ({
                  [`section${index + 1}`]: evaluator
                    ? `${evaluator?.first_name} ${evaluator?.last_name}`
                    : '—'
                }));

              return sections?.reduce(
                (result, current) => Object.assign(result, current),
                { ...item }
              );
            }
          ) || [])
        ];
        setUsers(results);
      }
    })();
  }, [dispatch, evaluationScheduleID, codies]);

  useEffect(() => {
    fetchUserEvaluationInfo();
  }, []);

  useEffect(() => {
    if (!allUsers) return;
    const members = [
      ...new Map(allUsers?.map(item => [item.first_name, item])).values()
    ];
    const uniqueCodies = [
      ...(members?.map(user => {
        const { id, first_name, last_name, employee_id_number } = user;
        return { id, employee_id_number, name: `${first_name} ${last_name}` };
      }) || [])
    ];
    setCodies(uniqueCodies);

    const cody_evaluators = [
      ...(members?.map(user => {
        const { id, first_name, last_name } = user;
        return { id, first_name, last_name };
      }) || [])
    ];
    setEvaluators(cody_evaluators);
  }, [allUsers]);

  useEffect(() => {
    fetchScheduleData(evaluationScheduleID);
  }, [evaluationScheduleID]);

  useEffect(() => {
    if (!scheduleDataAPI) return;

    const {
      id,
      schedule_name,
      schedule_description,
      evaluation_start_date,
      evaluation_end_date,
      include_exempted_user
    } = scheduleDataAPI;

    setScheduleData({
      id,
      schedule_name,
      schedule_description,
      evaluation_start_date: formatDate(evaluation_start_date),
      evaluation_end_date: formatDate(evaluation_end_date),
      include_exempted_user
    });

    isSetChecked(include_exempted_user === 1);
  }, [scheduleDataAPI]);

  useEffect(() => {
    isSetChecked(scheduleData?.include_exempted_user === 1);

    fetchUserEvaluationInfo();
  }, [scheduleData, fetchUserEvaluationInfo]);

  useEffect(() => {
    if (!users) return;
    const userSections = users?.map(({ sections }) => sections);
    setMaxCount(Math.max(...userSections));
  }, [users]);

  async function fetchScheduleData(scheduleId) {
    try {
      const { data, success } = await dispatch(
        getEvaluationScheduleById(scheduleId)
      );
      if (success) {
        const { id, completion, evaluation_schedule_statuses, ...formData } =
          data;
        setScheduleDataAPI(data);
        setLoading(false);
        setCopy({ ...formData });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  async function handleUpdateSchedule(id) {
    setLoading(true);

    const formData = {
      schedule_name,
      schedule_description,
      evaluation_start_date,
      evaluation_end_date,
      include_exempted_user
    };

    if (isEqual(formData, copy)) {
      setLoading(false);
      setIsEditSchedule(false);
    }

    if (!isEqual(formData, copy)) {
      setScheduleData(prevState => ({
        ...prevState,
        schedule_name,
        schedule_description,
        evaluation_start_date,
        evaluation_end_date,
        include_exempted_user
      }));

      try {
        const payload = {
          schedule_name,
          schedule_description,
          evaluation_start_date,
          evaluation_end_date,
          is_included: include_exempted_user
        };
        const { success } = await dispatch(
          updateEvaluationScheduleById(id, payload)
        );
        if (success) {
          toast.success('Schedule successfully updated!', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          setLoading(false);
          setIsEditSchedule(false);
          copyData();
        } else {
          setLoading(false);
          setIsEditSchedule(false);

          toast.error('Template name already exists! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });

          setScheduleData(prevState => ({
            ...prevState,
            schedule_name: copy?.schedule_name,
            schedule_description: copy?.schedule_description,
            evaluation_start_date: copy?.evaluation_start_date,
            evaluation_end_date: copy?.evaluation_end_date,
            include_exempted_user: copy?.include_exempted_user
          }));
        }
      } catch (error) {
        return error;
      }
    }

    return undefined;
  }

  async function handleDeleteSchedule(scheduleId) {
    try {
      const { success } = await dispatch(
        deleteEvaluationScheduleById(scheduleId)
      );
      if (success) {
        dispatch(getEvaluationSchedules());
        navigate('/evaluations/schedules/');
        toast.success('Schedule successfully deleted!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      } else {
        toast.error('Something went wrong!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  function generateHeaders() {
    for (let i = 1; i <= maxCount; i += 1) {
      tableColumns?.push({
        key: `section${i}`,
        label: `Section ${i}`,
        type: 'select',
        placeholder: 'Select Section',
        selectOptions: codies?.map(({ id, name }) => ({
          label: name,
          value: id
        })),
        onChangeFn: async ({ data, key }) => {
          let sectionID;
          const newName = data[key];
          const sectionIndex = key.split('section').at(-1) - 1;
          const newEvaluatorID = codies?.filter(
            member => member?.name === newName
          )[0]?.id;
          const { user_evaluation_section } =
            userEvaluationList &&
            userEvaluationList.find(
              userEvaluation => userEvaluation?.id === data?.evaluation_id
            );

          user_evaluation_section?.forEach((section, index) => {
            if (sectionIndex === index) {
              sectionID = section?.id;
            }
          });

          const payload = {
            evaluation_id: data?.evaluation_id,
            user_eval_section_id: sectionID,
            evaluator_id: newEvaluatorID
          };

          updateEvaluator(evaluationScheduleID, payload, {
            key,
            sectionIndex,
            data,
            newEvaluatorID
          });
        }
      });
      if (i === maxCount) {
        GENERATING = false;
      }
    }
    return tableColumns;
  }

  function handleChange({ target: { name, value, checked } }) {
    setScheduleData(prevState => ({
      ...prevState,
      [name]: isExempted(name, checked, value)
    }));
  }

  function updateUserEvaluationStatus(userEvaluationID, status, user) {
    const { overall_score, project, project_months } = user;
    const payload = {
      overall_score,
      project_id: project?.id ?? 1,
      project_months: project_months < 0 ? 1 : project_months ?? 1,
      status_id: getScheduleStatusID(status)
    };
    updateEvaluationStatus(
      evaluationScheduleID,
      userEvaluationID,
      payload,
      status
    );
  }

  async function updateEvaluationStatus(
    scheduleId,
    evaluationId,
    payload,
    status
  ) {
    try {
      const { success } = await dispatch(
        updateEvaluationScheduleUserStatus(scheduleId, evaluationId, payload)
      );
      if (success) {
        toast.success('Status successfully updated!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });

        // update users (local)
        const list = [...users];
        const userIndex = users?.findIndex(
          user => user?.evaluation_id === evaluationId
        );
        list[userIndex].status = status;
        setUsers(list);

        // update userEvaluationList (local)
        setUserEvaluationList(prevState => [
          ...(prevState?.map(userEvaluation => {
            if (userEvaluation?.id === evaluationId) {
              return {
                ...userEvaluation,
                user_evaluation_status: setScheduleStatus(status)
              };
            }
            return userEvaluation;
          }) || [])
        ]);
      } else {
        toast.error('Internal Server Error! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  async function updateEvaluator(
    scheduleID,
    payload,
    { key, sectionIndex, data, newEvaluatorID }
  ) {
    try {
      const { success } = await dispatch(
        updateEvaluatorPerSectionById(scheduleID, payload)
      );
      if (success) {
        toast.success('Evaluator successfully updated!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });

        // Update Datatable
        const list = [...users];
        const userIndex = users?.findIndex(
          user => user?.evaluation_id === data?.evaluation_id
        );
        list[userIndex][key] = data[key];
        setUsers(list);

        // Update evaluator per section (local)
        setUserEvaluationList(prevState => [
          ...(prevState?.map(userEvaluation => {
            if (userEvaluation?.id === data?.evaluation_id) {
              return {
                ...userEvaluation,
                user_evaluation_section: [
                  ...(userEvaluation?.user_evaluation_section?.map(
                    (section, index) => {
                      if (sectionIndex === index) {
                        return {
                          ...section,
                          evaluator: evaluators?.find(
                            evaluator => evaluator?.id === newEvaluatorID
                          )
                        };
                      }
                      return section;
                    }
                  ) || [])
                ]
              };
            }
            return userEvaluation;
          }) || [])
        ]);
      } else {
        toast.error('Internal Server Error!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  const handleExportCSV = async () => {
    try {
      const response = await fetchAPI({
        method: 'GET',
        endpoint: `${SCHEDULES}/${evaluationScheduleID}/evaluations/export`,
        params: { responseType: 'blob' },
        isExport: true
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${schedule_name}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return error;
    }

    return undefined;
  };

  function copyData() {
    setCopy(prevState => ({
      ...prevState,
      schedule_name,
      schedule_description,
      evaluation_start_date,
      evaluation_end_date,
      include_exempted_user
    }));
  }

  if (!scheduleData) return null;

  const {
    schedule_name,
    schedule_description,
    evaluation_start_date,
    evaluation_end_date,
    include_exempted_user
  } = scheduleData;
  const isFilled =
    Boolean(schedule_name) &&
    Boolean(schedule_description) &&
    Boolean(evaluation_start_date) &&
    Boolean(evaluation_end_date);

  return (
    <div className="evaluation__container">
      <div className="evaluation__heading evaluation__heading--inner">
        <PageTitle
          title="Manage Evaluation Schedule"
          backButton
          backPath="/evaluations/schedules"
        />
        <KebabMenu
          options={[
            {
              label: 'Edit Information',
              onClick: () => setIsEditSchedule(true)
            },
            {
              label: 'Delete Schedule',
              onClick: () => handleDeleteSchedule(evaluationScheduleID)
            }
          ]}
        />
      </div>
      <div className="evaluation__breadcrumbs">
        <Breadcrumbs
          crumbs={[
            { link: '/evaluations/schedules', name: 'Evaluation Schedules' },
            'Manage Evaluation Schedule'
          ]}
        />
      </div>
      <div className="evaluation__container evaluation__container--inner">
        <div className="evaluation__content">
          <div className="evaluation__information">
            <div className="evaluation__row evaluation__row--information">
              <div className="evaluation__column evaluation__column--two center">
                <SectionTitle title="Information" />
              </div>
              {isEditSchedule ? (
                <div className="evaluation__column evaluation__column--two reverse">
                  <div className="evaluation__template-save">
                    <button
                      type="button"
                      className={`button button__save dark evaluation__save-button${
                        !isFilled ? ' disabled' : ''
                      }`}
                      disabled={!isFilled}
                      onClick={() => handleUpdateSchedule(evaluationScheduleID)}
                    >
                      <span className="button__text">
                        {loading ? <FaSpinner className="spinner" /> : 'Save'}
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="evaluation__schedule">
            <div
              className={`evaluation__schedule-overview${
                loading ? ' loading' : ''
              }`}
            >
              <Box>
                <div className="evaluation__row --column">
                  <div className="evaluation__column evaluation__column--two">
                    <label
                      className="evaluation__label"
                      htmlFor="evaluation_schedule_name"
                    >
                      Schedule Name
                      {isEditSchedule ? (
                        <input
                          className="evaluation__input"
                          type="text"
                          name="schedule_name"
                          id="evaluation_schedule_name"
                          value={schedule_name}
                          onChange={handleChange}
                        />
                      ) : (
                        <LabeledText text={schedule_name} />
                      )}
                    </label>
                  </div>
                  <div className="evaluation__column evaluation__column--two two">
                    <label className="evaluation__label" htmlFor="start_date">
                      Start Date
                      {isEditSchedule ? (
                        <input
                          className="evaluation__input"
                          name="evaluation_start_date"
                          id="start_date"
                          defaultValue={formatScheduleDate(
                            evaluation_start_date
                          )}
                          onChange={handleChange}
                          type="date"
                          max="9999-12-31"
                        />
                      ) : (
                        <LabeledText text={formatDate(evaluation_start_date)} />
                      )}
                    </label>
                  </div>
                  <div className="evaluation__column evaluation__column--two two">
                    <label className="evaluation__label" htmlFor="end_date">
                      End Date
                      {isEditSchedule ? (
                        <input
                          className="evaluation__input"
                          type="date"
                          name="evaluation_end_date"
                          id="end_date"
                          defaultValue={formatScheduleDate(evaluation_end_date)}
                          onChange={handleChange}
                          max="9999-12-31"
                        />
                      ) : (
                        <LabeledText text={formatDate(evaluation_end_date)} />
                      )}
                    </label>
                  </div>
                </div>
                <div className="evaluation__row">
                  <div className="evaluation__column">
                    <label
                      className="evaluation__label"
                      htmlFor="evaluation_schedule_description"
                    >
                      Schedule Description
                      {isEditSchedule ? (
                        <textarea
                          className="evaluation__input evaluation__input--textarea"
                          name="schedule_description"
                          id="evaluation_schedule_description"
                          value={schedule_description}
                          onChange={handleChange}
                        />
                      ) : (
                        <LabeledText text={schedule_description} description />
                      )}
                    </label>
                  </div>
                </div>
                <div className="evaluation__row">
                  <div className="evaluation__column">
                    {isEditSchedule ? (
                      <label
                        className="evaluation__label"
                        htmlFor="exempted_user"
                      >
                        Include Exempted User &nbsp;
                        <input
                          type="checkbox"
                          name="include_exempted_user"
                          id="exempted_user"
                          checked={isChecked}
                          onChange={handleChange}
                          max="9999-12-31"
                        />
                      </label>
                    ) : (
                      <LabeledText
                        label="Include Exempted User"
                        text={
                          scheduleData?.include_exempted_user
                            ? 'Yes'
                            : 'No' ?? '--'
                        }
                      />
                    )}
                  </div>
                </div>
              </Box>
            </div>
            <div className="evaluation__section mt30">
              <pre style={{ display: 'none' }}>
                {JSON.stringify(generateHeaders(), null, 4)}
              </pre>
              {GENERATING ? (
                <FaSpinner className="spinner" />
              ) : (
                <SearchFilter
                  pathName="/evaluations/schedules"
                  title="Evaluation Schedule List"
                  searchInputPlaceholder="Search Users"
                  data={users}
                  editable
                  isSchedule
                  showActionBtn={false}
                  clickableRows={false}
                  isExport
                  onExport={handleExportCSV}
                  inputs={[
                    {
                      label: 'Cost Center',
                      type: 'dropdown',
                      options: filterData(getCostCenter(), 'cost_center_code'),
                      name: 'cost_center'
                    },
                    {
                      label: 'User Evaluation Status',
                      type: 'checkbox',
                      options: filterData(
                        userEvaluationScheduleStatuses,
                        'name'
                      ),
                      name: 'status'
                    }
                  ]}
                  columns={[
                    ...new Map(
                      generateHeaders()?.map(item => [item.key, item])
                    ).values()
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationScheduleDetail;
