import {
  GET_EMPLOYEES_LIST,
  GET_ALL_EMPLOYEES_LIST,
  GET_SALARY_GRADE,
  GET_GENDER,
  GET_CIVIL_STATUS,
  GET_EMPLOYMENT_STATUS,
  GET_ALL_EMPLOYEES,
  SET_LIST_UPDATE,
  GET_POSITIONS,
  GET_TEAMS
} from './employeeTypes';

const INITIAL_STATE = {
  employees: []
};

export default function employeeReducers(
  state = INITIAL_STATE,
  { type, payload } = null
) {
  switch (type) {
    case GET_EMPLOYEES_LIST:
      return {
        ...state,
        employees: payload
      };
    case GET_ALL_EMPLOYEES_LIST:
      return {
        ...state,
        allEmployees: payload
      };
    case GET_SALARY_GRADE:
      return {
        ...state,
        salaryGrade: payload
      };
    case GET_GENDER:
      return {
        ...state,
        gender: payload
      };
    case GET_CIVIL_STATUS:
      return {
        ...state,
        civilStatus: payload
      };
    case GET_EMPLOYMENT_STATUS:
      return {
        ...state,
        employmentStatus: payload
      };
    case GET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: payload
      };
    case SET_LIST_UPDATE:
      return {
        ...state,
        isListUpdated: payload
      };
    case GET_POSITIONS:
      return {
        ...state,
        positions: payload
      };
    case GET_TEAMS:
      return {
        ...state,
        teams: payload
      };
    default:
      return state;
  }
}
