import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import _ from 'lodash';

import Box from 'src/components/Common/Box';
import KebabMenu from 'src/components/Common/KebabMenu';
import PageTitle from 'src/components/Common/PageTitle';
import CostCenter from 'src/components/Common/CostCenter';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import LabeledText from 'src/components/Common/LabeledText';
import SectionTitle from 'src/components/Common/SectionTitle';

import { isEqual, getCostCenter } from 'src/helpers/utils';
import { TEMPLATES } from 'src/api/endpoints';
import useKeypressEscape from 'src/hooks/useKeypressEscape';
import TemplateSectionList from 'src/components/Parts/Evaluation/TemplateSectionList';
import {
  getStatusID,
  getScoreWeights
} from 'src/components/Parts/Evaluation/TemplateHelpers';
import {
  getEvaluationTemplates,
  getEvaluationTemplateById,
  updateEvaluationTemplateById,
  deleteEvaluationTemplateById
} from 'src/redux/modules/evaluations/templates/templatesActions';

const EvaluationTemplateDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copy, setCopy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sectionFields, setSectionFields] = useState([]);
  const [costCenterCode, setCostCenterCode] = useState('');
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [templateDataAPI, setTemplateDataAPI] = useState(null);
  const [templateData, setTemplateData] = useState({
    form_name: '',
    form_description: '',
    cost_center_code: '',
    sections: []
  });

  const checkSections = useCallback(() => {
    if (!templateData?.sections.length && !templateDataAPI?.sections.length) {
      setSectionFields(prevState => [
        ...prevState,
        {
          g1: 0,
          g2: 0,
          g3: 0,
          sg1: 0,
          sg2: 0,
          sg3: 0,
          title: '',
          description: '',
          sub_sections: [],
          evaluator_level: 1,
          isNewSection: true,
          evaluation_form_id: parseInt(id, 10)
        }
      ]);
    }
  }, [id, templateData, templateDataAPI]);

  useKeypressEscape({
    isEdit: isEditTemplate,
    cancelEdit: () => {
      const { sections, status_id, ...formData } = templateData;
      delete formData.id;
      if (!isEqual(formData, copy)) {
        toast.warn('Changes not saved! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
      const { form_name, form_description, cost_center_code } = copy;
      setTemplateData(prevState => ({
        ...prevState,
        form_name,
        form_description,
        cost_center_code
      }));
      setIsEditTemplate(false);
    }
  });

  useEffect(() => {
    const { sections, status_id, ...rest } = templateData;
    delete rest.id;
    setCopy(rest);
  }, []);

  useEffect(() => {
    if (!templateDataAPI) return;

    const {
      _id,
      form_name,
      form_description,
      evaluation_form_cost_center,
      evaluation_form_statuses
    } = templateDataAPI;
    const sectionsFromAPI = templateDataAPI?.sections;
    const sectionFieldsFromAPI = [
      ...(sectionsFromAPI.map(
        ({
          id: sectionID,
          section_name,
          section_description,
          section_weights,
          evaluator_level,
          ...rest
        }) => ({
          id: sectionID,
          isNewSection: false,
          title: section_name,
          description: section_description ?? '',
          evaluation_form_id: parseInt(_id, 10),
          evaluator_level: evaluator_level ?? 1,
          g1: getScoreWeights('G1', section_weights),
          g2: getScoreWeights('G2', section_weights),
          g3: getScoreWeights('G3', section_weights),
          sg1: getScoreWeights('SG1', section_weights),
          sg2: getScoreWeights('SG2', section_weights),
          sg3: getScoreWeights('SG3', section_weights),
          ...rest
        })
      ) || [])
    ];

    setTemplateData(prevState => ({
      ...prevState,
      id: _id,
      form_name,
      form_description,
      cost_center_code: getCostCenterCode(
        evaluation_form_cost_center,
        'cost_center_code'
      ),
      status_id: evaluation_form_statuses
        ? getStatusID(evaluation_form_statuses?.name)
        : 1
    }));

    setSectionFields(_.cloneDeep(sectionFieldsFromAPI));
    checkSections();
  }, [templateDataAPI]);

  useEffect(() => {
    setTemplateData(prevState => ({
      ...prevState,
      sections: _.cloneDeep(sectionFields)
    }));
  }, [sectionFields]);

  useEffect(() => {
    setTemplateData(prevState => ({
      ...prevState,
      cost_center_code: costCenterCode
    }));
  }, [costCenterCode]);

  useEffect(() => fetchDataByID(id), [id]);

  async function handleUpdateTemplate(_id, approved = false) {
    setLoadingActive(approved);

    const formData = { form_name, form_description, cost_center_code };
    const args = {
      template_name: form_name,
      template_description: form_description,
      cost_center_code,
      status_id: approved ? 2 : 1
    };
    if (isEqual(formData, copy)) {
      if (!approved) {
        if (status_id === 1) {
          setLoadingDetails(false);
          setIsEditTemplate(false);
          return;
        }
        setLoadingDetails(true);
        const payload = { ...args, status_id: 1 };
        const { success } = await dispatch(
          updateEvaluationTemplateById(TEMPLATES, Number(_id), payload)
        );
        if (success) {
          toast('Saved Template as Draft!', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          setLoadingDetails(false);
          setIsEditTemplate(false);
          setTemplateData(prevState => ({
            ...prevState,
            status_id: 1
          }));
        } else {
          toast.error('Something went wrong! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
        }
        return;
      }

      if (approved) {
        if (status_id === 2) {
          setLoadingActive(false);
          setIsEditTemplate(false);
          return;
        }
        setLoadingActive(true);
        const payload = { ...args, status_id: 2 };
        const { success } = await dispatch(
          updateEvaluationTemplateById(TEMPLATES, Number(_id), payload)
        );
        if (success) {
          toast.success('Saved Template as Active!', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
          setLoadingActive(false);
          setIsEditTemplate(false);
          setTemplateData(prevState => ({
            ...prevState,
            status_id: 2
          }));
        } else {
          toast.error('Something went wrong! ', {
            autoClose: 1200,
            newestOnTop: true,
            hideProgressBar: true
          });
        }
        return;
      }
    }

    if (!isEqual(formData, copy)) {
      setTemplateData(prevState => ({
        ...prevState,
        form_name,
        form_description,
        cost_center_code
      }));

      const { success } = await dispatch(
        updateEvaluationTemplateById(TEMPLATES, Number(_id), args)
      );

      if (success) {
        toast.success('Template successfully updated!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
        setLoadingActive(false);
        setLoadingDetails(false);
        setIsEditTemplate(false);
        copyData();
      } else {
        setLoadingDetails(false);
        setLoadingActive(false);
        setIsEditTemplate(false);

        toast.error('Template name already exists! ', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });

        setTemplateData(prevState => ({
          ...prevState,
          form_name: copy?.form_name,
          form_description: copy?.form_description,
          cost_center_code: copy?.cost_center_code
        }));
      }
    }
  }

  async function deleteTemplate(templateID) {
    try {
      const { success } = await dispatch(
        deleteEvaluationTemplateById(TEMPLATES, templateID)
      );
      if (success) {
        dispatch(getEvaluationTemplates());
        navigate('/evaluation-templates/');
        toast.success('Template successfully deleted!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      } else {
        toast.error('Something went wrong!', {
          autoClose: 1200,
          newestOnTop: true,
          hideProgressBar: true
        });
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  async function fetchDataByID(templateID) {
    try {
      const { data, success } = await dispatch(
        getEvaluationTemplateById(templateID)
      );
      if (success) {
        const {
          sections,
          evaluation_form_statuses,
          evaluation_form_cost_center,
          ...formData
        } = data;
        setTemplateDataAPI(_.cloneDeep(data));
        setLoading(false);
        delete formData.id;
        setCopy({
          ...formData,
          cost_center_code: getCostCenterCode(
            evaluation_form_cost_center,
            'cost_center_code'
          )
        });
      } else {
        navigate('/evaluation-templates/add');
      }
    } catch (error) {
      return error;
    }

    return undefined;
  }

  function handleChange({ target: { name, value } }) {
    setTemplateData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleCostCenterCode = useCallback(data => {
    const { cost_center_code } = data;
    setCostCenterCode(cost_center_code);
  });

  function getCostCenterCode(list, key) {
    return list?.map(item => item[key])[0];
  }

  function copyData() {
    setCopy(prevState => ({
      ...prevState,
      form_name,
      form_description,
      cost_center_code
    }));
  }

  if (!templateData) return null;
  const { form_name, form_description, cost_center_code, status_id } =
    templateData;
  const isFilled =
    Boolean(form_name) &&
    Boolean(form_description) &&
    Boolean(cost_center_code);

  return (
    <div className="evaluation__container">
      <div className="evaluation__heading evaluation__heading--inner">
        <PageTitle title="Template Details" backButton backPath={-1} />
        <KebabMenu
          options={[
            {
              label: 'Edit Information',
              onClick: () => setIsEditTemplate(true)
            },
            {
              label: 'Delete Template',
              onClick: () => deleteTemplate(id)
            }
          ]}
          evaluation
        />
      </div>
      <div className="evaluation__breadcrumbs">
        <Breadcrumbs
          crumbs={[
            { name: 'Evaluation' },
            { link: '/evaluation-templates/', name: 'Evaluation Templates' },
            'Template Details'
          ]}
        />
      </div>
      <div className="evaluation__container evaluation__container--inner">
        <div className="evaluation__content">
          <div className="evaluation__information">
            <div className="evaluation__row evaluation__row--information">
              <div className="evaluation__column evaluation__column--two center">
                <SectionTitle title="Information" />
              </div>
              {isEditTemplate ? (
                <div className="evaluation__column evaluation__column--two reverse">
                  <div className="evaluation__template-save">
                    <button
                      type="button"
                      className={`button button__save dark evaluation__save-button${
                        !isFilled ? ' disabled' : ''
                      }`}
                      disabled={!isFilled}
                      onClick={() => handleUpdateTemplate(id)}
                    >
                      <span className="button__text">
                        {loadingDetails ? (
                          <FaSpinner className="spinner" />
                        ) : (
                          'Save as Draft'
                        )}
                      </span>
                    </button>
                    <button
                      type="button"
                      className={`button button__save dark evaluation__save-button${
                        !isFilled ? ' disabled' : ''
                      }`}
                      disabled={!isFilled}
                      onClick={() => handleUpdateTemplate(id, true)}
                    >
                      <span className="button__text">
                        {loadingActive ? (
                          <FaSpinner className="spinner" />
                        ) : (
                          'Save'
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="evaluation__template">
            <div
              className={`evaluation__template-overview${
                loading ? ' loading' : ''
              }`}
            >
              <Box>
                <div className="evaluation__row --column">
                  <div className="evaluation__column evaluation__column--two">
                    <label
                      className="evaluation__label"
                      htmlFor="template_name"
                    >
                      Template Name
                      {isEditTemplate ? (
                        <input
                          className="evaluation__input"
                          type="text"
                          name="form_name"
                          id="template_name"
                          value={form_name}
                          onChange={handleChange}
                        />
                      ) : (
                        <LabeledText text={form_name} />
                      )}
                    </label>
                  </div>
                  <div className="evaluation__column evaluation__column--two">
                    <h3 className="evaluation__label">Cost Center</h3>
                    {isEditTemplate ? (
                      <CostCenter
                        columns={2}
                        isEdit={isEditTemplate}
                        currentValue={cost_center_code}
                        onChange={handleCostCenterCode}
                      />
                    ) : (
                      <LabeledText
                        text={`${cost_center_code} (${getCostCenter(
                          'name',
                          cost_center_code
                        )})`}
                      />
                    )}
                  </div>
                </div>
                <div className="evaluation__row">
                  <div className="evaluation__column">
                    <label
                      className="evaluation__label"
                      htmlFor="template_description"
                    >
                      Template Description
                      {isEditTemplate ? (
                        <textarea
                          className="evaluation__input evaluation__input--textarea"
                          name="form_description"
                          id="template_description"
                          value={form_description}
                          onChange={handleChange}
                        />
                      ) : (
                        <LabeledText text={form_description} />
                      )}
                    </label>
                  </div>
                </div>
              </Box>
            </div>

            <TemplateSectionList
              templateID={id}
              loading={loading}
              sectionFields={sectionFields}
              isEditTemplate={isEditTemplate}
              setSectionFields={setSectionFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluationTemplateDetails;
