import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'src/components/Common/Tab';
import Team from 'src/components/Parts/Dashboard/Team';
import ProjectTeam from 'src/components/Parts/Dashboard/ProjectTeam';
import Account from 'src/components/Parts/Dashboard/Account';
import OtherInfo from 'src/components/Parts/Dashboard/OtherInfo';
// import { fetchAPI } from 'src/api/fetchAPI';
// import { USER_INFO } from 'src/api/endpoints';
import { getUserInfo } from 'src/redux/modules/users/userActions';
// import { store } from 'src/redux';
import 'src/assets/css/info.scss';
import defaultPicture from 'src/assets/icons/defaultProfile2.png';

const Information = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const tabs = [
    {
      title: 'DETAILS',
      component: <OtherInfo />
    },
    {
      title: 'MY TEAM',
      component: <Team />
    },
    {
      title: 'PROJECT TEAM',
      component: <ProjectTeam />,
      modifier: 'is-project-team'
    },
    {
      title: 'ACCOUNT',
      component: <Account />
    }
  ];

  useEffect(() => {
    // dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <div className="info__container">
      <h2 className="info__heading">INFORMATION</h2>
      <div className="info__wrapper">
        <div className="info__column">
          <img
            className="info__image"
            src={user.user.image === null ? defaultPicture : user.user.image}
            alt={user.user.first_name ? user.user.first_name : '-'}
          />
        </div>
        <div className="info__column">
          <div className="info__detail">
            <div className="info">
              <span className="info__label">ID</span>
              <span className="info__text">{user.info?.id_no ?? '-'}</span>
            </div>
            <div className="info">
              <span className="info__label">First Name</span>
              <span className="info__text">{user.user?.first_name ?? '-'}</span>
            </div>
            <div className="info">
              <span className="info__label">Last Name</span>
              <span className="info__text">{user.user?.last_name ?? '-'}</span>
            </div>
            <div className="info">
              <span className="info__label">Position</span>
              <span className="info__text">{user.user?.position ?? '-'}</span>
            </div>
            <div className="info">
              <span className="info__label">Employment Status</span>
              <span className="info__text">
                {user.info?.info?.employment_status?.name ?? '-'}
              </span>
            </div>
            <div className="info">
              <span className="info__label">Work Years</span>
              <span className="info__text">
                {user.info && (user.info.work_years || user.info.work_months)
                  ? `${user.info.work_years}y ${user.info.work_months}m`
                  : '-'}
              </span>
            </div>
            <div className="info">
              <span className="info__label">Email</span>
              <span className="info__text">{user.user?.email ?? '-'}</span>
            </div>
            <div className="info" />
          </div>
        </div>
      </div>

      <Tab tabs={tabs} />
    </div>
  );
};

export default Information;
