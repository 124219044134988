import { useState, useEffect, useCallback, React } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getEmployeeEvaluationsByIdService,
  updateEmployeeEvaluationsByIdService
} from 'src/api/modules/evaluation';
import { costCenterName } from 'src/helpers/utils';
import { CgCheckO, CgCloseO, CgDanger } from 'react-icons/cg';

const useEvaluationEntrySingle = () => {
  const [employeeDetail, setEmployeeDetail] = useState({
    employee_name: '',
    cost_center_code: '',
    salary_grade: '',
    template_name: '',
    schedule_name: '',
    evaluation_date: ''
  });
  const [evaluationGroupData, setEvaluationGroupData] = useState([]);
  const [scoreMultiInputsForm, setScoreMultiInputsForm] = useState({});
  const evaluationEntryId = useParams().id || null;
  const { user } = useSelector(state => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const [secId, setSecId] = useState(null);
  const [critId, setCritId] = useState(null);
  const [secType, setSecType] = useState(null);
  const [customError, setCustomError] = useState({});
  const [isFulfilled, setIsFulfilled] = useState(false);
  const dispatch = useDispatch();
  const costCenterList = useSelector(
    state => state.costCenter.costCenterAll.items
  );
  const infoDataColumns = [
    { key: 'employee_name', label: 'Employee Name' },
    { key: 'cost_center_code', label: 'Cost Center' },
    { key: 'salary_grade', label: 'Salary Grade' },
    { key: 'template_name', label: 'Template Name' },
    { key: 'schedule_name', label: 'Schedule Name' },
    { key: 'evaluation_date', label: 'Evaluate Date' }
  ];

  const handleSubmit = async (sectionId, criteriaId, sectionType, form) => {
    try {
      const formItem =
        form[`evaluation_section_id-${sectionId}`][`criteria_id-${criteriaId}`];

      if (formItem.boss_score === 0)
        throw new Error('Please input a score of at least 1');

      const fieldAnswer = {
        boss_score: formItem.boss_score
      };

      let sectionKey = 'comment';

      switch (sectionType) {
        case 'T':
          fieldAnswer.target_details = formItem.target_details || '';
          fieldAnswer.how_to_achieve = formItem.boss_comment || '';
          sectionKey = 'boss_comment';
          break;
        case 'R':
          fieldAnswer.comment = formItem.comment;
          break;
        case 'C':
          fieldAnswer.question = formItem.boss_comment;
          sectionKey = 'boss_comment';
          break;
        default:
          fieldAnswer.comment = formItem.comment;
          break;
      }

      const evaluationItemForm = {
        evaluation_section_id: sectionId,
        evaluation_criteria_id: criteriaId,
        field_answers: fieldAnswer
      };

      const updateEvaluationResponse =
        await updateEmployeeEvaluationsByIdService(
          evaluationEntryId,
          evaluationItemForm
        );

      if (
        !updateEvaluationResponse.statusCode &&
        updateEvaluationResponse.response.status !== 200
      ) {
        setCustomError(prevState => ({
          ...prevState,
          [`${sectionKey}-${sectionType}-${sectionId}-${criteriaId}`]:
            updateEvaluationResponse?.response?.data?.message
        }));
        toast.error(updateEvaluationResponse?.response?.data?.message, {
          position: 'top-right',
          autoClose: 2500,
          bodyClassName:
            'slim-toast !text-[14px] !text-[#000000] font-stolzlBook mx-4 flex !flex-row gap-2',
          icon: (
            <CgDanger className="!w-[30px] !text-[30px] !color-[#F05848]" />
          ),
          closeButton: false,
          className: 'slim-toast-container !h-[55px] !min-h-0 !min-w-0 !mb-4'
        });
      }

      if (
        updateEvaluationResponse.statusCode &&
        updateEvaluationResponse.statusCode === 200
      ) {
        setCustomError(prevState => ({
          ...prevState,
          [`${sectionKey}-${sectionType}-${sectionId}-${criteriaId}`]: ''
        }));
        toast.success(updateEvaluationResponse?.data?.message, {
          position: 'top-right',
          autoClose: 2500,
          bodyClassName:
            'slim-toast !text-[14px] !text-[#000000] font-stolzlBook mx-4 flex !flex-row gap-2',
          icon: <CgCheckO className="!w-[30px] !text-[30px]" />,
          closeButton: false,
          className: 'slim-toast-container !h-[55px] !min-h-0 !min-w-0 !mb-4'
        });
      }
    } catch (error) {
      toast.error(error, {
        bodyClassName: '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
        icon: <CgCloseO />
      });
    }
  };

  const handleCheckBossScore = ({ target: { value } }, ...args) => {
    const errorMessage = 'Please input a score of at least 1';
    const [key, type, sectionId, criteriaId] = args || [];
    if (Number(value) === 0) {
      setCustomError(prevState => ({
        ...prevState,
        [`${key}-${type}-${sectionId}-${criteriaId}`]: errorMessage
      }));
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 2500,
        bodyClassName:
          'slim-toast !text-[14px] !text-[#000000] font-stolzlBook mx-4 flex !flex-row gap-2',
        icon: <CgDanger className="!w-[30px] !text-[30px] !color-[#F05848]" />,
        closeButton: false,
        className: 'slim-toast-container !h-[55px] !min-h-0 !min-w-0 !mb-4'
      });
    } else {
      setCustomError(prevState => ({
        ...prevState,
        [`${key}-${type}-${sectionId}-${criteriaId}`]: '' || null
      }));
    }
  };

  const score = (val, max) => {
    let inputVal = Number(val);
    if (inputVal > max) {
      inputVal = max;
    }
    return inputVal;
  };

  const handleChange = (e, setFieldValue, setFieldTouched, setFieldError) => {
    const { name, value, type, max } = e.target;
    const fieldValue = type === 'number' ? score(value, max) : value;
    const ids = name.split('-');
    const sectionId = ids[2];
    const criteriaId = ids[3];
    const column = ids[0];
    const sectionType = ids[1];
    const sectionName = `evaluation_section_id-${sectionId}`;
    const criteriaName = `criteria_id-${criteriaId}`;

    setScoreMultiInputsForm(prevState => ({
      ...prevState,
      [sectionName]: {
        ...prevState[sectionName],
        [criteriaName]: {
          ...prevState[sectionName][criteriaName],
          [column]: fieldValue
        }
      }
    }));

    setCustomError(prevState => ({
      ...prevState,
      [`boss_comment-${sectionType}-${sectionId}-${criteriaId}`]: ''
    }));

    setIsEdit(true);

    setSecId(sectionId);
    setCritId(criteriaId);
    setSecType(sectionType);

    setFieldValue(name, fieldValue);
    setFieldTouched(name, true);
    setFieldError(name, '');
  };

  const handleInputBlur = () => {
    setIsEdit(false);
  };

  const inputPlaceholder = inputName => {
    const comment = ['boss_comment', 'comment'].includes(inputName);
    const details = inputName === 'target_details';
    let placeholderText = details ? 'Target Details' : '';

    if (comment) {
      placeholderText = 'Comment is required';
    }

    return placeholderText;
  };

  const handleFocus = e => {
    e.target.select();
  };

  const getEmployeeInfo = useCallback(async () => {
    try {
      const res = await getEmployeeEvaluationsByIdService(evaluationEntryId);
      const {
        employee_evaluation_master: master,
        employee_evaluation_details: details
      } = res.data;
      const { employee, evaluation_schedule, evaluation_template, status } =
        master;
      const { cost_center_code, employee_info, salary_grade } = employee;
      const { start_date, end_date } = evaluation_schedule;
      const employee_fullname = `${employee_info.first_name} ${
        employee_info.last_name
      } ${employee_info.suffix || ''}`;
      const costCenterLabel = costCenterName(cost_center_code, costCenterList);

      setIsFulfilled(status === 'F');

      setEmployeeDetail(prevState => ({
        ...prevState,
        employee_name: employee_fullname,
        cost_center_code: costCenterLabel,
        salary_grade: salary_grade.type,
        template_name: evaluation_template.name,
        schedule_name: evaluation_schedule.name,
        evaluation_date: `${start_date} - ${end_date}`
      }));

      const evaluateGroup = {};
      const tempGroupData = [];

      details?.forEach((item, i) => {
        const no = i + 1;
        const evaluationData = {
          group_name: `${no}. ${item.template_section.name}`,
          evaluator_id: item.evaluator?.id,
          section_id: item.id,
          type: item.template_section.type,
          details: [],
          table: []
        };
        const subEvaluateGroup = {};

        item.criteria_details?.forEach((sub_item, sub_i) => {
          let bossComment = '';
          let targetDetails = '';
          switch (item.template_section.type) {
            case 'T':
              bossComment = sub_item.evaluator_value?.how_to_achieve;
              targetDetails = sub_item.evaluator_value?.target_details;
              evaluationData.table = [
                { key: 'name', label: '' },
                { key: 'target_details', label: 'Target Details' },
                { key: 'boss_score', label: 'Boss Score' },
                { key: 'boss_comment', label: 'Boss Comment' }
              ];
              break;
            case 'C':
              bossComment = sub_item.evaluator_value?.question;
              evaluationData.table = [
                { key: 'name', label: '' },
                { key: 'boss_score', label: 'Boss Score' },
                { key: 'boss_comment', label: 'Boss Comment' }
              ];
              break;
            default:
              bossComment = '';
              evaluationData.table = [
                { key: 'name', label: '' },
                { key: 'boss_score', label: 'Boss Score' },
                { key: 'comment', label: 'Comment' }
              ];
              break;
          }

          const evaluationSingle = {
            criteria_id: sub_item.id,
            name: `${
              typeof sub_item.template_criteria?.fields?.title !== 'undefined'
                ? `${no}-${sub_i + 1}. ${
                    sub_item.template_criteria?.fields?.title
                  }`
                : `${sub_item.template_criteria?.fields?.question}`
            }`,
            max_score: sub_item.max_rating,
            self_score: sub_item.self_score,
            boss_score: sub_item.boss_score,
            target_details: targetDetails || '',
            boss_comment: bossComment || '',
            comment: sub_item.evaluator_value?.comment || '',
            description: sub_item.template_criteria.fields?.description || ''
          };
          const evaluate = {
            max_score: sub_item.max_rating,
            self_score: sub_item.self_score,
            boss_score: sub_item.boss_score,
            target_details: targetDetails || '',
            boss_comment: bossComment || '',
            comment: sub_item.evaluator_value?.comment || ''
          };

          subEvaluateGroup[`criteria_id-${sub_item.id}`] = evaluate;
          evaluationData.details.push(evaluationSingle);
        });
        evaluateGroup[`evaluation_section_id-${item.id}`] = subEvaluateGroup;
        tempGroupData.push(evaluationData);
      });

      setScoreMultiInputsForm(evaluateGroup);
      setEvaluationGroupData(tempGroupData);
    } catch (error) {
      toast.error(error, {
        bodyClassName: '!text-[16px] !text-[#000000] font-stolzlBook mx-4',
        icon: <CgCloseO />
      });
    }
  }, [
    employeeDetail,
    setEmployeeDetail,
    setEvaluationGroupData,
    setScoreMultiInputsForm,
    evaluationEntryId,
    evaluationGroupData,
    getEmployeeEvaluationsByIdService
  ]);

  useEffect(() => {
    getEmployeeInfo();
  }, [dispatch]);

  useEffect(() => {
    if (!isEdit) handleSubmit(secId, critId, secType, scoreMultiInputsForm);
  }, [isEdit]);

  return {
    user,
    infoDataColumns,
    employeeDetail,
    scoreMultiInputsForm,
    evaluationGroupData,
    handleSubmit,
    handleChange,
    handleInputBlur,
    customError,
    isFulfilled,
    inputPlaceholder,
    handleFocus,
    handleCheckBossScore
  };
};

export default useEvaluationEntrySingle;
