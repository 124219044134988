import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  deleteProjectsByIdService,
  getProjectsByIdService,
  getProjectsService
} from 'src/api/modules/projects';
import { getAllProjects } from 'src/redux/modules/projects/projectActions';
import { CgCloseO, CgCheckO } from 'react-icons/cg';

export const useDeleteModal = ({ handleDeleteModal, id, modal }) => {
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState(null);

  const fetchNewUserList = async () => {
    getProjectsService(1)
      .then(res => {
        dispatch(getAllProjects(res.data.items));
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (id) {
      getProjectsByIdService(id).then(res => {
        const user = res.data.name;
        setProjectName(user);
      });
    }
  }, []);
  const handleDelete = userId => {
    if (userId) {
      deleteProjectsByIdService(userId).then(res => {
        fetchNewUserList();
        toast.success('Successfully Deleted!', { icon: <CgCheckO /> });
        handleDeleteModal(modal);
      });
    }
  };
  return {
    projectName,
    handleDelete
  };
};
