import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers } from 'src/redux/v1/modules/users/userActions';
import { filterData } from 'src/helpers/v1/utils';
import PageTitle from 'src/components/v1/Common/PageTitle';
import SearchFilter from 'src/components/v1/Common/SearchFilter';
import 'src/assets/v1/css/user.scss';

const UsersIndex = () => {
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const userColumns = [
    {
      key: 'employee_id_number',
      label: 'ID Number',
      userIconKey: 'image'
    },
    {
      key: 'first_name',
      label: 'First Name'
    },
    {
      key: 'last_name',
      label: 'Last Name'
    },
    {
      key: 'project',
      label: 'Project'
    },
    {
      key: 'section_name',
      label: 'Team'
    },
    {
      key: 'date_hired',
      label: 'Date Hired'
    },
    {
      key: 'employment_status',
      label: 'employment_status'
    }
  ];

  return (
    <div className="user__container">
      <div className="user__heading">
        <div className="user__heading--title">
          <PageTitle title="USERS" />
        </div>
      </div>

      <div className="user__section">
        <div className="filter__content--search">
          <SearchFilter
            searchInputPlaceholder="Search"
            buttonName="User"
            inputs={[
              {
                label: 'Project',
                type: 'dropdown',
                options: filterData(users, 'project'),
                name: 'project'
              },
              {
                label: 'Team',
                type: 'dropdown',
                options: filterData(users, 'section_name'),
                name: 'section_name'
              },
              {
                label: 'Employment Status',
                type: 'dropdown',
                options: filterData(users, 'employment_status'),
                name: 'employment_status'
              }
            ]}
            buttonLink="/users/add"
            title="User List"
            data={users}
            columns={userColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersIndex;
