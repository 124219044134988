import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getUserByIDService,
  deleteUserService,
  getAllUsersService
} from 'src/api/modules/user';
import { setIdDeletion } from 'src/redux/modules/datatable/datatableActions';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

export const useDeleteModal = ({ handleDeleteModal, id, modal }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    if (id) {
      getUserByIDService(id).then(res => {
        const user = `${res.data.first_name} ${res.data.last_name} ${
          res.data.suffix ?? ''
        }`;
        setUserName(user.trimEnd());
      });
    }
  }, []);
  const handleDelete = userId => {
    if (userId) {
      deleteUserService(userId)
        .then(res => {
          if (res.statusCode === 200) {
            dispatch(setIdDeletion([userId]));
            handleDeleteModal(modal);
            toast.success('Successfully Deleted!', {
              icon: <CgCheckO />,
              toastId: id
            });
          } else {
            toast.error('Unauthorized Deletion of Data.', {
              icon: <CgCloseO />,
              toastId: id
            });
          }
        })
        .catch(error => {
          return error;
        });
    }
  };
  return {
    userName,
    handleDelete
  };
};
