import React, { useState } from 'react';
import '../../../assets/v1/css/kebabMenu.scss';
import PropTypes from 'prop-types';

const KebabMenu = ({ options, evaluation = false }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="kebab-menu">
      {evaluation ? (
        <div
          className="kebab-menu__button"
          onClick={() => setIsActive(!isActive)}
          aria-hidden="true"
        >
          <img src="/icons/kebab-menu.svg" alt="menu" />
        </div>
      ) : (
        <button
          type="button"
          className="kebab-menu__button"
          onClick={() => setIsActive(!isActive)}
        >
          <img src="/icons/kebab-menu.svg" alt="menu" />
        </button>
      )}
      {options ? (
        <>
          {isActive && <div className="kebab-menu__tooltip-arrow" />}
          <div
            className={`kebab-menu__tooltip-menu${
              isActive ? ' kebab-menu__tooltip-menu--active' : ''
            }`}
          >
            <ul className="kebab-menu__tooltip-menu-list">
              {options?.map((opt, index) => (
                <li
                  key={opt.label}
                  className="kebab-menu__tooltip-menu-list-item"
                  onClick={() => {
                    opt.onClick();
                    setIsActive(!isActive);
                  }}
                  aria-hidden="true"
                >
                  {opt.label}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};

KebabMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  evaluation: PropTypes.bool
};

export default KebabMenu;
