import React, { useEffect, useState, useRef } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from 'src/api/v1/fetchAPI';
import { getUserSkills } from 'src/redux/v1/modules/users/userActions';
import Box from 'src/components/v1/Common/Box';
import Input from 'src/components/v1/Common/Input';
import Button from 'src/components/v1/Common/Button';
import SectionTitle from 'src/components/v1/Common/SectionTitle';
import Datatable from 'src/components/v1/Common/Datatable';
import Modal from 'src/components/v1/Common/Modal';
import {
  USER_SKILL_BY_ID,
  USER_SKILLS_BY_ID,
  USER_SKILL_CATEGORY_LIST
} from 'src/api/v1/endpoints';
import 'src/assets/v1/css/skillsListEdit.scss';
import PropTypes from 'prop-types';

const SkillsListEdit = () => {
  const { id: userID } = useParams();
  const dispatch = useDispatch();
  const userSkill = useSelector(state => state.user.userSkillDetails);
  const [skillCategoryList, setSkillCategoryList] = useState([]);
  const [currentSkillList, setCurrentSkillList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setActive] = useState(true);
  const formRef = useRef(null);
  const [skillOptions, setSkillOptions] = useState([]);
  const [ratingOptions, setRatingOptions] = useState([]);
  const [selectedSkillId, setSelectedSkillId] = useState('');
  const [selectedUserSkillId, setSelectedUserSkillId] = useState('');
  const [selectedRating, setSelectedRating] = useState('');
  const [isCategorySelected, setCategoryFlag] = useState(false);
  const [isSkillSelected, setSkillFlag] = useState(false);
  const [isRatingSelected, setRatingFlag] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const addSkillData = async data => {
    return await fetchAPI({
      method: 'POST',
      endpoint: USER_SKILL_BY_ID,
      body: data
    });
  };

  const updateSkillData = async data => {
    return await fetchAPI({
      method: 'PUT',
      endpoint: USER_SKILL_BY_ID,
      body: data
    });
  };

  const deleteSkillData = async userSkillId => {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${USER_SKILLS_BY_ID}/${userSkillId}`
    });
  };

  useEffect(() => {
    dispatch(getUserSkills(Number(userID)));
  }, [dispatch, userID]);

  const fetchSkillCategoryList = async () => {
    const { data, success } = await fetchAPI({
      method: 'GET',
      endpoint: USER_SKILL_CATEGORY_LIST
    });

    if (success) {
      setSkillCategoryList(data);
    }
  };

  useEffect(() => {
    fetchSkillCategoryList();
  }, []);

  useEffect(() => {
    const currentSkills = [
      userSkill?.map(
        ({
          id,
          rating,
          skill: {
            SkillCategory: { name: categoryName },
            name: skillName,
            id: skillId
          }
        }) => ({
          userSkillId: id,
          category: categoryName,
          skillId,
          skill: skillName,
          rating
        })
      )
    ];

    const currentSortedSkill = currentSkills.sort((a, b) =>
      a.skill.localeCompare(b.skill)
    );
    setCurrentSkillList(currentSortedSkill);
  }, [userSkill]);

  const renderCategoryList = skillCategoryList.map(({ name }, index) => {
    return name;
  });

  const renderSkillList = skillOptions.map(({ name }, index) => {
    return name;
  });

  const renderRatingList = ratingOptions.map((rating, index) => {
    return rating;
  });

  const setCategory = category => {
    setCategoryFlag(false);
    setSkillFlag(false);
    setSkillOptions([]);
    setRatingOptions([]);

    skillCategoryList?.map(({ name, Skills }) => {
      if (category === name) {
        setCategoryFlag(true);
        const existingSkill = new Set(
          currentSkillList.map(({ skill }) => skill)
        );
        const filteredSkill = Skills.filter(
          ({ filteredName }) => !existingSkill.has(filteredName)
        );
        const sortedSkill = filteredSkill.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setSkillOptions(sortedSkill);
      }

      return null;
    });
  };

  const setSkill = skills => {
    setSkillFlag(false);
    setRatingOptions([]);

    skillOptions.map(({ id, name }) => {
      if (skills === name) {
        setSkillFlag(true);
        setSelectedSkillId(id);

        const ratingList = [...Array(10)].map((_, i) => i + 1);
        setRatingOptions(ratingList);
      }

      return null;
    });
  };

  const setRating = rating => {
    setRatingFlag(false);

    if (rating > 0) {
      setRatingFlag(true);
      setSelectedRating(rating);
    }
  };

  const save = async () => {
    setIsLoading(true);

    const { success } = await addSkillData({
      user_id: Number(userID),
      skill_id: Number(selectedSkillId),
      rating: Number(selectedRating)
    });

    if (success) {
      toast.success('Saved Successfully!');
      setActive(!isActive);
      handleSubmit();
      dispatch(getUserSkills(Number(userID)));
      setSkillOptions([]);
      setRatingOptions([]);
      setIsLoading(false);
      setCategoryFlag(false);
      setSkillFlag(false);
      setRatingFlag(false);
    }
  };

  const validate = () => {
    const isFilled =
      Boolean(isCategorySelected) &&
      Boolean(isSkillSelected) &&
      Boolean(isRatingSelected);
    return isFilled;
  };

  const handleClick = () => {
    setActive(!isActive);
  };

  const handleSubmit = e => {
    const event = e || window.event;
    event.preventDefault();
    formRef.current.reset();
  };

  const handleRowUpdate = async rowData => {
    const { userSkillId, skillId, rating } = rowData;

    let currentRating;
    currentSkillList.map(({ userSkillId: currentUserSkillId, skillRating }) => {
      if (currentUserSkillId === userSkillId) {
        currentRating = skillRating;
      }
      return null;
    });

    if (Number(rating) !== currentRating) {
      const { success } = await updateSkillData({
        id: userSkillId,
        user_id: Number(userID),
        skill_id: skillId,
        rating: Number(rating)
      });

      if (success) {
        toast.success('Saved Successfully!');
        dispatch(getUserSkills(Number(userID)));
      }
    }
  };

  const handleRowDelete = rowData => {
    setIsConfirm(true);
    const { userSkillId } = rowData;
    setSelectedUserSkillId(userSkillId);
  };

  const handleModalClose = () => {
    setIsConfirm(false);
  };

  const handleConfirm = async () => {
    const { success } = await deleteSkillData(selectedUserSkillId);
    if (success) {
      toast.success('Deleted Successfully!');
      dispatch(getUserSkills(Number(userID)));
    }
    setIsConfirm(false);
  };

  return (
    <div className="skills__add-wrapper">
      <Button
        name="Add Skills"
        modifier={`button__add-white dark ${
          isActive ? 'skillsList__add-skill-show' : 'skillsList__add-skill-hide'
        }`}
        onClick={handleClick}
      />

      <div className="skills__add-container">
        <div
          className={`skills__add-skill-inner ${
            isActive ? 'skills__add-skill-hide' : 'skills__add-skill-show'
          }`}
        >
          <div className="skills__add-skill-heading">
            <SectionTitle title="Add Skills" />
            <Button
              disabled={isLoading || !validate()}
              name={isLoading ? <FaSpinner className="spinner" /> : 'Save'}
              modifier="button__save dark"
              onClick={() => {
                if (validate()) {
                  save();
                }
              }}
            />
          </div>

          <Box modifier="skills__add-skill-form">
            <form ref={formRef}>
              <div className="skills__row">
                <div className="skills__column-wrapper">
                  <div className="skills__column">
                    <Input
                      type="select"
                      modifier="skills__input-select"
                      selectOptions={['Select Category', ...renderCategoryList]}
                      onChange={selectOptions => setCategory(selectOptions)}
                      label="Category"
                    />
                  </div>
                  <div className="skills__column">
                    <Input
                      type="select"
                      modifier="skills__input-select"
                      selectOptions={['Select Skills', ...renderSkillList]}
                      onChange={selectOptions => setSkill(selectOptions)}
                      label="Skills"
                      disable={!isCategorySelected}
                    />
                  </div>
                  <div className="skills__column">
                    <Input
                      type="select"
                      modifier="skills__input-select"
                      selectOptions={['Select Rating', ...renderRatingList]}
                      onChange={selectOptions => setRating(selectOptions)}
                      label="Rating"
                      disable={!isCategorySelected || !isSkillSelected}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </div>

      <div className="skillsList__container">
        <Datatable
          datasource={currentSkillList}
          keyField="skill"
          breakOn="small"
          editable
          allowDelete
          onRowUpdate={handleRowUpdate}
          onRowDelete={handleRowDelete}
          isSelectedValue
          headingColumns={[
            {
              key: 'category',
              label: 'Category',
              readOnly: true
            },
            {
              key: 'skill',
              label: 'Skill',
              readOnly: true
            },
            {
              key: 'rating',
              label: 'Rating',
              type: 'select',
              placeholder: 'Select Rating',
              selectOptions: [...Array(10)].map((_, i) => i + 1)
            }
          ]}
        />
      </div>

      <FormDialog
        isOpen={isConfirm}
        onClose={handleModalClose}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

const FormDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="dialog-fields">
        <div className="dialog-fields__title">
          Are you sure you want to delete?
        </div>
        <div className="dialog-fields__buttons">
          <Button
            name="Cancel"
            modifier="button__default default"
            type="button"
            onClick={onClose}
          />
          <Button
            name="Confirm"
            modifier="button__default dark"
            type="button"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

FormDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.bool,
  onConfirm: PropTypes.bool
};
export default SkillsListEdit;
