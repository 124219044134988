export const MENU_CODES = {
  userList: 'ST01',
  groupList: 'ST02',
  companyInformation: 'ST03',
  employees: 'RM01',
  projectList: 'RM02',
  projectAssignment: 'RM03',
  evaluationTemplate: 'PE01',
  evaluationSchedule: 'PE02',
  evaluationEntry: 'PE03',
  myEvaluation: 'PE04',
  evaluationResults: 'PE05',
  performanceAppraisal: 'PE06',
  skillsetSummary: 'SM01',
  skillsetRoadMap: 'SM02',
  COST_CENTER: 'CC01',
  DIVISION: 'CC02',
  DEPARTMENT: 'CC03',
  SECTION: 'CC04',
  SUB_SECTION: 'CC05',
  QUIZ_MANAGER: 'LM03',
  ASSESSMENTS: 'LM01',
  QUESTIONS: 'LM04',
  MY_ASSESSMENTS: 'LM05'
};

export const MENU_ACTIONS = {
  CRUD: 'CRUD',
  PRINT: 'PRINT',
  APPROVED: 'APPROVED',
  GENERAT: 'GENERATE'
};
