/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getLowerCasedValue, filterData } from 'src/helpers/utils';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getAllEvaluationsResultsService } from 'src/api/modules/evaluation';

const useEvaluationResult = () => {
  const [evaluationResultList, setEvaluationResultList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [resultId, setResultId] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();

  const evaluationResultColumns = [
    { key: 'schedule_name', label: 'Schedule Name' },
    { key: 'created_by', label: 'Created by' },
    { key: 'evaluation_date', label: 'Evaluation Date' }
  ];

  const access = {
    can_add: true,
    can_approve: true,
    can_delete: true,
    can_edit: true,
    can_generate: true,
    can_print: true,
    can_view: true
  };

  const inputs = [
    {
      type: 'date-range',
      options: [
        {
          label: 'Start Date',
          name: 'start_date'
        },
        {
          label: 'End Date',
          name: 'end_date'
        }
      ]
    }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') delete searchParamsObject.search;

    if (params.status === '') delete searchParamsObject.status;

    if (params.start_date === '') delete searchParamsObject.start_date;

    if (params.end_date === '') delete searchParamsObject.end_date;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: getLowerCasedValue(value) });
      }
      if (value === 'all' || (value === '' && searchParamsObject[key])) {
        delete searchParamsObject[key];
      }
      if (value === '') {
        delete searchParamsObject[key];
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-evaluation-result-search-params', searchParams);
  };

  const evaluationResultMemo = useMemo(() => {
    return evaluationResultList?.map(evaluationItem => {
      evaluationItem.schedule_name = evaluationItem.name;
      evaluationItem.created_by = `${
        evaluationItem.user_createdBy.first_name
      } ${
        evaluationItem.user_createdBy.middle_name ??
        evaluationItem.user_createdBy.middle_name
      } ${evaluationItem.user_createdBy.last_name} ${
        evaluationItem.user_createdBy.suffix !== null
          ? evaluationItem.user_createdBy.suffix
          : ''
      }`;
      evaluationItem.evaluation_date = `${evaluationItem.start_date} - ${evaluationItem.end_date}`;

      return evaluationItem;
    });
  }, [evaluationResultList]);

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-result-search-params'
      );
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-evaluation-result-page-no'),
        10
      );

      const newSearchParams = savedSearchParams
        .replace('status=A&', '')
        .replace('status=A', '');

      const newPageIndex =
        savedPageIndex > totalPage ? savedPageIndex - 1 : savedPageIndex;

      const params = new URLSearchParams(newSearchParams);
      const res = await getAllEvaluationsResultsService(
        newPageIndex || page,
        params
      );

      const newList =
        savedPageIndex !== 1
          ? evaluationResultList.concat(res.data.items)
          : res.data.items;

      setTotalPage(res.data.total_pages);
      setEvaluationResultList(newList);

      if (
        res.data.items.length === 0 ||
        res.data.current_page >= res.data.total_pages
      ) {
        setHasMore(false);
      } else {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
    }
  }, [
    page,
    getAllEvaluationsResultsService,
    setEvaluationResultList,
    setPage,
    setHasMore,
    evaluationResultList
  ]);

  const handleViewPage = useCallback((modal, id = null) => {
    navigate(`/evaluation-results/${id}`);
  }, []);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-result-search-params'
    );
    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-result-page-no'
    );
    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }
    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      localStorage.setItem('hris-evaluation-result-page-no', JSON.stringify(1));
      form.reset(Object.fromEntries(parsedSearchParams));
      fetchData();
    } else {
      // set default status
      form.reset({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    const newLocation = { ...location, search: '' };
    navigate(newLocation);
  }, []);

  useEffect(() => {
    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-result-search-params'
    );
    const newSearch = location.search
      ?.replace('status=A&', '')
      .replace('status=A', '');
    const newLocation = { ...location, search: newSearch };
    const searchParams = Object.fromEntries(
      new URLSearchParams(savedSearchParams)
    );
    pushQuery(searchParams);
    setPage(1);
    localStorage.setItem('hris-evaluation-result-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
    navigate(newLocation);
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem(
      'hris-evaluation-result-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  return {
    page,
    form,
    inputs,
    hasMore,
    access,
    evaluationResultColumns,
    evaluationResultList,
    evaluationResultMemo,
    fetchData,
    submitFilter,
    handleViewPage
  };
};

export default useEvaluationResult;
