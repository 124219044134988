import React from 'react';

// Common Components
import Breadcrumbs from 'src/components/Common/Breadcrumbs';

// Hooks
import useTakeQuizIndex from 'src/hooks/Pages/LearningManagement/TakeQuiz/useTakeQuizIndex';

// Quiz Components
import Quiz from './components/Quiz';

// eslint-disable-next-line react/prop-types
const Index = ({ menuCode, viewOnly }) => {
  const { myAssessment } = useTakeQuizIndex();
  const { assessment } = myAssessment;

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'My Assessment', link: '/my-assessments' },
          { name: assessment?.title }
        ]}
      />

      <div className="mt-5">
        {assessment && <Quiz assessment={assessment} />}
      </div>
    </div>
  );
};

export default Index;
