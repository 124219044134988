import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { getAllEmployees } from 'src/redux/modules/employee/employeeActions';
import { getActiveScheduleTemplates } from 'src/redux/modules/EvaluationSchedule/evaluationScheduleActions';
// Services
import {
  getEvaluationTemplatesService,
  deleteEvaluationScheduleByIdService
} from 'src/api/modules/evaluationSchedule';

// Utilities
import { filterData } from 'src/helpers/utils';
import { STATUS_OPTIONS, TOAST_OPTIONS } from 'src/helpers/constants';

// Others
import { toast } from 'react-toastify';
import { CgCheckO } from 'react-icons/cg';
import { MdOutlineErrorOutline } from 'react-icons/md';

const useEvaluationScheduleList = menuCode => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { evaluationTemplates } = useSelector(
    state => state.evaluationTemplate
  );

  const form = useForm({ defaultValues: undefined });
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const currentSearchParams = useRef({});
  const [deleteId, setDeleteId] = useState({ id: undefined, name: null });

  const handleDeleteModal = useCallback(
    ({ modal, id, name }) => {
      if (modal) {
        document.body.style.overflow = 'hidden';
      }
      setDeleteId({ id, name });
      setShowDeleteModal('DeleteModal');
    },
    [showDeleteModal]
  );

  const handleDeleteSchedule = async id => {
    try {
      const response = await deleteEvaluationScheduleByIdService(id);
      if (response?.success) {
        fetchScheduleData();
        toast.success('Successfully Deleted!', {
          ...TOAST_OPTIONS,
          icon: <CgCheckO />,
          toastId: id
        });
      } else {
        fetchScheduleData();
        toast.error(response?.data?.message, {
          ...TOAST_OPTIONS,
          icon: <MdOutlineErrorOutline />,
          toastId: id
        });
      }
    } catch (error) {
      toast.error('Failed to Delete!', {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: id
      });
    }
    setShowDeleteModal(null);
  };

  const [templateList, setTemplateList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const scheduleColumns = [
    {
      key: 'name',
      label: 'Schedule Name'
    },
    {
      key: 'schedule_createdBy',
      label: 'Created By'
    },
    {
      key: 'schedule_date',
      label: 'Evaluation Cycle'
    },
    {
      key: 'status',
      label: 'Status'
    }
  ];

  const inputs = [
    {
      type: 'multiselect',
      options: filterData(STATUS_OPTIONS, 'label', 'value'),
      multiOptions: STATUS_OPTIONS,
      name: 'status',
      label: 'Status',
      defaultValue: ['A']
    },
    {
      type: 'date-range',
      options: [
        {
          label: 'Start Date',
          name: 'start_date'
        },
        {
          label: 'End Date',
          name: 'end_date'
        }
      ]
    }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;

    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    if (params.start_date === '') {
      delete searchParamsObject.start_date;
    }

    if (params.end_date === '') {
      delete searchParamsObject.end_date;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' || key === 'status') return;
      if (value === '' || value === null) {
        delete searchParamsObject[key];
      }
    });

    if ('start_date' in searchParamsObject) {
      currentSearchParams.current = {
        ...currentSearchParams.current,
        start_date: searchParamsObject.start_date
      };
    } else {
      delete currentSearchParams.current.start_date;
    }

    if ('end_date' in searchParamsObject) {
      currentSearchParams.current = {
        ...currentSearchParams.current,
        end_date: searchParamsObject.end_date
      };
    } else {
      delete currentSearchParams.current.end_date;
    }

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem(
      'hris-evaluation-schedule-search-params',
      searchParams
    );
  };

  const fetchScheduleData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem(
        'hris-evaluation-schedule-search-params'
      );
      const params = new URLSearchParams(savedSearchParams);
      const response = await getEvaluationTemplatesService(page, params);
      const success =
        response?.success &&
        response?.message === 'OK' &&
        response?.statusCode === 200;

      if (success) {
        const {
          data: { items, current_page, total_pages }
        } = response;

        const modifiedResult = items.map(item => {
          const { name, user_createdBy, start_date, end_date } = item || {};
          const { first_name, middle_name, last_name, suffix } =
            user_createdBy || {};

          const checkSuffix = ['', null].every(nullish => nullish !== suffix);
          const hasSuffix = checkSuffix ? `, ${suffix}` : '';
          const creator = `${first_name} ${
            middle_name || ''
          } ${last_name}${hasSuffix}`;

          const result = {
            ...item,
            schedule_name: name,
            schedule_createdBy: creator,
            schedule_date: `${start_date} - ${end_date}`
          };

          return result;
        });

        const newList =
          page !== 1 ? templateList.concat(modifiedResult) : modifiedResult;
        setTemplateList(newList);

        if (items.length === 0 || current_page >= total_pages) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`, {
        ...TOAST_OPTIONS,
        icon: <MdOutlineErrorOutline />,
        toastId: menuCode
      });
    }
  }, [
    page,
    getEvaluationTemplatesService,
    setTemplateList,
    setPage,
    setHasMore,
    templateList
  ]);

  const scheduleListMemo = useMemo(() => {
    return templateList ?? [];
  }, [templateList]);

  const removeSearchParam = paramName => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
  };

  const detectDateOnChange = e => {
    const { name, value } = e.target;
    if (Object.keys(currentSearchParams.current).length === 1 && value === '') {
      const currentParams = localStorage.getItem(
        'hris-evaluation-schedule-search-params'
      );

      const paramsList = currentParams.split('&');
      const updatedParamsList = paramsList.filter(
        param => !param.startsWith(name.toString())
      );

      const updatedParams = updatedParamsList.join('&');

      navigate({
        pathname: location.pathname,
        search: updatedParams.toString()
      });

      localStorage.setItem(
        'hris-evaluation-schedule-search-params',
        updatedParams
      );

      removeSearchParam(name);
    }
  };

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getActiveScheduleTemplates());

    const savedSearchParams = localStorage.getItem(
      'hris-evaluation-schedule-search-params'
    );

    const savedPageIndex = localStorage.getItem(
      'hris-evaluation-schedule-page-no'
    );

    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }

    if (savedSearchParams) {
      const parsedSearchParams = new URLSearchParams(savedSearchParams);
      form.reset(Object.fromEntries(parsedSearchParams));
      fetchScheduleData();
    } else {
      // set default status
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
  }, []);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    fetchScheduleData();
  }, [location.search, evaluationTemplates]);

  useEffect(() => {
    localStorage.setItem(
      'hris-evaluation-schedule-page-no',
      JSON.stringify(page)
    );
  }, [page]);

  useEffect(() => {
    return () => {
      // Cleanup hris-evaluation-schedule-search-params
      localStorage.setItem('hris-evaluation-schedule-search-params', '');
    };
  }, [navigate]);

  useEffect(() => {
    if (showDeleteModal === null) {
      document.body.style.overflow = 'unset';
    }
  }, [showDeleteModal]);

  return {
    form,
    inputs,
    hasMore,
    deleteId,
    showDeleteModal,
    scheduleColumns,
    scheduleListMemo,
    submitFilter,
    fetchScheduleData,
    handleDeleteModal,
    detectDateOnChange,
    setShowDeleteModal,
    handleDeleteSchedule
  };
};

export default useEvaluationScheduleList;
