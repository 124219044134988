import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getMyAssessmentByIDService } from 'src/api/modules/assessment';
import { setMyAssessment } from 'src/redux/modules/assessment/assessmentActions';
// Others
import { toast } from 'react-toastify';
import { CgCloseO } from 'react-icons/cg';

const useMyAssessmentAction = (userAssessmentId, viewOnly) => {
  const defaultFields = {
    id: null,
    assessment_id: '',
    user_id: '',
    start_date: '',
    end_date: '',
    status: '',
    created_by: '',
    updated_by: '',
    created_at: '',
    updated_at: '',
    assessment: {
      id: null,
      skill_id: '',
      code: '',
      title: '',
      content: '',
      status: '',
      type: '',
      skill_name: '',
      duration: '',
      total_questions: '',
      quizzes: [
        {
          questions: []
        }
      ]
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [myAssessmentInfo, setMyAssessmentInfo] = useState(defaultFields);
  const [showConfirmModal, setShowConfirmModal] = useState(null);

  const getMyAssessmentInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getMyAssessmentByIDService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      const responseData = response?.data;

      if (responseData) {
        const { assessment, user_assessment_attempts } = responseData;

        const quizzes = assessment.quizzes.map(quiz => ({
          ...quiz,
          user_attempt: {
            ...(user_assessment_attempts.find(
              item => item.quiz_id === quiz.id
            ) || {})
          }
        }));

        const updatedResponseData = {
          ...responseData,
          assessment: {
            ...assessment,
            quizzes
          }
        };

        setMyAssessmentInfo(updatedResponseData);
        dispatch(setMyAssessment(updatedResponseData));
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  };

  const handleTakeAssessment = useCallback(
    (modal, id) => {
      setShowConfirmModal(modal);

      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowConfirmModal]
  );

  useEffect(() => {
    if (userAssessmentId) {
      getMyAssessmentInfo(userAssessmentId);
    }
  }, [viewOnly]);

  return {
    myAssessmentInfo,
    handleTakeAssessment,
    showConfirmModal
  };
};

export default useMyAssessmentAction;
