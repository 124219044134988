import React from 'react';
import PropTypes from 'prop-types';
import { useTakeQuizModal } from 'src/hooks/Pages/LearningManagement/TakeQuiz/useTakeQuizModal';
import ConfirmModalUI from 'src/components/Common/ConfirmModalUI';

const TakeQuizModal = ({
  handleTakeQuizModal,
  handleConfirmTakeQuiz,
  handleCancelTakeQuiz,
  id,
  quizDuration,
  modal,
  section,
  resume
}) => {
  const { handleConfirm, handleCancel } = useTakeQuizModal({
    handleTakeQuizModal,
    handleConfirmTakeQuiz,
    handleCancelTakeQuiz,
    modal
  });

  const timeUnit = quizDuration <= 1 ? 'minute' : 'minutes';
  const takeMessage = (
    <span>
      You have <strong>{quizDuration}</strong> {timeUnit} to complete this
      section. The timer starts after you press the <strong>Start Quiz</strong>{' '}
      button below.
    </span>
  );

  const resumeMessage = (
    <span>
      You have <strong>{quizDuration}</strong> {timeUnit} left to complete this
      section.
    </span>
  );

  const message = resume ? resumeMessage : takeMessage;

  const expiresMessage =
    'Sorry, you have surpassed the allotted time for this section.';

  return (
    <ConfirmModalUI
      title={`Take Quiz: Section ${section}`}
      message={quizDuration <= 0 ? expiresMessage : message}
      confirmBtnLabel={quizDuration <= 0 ? 'OK' : 'Start Quiz'}
      confirm={() => handleConfirm(id)}
      cancel={() => handleCancel()}
      hideCancel={quizDuration <= 0}
    />
  );
};

TakeQuizModal.propTypes = {
  handleConfirmTakeQuiz: PropTypes.func,
  handleCancelTakeQuiz: PropTypes.func,
  handleTakeQuizModal: PropTypes.func,
  id: PropTypes.number,
  modal: PropTypes.string,
  quizDuration: PropTypes.number,
  section: PropTypes.number,
  resume: PropTypes.bool
};

export default TakeQuizModal;
