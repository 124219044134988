import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getCostCenterByIDService,
  deleteCostCenterService,
  getCostCenterDataItems
} from 'src/api/modules/costcenter';
import { CgCheckO, CgCloseO } from 'react-icons/cg';
import { MdOutlineClear, MdOutlineAddCircle } from 'react-icons/md';

import { getAllCostCenterService } from 'src/redux/modules/costcenter/costcenterActions';

export const useDeleteModal = ({
  handleDeleteModal,
  editModal,
  code,
  modal
}) => {
  const dispatch = useDispatch();
  const [costCenterDetail, setCostCenterDetail] = useState(null);

  const fetchNewUserList = async () => {
    getCostCenterDataItems(1)
      .then(() => {
        dispatch(getAllCostCenterService());
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    if (code) {
      getCostCenterByIDService(code).then(res => {
        const codeDetail = `Cost Center: ${res.data.cost_center_code}`;
        setCostCenterDetail(codeDetail);
      });
    }
  }, []);
  const handleDelete = codeId => {
    if (codeId) {
      deleteCostCenterService(codeId).then(res => {
        if (res.success) {
          toast.success('Successfully Deleted!', { icon: <CgCheckO /> });
          fetchNewUserList();
        } else {
          toast.error('Unable to delete!', { icon: <CgCloseO /> });
        }
        handleDeleteModal(modal);
        if (editModal) {
          editModal(null);
        }
      });
    }
  };
  return {
    costCenterDetail,
    handleDelete
  };
};
