import React from 'react';
import { useParams } from 'react-router-dom';

// Common Components
import Breadcrumbs from 'src/components/Common/Breadcrumbs';

// Hooks
import useAssessmentAction from 'src/hooks/Pages/LearningManagement/Assessment/useAssessmentAction';

// Assessment Components
import AddAssessment from 'src/Pages/LearningManagement/Assessment/components/AddAssessment';

// eslint-disable-next-line react/prop-types
const AssessmentAction = ({ menuCode, viewOnly }) => {
  const { id } = useParams();

  const {
    getAssessmentInfo,
    handleChange,
    handleSelectChange,
    assessmentFields,
    handleAssessmentSubmit,
    validationAssessmentSchema,
    statusOptions,
    handleSearchQuiz,
    searchQuiz,
    handleAddQuiz,
    addedQuiz,
    quizRef,
    handleDeleteQuiz,
    skillOptions,
    typeOptions,
    handleSearchUser,
    searchUser,
    handleAddUser,
    addedUser,
    usersRef,
    handleDeleteUser,
    handleDeadlineChange,
    statusData
  } = useAssessmentAction(id, viewOnly);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'Assessments', link: '/assessments' },
          {
            name: `${!id ? 'Add Assessment' : 'Assessment Information'}`
          }
        ]}
      />

      <div className="mt-5">
        <AddAssessment
          assessmentId={id}
          getAssessmentInfo={getAssessmentInfo}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          handleAssessmentSubmit={handleAssessmentSubmit}
          viewOnly={viewOnly}
          menuCode={menuCode}
          statusOptions={statusOptions}
          skillOptions={skillOptions}
          typeOptions={typeOptions}
          assessmentFields={assessmentFields}
          validationAssessmentSchema={validationAssessmentSchema}
          handleSearchQuiz={handleSearchQuiz}
          searchQuiz={searchQuiz}
          handleAddQuiz={handleAddQuiz}
          addedQuiz={addedQuiz}
          quizRef={quizRef}
          handleDeleteQuiz={handleDeleteQuiz}
          handleSearchUser={handleSearchUser}
          searchUser={searchUser}
          handleAddUser={handleAddUser}
          addedUser={addedUser}
          usersRef={usersRef}
          handleDeleteUser={handleDeleteUser}
          handleDeadlineChange={handleDeadlineChange}
          statusData={statusData}
        />
      </div>
    </div>
  );
};

export default AssessmentAction;
