export const GET_EMPLOYEES_LIST = 'GET_EMPLOYEES_LIST';
export const GET_ALL_EMPLOYEES_LIST = 'GET_ALL_EMPLOYEES_LIST';
export const GET_SALARY_GRADE = 'GET_SALARY_GRADE';
export const GET_GENDER = 'GET_GENDER';
export const GET_CIVIL_STATUS = 'GET_CIVIL_STATUS';
export const GET_EMPLOYMENT_STATUS = 'GET_EMPLOYMENT_STATUS';
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const SET_LIST_UPDATE = 'SET_LIST_UPDATE';
export const GET_TEAMS = 'GET_TEAMS';
export const GET_POSITIONS = 'GET_POSITIONS';
