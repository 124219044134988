import axios from 'axios';
import { QUIZZES, BULK_DELETE_QUIZZES } from '../endpoints';
import { fetchAPI } from '../fetchAPI';
import { auth, ENV } from '../config';

export const getQuizItemsService = async (page, searchParams) => {
  try {
    let endpoint = `${QUIZZES}?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getQuizByIDService = async quiz_id => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${QUIZZES}/${quiz_id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateQuestionByIdService = async (id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${QUIZZES}/${id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const createQuizService = async data => {
  try {
    const response = await axios.post(`${ENV.test}${QUIZZES}`, data, auth());
    return response;
  } catch (error) {
    return error;
  }
};

export const updateQuizQuestionsByIdService = async (quiz_id, data) => {
  try {
    const response = await axios.post(
      `${ENV.test}${QUIZZES}/${quiz_id}/questions`,
      data,
      auth()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteQuizByIdService = async quiz_id => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${QUIZZES}/${quiz_id}`
    });
  } catch (error) {
    return error;
  }
};

export const updateQuizByIdService = async (quiz_id, data) => {
  try {
    return await fetchAPI({
      method: 'PUT',
      endpoint: `${QUIZZES}/${quiz_id}`,
      body: data
    });
  } catch (error) {
    return error;
  }
};

export const getAllQuizItemService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: `${QUIZZES}?all=true`
    });
  } catch (error) {
    return error;
  }
};

export const bulkDeleteQuizService = async ids => {
  try {
    // const response = await axios.delete(`${ENV.test}${BULK_DELETE_QUIZZES}`, {
    //   data: { ids },
    //   ...auth()
    // });

    return await fetchAPI({
      method: 'DELETE',
      endpoint: BULK_DELETE_QUIZZES,
      params: { data: { ids } }
    });
  } catch (error) {
    return error;
  }
};

export const deleteQuestionService = async (quiz_id, ids) => {
  try {
    return await fetchAPI({
      method: 'DELETE',
      endpoint: `${QUIZZES}/${quiz_id}/questions`,
      params: { data: { ids } }
    });
  } catch (error) {
    return error;
  }
};

export const getAllQuizListService = async () => {
  try {
    const endpoint = `${QUIZZES}/all`;
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};

export const getQuizQuestionItemsService = async (
  quiz_id,
  page,
  searchParams
) => {
  try {
    let endpoint = `${QUIZZES}/${quiz_id}/questions?page=${page}`;
    if (searchParams) {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      endpoint += `&${searchParamsString}`;
    }
    return await fetchAPI({
      method: 'GET',
      endpoint
    });
  } catch (error) {
    return error;
  }
};
