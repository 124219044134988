import { SKILLS_ALL } from '../endpoints';
import { fetchAPI } from '../fetchAPI';

export const getAllSkillsService = async () => {
  try {
    return await fetchAPI({
      method: 'GET',
      endpoint: SKILLS_ALL
    });
  } catch (error) {
    return error;
  }
};
