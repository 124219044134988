import React from 'react';
import 'src/assets/v1/css/userEvaluation.scss';
import PropTypes from 'prop-types';

const UserEvaluationDetails = ({ userDetails }) => {
  return (
    <div className="user-evaluation__container">
      {userDetails ? (
        <div className="user-evaluation_inner">
          {userDetails.map(userDetail => (
            <div
              className="user-evaluation__content user-evaluation-details__content"
              key={userDetail.id}
            >
              <div className="user-evaluation__column">
                <span className="user-evaluation__label">Name</span>
                <span className="user-evaluation__item">{userDetail.name}</span>
              </div>
              <div className="user-evaluation__column">
                <span className="user-evaluation__label">Job Title</span>
                <span className="user-evaluation__item">
                  {userDetail.jobTitle}
                </span>
              </div>
              <div className="user-evaluation__column">
                <span className="user-evaluation__label">Latest Project</span>
                <span className="user-evaluation__item">
                  {userDetail.latestProject}
                </span>
              </div>
              <div className="user-evaluation__column">
                <span className="user-evaluation__label">Salary Grade</span>
                <span className="user-evaluation__item">
                  {userDetail.salaryGrade}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

UserEvaluationDetails.propTypes = {
  userDetails: PropTypes.arrayOf(PropTypes.instanceOf(Object))
};

export default UserEvaluationDetails;
