import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getProject } from 'src/api/v1/modules/projects';
import Breadcrumbs from 'src/components/v1/Common/Breadcrumbs';
import SectionTitle from 'src/components/v1/Common/SectionTitle';
import PageTitle from 'src/components/v1/Common/PageTitle';
import Button from 'src/components/v1/Common/Button';
import Datatable from 'src/components/v1/Common/Datatable';
import 'src/assets/v1/css/projectDetails.scss';

const ProjectDetails = () => {
  const [ProDetails, setProDetails] = useState({});
  const [ProjectMemberList, setProjectMemberList] = useState([]);
  const [TechnicalDirector, setTechnicalDirector] = useState('');
  const [ProjectManager, setProjectManager] = useState('');
  const [BridgeEngineer, setBridgeEngineer] = useState('');
  const [ProjectManagerCebu, setProjectManagerCebu] = useState('');
  const [Industry, setIndustry] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(async () => {
    GetProjectDetails();
  }, []);

  const GetProjectDetails = () => {
    getProject(params.id)
      .then(res => {
        setProDetails(res.data.data);
        setProjectMemberList(
          res.data.data.members.map(p => ({
            id: p.id,
            member: `${p.last_name}, ${p.first_name}`,
            userIcon: 'icons/profile.svg',
            occupancy: p.occupancy,
            role: p.role,
            skill: p.skill.name,
            start_date: p.start_date,
            end_date: p.end_date
          }))
        );
        setTechnicalDirector(
          `${res.data.data.TechnicalDirector.last_name}, ${res.data.data.TechnicalDirector.first_name}`
        );
        setProjectManager(
          `${res.data.data.ProjectManager.last_name}, ${res.data.data.ProjectManager.first_name}`
        );
        setBridgeEngineer(
          `${res.data.data.BridgeEngineer.last_name}, ${res.data.data.BridgeEngineer.first_name}`
        );
        setProjectManagerCebu(
          `${res.data.data.ProjectManagerCebu.last_name}, ${res.data.data.ProjectManagerCebu.first_name}`
        );
        setIndustry(res.data.data.Industry.name);
      })
      .catch(err => {});
  };

  const ProjectDetailColumns = [
    {
      key: 'member',
      label: 'Member',
      type: 'input',
      userIconKey: 'userIcon'
    },
    { key: 'occupancy', label: 'Occupancy', type: 'input' },
    {
      key: 'role',
      label: 'Role',
      type: 'select',
      selectOptions: ['Designer', 'Frontend', 'Backend']
    },
    {
      key: 'skill',
      label: 'Skill Set',
      type: 'input'
    },
    {
      key: 'start_date',
      label: 'Assign Start Date',
      type: 'date'
    },
    {
      key: 'end_date',
      label: 'Assign End Date',
      type: 'date'
    }
  ];

  return (
    <div className="project-details__container">
      <div className="project-details__heading">
        <div className="project-details__heading--title">
          <PageTitle title="Project Details" backButton backPath="/project" />
        </div>
        <Breadcrumbs
          crumbs={[{ link: '/project', name: 'Projects' }, 'Project Details']}
        />
      </div>

      <div className="project-details__section">
        <div className="basic-information">
          <div className="basic-information__heading">
            <SectionTitle title="Basic Information" />
            <Button
              name="Edit Information"
              modifier="button__edit gray"
              onClick={() => navigate(`${location.pathname}/edit`)}
            />
          </div>

          <div className="basic-information__content">
            <div className="activeProjects__column">
              <div className="activeProjects__row">
                <span className="activeProjects__label">Project Code</span>
                <span className="activeProjects__text">
                  {ProDetails.project_code}
                </span>
              </div>

              <div className="activeProjects__row">
                <span className="activeProjects__label">Project Name</span>
                <span className="activeProjects__text">{ProDetails.name}</span>
              </div>

              <div className="activeProjects__row">
                <span className="activeProjects__label">Start Date</span>
                <span className="activeProjects__text">
                  {ProDetails.start_date}
                </span>
              </div>

              <div className="activeProjects__row">
                <span className="activeProjects__label">End Date</span>
                <span className="activeProjects__text">
                  {ProDetails.end_date}
                </span>
              </div>
            </div>

            <div className="activeProjects__column">
              <div className="activeProjects__row">
                <span className="activeProjects__label">Client</span>
                <span className="activeProjects__text">
                  {ProDetails.client_name}
                </span>
              </div>

              <div className="activeProjects__row">
                <span className="activeProjects__label">
                  Client Representative
                </span>
                <span className="activeProjects__text">
                  {ProDetails.client_representative}
                </span>
              </div>

              <div className="activeProjects__row">
                <span className="activeProjects__label">Manpower</span>
                <span className="activeProjects__text">
                  {ProDetails.manpower}
                </span>
              </div>

              <div className="activeProjects__row">
                <span className="activeProjects__label">Status</span>
                <span className="activeProjects__text">
                  {ProDetails.status}
                </span>
              </div>
            </div>

            <div className="activeProjects__column">
              <div className="activeProjects__row">
                <span className="activeProjects__label">
                  Project Description
                </span>
                <span className="activeProjects__text">
                  {ProDetails.description}
                </span>
              </div>
            </div>

            <div className="activeProjects__column">
              <div className="activeProjects__row">
                <div className="activeProjects__members">
                  <img
                    className="activeProjects__memberImage"
                    src="images/user-sample.jpeg"
                    alt=""
                  />
                  <div className="activeProjects__memberInfo">
                    <p className="activeProjects__role">Technical Director</p>
                    <p className="activeProjects__member">
                      {TechnicalDirector}
                    </p>
                  </div>
                </div>
              </div>

              <div className="activeProjects__row">
                <div className="activeProjects__members">
                  <img
                    className="activeProjects__memberImage"
                    src="images/user-sample.jpeg"
                    alt=""
                  />
                  <div className="activeProjects__memberInfo">
                    <p className="activeProjects__role">Cebu Project Manager</p>
                    <p className="activeProjects__member">
                      {ProjectManagerCebu}
                    </p>
                  </div>
                </div>
              </div>

              <div className="activeProjects__row">
                <div className="activeProjects__members">
                  <img
                    className="activeProjects__memberImage"
                    src="images/user-sample.jpeg"
                    alt=""
                  />
                  <div className="activeProjects__memberInfo">
                    <p className="activeProjects__role">Project Manager</p>
                    <p className="activeProjects__member">{ProjectManager}</p>
                  </div>
                </div>
              </div>

              <div className="activeProjects__row">
                <div className="activeProjects__members">
                  <img
                    className="activeProjects__memberImage"
                    src="images/user-sample.jpeg"
                    alt=""
                  />
                  <div className="activeProjects__memberInfo">
                    <p className="activeProjects__role">Bridge Engineer</p>
                    <p className="activeProjects__member">{BridgeEngineer}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="activeProjects__column">
              <div className="activeProjects__row">
                <span className="activeProjects__label">Industry</span>
                <span className="activeProjects__text">{Industry}</span>
              </div>
              <div className="activeProjects__row">
                <div className="projects__column projects__checkbox-wrapper">
                  <label
                    className="projects__checkbox_custom-checkbox"
                    htmlFor="outsideCody"
                  >
                    <input
                      type="checkbox"
                      className="projects__input-checkbox"
                      id="outsideCody"
                      name="outsideCody"
                      disabled="false"
                      checked={ProDetails.external}
                    />
                    <span className="projects__checkbox-checkmark">
                      Outside Cody
                    </span>
                  </label>
                </div>
              </div>
              <div className="activeProjects__row" />
            </div>
          </div>
        </div>
      </div>

      <div className="project-details__section">
        <div className="project-members">
          <div className="project-members__heading">
            <SectionTitle title="Project Members" />
            <Button
              name="Add Member"
              modifier="button__add-white dark"
              onClick={() => navigate(`${location.pathname}/add-member`)}
            />
          </div>

          <div className="project-members__content">
            <Datatable
              datasource={ProjectMemberList}
              editable
              clickableRows
              keyField="id"
              link="edit-member"
              headingColumns={ProjectDetailColumns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
