/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

const AddQuestionModal = ({
  quizId,
  handleSearchQuestions,
  searchQuestion,
  handleAddQuestion,
  addedQuestion,
  questionsRef,
  handleDeleteSearchModal,
  handleQuestionAddSubmit
}) => {
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={[]}
      onSubmit={() => handleQuestionAddSubmit(quizId)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, touched, setFieldValue, setFieldTouched, setFieldError }) => (
        <Form id="questionForm">
          <h4 className="text-[22px] flex justify-between items-center w-auto mx-[35px] border-solid border-b-[1px] border-[#eaeaea] pb-[20px]">
            Add Question
            <button
              type="submit"
              className="text-[12px] text-white font-normal leading-[100%] bg-gray-400 hover:bg-gray-500 border-none p-[14px_41px] rounded"
              onClick={e => {
                navigate(`/quiz-manager/${quizId}/questions`);
              }}
            >
              <span className="relative before:content-[''] before:block before:w-3 before:h-3 before:bg-[url('/src/assets/icons/save.svg')] before:bg-no-repeat before:bg-center before:absolute before:top-[50%] before:left-0 before:translate-y-[-50%] before:translate-x-0 pl-[18px]">
                Save
              </span>
            </button>
          </h4>

          <div className="p-[31px_30px_28px_30px] rounded-[3px] mb-5">
            <div className="flex">
              <div className="w-full">
                <div className="mb-4 relative">
                  <label
                    className="block mb-2.5 text-[12px] text-[#414141] leading-[14px] font-stolzlBook"
                    htmlFor="questions"
                  >
                    Questions
                    <div className="relative">
                      <input
                        className={`block w-full bg-white border-solid border-[1px] mt-[5px] ${
                          errors.questions
                            ? 'border-[#E43B26] placeholder-[#E43B26]'
                            : 'border-[#eaeaea] text-[14px]'
                        } rounded placeholder:text-[14px] focus:outline-none focus:border-[#000] placeholder:font-stolzlBook placeholder:text-[#797979] font-stolzlBook text-[14px] text-[#232932] font-normal leading-[17px] h-10 pl-[34px] pr-3 bg-no-repeat bg-[10px_10px] bg-[url('/src/assets/icons/black-search-icon.svg')]`}
                        type="text"
                        name="questions"
                        id="questions"
                        ref={questionsRef}
                        placeholder="Search"
                        autoComplete="off"
                        onChange={e =>
                          handleSearchQuestions(
                            e,
                            setFieldValue,
                            setFieldTouched,
                            setFieldError
                          )
                        }
                      />
                    </div>
                  </label>
                  {searchQuestion.length ? (
                    <div className="absolute top-62px left-0 w-full bg-white z-20 shadow-[0_2px_4px_rgba(0,0,0,0.16)]">
                      <ul className="max-h-[405px] overflow-auto custom-scrollba divide-y divide-[#efefef]">
                        {searchQuestion.map(item => (
                          <li
                            key={item.id}
                            className="flex justify-between gap-x-6 py-4 px-4 font-stolzlBook text-[14px] hover:bg-[#E8F1FF60] ease duration-200"
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                            role="button"
                            tabIndex={0}
                            onClick={() => handleAddQuestion(item)}
                            onKeyDown={event => {
                              if (event.key === 'Enter' || event.key === ' ') {
                                handleAddQuestion(item);
                              }
                            }}
                          >
                            <div className="flex min-w-0 gap-x-4">
                              <div className="min-w-0 flex-auto">
                                <p className="text-sm leading-6 text-[#222222]">
                                  {item.content}
                                </p>
                                <p className="mt-1 truncate text-xs leading-5 text-[#999999]">
                                  {item.skill.name}
                                </p>
                              </div>
                            </div>
                            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                              <p className="text-xs leading-6 text-[#666666] uppercase">
                                {item.level}
                              </p>
                              <p className="mt-1 text-xs leading-5 text-[#999999]">
                                <time dateTime="2023-01-23T13:23Z">
                                  {item.points}
                                </time>{' '}
                                PTS
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>

                {/* table start */}
                <div className="flex flex-row items-center gap-[5px] border-solid border-b-[1px] border-[#eaeaea] pb-2.5 mb-[18px]">
                  <div className="basis-[50%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Content
                    </h5>
                  </div>
                  <div className="basis-[20%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Skill
                    </h5>
                  </div>
                  <div className="basis-[15%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Difficulty
                    </h5>
                  </div>
                  <div className="basis-[5%]">
                    <h5 className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook">
                      Points
                    </h5>
                  </div>
                  <div className="flex-none w-5" />
                </div>
                <div className="h-[240px] overflow-auto custom-scrollbar">
                  {addedQuestion.length ? (
                    addedQuestion.map(question => (
                      <div
                        className="flex flex-row items-center gap-[5px] mb-4"
                        key={question.id}
                      >
                        <div className="basis-[50%]">
                          <p
                            title={question.content}
                            className="text-[14px] text-[#232932] font-normal leading-[17px] font-stolzlBook overflow-hidden text-ellipsis line-clamp-1 max-w-[360px]"
                          >
                            {question.content}
                          </p>
                        </div>
                        <div className="basis-[20%]">
                          <p className="text-[14px] text-[#232932] font-normal leading-[17px] font-stolzlBook">
                            {question.skill.name}
                          </p>
                        </div>
                        <div className="basis-[15%]">
                          <p className="text-[14px] text-[#232932] font-normal leading-[17px] font-stolzlBook">
                            {question.level}
                          </p>
                        </div>
                        <div className="basis-[5%]">
                          <p className="text-[14px] text-[#232932] font-normal leading-[17px] font-stolzlBook text-center">
                            {question.points}
                          </p>
                          <div />
                        </div>
                        <div className="flex-none w-5">
                          <button
                            type="button"
                            className="relative inline-block align-super w-4 h-3.5"
                            onClick={() => handleDeleteSearchModal(question.id)}
                          >
                            <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-45 rounded" />
                            <span className="block w-[13px] h-0.5 bg-[#d8dadc] absolute top-[calc(50%+1px)] left-[calc(50%+5px)] translate-y-[-50%] translate-x-[-50%] rotate-[-45deg] rounded" />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-[12px] text-[#414141] font-normal leading-[14px] font-stolzlBook text-center">
                      No questions selected.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddQuestionModal;
