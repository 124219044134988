import React from 'react';
import PropTypes from 'prop-types';

const EvaluationSubSectionTitle = ({ title, weight }) => {
  return (
    <div className="user-evaulation-sub-section__title">
      <div className="user-evaulation-sub-section__column">
        <div className="user-evaulation-sub-section__label weight__bold">
          {title}
        </div>
      </div>
    </div>
  );
};

EvaluationSubSectionTitle.propTypes = {
  title: PropTypes.string,
  weight: PropTypes.number
};

export default EvaluationSubSectionTitle;
