export const userEvaluationColumns = [
  {
    key: 'id',
    label: 'Employee ID',
    readOnly: true
  },
  {
    key: 'name',
    label: 'Name',
    readOnly: true
  },
  {
    key: 'position',
    label: 'Position',
    readOnly: true
  },
  {
    key: 'template',
    label: 'Template',
    readOnly: true
  },
  {
    key: 'date',
    label: 'Date',
    type: 'date',
    readOnly: true
  }
];
