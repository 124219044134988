import PropTypes from 'prop-types';
import { useBulkDeleteQuestionModal } from 'src/hooks/Pages/LearningManagement/QuizManager/useBulkDeleteQuestionModal';
import DeleteModalUI from 'src/components/Common/DeleteModalUI';

const BulkDeleteQuestionModal = ({ quizId, handleBulkDeleteModal }) => {
  const { handleDelete } = useBulkDeleteQuestionModal({
    handleBulkDeleteModal
  });

  return (
    <DeleteModalUI
      label="the selected item"
      submit={() => handleDelete(quizId)}
      cancel={() => handleBulkDeleteModal(null)}
    />
  );
};
BulkDeleteQuestionModal.propTypes = {
  quizId: PropTypes.string,
  handleBulkDeleteModal: PropTypes.func
};
export default BulkDeleteQuestionModal;
