import React from 'react';

// Common components
import { Breadcrumbs } from 'src/components/Common';
import PropTypes from 'prop-types';

// hooks
import useAssessmentResult from 'src/hooks/Pages/LearningManagement/Assessment/useAssessmentResult';

// assement result component
import Results from 'src/Pages/LearningManagement/components/Results';

const AssessmentResult = ({ menuCode }) => {
  const { assessmentResult, getRemarks } = useAssessmentResult(menuCode);

  return (
    <div className="user__container">
      <Breadcrumbs
        crumbs={[
          { name: 'Learning Management' },
          { name: 'Assessments', link: '/assessments' },
          { name: assessmentResult?.assessment?.title }
        ]}
      />

      <div className="mt-5">
        <Results assessmentResult={assessmentResult} getRemarks={getRemarks} />
      </div>
    </div>
  );
};

AssessmentResult.propTypes = {
  menuCode: PropTypes.string
};

export default AssessmentResult;
