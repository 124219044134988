import { useDeleteModal } from 'src/hooks/components/Parts/Projects/useDeleteModal';
import PropTypes from 'prop-types';

const DeleteModal = ({ handleDeleteModal, id, name, modal }) => {
  const { projectName, handleDelete } = useDeleteModal({
    handleDeleteModal,
    id,
    modal
  });

  return (
    <>
      <div className="font-stolzlBold text-[14px] p-[14px] border-b-[1px] border-[#DEDEDE] relative">
        Delete
        <button
          className="w-[12px] h-[12px] absolute right-[15px] top-1/2 transform -translate-y-1/2 after:content-[''] after:w-full after:h-[2px] after:left-0 before:left-0 after:bg-[#999999] after:absolute after:rotate-45 after:rounded before:rounded before:content-[''] before:w-full before:h-[2px] before:bg-[#999999] before:absolute before:-rotate-45"
          type="button"
          onClick={() => handleDeleteModal(modal)}
        />
      </div>
      <div className="text-center font-stolzlRegular text-[14px] mt-7">
        Are you sure you want to delete
      </div>
      <div className="text-center font-stolzlBold mt-2 capitalize">
        {name || projectName}?
      </div>
      <div className="flex justify-between w-[210px] mt-[30px] m-auto">
        <div className="">
          <button
            form="userGrpForm"
            type="submit"
            className="text-[12px] text-white leading-[100%] bg-delete hover:bg-[#F29396] border-none rounded w-[100px] h-10"
            onClick={() => handleDelete(id)}
          >
            <span>Yes</span>
          </button>
        </div>
        <div className="">
          <button
            type="button"
            className="text-[12px] text-[#232932] font-normal leading-[100%] bg-gray-700 hover:bg-gray-600 border-none rounded w-[100px] h-10"
            onClick={() => handleDeleteModal(modal)}
          >
            <span>No</span>
          </button>
        </div>
      </div>
    </>
  );
};

DeleteModal.propTypes = {
  handleDeleteModal: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  modal: PropTypes.string
};

export default DeleteModal;
