import * as Yup from 'yup';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  updateQuizByIdService,
  createQuizService,
  getQuizByIDService
} from 'src/api/modules/quiz';

// Utilities
import { getLowerCasedValue } from 'src/helpers/utils';

// Others
import { toast } from 'react-toastify';
import { CgCheckO, CgCloseO } from 'react-icons/cg';

import { getAllSkillsList } from 'src/redux/modules/skill/skillActions';
import { getAllStatusList } from 'src/redux/modules/status/statusActions';

const useQuizManagerAction = (quizId, viewOnly) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const skillsRef = useRef(null);

  const defaultQuizFields = {
    title: '',
    duration: '',
    status: 'N',
    description: '',
    code: '',
    image: null,
    skills: []
  };

  const skillList = useSelector(state => state.skill.skills);
  const statusList = useSelector(state => state.status.statuses);
  const form = useForm({ defaultValues: undefined });
  const [searchSkill, setSearchSkill] = useState([]);
  const [addedSkill, setAddedSkill] = useState([]);
  const [quizFields, setQuizFields] = useState(defaultQuizFields);

  const handleSearchSkills = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    let foundSkill = [];
    const {
      target: { name, value }
    } = e;
    if (value.length) {
      foundSkill = skillList.filter(data =>
        `${getLowerCasedValue(data.name)}`.includes(
          getLowerCasedValue(e.target.value)
        )
      );
    }
    setSearchSkill(foundSkill);
    setFieldValue('skill', value);
    setFieldTouched('skill', true);
    setFieldError('skill', '');
  };

  const handleSelectChange = useCallback((value, name) => {
    setQuizFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleNumberInput = e => {
    const { name } = e.target;
    const newValue = e.target.value.replace(/[^\d.-]+e/g, '');
    const validValue = newValue.slice(0, 3);

    setQuizFields(prevState => ({
      ...prevState,
      [name]: validValue
    }));
  };

  const handleChange = (
    e,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    allowAllCharacters = true
  ) => {
    const { name, value } = e.target;
    const filteredValue = !allowAllCharacters
      ? e.target.value.replace(/[^a-zA-Z0-9]/g, '')
      : value;

    setQuizFields(prevState => ({
      ...prevState,
      [name]: filteredValue
    }));
  };

  const handleAddSkill = e => {
    const dataFind = quizFields?.skills.some(data => data === e.id);
    if (dataFind) {
      setSearchSkill([]);
      return;
    }

    const { name, id } = e;
    const skillInfo = { name, id };

    setAddedSkill(prevState => [...prevState, skillInfo]);
    setQuizFields(prevState => ({
      ...prevState,
      skills: [...prevState.skills, id]
    }));

    setSearchSkill([]);
    skillsRef.current.value = null;
  };

  const handleDeleteSkill = skillId => {
    const updatedQuiz = quizFields?.skills.filter(e => {
      return e !== skillId;
    });
    const updatedSkills = addedSkill?.filter(e => {
      return e.id !== skillId;
    });

    setQuizFields(prevState => ({
      ...prevState,
      skills: updatedQuiz
    }));
    setAddedSkill(updatedSkills);
  };

  const statusOptions = statusList
    .filter(status => status.code !== 'F')
    .map(status => ({
      value: status.code,
      label: status.name,
      hidden: false
    }));

  const validCodeChars = /^[a-zA-Z0-9]+$/;
  const validCodeCharsMessage = 'Only letters and numbers are allowed';
  const blankSpaceRegex = /^\S.*$/;
  const blankSpageMessage =
    'Only letters with spaces, hyphens, or periods are allowed';

  const validationQuizSchema = Yup.object({
    title: Yup.string()
      .required('Title is required')
      .max(255, 'Maximum 255 characters allowed')
      .matches(blankSpaceRegex, blankSpageMessage),
    description: Yup.string()
      .required('Description is required')
      .matches(blankSpaceRegex, blankSpageMessage),
    status: Yup.string().required('Status is required'),
    duration: Yup.number().required('Duration is required'),
    code: Yup.string()
      .required('Code is required')
      .matches(validCodeChars, validCodeCharsMessage)
      .min(2, 'Add at least 2 characters')
      .max(8, 'Maximum of 8 characters allowed')
  });

  const handleQuizSubmit = useCallback(async id => {
    try {
      if (id) {
        const updateResponse = await updateQuizByIdService(id, quizFields);
        if (updateResponse.statusCode === 200) {
          toast.success('Successfully Updated!', { icon: <CgCheckO /> });
          navigate('/quiz-manager');
        } else {
          toast.error('Unable to update!', { icon: <CgCloseO /> });
        }
      } else {
        const addResponse = await createQuizService(quizFields);
        if (addResponse.status === 201) {
          toast.success('Successfully Added!', { icon: <CgCheckO /> });
          navigate('/quiz-manager');
        } else {
          toast.error('Add Quiz Failed', { icon: <CgCloseO /> });
        }
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  });

  const getQuizInfo = async id => {
    try {
      if (typeof id !== 'number' && !Number.isInteger(parseInt(id, 10))) {
        return;
      }
      const response = await getQuizByIDService(id);

      if (response?.response?.data.statusCode === 404) {
        navigate('/page-not-found');
      }

      if (response.data) {
        const responseData = response.data;
        const skillIds = responseData.skills.map(item => item.id);
        const quizData = {
          title: responseData.title,
          description: responseData.description,
          code: responseData.code,
          skills: skillIds,
          duration: responseData.duration.toString(),
          status: responseData.status
        };
        setQuizFields(quizData);
        setAddedSkill(responseData.skills);
      }
    } catch (error) {
      toast.error('Error fetching data:', { icon: <CgCloseO /> });
    }
  };

  useEffect(() => {
    if (quizId) {
      getQuizInfo(quizId);
    }
  }, [viewOnly]);

  useEffect(() => {
    dispatch(getAllSkillsList());
  }, []);

  useEffect(() => {
    dispatch(getAllStatusList());
  }, []);

  return {
    getQuizInfo,
    handleChange,
    handleNumberInput,
    handleSelectChange,
    setQuizFields,
    handleSearchSkills,
    handleAddSkill,
    handleDeleteSkill,
    handleQuizSubmit,
    validationQuizSchema,
    form,
    statusOptions,
    quizFields,
    skillList,
    searchSkill,
    addedSkill,
    skillsRef
  };
};

export default useQuizManagerAction;
