export const departmentsList = [
  {
    id: 1,
    name: 'Operations'
  },
  {
    id: 2,
    name: 'Back Office'
  }
];

export const positionsList = [
  {
    id: 1,
    name: 'QA',
    department_id: 1
  },
  {
    id: 2,
    name: 'FE',
    department_id: 1
  },
  {
    id: 3,
    name: 'BE',
    department_id: 1
  },
  {
    id: 4,
    name: 'ZOZO',
    department_id: 1
  },
  {
    id: 5,
    name: 'DE',
    department_id: 1
  },
  {
    id: 6,
    name: 'Mobile',
    department_id: 1
  },
  {
    id: 7,
    name: 'HR',
    department_id: 2
  },
  {
    id: 8,
    name: 'IT',
    department_id: 2
  },
  {
    id: 9,
    name: 'Accounting',
    department_id: 2
  },
  {
    id: 10,
    name: 'Admin',
    department_id: 2
  }
];

export const salaryGrades = [
  {
    value: 0,
    label: 'Select'
  },
  {
    value: 1,
    label: 'G1'
  },
  {
    value: 2,
    label: 'G2'
  },
  {
    value: 3,
    label: 'G3'
  },
  {
    value: 4,
    label: 'SG1'
  },
  {
    value: 5,
    label: 'SG2'
  },
  {
    value: 6,
    label: 'SG3'
  }
];

export const ratingRange = [1, 2, 3, 4, 5];

export const sectionTypeOption = [
  {
    value: 1,
    label: 'Target / Result'
  },
  {
    value: 2,
    label: 'Rating'
  },
  {
    value: 3,
    label: 'Comment'
  }
];

export const sectionWeight = [
  {
    value: 0,
    label: 'Select'
  },
  {
    value: 10,
    label: '10%'
  },
  {
    value: 20,
    label: '20%'
  },
  {
    value: 30,
    label: '30%'
  },
  {
    value: 40,
    label: '40%'
  },
  {
    value: 50,
    label: '50%'
  },
  {
    value: 60,
    label: '60%'
  },
  {
    value: 70,
    label: '70%'
  },
  {
    value: 80,
    label: '80%'
  },
  {
    value: 90,
    label: '90%'
  },
  {
    value: 100,
    label: '100%'
  }
];

export const divisions_list = [
  {
    id: 1,
    code: '00',
    name: 'LIGJP'
  },
  {
    id: 2,
    code: '01',
    name: 'CODY'
  },
  {
    id: 3,
    code: '02',
    name: 'LTV'
  }
];

export const departments_list = [
  {
    id: 1,
    code: '00',
    name: 'Back Office',
    division: 'CODY'
  },
  {
    id: 2,
    code: '01',
    name: 'Operations',
    division: 'CODY'
  }
];

export const sections_list = [
  {
    id: 1,
    code: '00',
    name: 'HR Admin'
  },
  {
    id: 2,
    code: '01',
    name: 'Accounting'
  },
  {
    id: 3,
    code: '02',
    name: 'Frontend'
  },
  {
    id: 4,
    code: '03',
    name: 'Backend'
  },
  {
    id: 5,
    code: '04',
    name: '.net'
  },
  {
    id: 6,
    code: '05',
    name: 'Dev Support'
  }
];

export const sub_sections_list = [
  {
    id: 1,
    code: '00',
    name: 'Support'
  },
  {
    id: 2,
    code: '01',
    name: 'Recruitment'
  },
  {
    id: 3,
    code: '02',
    name: 'Legal'
  },
  {
    id: 4,
    code: '03',
    name: 'Accounting'
  },
  {
    id: 5,
    code: '04',
    name: 'Frontend 1'
  },
  {
    id: 6,
    code: '05',
    name: 'Frontend 2'
  },
  {
    id: 7,
    code: '06',
    name: 'Frontend 3'
  },
  {
    id: 8,
    code: '07',
    name: 'Backend 1'
  },
  {
    id: 9,
    code: '08',
    name: 'Backend 2'
  },
  {
    id: 10,
    code: '09',
    name: 'Mobile'
  },
  {
    id: 11,
    code: '10',
    name: '.net Team 1'
  },
  {
    id: 12,
    code: '11',
    name: '.net Team 2'
  },
  {
    id: 13,
    code: '12',
    name: '.net Team 3'
  },
  {
    id: 14,
    code: '13',
    name: 'Quality Assurance'
  },
  {
    id: 15,
    code: '14',
    name: 'Designers'
  }
];
