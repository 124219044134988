export const useSubmitQuizModal = ({
  handleSubmitQuizModal,
  handleConfirmSubmitQuiz,
  handleCancelSubmitQuiz
}) => {
  const handleConfirm = quizId =>
    handleConfirmSubmitQuiz(quizId, handleSubmitQuizModal);

  const handleCancel = () => handleCancelSubmitQuiz();

  return {
    handleConfirm,
    handleCancel
  };
};
