import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {
  getProjectsService,
  getProjectsServiceList
} from 'src/api/modules/projects';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import {
  getLowerCasedValue,
  filterData,
  updateObjectById
} from 'src/helpers/utils';
import { setProjectListUpdate } from 'src/redux/modules/projects/projectActions';

const useProjectsIndex = () => {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const dataList = useSelector(state => state.projects.dataList);
  const { isListUpdated } = useSelector(state => state.projects);
  const [showModal, setShowModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [projectName, setProjectName] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(null);
  const form = useForm({ defaultValues: undefined });
  const location = useLocation();
  const navigate = useNavigate();

  const projectColumns = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'client',
      label: 'Client'
    },
    {
      key: 'project_name',
      label: 'Project Name'
    },
    {
      key: 'project_leader',
      label: 'Project Leader'
    },
    {
      key: 'project_date',
      label: 'Project Date'
    },
    {
      key: 'total_members',
      label: 'Total Members'
    },
    {
      key: 'status',
      label: 'Status'
    }
  ];

  const statusOptions = [
    {
      value: 'A',
      label: 'Active'
    },
    {
      value: 'C',
      label: 'Cancelled'
    },
    {
      value: 'N',
      label: 'New'
    },
    {
      value: 'O',
      label: 'Onhold'
    },
    {
      value: 'F',
      label: 'Fulfilled'
    }
  ];
  const inputs = [
    {
      type: 'multiselect',
      options: filterData(statusOptions, 'label', 'value'),
      multiOptions: statusOptions,
      name: 'status',
      label: 'Status'
    },

    {
      type: 'date-range',
      options: [
        {
          label: 'Start Date',
          name: 'start_date'
        },
        {
          label: 'End Date',
          name: 'end_date'
        }
      ]
    }
  ];

  const submitFilter = form.handleSubmit(params => {
    setPage(1);
    pushQuery(params);
  });

  const pushQuery = params => {
    const searchParamsObject = { ...params };
    delete searchParamsObject.page;
    if (params.search === '') {
      delete searchParamsObject.search;
    }

    if (params.status === '') {
      delete searchParamsObject.status;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search') return;

      if (value === null || value === '') {
        delete searchParamsObject[key];
      } else if (value !== 'all') {
        Object.assign(searchParamsObject, { [key]: value });
      }
    });

    const searchParams = createSearchParams(searchParamsObject).toString();
    navigate({
      pathname: location.pathname,
      search: searchParams
    });

    // Save search params in localStorage
    localStorage.setItem('hris-project-params', searchParams);
  };

  const projectListMemo = useMemo(() => {
    return projectList ?? [];
  }, [projectList]);

  const fetchData = useCallback(async () => {
    try {
      const savedSearchParams = localStorage.getItem('hris-project-params');
      const savedPageIndex = parseInt(
        localStorage.getItem('hris-users-page-no'),
        10
      );

      const params = new URLSearchParams(savedSearchParams);
      const res = await getProjectsServiceList(
        savedPageIndex,
        savedSearchParams
      );

      const modifiedResult = res.data.items.map(item => {
        const project_name = toCapitalize(item.name);
        const project_date = `${item.start_date} - ${item.end_date}`;
        const leader_info = item.project_members.filter(
          member => member.is_project_lead
        );
        let project_leader = null;
        if (leader_info.length) {
          project_leader = `${leader_info[0]?.employee?.employee_info?.first_name} ${leader_info[0]?.employee?.employee_info?.last_name}`;
        }
        const total_members = `${item.project_members.length}`;
        return {
          ...item,
          project_name,
          project_date,
          project_leader,
          total_members
        };
      });
      if (mounted.current) {
        const newList =
          savedPageIndex !== 1
            ? projectList.concat(modifiedResult)
            : modifiedResult;
        setProjectList(newList);
        if (
          res.data.items.length === 0 ||
          res.data.current_page >= res.data.total_pages
        ) {
          setHasMore(false);
        } else {
          setPage(savedPageIndex + 1);
        }
      }
    } catch (error) {
      toast.error('Error fetching data:');
    }
  }, [
    page,
    getProjectsServiceList,
    setProjectList,
    setPage,
    setHasMore,
    projectList
  ]);

  const handleModal = useCallback(
    (modal, id = null) => {
      setPage(1);
      setShowModal(modal);
      setProjectId(modal ? id : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setProjectId]
  );

  const handleViewModal = useCallback(
    (modal, id = null) => {
      setShowViewModal(modal);
      setProjectId(modal ? id : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowModal, setProjectId]
  );

  const handleDeleteModal = useCallback(
    ({ modal, id, project_name }) => {
      setShowDeleteModal(modal);
      setProjectId(modal ? id : null);
      setProjectName(modal ? project_name : null);
      setIsOpen(!!modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowDeleteModal, setProjectId, setProjectName]
  );

  const handleBulkDeleteModal = useCallback(
    (modal, id = null) => {
      setShowBulkDeleteModal(modal);
      // disable scroll when modal is shown
      if (modal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [setShowBulkDeleteModal]
  );

  const toCapitalize = str => {
    return str
      .split(' ')
      .map(name => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');
  };

  useEffect(() => {
    const savedPageIndex = localStorage.getItem('hris-users-page-no');
    mounted.current = true;

    if (savedPageIndex) {
      setPage(parseInt(savedPageIndex, 10));
    }

    submitFilter();
    form.reset({ status: 'A' });
    pushQuery({ status: 'A' });

    return () => {
      // Set mounted to false when the component is unmounted
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!location.search && form.getValues('status')) {
      form.reset({ status: 'A' });
      pushQuery({ status: 'A' });
    }
  }, [location]);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('hris-users-page-no', JSON.stringify(1));
    setHasMore(true);
    fetchData();
  }, [location.search, dataList]);

  useEffect(() => {
    if (isListUpdated) {
      const updatedObjects = updateObjectById(
        projectList,
        isListUpdated.id,
        isListUpdated.payload
      );
      setProjectList(updatedObjects);
      dispatch(setProjectListUpdate(false));
    }
  }, [isListUpdated]);

  useEffect(() => {
    localStorage.setItem('hris-users-page-no', JSON.stringify(page));
  }, [page]);

  return {
    dataList,
    showModal,
    showViewModal,
    showDeleteModal,
    projectId,
    projectName,
    projectList,
    projectListMemo,
    page,
    hasMore,
    inputs,
    form,
    isOpen,
    projectColumns,
    statusOptions,
    showBulkDeleteModal,
    handleModal,
    handleViewModal,
    handleDeleteModal,
    handleBulkDeleteModal,
    fetchData,
    submitFilter
  };
};

export default useProjectsIndex;
