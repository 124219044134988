import * as Yup from 'yup';
import { EMAILREGEX } from 'src/static/regex';

const MAX = 100;
const emailValue = value => {
  return value.indexOf(',') !== -1
    ? value.split(',').map(email => email.trim())
    : value;
};
const blankSpaceRegex = /^\S.*$/;
const blankSpageMessage =
  'Blank spaces are not allowed at the beginning or end';

export const textRequired = () => Yup.string().required('Required');

export const textRequiredWithMax = ($max = MAX) =>
  Yup.string()
    .trim()
    .required('Required')
    .test(
      'is-valid-input',
      'Only letters with spaces, hyphens, or periods are allowed',
      value => {
        // Skip validation if the value is an empty string
        if (value === '' || !value) {
          return true;
        }
        return typeof value === 'string' && /^[^0-9]+$/.test(value.trim());
      }
    )
    .max($max, `Maximum of ${$max} characters allowed`);

export const textStringOnly = ($max = MAX) =>
  Yup.string()
    .test(
      'is-valid-input',
      'Only letters with spaces, hyphens, or periods are allowed',
      value => {
        return typeof value === 'string' && /^[^0-9]+$/.test(value.trim());
      }
    )
    .required('Required')
    .max($max, `Maximum of ${$max} characters allowed`);

export const textWithMax = ($max = MAX) =>
  Yup.string().trim().max($max, `Maximum of ${$max} characters allowed`);

export const textNullableWithMax = ($max = MAX) =>
  Yup.string()
    .nullable()
    .trim()
    .test(
      'is-valid-input',
      'Only letters with spaces, hyphens, or periods are allowed',
      value => {
        // Skip validation if the value is an empty string
        if (value === '' || !value) {
          return true;
        }
        return typeof value === 'string' && /^[^0-9]+$/.test(value.trim());
      }
    )
    .max($max, `Maximum of ${$max} characters allowed`);

export const email = ($max = MAX) =>
  Yup.string()
    .email('Invalid email address')
    .trim()
    .matches(EMAILREGEX, 'Invalid email address')
    .required('Required')
    .max($max, `Maximum of ${$max} characters allowed`)
    .test(
      'is-cody-inc-email',
      'Only @cody.inc email address is allowed',
      function check(value) {
        if (value) {
          return value.endsWith('@cody.inc');
        }
        return true;
      }
    );

export const image = () =>
  Yup.lazy(value => {
    if (value) {
      return Yup.string().required('Required');
    }
    return Yup.mixed()
      .test('fileType', 'Only JPEG and PNG files are allowed', val => {
        if (val) {
          return val && ['image/jpeg', 'image/png'].includes(val.type);
        }
        return true;
      })
      .test('fileSize', 'File size exceeds maximum limit (4mb)', val => {
        if (val) {
          return val && val.size <= 4 * 1024 * 1024; // 4MB
        }
        return true;
      });
  });

export const personalEmail = ($max = MAX) =>
  Yup.string()
    .trim()
    .email('Invalid email address')
    .matches(EMAILREGEX, 'Invalid email address')
    .matches(blankSpaceRegex, blankSpageMessage)
    .max($max, `Maximum of ${$max} characters allowed`);

export const number = () =>
  Yup.number()
    .typeError('Must be a number')
    .test('maxDigits', 'Maximum of 15 digits allowed', value => {
      if (value === null || value === undefined || value === '') {
        return true;
      }
      const stringValue = String(value);
      return stringValue.length <= 15;
    })
    .nullable();

export const numberID = () =>
  Yup.number()
    .typeError('ID must be a number')
    .test('isNumber', 'ID must be a number', value => {
      if (
        value === null ||
        value === undefined ||
        value === '' ||
        value === blankSpaceRegex
      ) {
        return true;
      }
      const stringValue = String(value);
      return stringValue;
    });

const validationYupSchema = {
  textRequired,
  textRequiredWithMax,
  textWithMax,
  textNullableWithMax,
  email,
  image,
  personalEmail,
  number,
  numberID,
  textStringOnly
};

export default validationYupSchema;
