import React from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'src/components/v1/Common/Tab';
import SkillsListEdit from 'src/components/v1/Parts/Skills/SkillsListEdit';
import PageTitle from 'src/components/v1/Common/PageTitle';

const SkillsAndTrainingEdit = () => {
  const { id: userID } = useParams();
  const tabs = [
    {
      title: 'Skills',
      component: <SkillsListEdit />
    },
    {
      title: 'Training',
      component: ''
    }
  ];

  return (
    <div>
      <PageTitle
        title="Skills and Training"
        backButton
        backPath={`/users/${userID}`}
      />
      <div>
        <Tab tabs={tabs} />
      </div>
    </div>
  );
};

export default SkillsAndTrainingEdit;
