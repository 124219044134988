import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Box from 'src/components/Common/Box';
import PageTitle from 'src/components/Common/PageTitle';
import CostCenter from 'src/components/Common/CostCenter';
import Breadcrumbs from 'src/components/Common/Breadcrumbs';
import useKeypressEscape from 'src/hooks/useKeypressEscape';
import { TEMPLATES } from 'src/api/endpoints';
import { createEvaluationTemplate } from 'src/redux/modules/evaluations/templates/templatesActions';

const EvaluationAddTemplate = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const nameRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [costCenterCode, setCostCenterCode] = useState(null);
  const [templateFields, setTemplateFields] = useState({
    template_name: '',
    template_description: '',
    cost_center_code: ''
  });

  useKeypressEscape({
    isEdit: true,
    cancelEdit: () => {
      formRef.current.reset();
      nameRef.current.focus();
      setTemplateFields(prevState => ({
        ...prevState,
        template_name: '',
        template_description: '',
        cost_center_code: ''
      }));
    }
  });

  useEffect(() => nameRef.current.focus(), []);

  useEffect(() => {
    setTemplateFields(prevState => ({
      ...prevState,
      cost_center_code: costCenterCode
    }));
  }, [costCenterCode]);

  const handleCostCenterCode = useCallback(
    data => {
      const { cost_center_code } = data;
      setCostCenterCode(cost_center_code);
    },
    [setCostCenterCode]
  );

  function handleChange({ target: { name, value } }) {
    setTemplateFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = async () => {
    setLoading(true);

    const { data, success } = await dispatch(
      createEvaluationTemplate(TEMPLATES, {
        template_name,
        template_description,
        cost_center_code
      })
    );

    if (success) {
      navigate(`/evaluation-templates/${data?.id}`);
      toast.success('Template successfully created!', {
        autoClose: 1200,
        newestOnTop: true,
        hideProgressBar: true
      });
    } else {
      setLoading(false);
      formRef.current.reset();
      nameRef.current.focus();
      toast.error('Template name already exists! ', {
        autoClose: 1200,
        newestOnTop: true,
        hideProgressBar: true
      });
      setTemplateFields({
        template_name: '',
        template_description: '',
        cost_center_code: ''
      });
    }
  };

  if (!templateFields) return null;
  const { template_name, template_description, cost_center_code } =
    templateFields;
  const isFilled =
    Boolean(template_name) &&
    Boolean(template_description) &&
    Boolean(cost_center_code);

  return (
    <>
      <div className="evaluation__heading evaluation__heading--inner">
        <PageTitle
          title="Add Evaluation Template"
          backButton
          backPath="/evaluation-templates"
        />
      </div>

      <div className="evaluation__breadcrumbs">
        <Breadcrumbs
          crumbs={[
            { name: 'Evaluation' },
            { link: '/evaluation-templates/', name: 'Evaluation Templates' },
            'Add Evaluation Template'
          ]}
        />
      </div>

      <div className="evaluation__container evaluation__container--inner">
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="evaluation__content">
            <div className="evaluation__information">
              <div className="evaluation__row mb0 alignCenter">
                <div className="evaluation__column evaluation__column--two">
                  <h2>Information</h2>
                </div>
                <div className="evaluation__column evaluation__column--two reverse">
                  <button
                    className={`button button__save dark evaluation__save-button${
                      !isFilled ? ' disabled' : ''
                    }`}
                    disabled={!isFilled}
                    type="submit"
                  >
                    <span className="button__text">
                      {loading ? <FaSpinner className="spinner" /> : 'Save'}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="evaluation__information evaluation__information--form">
              <Box>
                <div className="evaluation__row">
                  <div className="evaluation__column evaluation__column--two">
                    <label
                      className="evaluation__label"
                      htmlFor="template_name"
                    >
                      Template Name{' '}
                    </label>
                    <input
                      className="evaluation__input"
                      type="text"
                      name="template_name"
                      id="template_name"
                      ref={nameRef}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="evaluation__column evaluation__column--two">
                    <p className="evaluation__label">Cost Center</p>
                    <CostCenter onChange={handleCostCenterCode} columns={2} />
                  </div>
                </div>
                <div className="evaluation__row">
                  <div className="evaluation__column">
                    <label
                      className="evaluation__label"
                      htmlFor="template_description"
                    >
                      Description
                      <textarea
                        className="evaluation__input evaluation__input--textarea"
                        name="template_description"
                        id="template_description"
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EvaluationAddTemplate;
